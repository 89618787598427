import { Alert, Stack, Text } from '@zorro/zorro-ui-design';

import {
  CompanyDetailsForm,
  useCompanyDetailsForm,
} from '../../CompanyDetailsForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';

type Props = {
  employerId?: string;
  isFinalizationMode?: boolean;
  isDon?: boolean;
};

export const AddEmployerCompanyDetailsStep = ({
  employerId,
  isFinalizationMode = false,
  isDon = false,
}: Props) => {
  const shouldHideSignedAgreement = isDon;
  const { companyDetailsForm, onSubmit } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
    shouldHideSignedAgreement,
  });

  const handleStepSubmit: HandleStepSubmit = async () => {
    const result = await onSubmit();
    if (result) {
      return { navigate: true, employerId: result.employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !companyDetailsForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.COMPANY_DETAILS}
      isSubmitDisabled={isSubmitDisabled}
      onSubmit={handleStepSubmit}
      title="Add company details"
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={isDon}
    >
      {!isDon && (
        <Alert
          variant="light"
          bg="zorroSky.0"
          style={(theme) => ({
            border: `1px solid ${theme.colors.zorroSky[5]}`,
          })}
        >
          <Stack gap="xs" pl="xs">
            <Text fw={600}>Set up a new employer in a few steps </Text>
            <Text fw={400} size="sm" lh="lg">
              1. Enter initial employer information <br />
              2. Invite the employer to review and add missing details <br />
              3. Watch out for a notification to finalize setup once the
              employer completes their part
            </Text>
          </Stack>
        </Alert>
      )}
      <CompanyDetailsForm
        shouldHideSignedAgreement={shouldHideSignedAgreement}
        companyDetailsForm={companyDetailsForm}
        isFinalizationMode={isFinalizationMode}
      />
    </AddEmployerStep>
  );
};
