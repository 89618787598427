import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useMonolithQuery } from '@zorro/shared/utils';
import Decimal from 'decimal.js';
import { useEffect } from 'react';

import { useMonolithMutation } from '../hooks';
import { AllowanceFormFields, allowanceFormSchema } from './AllowanceFormUtils';

type Props = {
  employeeId: string;
  onboardingPeriodId: string;
};

export const useAllowanceForm = ({ employeeId, onboardingPeriodId }: Props) => {
  const { mutate: mutateMajorMedical } = useMonolithMutation({
    method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
  });

  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employeeId, onboardingPeriodId],
  });

  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });

  const majorMedicalPlan = benefits?.majorMedicalBenefit?.plan;
  const premium = majorMedicalPlan?.premium || 0;

  const allowanceForm = useForm<AllowanceFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(allowanceFormSchema),
    defaultValues: {
      allowance:
        onboardingPeriod?.allowance ??
        majorMedicalPlan?.employerMonthlyContribution,
      employeeMonthlyContribution:
        majorMedicalPlan?.employeeMonthlyContribution,
      premium,
    },
  });

  const allowance = allowanceForm.watch('allowance');
  const employerMonthlyContribution = Math.min(allowance, premium);

  useEffect(() => {
    const employeeMonthlyContribution = Math.max(
      new Decimal(premium).sub(employerMonthlyContribution).toNumber(),
      0
    );
    allowanceForm.setValue(
      'employeeMonthlyContribution',
      employeeMonthlyContribution,
      {
        shouldValidate: true,
      }
    );
  }, [employerMonthlyContribution, premium, allowanceForm]);

  return {
    allowanceForm,
    employerMonthlyContribution,
    onSubmit: async () => {
      if (!majorMedicalPlan || !onboardingPeriod) return false;

      const { allowance, employeeMonthlyContribution } =
        allowanceForm.getValues();

      try {
        await mutateMajorMedical([
          onboardingPeriodId,
          true,
          {
            updateManuallyEnteredMajorMedicalPlan: {
              ...majorMedicalPlan,
              employeeMonthlyContribution,
              allowance,
            },
          },
        ]);

        return true;
      } catch {
        /* empty */
      }
      return false;
    },
  };
};
