import { IconAlertTriangle } from '@tabler/icons-react';
import {
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useMurrietaRouter,
} from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Icon,
  Modal,
  Space,
  Table,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import { EmployeeRow } from '../../EmployeesListTable';
import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ModalControlProps, ModalLocation } from '../../ModalControls';

export type DeleteEmployeeRow = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type DeleteEmployeesModalProps = {
  selectedEmployees: DeleteEmployeeRow[];
  modalControlProps: ModalControlProps;
  setSelectedEmployees?: (employees: EmployeeRow[]) => void;
};

export const DeleteEmployeeModal = ({
  selectedEmployees,
  modalControlProps,
  setSelectedEmployees,
}: DeleteEmployeesModalProps) => {
  const { router } = useMurrietaRouter();
  const { onCloseModal, refetchData, reloadPage, modalLocation } =
    modalControlProps;
  const { startLoading, stopLoading } = useLoadingOverlay();
  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  const deleteEmployees = async () => {
    startLoading();

    setErrors([]);

    const employeeBatches = _chunk(selectedEmployees, 5);

    let results: PromiseSettledResult<unknown>[] = [];
    for (const batch of employeeBatches) {
      const batchResults = await Promise.allSettled(
        batch.map(({ id: employeeId }) =>
          callEndpoint({
            method: 'employeesControllerDelete',
            params: [employeeId],
          })
        )
      );

      results = [...results, ...batchResults];
    }

    const resultErrors = getErrorsFromPromises(
      results,
      selectedEmployees.map(({ firstName, lastName }) => ({
        fullName: `${firstName} ${lastName}`,
      })),
      'fullName'
    );

    if (resultErrors.length > 0 && selectedEmployees.length > 1) {
      setErrors(resultErrors);
    }

    if (modalLocation === ModalLocation.SINGLE_EMPLOYEE_PAGE) {
      // if deleted from single employee page - redirect to all employees page
      await router.push(`/employers/${router.query.employerId}/`);
    }
    await handleBatchNotifications(
      results,
      { singular: 'employee' },

      'deleted'
    );

    if (
      modalLocation === ModalLocation.EMPLOYEES_PAGE &&
      setSelectedEmployees &&
      resultErrors.length === 0
    ) {
      await refetchData();
      reloadPage();
    }

    stopLoading();

    if (resultErrors.length === 0) {
      onCloseModal();
    }
  };

  return (
    <Modal
      onClose={() => {
        onCloseModal();
        setErrors([]);
      }}
      size="55rem"
      title={
        <Flex align="center">
          <Icon icon={IconAlertTriangle} color={brand.zorroFire700} size={32} />
          <Text size="xl" ml="sm" fw={500}>
            Confirm deleting employees
          </Text>
        </Flex>
      }
    >
      {errors?.length ? (
        <>
          <Space h="xl" />

          <Grid gutter="xl">
            <Grid.Col>
              <Text c="zorroFire.7" size="md" fw="bold">
                Errors
              </Text>
              <Table
                columns={[
                  {
                    accessor: 'fullName',
                    title: 'Employee name',
                  },
                  { accessor: 'error', title: 'Message' },
                ]}
                records={errors}
              />
            </Grid.Col>
          </Grid>
        </>
      ) : (
        <Box>
          <Text>
            <strong>
              This will permanently delete the following employees. Are you sure
              you want to continue?
            </strong>
          </Text>
          <Space h="sm" />
          <Table
            columns={[
              { accessor: 'fullName' },
              { accessor: 'email' },
              { accessor: 'id' },
            ]}
            records={selectedEmployees}
          />
          <Space h="md" />
          <Center mt="md">
            <Button variant="secondary" onClick={() => onCloseModal()}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await deleteEmployees();
              }}
              bg="zorroFire.7"
              ml="xl"
            >
              Remove
            </Button>
          </Center>
        </Box>
      )}
    </Modal>
  );
};
