import { useAuth } from '@frontegg/nextjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import {
  CreateEmployerDto,
  DocumentType,
  EmployerDto,
  EmployerSignupStatus,
} from '@zorro/clients';
import {
  callEndpoint,
  createEmployerDocumentUrl,
  logger,
  responseErrorToString,
  showErrorNotification,
  showWarningNotification,
  useForm,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { UseFormReturn } from 'react-hook-form';

import {
  CompanyDetailsFormFields,
  getCompanyDetailsSchemaObject,
} from './CompanyDetailsUtils';

type UseCompanyDetailsForm = (props: {
  isFinalizationMode: boolean;
  shouldHideSignedAgreement?: boolean;
  employerId?: string;
  employerStatus?: EmployerSignupStatus;
}) => {
  companyDetailsForm: UseFormReturn<CompanyDetailsFormFields>;
  onSubmit: () => Promise<{ employerId: string } | null>;
};

export const useCompanyDetailsForm: UseCompanyDetailsForm = ({
  employerId,
  isFinalizationMode,
  shouldHideSignedAgreement = false,
  employerStatus = EmployerSignupStatus.DRAFT,
}) => {
  const { isAgent } = useRoles();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: employerId && [employerId],
  });

  const { data: documents = [] } = useMonolithQuery({
    method: 'employerDocumentControllerGetDocuments',
    params: employerId && [employerId],
  });

  const previousSignedAgreement = documents.find(
    ({ isLegalAgreement }) => isLegalAgreement
  );

  const companyDetailsForm = useForm<CompanyDetailsFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(
      getCompanyDetailsSchemaObject(
        isFinalizationMode,
        shouldHideSignedAgreement
      )
    ),
    defaultValues: {
      name: employer?.name || '',
      legalName: employer?.legalName || '',
      isLegalNameSameAsCompanyName:
        employer?.name && employer?.legalName
          ? employer.name === employer.legalName
          : true,
      address: employer?.address || '',
      mailingAddress: employer?.mailingAddress || '',
      isMailingAddressSameAsHQAddress:
        employer?.address && employer?.mailingAddress
          ? employer.address === employer.mailingAddress
          : true,
      businessEntityType: employer?.businessEntityType,
      taxIdType: employer?.taxIdType ?? undefined,
      taxId: employer?.taxId || '',
      logoUrl: employer?.logoUrl || undefined,
      signedAgreement: previousSignedAgreement?.fileName,
    },
  });

  return {
    companyDetailsForm,
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onSubmit: async () => {
      const data = companyDetailsForm.getValues();
      const { signedAgreement } = data;

      const body: CreateEmployerDto = {
        name: data.name,
        legalName: data.isLegalNameSameAsCompanyName
          ? data.name
          : data.legalName,
        address: data.address,
        mailingAddress: data.isMailingAddressSameAsHQAddress
          ? data.address
          : data.mailingAddress,
        taxId: data.taxId,
        taxIdType: data.taxIdType,
        logoUrl: data.logoUrl,
        businessEntityType: data.businessEntityType || undefined,
        signupStatus: employerStatus,
        enrollmentTeamIds: employer?.enrollmentTeamIds || [],
        zorroPartnerId: isAgent
          ? employer?.zorroPartnerId || user?.tenantId || null
          : employer?.zorroPartnerId || null,
        producerId: employer?.producerId || null,
        producerAgentId: employer?.producerAgentId || null,
        isDemo: employer?.isDemo || false,
        ccEmailList: employer?.ccEmailList || [],
        isProspect: false,
      };

      let newEmployer: EmployerDto;
      try {
        newEmployer = employerId
          ? await callEndpoint({
              method: 'employersControllerUpdate',
              params: [employerId, body],
            })
          : await callEndpoint({
              method: 'employersControllerCreate',
              params: [body],
            });
      } catch (error) {
        logger.error(error);
        showErrorNotification({ message: responseErrorToString(error) });

        return null;
      }

      try {
        if (signedAgreement && typeof signedAgreement !== 'string') {
          const { documentUrl, fileName } = await createEmployerDocumentUrl(
            signedAgreement
          );

          if (previousSignedAgreement) {
            await callEndpoint({
              method: 'employerDocumentControllerDeleteDocument',
              params: [newEmployer.id, previousSignedAgreement?.id],
            });
          }

          await callEndpoint({
            method: 'employerDocumentControllerPostDocument',
            params: [
              newEmployer.id,
              {
                type: DocumentType.ADMIN,
                isLegalAgreement: true,
                documentUrl,
                fileName,
              },
            ],
          });
        }

        await queryClient.invalidateQueries();
      } catch (error) {
        logger.error(error);
        showWarningNotification({ message: responseErrorToString(error) });
      }

      return { employerId: newEmployer.id };
    },
  };
};
