import { Grid, Space } from '@zorro/zorro-ui-design';
import { UseFormReturn } from 'react-hook-form';

import { AgentOrEmployee, AgentOrEmployeeInput } from './AgentOrEmployeeInput';
import { PaymentStatusFormFields } from './PaymentStatusUtils';
import { YesNoNotSureInput } from './YesNoNotSureInput';

type Props = {
  paymentStatusForm: UseFormReturn<PaymentStatusFormFields>;
};

export const PaymentStatusForm = ({ paymentStatusForm }: Props) => {
  const { control, watch } = paymentStatusForm;
  const initialPremiumHandler = watch('initialPremiumHandler');
  const autoPaySetter = watch('autoPaySetter');

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <AgentOrEmployeeInput
            name="initialPremiumHandler"
            control={control}
            label="Initial premium (binder) payment status"
            description="Who handles the payment?"
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <YesNoNotSureInput
            name="initialPremiumCompleted"
            control={control}
            description="Was the initial premium (binder) payment completed?"
            shouldShowUnknown={
              initialPremiumHandler === AgentOrEmployee.EMPLOYEE
            }
          />
        </Grid.Col>
      </Grid>
      <Space h="24px" />
      <Grid>
        <Grid.Col span={12}>
          <AgentOrEmployeeInput
            name="autoPaySetter"
            control={control}
            label="Auto-pay status"
            description="Who handles setting up auto-pay?"
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <YesNoNotSureInput
            name="autoPayCompleted"
            control={control}
            description="Has auto-pay been set up?"
            shouldShowUnknown={autoPaySetter === AgentOrEmployee.EMPLOYEE}
          />
        </Grid.Col>
      </Grid>
    </>
  );
};
