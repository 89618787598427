import { AllowanceModelDto } from '@zorro/clients';
import { Anchor, Button } from '@zorro/zorro-ui-design';

import { FileUploadDisplay } from '../FileUploadDisplay/FileUploadDisplay';
import { exportAllowanceModelToCsv } from '../PlanYearSetupForm/PlanYearSetupUtils';
import { UploadedFileBox } from '../UploadedFileBox';

type AllowanceModelInfoProps = {
  allowanceModel: AllowanceModelManagementProps['allowanceModel'];
};

function AllowanceModelInfo({ allowanceModel }: AllowanceModelInfoProps) {
  if (!allowanceModel) {
    return null;
  }

  if (allowanceModel instanceof File) {
    return <FileUploadDisplay fileName={allowanceModel.name} />;
  }

  return (
    <Button
      variant="transparent"
      fw="normal"
      size="sm"
      onClick={() => exportAllowanceModelToCsv(allowanceModel)}
    >
      {`Existing ${allowanceModel.year} allowance model`}
    </Button>
  );
}

type AllowanceModelManagementProps = {
  allowanceModel?: File | AllowanceModelDto;
  onClickDelete: () => void;
};

const AllowanceModelManagement = ({
  allowanceModel,
  onClickDelete,
}: AllowanceModelManagementProps) => {
  if (!allowanceModel) {
    return (
      <Anchor href="/files/sample_allowance_model.csv" download size="sm">
        Download sample allowance model
      </Anchor>
    );
  }

  return (
    <UploadedFileBox onClickDelete={onClickDelete}>
      <AllowanceModelInfo allowanceModel={allowanceModel} />
    </UploadedFileBox>
  );
};

export { AllowanceModelManagement };
