import { yupResolver } from '@hookform/resolvers/yup';
import { InfoIconColored } from '@zorro/shared/assets';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
  useForm,
  validationMessages,
} from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  DateInput,
  Flex,
  FormErrorMessage,
  Grid,
  List,
  Space,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

type TerminateEmployeeDetailsProps = {
  id: string;
  fullName: string;
  terminationDate?: string | null;
  eligibleUntil?: string | null;
};

const terminateEmployeeFormSchema = yup.object({
  terminationDate: yup
    .date()
    .typeError(validationMessages.lastDateOfEmploymentRequired)
    .required(validationMessages.lastDateOfEmploymentRequired),
  eligibleUntil: yup
    .date()
    .typeError(validationMessages.lastDateOfBenefitEligibilityRequired)
    .required(validationMessages.lastDateOfBenefitEligibilityRequired),
});

type TerminateEmployeeFormFields = yup.InferType<
  typeof terminateEmployeeFormSchema
>;

type Props = {
  employee: TerminateEmployeeDetailsProps;
  onSuccess: (terminateEmployeeDetails: TerminateEmployeeDetailsProps) => void;
  onSubmit?: () => void;
  onError?: () => void;
};

export function TerminateEmployeeForm({
  employee,
  onSuccess,
  onSubmit,
  onError,
}: Props) {
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<TerminateEmployeeFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(terminateEmployeeFormSchema),
    defaultValues: {
      terminationDate: employee.terminationDate
        ? parseDateISO(employee.terminationDate).toDate()
        : undefined,
      eligibleUntil: employee.eligibleUntil
        ? parseDateISO(employee.eligibleUntil).toDate()
        : undefined,
    },
  });

  const terminateEmployee = async (formFields: TerminateEmployeeFormFields) => {
    onSubmit?.();
    try {
      const terminationData = {
        terminationDate: formFields.terminationDate
          ? formatDateISO(formFields.terminationDate)
          : null,
        eligibleUntil: formFields.eligibleUntil
          ? formatDateISO(formFields.eligibleUntil)
          : null,
      };

      await callEndpoint({
        method: 'employeesControllerUpdateByAdmin',
        params: [employee.id, terminationData],
      });

      showSuccessNotification({
        message: 'Employee terminated successfully!',
      });
      onSuccess({
        ...employee,
        ...terminationData,
      });
    } catch (error) {
      showErrorNotification({ message: responseErrorToString(error) });
      onError?.();
    }
  };

  return (
    <Stack gap="1rem">
      <Flex
        align="flex-start"
        ta="center"
        gap="0.5rem"
        p="0.75rem 0.75rem 0.75rem 1rem"
        style={(theme) => ({
          border: `1px solid ${theme.colors.zorroGray[6]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <InfoIconColored />
        <Box ta="left">
          <Text size="sm" fw={600}>
            Here’s what to expect:
          </Text>
          <List size="sm" fw={400}>
            <List.Item>
              {`After you terminate an employee on the Zorro platform, the Zorro
                team will cancel the employee’s Zorro payment card.`}
            </List.Item>
            <List.Item>
              {`The terminated employee will be notified with instructions on how
                to set-up payment independently, should they wish to continue
                their coverage.`}
            </List.Item>
          </List>
        </Box>
      </Flex>
      <Space h="0.5rem" />
      <Text
        size="1rem"
        fw={500}
      >{`${employee.fullName}’s termination details`}</Text>
      <form onSubmit={handleSubmit(terminateEmployee)}>
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="terminationDate"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  required
                  label="Last date of employment"
                  data-testid="employee-last-date-of-employment"
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="terminationDate" />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="eligibleUntil"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  required
                  label="Last date of benefit eligibility"
                  data-testid="employee-last-date-of-benefit-elgibility"
                />
              )}
            />
            <FormErrorMessage errors={errors} fieldName="eligibleUntil" />
          </Grid.Col>
        </Grid>
        <Space h="xl" />
        <Center>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            Submit
          </Button>
        </Center>
      </form>
    </Stack>
  );
}
