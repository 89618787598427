/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Market in which the plan is offered (onMarketplace, shop, etc)
 */
export enum PlanMarket {
  ON_EXCHANGE = 'on_exchange',
  ON_MARKET = 'on_market',
  OFF_MARKET = 'off_market',
  BOTH_MARKETS = 'both_markets',
  SHOP = 'shop',
}
