import { TextInput, TextInputProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './TextInput.module.scss';

export type ITextInputProps = Omit<TextInputProps, 'value'> & {
  value?: string;
};

export const ZorroTextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  ({ size = 'md', disabled = false, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        size={size}
        classNames={classNames}
        disabled={disabled}
        {...props}
      />
    );
  }
);

ZorroTextInput.displayName = 'ZorroTextInput';
