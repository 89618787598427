import { useQueryClient } from '@tanstack/react-query';
import { SumIcon } from '@zorro/shared/assets';
import {
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useDynamicRouter,
} from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Modal,
  Space,
  Table,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ModalControlProps } from '../../ModalControls';

type OnboardingPeriodIdsAndNames = {
  onboardingPeriodId: string;
  fullName: string;
};

type SendElectionSummaryModalProps = {
  selectedEmployees: OnboardingPeriodIdsAndNames[];
  modalControlProps: ModalControlProps;
};

export const SendElectionSummaryModal = ({
  selectedEmployees,
  modalControlProps,
}: SendElectionSummaryModalProps) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const { onCloseModal } = modalControlProps;
  const employeeNames = selectedEmployees.map((employee) => employee.fullName);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  async function sendSummaryEmail() {
    try {
      startLoading();

      const employeeBatches = _chunk(
        selectedEmployees.filter((employee) => employee.onboardingPeriodId),
        5
      );

      let results: PromiseSettledResult<unknown>[] = [];
      for (const batch of employeeBatches) {
        const batchResults = await Promise.allSettled(
          batch.map((employee) =>
            callEndpoint({
              method: 'onboardingPeriodsControllerSendSummaryEmail',
              params: [employee.onboardingPeriodId || ''],
            })
          )
        );

        results = [...results, ...batchResults];
      }

      await queryClient.invalidateQueries();
      reloadPage();
      stopLoading();

      await handleBatchNotifications(
        results,
        { singular: 'summary email' },
        'sent'
      );

      const resultErrors = getErrorsFromPromises(
        results,
        selectedEmployees,
        'fullName'
      );

      if (resultErrors.length > 0) {
        setErrors(resultErrors);
      } else {
        onCloseModal();
      }
    } catch {
      /* empty */
    }
  }

  return (
    <Modal
      onClose={() => {
        onCloseModal();
        setErrors([]);
      }}
      size="md"
      title={
        <Flex align="center">
          <SumIcon />
          <Text size="xl" ml="sm" fw={500}>
            Send summary email
          </Text>
        </Flex>
      }
      styles={{
        content: {
          border: `1px solid ${brand.zorroPlum900}`,
        },
      }}
      dividerColor={undefined}
    >
      <Box>
        <Text display="inline">You are about to send a summary email to </Text>
        <Text fw={600} display="inline">
          {employeeNames.join(', ')}.
        </Text>
        <Text fw="inherit">Please confirm.</Text>
        <Space h="xl" />
        <Center mt="md">
          <Button variant="secondary" onClick={onCloseModal} size="lg">
            Cancel
          </Button>
          <Button
            onClick={async () => await sendSummaryEmail()}
            size="lg"
            ml="xl"
          >
            Confirm
          </Button>
        </Center>
      </Box>
      {errors && errors?.length > 0 && (
        <Grid gutter="xl">
          <Grid.Col>
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                {
                  accessor: 'fullName',
                  title: 'Employee name',
                },
                { accessor: 'error', title: 'Message' },
              ]}
              records={errors}
              idAccessor="fullName"
            />
          </Grid.Col>
        </Grid>
      )}
    </Modal>
  );
};
