import { useAuth } from '@frontegg/nextjs';
import {
  IconBook,
  IconBooks,
  IconHeadset,
  IconMail,
  IconMessage,
} from '@tabler/icons-react';
import { Roles } from '@zorro/clients';
import {
  ZORRO_UI_DYNAMIC_ROUTES,
  doesUserHaveAccess,
} from '@zorro/shared/utils';
import {
  Group,
  Icon,
  Menu,
  Text,
  UnstyledButton,
} from '@zorro/zorro-ui-design';
import Link from 'next/link';
import { UrlObject } from 'node:url';
import { ElementType } from 'react';

import { SupportIcon } from './SupportIcon';

enum SupportLinkEnum {
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  REACH_OUT_TO_US = 'REACH_OUT_TO_US',
  EMPLOYEE_KNOWLEDGE_CENTER = 'EMPLOYEE_KNOWLEDGE_CENTER',
  EMPLOYER_KNOWLEDGE_CENTER = 'EMPLOYER_KNOWLEDGE_CENTER',
}

export const supportDialogConfig: {
  [key in SupportLinkEnum]: {
    href: string | UrlObject;
    icon: ElementType;
    label: string;
    testId: string;
    isAdminOnly?: boolean;
  };
} = {
  [SupportLinkEnum.SUPPORT_EMAIL]: {
    icon: IconMail,
    href: 'mailto:support@myzorro.co',
    testId: 'support-email-contact',
    label: 'support@myzorro.co',
  },
  [SupportLinkEnum.REACH_OUT_TO_US]: {
    icon: IconMessage,
    href: 'https://share-eu1.hsforms.com/1b9LlIm_nRpCL2iyyZ2NnKAg5byk',
    testId: 'support-reach-out-to-us',
    label: 'Reach out to us',
  },
  [SupportLinkEnum.EMPLOYEE_KNOWLEDGE_CENTER]: {
    icon: IconBook,
    href: ZORRO_UI_DYNAMIC_ROUTES.EMPLOYEE_KNOWLEDGE_CENTER(),
    testId: 'support-employee-knowledge-center',
    label: 'Employee Knowledge Center',
  },
  [SupportLinkEnum.EMPLOYER_KNOWLEDGE_CENTER]: {
    icon: IconBooks,
    href: ZORRO_UI_DYNAMIC_ROUTES.EMPLOYER_KNOWLEDGE_CENTER(),
    testId: 'support-employer-knowledge-center',
    label: 'Employer Knowledge Center',
    isAdminOnly: true,
  },
};

type SupportLinkProps = {
  supportLink: SupportLinkEnum;
  isEmployerAdmin: boolean;
};

const SupportLink = ({ supportLink, isEmployerAdmin }: SupportLinkProps) => {
  const { icon, href, testId, label, isAdminOnly } =
    supportDialogConfig[supportLink];

  if (isAdminOnly && !isEmployerAdmin) {
    return null;
  }

  return (
    <Menu.Item
      leftSection={<Icon icon={icon} size={24} />}
      data-testid={testId}
    >
      <Link href={href} target="_blank" style={{ textDecoration: 'none' }}>
        <Text fw={500}>{label}</Text>
      </Link>
    </Menu.Item>
  );
};

export const SupportDialog = () => {
  const { user } = useAuth();

  const isEmployerAdmin = doesUserHaveAccess(user, [Roles.EMPLOYER_ADMIN]);

  return (
    <Menu width={290} closeOnItemClick={false} data-testid="support-dialog">
      <Menu.Target>
        <UnstyledButton
          mr={32}
          style={{ maxHeight: 48 }}
          aria-label="Expand support dialog tooltip"
          className="excluded-from-print"
        >
          <SupportIcon />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <Group gap="xs">
            <Icon icon={IconHeadset} size={24} />
            <Text fw={500}>Contact</Text>
          </Group>
        </Menu.Label>
        <Menu.Divider />
        {Object.values(SupportLinkEnum).map((supportItem) => (
          <SupportLink
            key={supportItem}
            supportLink={supportItem}
            isEmployerAdmin={isEmployerAdmin}
          />
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
