import { IconInfoCircle } from '@tabler/icons-react';
import { Alert, Box, Icon, Text, brand } from '@zorro/zorro-ui-design';

import { addOrEditAlertBoxText } from './ChangeEnrollmentUtils';

export const ChangeEnrollmentAlertBox = ({
  isSingleEmployee,
}: {
  isSingleEmployee: boolean;
}) => {
  return (
    <Alert
      variant="light"
      bg={brand.zorroGray900}
      style={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: brand.zorroGray200,
        marginBottom: '16px',
      }}
    >
      <Box style={{ display: 'inline-flex' }}>
        <Icon
          icon={IconInfoCircle}
          size="20px"
          style={{
            flexShrink: 0,
            marginRight: '12px',
            marginTop: '4px',
          }}
        />
        <Text size="md" c={brand.zorroGray400}>
          {addOrEditAlertBoxText(isSingleEmployee)}
        </Text>
      </Box>
    </Alert>
  );
};
