import { IconInfoCircle } from '@tabler/icons-react';
import {
  QualifyingLifeEventDto,
  QualifyingLifeEventType,
} from '@zorro/clients';
import { parseDateISOThenFormatEnLocale } from '@zorro/shared/formatters';
import { qualifyingLifeEventLabelConfig } from '@zorro/types';
import {
  Box,
  Center,
  Group,
  Icon,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';

type Props = {
  qualifyingLifeEvent?: QualifyingLifeEventDto | null;
};

export const QualifyingLifeEventLabel = ({ qualifyingLifeEvent }: Props) => {
  if (!qualifyingLifeEvent) {
    return null;
  }

  const label = qualifyingLifeEventLabelConfig[qualifyingLifeEvent.type];
  const date = parseDateISOThenFormatEnLocale(qualifyingLifeEvent.occurredOn);
  const isOtherReason =
    qualifyingLifeEvent.type === QualifyingLifeEventType.OTHER;

  return (
    <Group gap="xs">
      <Text fw={600}>QLE details:</Text>
      <Group gap="0.25rem" align="center" justify="center">
        <Text>{label}</Text>
        {isOtherReason && (
          <Tooltip
            inline
            label={
              <Text fw={600} size="sm" c="zorroWhite.0">
                Employee&apos;s description:
                <Text fw={400} c="zorroWhite.0">
                  {qualifyingLifeEvent.otherReason}
                </Text>
              </Text>
            }
          >
            <Box pos="relative">
              <Center>
                <Icon icon={IconInfoCircle} size="1.25rem" />
              </Center>
            </Box>
          </Tooltip>
        )}
        , {date}
      </Group>
    </Group>
  );
};
