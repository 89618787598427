import {
  EMAILS_SENT_SUCCESSFULLY_MESSAGE,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
} from '@zorro/shared/utils';

// noinspection ES6PreferShortImport
import { useLoadingOverlay } from '../LoadingOverlayContext';

export function useSendElectionReminderEmails() {
  const { startLoading, stopLoading } = useLoadingOverlay();

  return {
    sendElectionReminderEmails: async (onboardingPeriodsIds: string[]) => {
      startLoading();
      try {
        await Promise.all(
          onboardingPeriodsIds.map(async (onboardingPeriodId) =>
            callEndpoint({
              method: 'onboardingPeriodsControllerSendElectionReminder',
              params: [onboardingPeriodId],
            })
          )
        );

        showSuccessNotification({ message: EMAILS_SENT_SUCCESSFULLY_MESSAGE });
      } catch (error) {
        showErrorNotification({ message: responseErrorToString(error) });
      } finally {
        stopLoading();
      }
    },
  };
}
