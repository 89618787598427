import { EmployeeDto, Gender, InsuredPersonDto } from '@zorro/clients';
import {
  earliestAllowedNonDependentBirthDate,
  latestAllowedNonDependentBirthDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  convertToYesNoEnum,
  ssnRegex,
  validationMessages,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import * as yup from 'yup';
import { InferType } from 'yup';

export const getInsuredBaseSchema = (isFinalizationMode: boolean) =>
  yup.object({
    firstName: yup
      .string()
      .required(validationMessages.firstNameRequired)
      .min(2, validationMessages.firstNameMinTwoLetters),
    lastName: yup
      .string()
      .required(validationMessages.lastNameRequired)
      .min(2, validationMessages.lastNameMinTwoLetters),
    dateOfBirth: yup
      .date()
      .min(
        latestAllowedNonDependentBirthDate(),
        validationMessages.nonDependentMaxAgeValid
      )
      .max(
        earliestAllowedNonDependentBirthDate(),
        validationMessages.nonDependentMinAgeValid
      )
      .typeError(validationMessages.dateOfBirthRequired)
      .required(validationMessages.dateOfBirthRequired),
    ...(isFinalizationMode
      ? {
          residentialAddress: yup
            .string()
            .required(validationMessages.residentialAddressRequired),
          ssn: yup
            .string()
            .required(validationMessages.ssnRequired)
            .matches(ssnRegex, validationMessages.ssnNumberValid),
          gender: yup
            .mixed<Gender>()
            .oneOf(Object.values(Gender), validationMessages.genderRequired)
            .required(validationMessages.genderRequired),
          isSmoker: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), validationMessages.smokerRequired)
            .required(validationMessages.smokerRequired),
          isPregnant: yup.mixed<YesNo>().when('gender', {
            is: Gender.FEMALE,
            then: (schema) =>
              schema
                .oneOf(
                  Object.values(YesNo),
                  validationMessages.pregnantRequired
                )
                .required(validationMessages.pregnantRequired),
            otherwise: (schema) => schema.notRequired(),
          }),
        }
      : {
          residentialAddress: yup.string().optional(),
          ssn: yup.string().matches(ssnRegex, {
            message: validationMessages.ssnNumberValid,
            excludeEmptyString: true,
          }),
          gender: yup
            .mixed<Gender>()
            .oneOf(Object.values(Gender), validationMessages.genderRequired)
            .nullable(),
          isSmoker: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), validationMessages.smokerRequired)
            .nullable(),
          isPregnant: yup
            .mixed<YesNo>()
            .oneOf(Object.values(YesNo), validationMessages.pregnantRequired)
            .nullable(),
        }),
  });

type BaseInsuredFormFields = InferType<ReturnType<typeof getInsuredBaseSchema>>;

export const getDefaultInsuredFormFields = (
  residentialAddress?: InsuredPersonDto['residentialAddress']
): Omit<BaseInsuredFormFields, 'dateOfBirth'> => {
  return {
    residentialAddress: residentialAddress ?? '',
    firstName: '',
    lastName: '',
    gender: null,
    ssn: '',
    isPregnant: YesNo.NO,
    isSmoker: YesNo.NO,
  };
};

export const mapInsuredDtoToBaseInsuredFormFields = (
  insuredDto: InsuredPersonDto | EmployeeDto
): BaseInsuredFormFields => {
  return {
    firstName: insuredDto.firstName,
    lastName: insuredDto.lastName,
    dateOfBirth: parseDateISO(insuredDto.dateOfBirth).toDate(),
    gender: insuredDto?.gender || Gender.MALE,
    ssn: ('ssn' in insuredDto && insuredDto?.ssn) || '',
    residentialAddress:
      ('residentialAddress' in insuredDto && insuredDto?.residentialAddress) ||
      '',
    isPregnant:
      'isPregnant' in insuredDto && insuredDto?.isPregnant
        ? convertToYesNoEnum(insuredDto.isPregnant)
        : YesNo.NO,
    isSmoker:
      'isSmoker' in insuredDto && insuredDto?.isSmoker
        ? convertToYesNoEnum(insuredDto.isSmoker)
        : YesNo.NO,
  };
};
