import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  BenefitDocumentDto,
  BenefitDocumentType,
  EmployeeDto,
  OnboardingPeriodDto,
} from '@zorro/clients';
import {
  formatDateISO,
  getDateUtil,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  callEndpoint,
  createBenefitDocument,
  downloadBenefitDocument,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  Button,
  Drawer,
  FormErrorMessage,
  Group,
  Icon,
  Text,
} from '@zorro/zorro-ui-design';
import { Control, Controller } from 'react-hook-form';
import * as yup from 'yup';

import { CoveragePeriodInput } from '../CoveragePeriodInput';
import { FileUploadDisplay } from '../FileUploadDisplay';
import { FileUploader } from '../FileUploader';
import { FormFooter } from '../FormFooter';
import { useLoadingOverlay } from '../LoadingOverlayContext';
import { UploadedFileBox } from '../UploadedFileBox';
import { useMonolithMutation } from '../hooks';

const getDefaultWaiveEffectiveDates = (
  onboardingPeriod: OnboardingPeriodDto,
  employee: EmployeeDto | undefined
): [Date | null, Date | null] => {
  if (!onboardingPeriod) {
    return [null, null];
  }

  return [
    parseDateISO(onboardingPeriod.targetEnrollmentDate).toDate(),
    employee?.eligibleUntil
      ? getDateUtil()
          .min(
            parseDateISO(onboardingPeriod.enrollmentEffectiveUntil),
            parseDateISO(employee.eligibleUntil)
          )
          .toDate()
      : parseDateISO(onboardingPeriod.enrollmentEffectiveUntil).toDate(),
  ];
};

const waiveCoverageSchema = yup.object({
  effectiveDates: yup
    .tuple([yup.date().required().nullable(), yup.date().required().nullable()])
    .required(),
  waiveEvidence: yup.mixed<File | BenefitDocumentDto>().nullable(),
});

type WaiveCoverageFormFields = yup.InferType<typeof waiveCoverageSchema>;

type Props = {
  onboardingPeriodId: string;
  employeeId: string;
  onClose: () => void;
  isOpen: boolean;
};

export const WaiveCoverageDrawer = ({
  isOpen,
  onClose,
  employeeId,
  onboardingPeriodId,
}: Props) => {
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { mutate: mutateWaiveCoverage } = useMonolithMutation({
    method: 'benefitsControllerWaiveCoverage',
    successMessage: 'Waiver successfully submitted',
    shouldShowLoadingOverlay: false,
  });

  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employeeId, onboardingPeriodId],
  });
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });

  const benefitEnrollment = benefits?.majorMedicalBenefit?.benefitEnrollment;
  const allBenefitDocuments = onboardingPeriod?.benefitDocuments ?? [];
  const previousWaiveEvidence = allBenefitDocuments.find(
    ({ type }) => type === BenefitDocumentType.WAIVE
  );

  const { control, getValues, setValue, reset, watch, formState } =
    useForm<WaiveCoverageFormFields>({
      mode: 'onBlur',
      defaultValues: {
        effectiveDates: benefitEnrollment
          ? [
              parseDateISO(benefitEnrollment.effectiveFrom).toDate(),
              parseDateISO(benefitEnrollment.effectiveUntil).toDate(),
            ]
          : onboardingPeriod
          ? getDefaultWaiveEffectiveDates(onboardingPeriod, employee)
          : undefined,
        waiveEvidence: previousWaiveEvidence || null,
      },
      resolver: yupResolver(waiveCoverageSchema),
    });

  const { isValid, errors } = formState;

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmit = async () => {
    try {
      const { waiveEvidence, effectiveDates } = getValues();
      const [effectiveFrom, effectiveUntil] = effectiveDates || [];

      if (!effectiveFrom || !effectiveUntil) {
        return;
      }
      startLoading();

      const newWaiveEvidence =
        waiveEvidence && waiveEvidence instanceof File
          ? await createBenefitDocument(
              waiveEvidence,
              onboardingPeriodId,
              false,
              BenefitDocumentType.WAIVE
            )
          : undefined;

      await Promise.all(
        allBenefitDocuments
          .filter(({ type }) => type === BenefitDocumentType.WAIVE)
          .map((document) =>
            callEndpoint({
              method: 'benefitDocumentsControllerRemoveBenefitDocument',
              params: [document.id],
            })
          )
      );
      if (newWaiveEvidence) {
        await callEndpoint({
          method: 'benefitDocumentsControllerCreateBenefitDocument',
          params: [newWaiveEvidence],
        });
      }

      await mutateWaiveCoverage([
        {
          employeeId,
          onboardingPeriodId,
          effectiveDates: {
            from: formatDateISO(effectiveFrom),
            until: formatDateISO(effectiveUntil),
          },
        },
      ]);
    } catch {
      /* empty */
    } finally {
      stopLoading();
      handleClose();
    }
  };

  const waiveEvidenceField = watch('waiveEvidence');

  return (
    <Drawer onClose={handleClose} opened={isOpen} title="Waive coverage">
      <Stack gap="xl">
        <Text c="zorroGray.4">Confirm coverage waiver details</Text>

        <Controller
          control={control}
          name="effectiveDates"
          render={({ field: { ...rest } }) => (
            <CoveragePeriodInput
              {...rest}
              label="Confirm waiver effective dates"
              onboardingPeriodId={onboardingPeriodId}
              employeeId={employeeId}
              required
            />
          )}
        />
        {!waiveEvidenceField && (
          <FileUploader
            control={control as unknown as Control}
            name="waiveEvidence"
            label="Waiver document"
            isLoading={false}
            onDrop={(files: File[], onBlur, onChange) => {
              onChange(files[0]);
              onBlur();
            }}
            buttonText="Upload document"
            zoneLabelText="Upload a document or screenshot as evidence of the waiver"
            isDisabled={Boolean(waiveEvidenceField)}
          />
        )}
        {waiveEvidenceField && (
          <UploadedFileBox
            onClickDelete={() =>
              setValue('waiveEvidence', null, { shouldValidate: true })
            }
          >
            {waiveEvidenceField instanceof File ? (
              <FileUploadDisplay
                fileName={`Confirmation file: ${waiveEvidenceField.name}`}
              />
            ) : (
              <Button
                variant="transparent"
                fw="normal"
                px={0}
                size="sm"
                onClick={() =>
                  downloadBenefitDocument(
                    waiveEvidenceField.documentUrl,
                    waiveEvidenceField.fileName
                  )
                }
              >
                Existing evidence: {waiveEvidenceField.fileName}
              </Button>
            )}
          </UploadedFileBox>
        )}

        <FormErrorMessage fieldName="waiveEvidence" errors={errors} />

        <Group wrap="nowrap" gap="xxs" align="flex-end">
          <Icon
            style={{ marginTop: '5px' }}
            icon={IconInfoCircle}
            hanging={34}
            width={34}
          />

          <Text>
            Please make sure to collect evidence of the employee’s waive
            election confirmation
          </Text>
        </Group>

        <FormFooter
          primaryLabel="Submit waiver"
          primaryButtonProps={{
            onClick: handleSubmit,
            disabled: !isValid,
          }}
          secondaryLabel="Cancel"
          secondaryButtonProps={{ onClick: handleClose }}
        />
      </Stack>
    </Drawer>
  );
};
