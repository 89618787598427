import { yupResolver } from '@hookform/resolvers/yup';
import { DatesRangeValue } from '@mantine/dates';
import { LeaveOfAbsenceDto, PaymentBy } from '@zorro/clients';
import { InfoIconColored } from '@zorro/shared/assets';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import { showErrorNotification, useForm } from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  DateRangeInput,
  Flex,
  FormErrorMessage,
  Group,
  List,
  Radio,
  RadioGroup,
  Space,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { useMonolithMutation } from '../../hooks/useMonolithMutation';

type LeaveOfAbsenceProps = LeaveOfAbsenceDto;

interface LeaveOfAbsenceFormFields {
  leaveDates?: DatesRangeValue;
  paymentBy?: PaymentBy;
}

const leaveOfAbsenceFormSchema = yup.object({
  leaveDates: yup.array().of(yup.date()).length(2).required(),
  paymentBy: yup.string().required(),
});

type Props = {
  leaveOfAbsence?: LeaveOfAbsenceProps;
  employeeId: string;
  onSuccess: (leaveOfAbsence: LeaveOfAbsenceProps) => void;
  onSubmit?: () => void;
  onError?: () => void;
};

export function EmployeeLeaveOfAbsenceForm({
  leaveOfAbsence,
  onSuccess,
  employeeId,
  onSubmit,
  onError,
}: Props) {
  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting, errors },
    watch,
  } = useForm<LeaveOfAbsenceFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(leaveOfAbsenceFormSchema),
    values: {
      leaveDates:
        leaveOfAbsence?.startDate && leaveOfAbsence.endDate
          ? [
              parseDateISO(leaveOfAbsence.startDate).toDate(),
              parseDateISO(leaveOfAbsence.endDate).toDate(),
            ]
          : undefined,
      paymentBy: leaveOfAbsence?.paymentBy || undefined,
    },
  });
  const data = watch();

  const { mutate: updateByAdmin } = useMonolithMutation({
    method: 'employeesControllerUpdateByAdmin',
    successMessage: 'Leave of absence set successfully!',
  });

  const setLeaveOfAbsence = async (
    employeeId: string,
    formFields: LeaveOfAbsenceFormFields
  ) => {
    try {
      onSubmit?.();

      const leaveOfAbsence: LeaveOfAbsenceProps = {
        startDate: formFields.leaveDates?.[0]
          ? formatDateISO(formFields.leaveDates?.[0])
          : '',
        endDate: formFields.leaveDates?.[1]
          ? formatDateISO(formFields.leaveDates?.[1])
          : '',
        paymentBy: formFields.paymentBy,
      };

      await updateByAdmin([
        employeeId,
        {
          leaveOfAbsence,
        },
      ]);

      onSuccess(leaveOfAbsence);
    } catch {
      showErrorNotification();
      onError?.();
    }
  };

  return (
    <Stack gap="1rem">
      <Flex
        align="flex-start"
        ta="center"
        gap="0.5rem"
        p="0.75rem 0.75rem 0.75rem 1rem"
        style={(theme) => ({
          border: `1px solid ${theme.colors.zorroGray[6]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <InfoIconColored />
        <Box ta="left">
          <Text size="sm" fw={600}>
            Here’s what to expect:
          </Text>
          <List size="sm" fw={400}>
            <List.Item>
              After you set a leave of absence, the Zorro team will temporarily
              suspend the employee’s allowance in the system.
            </List.Item>
            <List.Item>
              The employee will remain enrolled in their plan. Depending on the
              payment set-up during the leave, they may need to contact the
              carrier to replace the Zorro card with another form of payment.
            </List.Item>
          </List>
        </Box>
      </Flex>
      <form onSubmit={handleSubmit(() => setLeaveOfAbsence(employeeId, data))}>
        <Stack>
          <Controller
            control={control}
            name="leaveDates"
            render={({ field: { ref: _ref, ...rest } }) => (
              <DateRangeInput
                {...rest}
                required
                label="Leave dates"
                data-testid="employee-leave-of-absence"
                w="20rem"
              />
            )}
          />

          <Controller
            control={control}
            name="paymentBy"
            render={({
              field: { ref: _ref, ...rest },
              fieldState: { error },
            }) => (
              <RadioGroup
                {...rest}
                label="How would you like to manage premium payments during the leave?"
                required
                styles={(theme) => ({
                  label: { fontSize: theme.fontSizes.md },
                })}
              >
                <Group>
                  <Radio
                    mt="sm"
                    value={PaymentBy.EMPLOYEE}
                    label="Let the employee handle the payments"
                    shouldHaveBorder={false}
                  />
                  <Radio
                    value={PaymentBy.EMPLOYER}
                    label="The employer will continue to pay and collect any owed amounts"
                    shouldHaveBorder={false}
                  />
                </Group>
              </RadioGroup>
            )}
          />
          <FormErrorMessage errors={errors} fieldName="paymentBy" />
        </Stack>
        <Space h="xl" />
        <Center>
          <Button type="submit" disabled={!isValid || isSubmitting}>
            Submit
          </Button>
        </Center>
      </form>
    </Stack>
  );
}
