export const EmployeeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M5.66675 17.5001V15.8335C5.66675 14.9494 6.01794 14.1016 6.64306 13.4764C7.26818 12.8513 8.11603 12.5001 9.00008 12.5001H12.3334C13.2175 12.5001 14.0653 12.8513 14.6904 13.4764C15.3156 14.1016 15.6667 14.9494 15.6667 15.8335V17.5001M7.33341 5.83346C7.33341 6.71751 7.6846 7.56536 8.30973 8.19048C8.93485 8.8156 9.78269 9.16679 10.6667 9.16679C11.5508 9.16679 12.3986 8.8156 13.0238 8.19048C13.6489 7.56536 14.0001 6.71751 14.0001 5.83346C14.0001 4.9494 13.6489 4.10155 13.0238 3.47643C12.3986 2.85131 11.5508 2.50012 10.6667 2.50012C9.78269 2.50012 8.93485 2.85131 8.30973 3.47643C7.6846 4.10155 7.33341 4.9494 7.33341 5.83346Z"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
