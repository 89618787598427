export const CancelIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="4" fill="#CCF2FB" />
      <rect width="32" height="32" rx="4" fill="#FFBBAF" />
      <path
        d="M14.3333 14.3333L17.6667 17.6667M17.6667 14.3333L14.3333 17.6667M8.5 16C8.5 16.9849 8.69399 17.9602 9.0709 18.8701C9.44781 19.7801 10.0003 20.6069 10.6967 21.3033C11.3931 21.9997 12.2199 22.5522 13.1299 22.9291C14.0398 23.306 15.0151 23.5 16 23.5C16.9849 23.5 17.9602 23.306 18.8701 22.9291C19.7801 22.5522 20.6069 21.9997 21.3033 21.3033C21.9997 20.6069 22.5522 19.7801 22.9291 18.8701C23.306 17.9602 23.5 16.9849 23.5 16C23.5 15.0151 23.306 14.0398 22.9291 13.1299C22.5522 12.2199 21.9997 11.3931 21.3033 10.6967C20.6069 10.0003 19.7801 9.44781 18.8701 9.0709C17.9602 8.69399 16.9849 8.5 16 8.5C15.0151 8.5 14.0398 8.69399 13.1299 9.0709C12.2199 9.44781 11.3931 10.0003 10.6967 10.6967C10.0003 11.3931 9.44781 12.2199 9.0709 13.1299C8.69399 14.0398 8.5 15.0151 8.5 16Z"
        stroke="#1A1721"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
