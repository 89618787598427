import { CountryCode } from 'libphonenumber-js';
import { ForwardedRef, forwardRef } from 'react';
import {
  DefaultInputComponentProps,
  FeatureProps,
} from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';

import { ZorroTextInput } from '../TextInput';
import classNames from './PhoneInput.module.scss';

type IPhoneInputProps = FeatureProps<DefaultInputComponentProps> & {
  placeholder?: string;
  country?: CountryCode;
  label?: string;
  name?: string;
  // eslint-disable-next-line react/boolean-prop-naming
  required?: boolean;
};

export const ZorroPhoneInput = forwardRef(
  (
    {
      country = 'US',
      label: labelProp = 'Phone',
      placeholder = 'Phone',
      size = 'md',
      name = 'phoneInput',
      control,
      required,
      ...props
    }: IPhoneInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const label = required ? `${labelProp}*` : labelProp;

    return (
      <PhoneInput
        name={name}
        control={control}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent={ZorroTextInput as any}
        placeholder={placeholder}
        classNames={classNames}
        country={country}
        label={label}
        size={size}
        ref={ref}
        {...props}
      />
    );
  }
);

ZorroPhoneInput.displayName = 'PhoneInput';
