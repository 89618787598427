import { FileButton, Grid } from '@mantine/core';
import {
  BenefitDocumentDto,
  BenefitDocumentType,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { AddIcon } from '@zorro/shared/assets';
import {
  NO_FILE_SELECTED_ERROR_MESSAGE,
  createBenefitDocument,
  showErrorNotification,
  useImpersonation,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Button, Flex, Space, Title, brand } from '@zorro/zorro-ui-design';
import { Fragment, useMemo, useState } from 'react';

import { DeleteDocumentConfirmationModal } from '../Documents';
import { useLoadingOverlay } from '../LoadingOverlayContext';
import { useMonolithMutation } from '../hooks';
import { BenefitDocument } from './BenefitDocument';
import { EmptyFileSection } from './EmptyFileSection';

type Props = {
  onboardingPeriodId: OnboardingPeriodDto['id'];
  employeeFullName: string;
};

export const DocumentsTabContent = ({
  onboardingPeriodId,
  employeeFullName,
}: Props) => {
  const { isAgent, isZorroOperations } = useRoles();
  const { impersonatedEmployeeDetails } = useImpersonation();
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [isDeleteDocumentsModalOpen, setIsDeleteDocumentsModalOpen] =
    useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<BenefitDocumentDto>();

  const isDon =
    Boolean(impersonatedEmployeeDetails) || !(isAgent || isZorroOperations);

  const { data: documents } = useMonolithQuery({
    method: 'benefitDocumentsControllerGetBenefitDocumentsByOnboardingPeriod',
    params: [onboardingPeriodId],
  });
  const { mutate: createDocument } = useMonolithMutation({
    method: 'benefitDocumentsControllerCreateBenefitDocument',
    successMessage: 'Document uploaded',
  });
  const { mutate: removeDocument } = useMonolithMutation({
    method: 'benefitDocumentsControllerRemoveBenefitDocument',
    successMessage: 'Document deleted',
  });

  const uploadDocument = async (
    file: File | null,
    isSharedWithEmployee: boolean
  ) => {
    startLoading();
    try {
      if (!file) {
        showErrorNotification({ message: NO_FILE_SELECTED_ERROR_MESSAGE });
        return;
      }

      const document = await createBenefitDocument(
        file,
        onboardingPeriodId,
        isSharedWithEmployee,
        BenefitDocumentType.OTHER
      );

      await createDocument([document]);
    } catch {
      /* empty */
    } finally {
      stopLoading();
    }
  };

  const [adminDocuments, planDocuments] = useMemo(() => {
    const adminDocs: BenefitDocumentDto[] = [];
    const planDocs: BenefitDocumentDto[] = [];

    documents?.forEach((document) => {
      document.isSharedWithEmployee
        ? planDocs.push(document)
        : adminDocs.push(document);
    });

    return [adminDocs, planDocs];
  }, [documents]);

  const deleteDocument = async () => {
    if (selectedDocument) {
      await removeDocument([selectedDocument.id]);
    }
    setIsDeleteDocumentsModalOpen(false);
    setSelectedDocument(undefined);
  };

  return (
    <>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Flex justify="space-between">
            <Title order={4} fw={600}>
              Admin documents{' '}
              <span style={{ color: brand.zorroGray400, fontWeight: 400 }}>
                (not shared with {employeeFullName})
              </span>
            </Title>
            {!isDon && (
              <FileButton
                onChange={(file: File | null) => uploadDocument(file, false)}
              >
                {(props) => (
                  <Button {...props} variant="subtle" p={0}>
                    <AddIcon style={{ height: 28, width: 28 }} />
                  </Button>
                )}
              </FileButton>
            )}
          </Flex>
          <Space h="xs" />
          {adminDocuments.length === 0 ? (
            <EmptyFileSection
              isDon={isDon}
              isSharedWithEmployee={false}
              onboardingPeriodId={onboardingPeriodId}
            />
          ) : (
            adminDocuments.map((adminDocument) => (
              <Fragment key={adminDocument.id}>
                <BenefitDocument
                  document={adminDocument}
                  onDelete={() => {
                    setSelectedDocument(adminDocument);
                    setIsDeleteDocumentsModalOpen(true);
                  }}
                />
                <Space h="xs" />
              </Fragment>
            ))
          )}
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col mt="2rem" span={{ sm: 6 }}>
          <Flex justify="space-between">
            <Title order={4} fw={600}>
              Employee documents{' '}
              <span style={{ color: brand.zorroGray400, fontWeight: 400 }}>
                (shared with {employeeFullName})
              </span>
            </Title>
            {!isDon && (
              <FileButton
                onChange={(file: File | null) => uploadDocument(file, true)}
              >
                {(props) => (
                  <Button {...props} variant="subtle" p={0}>
                    <AddIcon style={{ height: 28, width: 28 }} />
                  </Button>
                )}
              </FileButton>
            )}
          </Flex>
          <Space h="xs" />
          {planDocuments.length === 0 ? (
            <EmptyFileSection
              isDon={isDon}
              isSharedWithEmployee
              onboardingPeriodId={onboardingPeriodId}
            />
          ) : (
            planDocuments.map((planDocument) => (
              <Fragment key={planDocument.id}>
                <BenefitDocument
                  document={planDocument}
                  onDelete={() => {
                    setSelectedDocument(planDocument);
                    setIsDeleteDocumentsModalOpen(true);
                  }}
                />
                <Space h="xs" />
              </Fragment>
            ))
          )}
        </Grid.Col>
      </Grid>

      <DeleteDocumentConfirmationModal
        isModalOpen={isDeleteDocumentsModalOpen}
        documentName={selectedDocument?.fileName ?? null}
        setIsModalOpen={setIsDeleteDocumentsModalOpen}
        performDeleteAction={deleteDocument}
      />
    </>
  );
};
