import { yupResolver } from '@hookform/resolvers/yup';
import { useQueries } from '@tanstack/react-query';
import {
  OnboardingType,
  OpenEnrollmentPeriodDto,
  SubmissionType,
} from '@zorro/clients';
import {
  formatDateISO,
  parseDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import {
  UNEXPECTED_ERROR_MESSAGE,
  callEndpoint,
  maximalDateISO,
  minimalDateISO,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  Box,
  Button,
  Center,
  DateInput,
  DateRangeInput,
  Drawer,
  Group,
  Table,
  Text,
} from '@zorro/zorro-ui-design';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { useBatchCallEndpoint } from '../../hooks';
import { ChangeEnrollmentAlertBox } from './ChangeEnrollmentAlertBox';
import {
  ChangeElectionWindowFormFields,
  ChangeElectionWindowSchemaObject,
  ChangeEnrollmentsFormFields,
  EditEnrollmentDrawerProps,
  formFieldStyle,
  getSharedEnrollmentType,
  handleFormStateChange,
  inlineGroupStyle,
} from './ChangeEnrollmentUtils';

interface ChangeElectionWindowDrawerProps
  extends Omit<EditEnrollmentDrawerProps, 'employerId'> {
  planYearId: OpenEnrollmentPeriodDto['id'];
}

export const ChangeElectionWindowDrawer = ({
  selectedEmployees,
  isOpen,
  closeModal,
  planYearId,
}: ChangeElectionWindowDrawerProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;

  const { data: openEnrollmentDto } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFind',
    params: [planYearId],
    enabled: isOpen,
  });

  const { data: associatedOnboardingPeriodsSingleEmployee } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isOpen && isSingleEmployee,
  });

  const associatedOtherOnboardingPeriodsSingleEmployee =
    associatedOnboardingPeriodsSingleEmployee?.filter(
      (onboardingPeriod) =>
        onboardingPeriod.id !== selectedEmployees[0].onboardingPeriodId
    );

  const allEditedOnboardingPeriodsMultipleEmployees = useQueries({
    queries: selectedEmployees.map((employee) => ({
      queryKey: ['onboardingPeriodsControllerFindMany', [employee.employeeId]],
      queryFn: async () =>
        callEndpoint({
          method: 'onboardingPeriodsControllerFindOne',
          params: [employee.employeeId, employee.onboardingPeriodId],
        }),
      enabled: isOpen,
    })),
  }).flatMap((query) => query.data ?? []);

  const singleEmployeeOnboardingPeriodForDefaultValues = isSingleEmployee
    ? allEditedOnboardingPeriodsMultipleEmployees[0]
    : undefined;

  const { data: singleEmployeeDto } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isOpen && isSingleEmployee,
  });

  const {
    executeBatchCall: updateOnboardingPeriods,
    errors,
    setErrors,
  } = useBatchCallEndpoint({
    methodName: 'onboardingPeriodsControllerUpdateByAdmin',
    singularItemName: 'Election window',
    action: 'updated',
  });

  const sharedEnrollmentType = getSharedEnrollmentType(
    allEditedOnboardingPeriodsMultipleEmployees
  );

  const editModeSingleEmployeeDefaultValues:
    | ChangeElectionWindowFormFields
    | undefined =
    isSingleEmployee && singleEmployeeOnboardingPeriodForDefaultValues
      ? {
          electionWindow: [
            parseDateISO(
              singleEmployeeOnboardingPeriodForDefaultValues.onboardingFrom
            ).toDate(),
            parseDateISO(
              singleEmployeeOnboardingPeriodForDefaultValues.onboardingUntil
            ).toDate(),
          ],
          expectedStartOfCoverage: parseDateISO(
            singleEmployeeOnboardingPeriodForDefaultValues.targetEnrollmentDate
          ).toDate(),
        }
      : undefined;

  const { control, setValue, handleSubmit, formState, reset } =
    useForm<ChangeElectionWindowFormFields>({
      mode: 'onChange',
      resolver: yupResolver(
        yup.object().shape(ChangeElectionWindowSchemaObject)
      ),
      defaultValues: isSingleEmployee
        ? editModeSingleEmployeeDefaultValues
        : undefined, // no default values for bulk edit mode
    });

  const { isValid } = formState;

  const electionWindow = useWatch({
    control,
    name: 'electionWindow',
  });

  const formValues = {
    planYear: openEnrollmentDto,
    enrollmentType: sharedEnrollmentType,
    electionWindow,
  };

  const onSubmit = handleSubmit(async (data) => {
    setErrors([]);
    const { electionWindow, expectedStartOfCoverage } = data;
    const [electionFrom, electionUntil] = electionWindow || [];

    if (!electionFrom || !electionUntil || !expectedStartOfCoverage) {
      showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
      return;
    }

    const electionWindowOnlyData = {
      onboardingFrom: formatDateISO(electionFrom),
      onboardingUntil: formatDateISO(electionUntil),
      expectedStartOfCoverage: formatDateISO(expectedStartOfCoverage),
    };

    const { errors } = await updateOnboardingPeriods(
      selectedEmployees.map((employee) => ({
        key: `${employee.firstName} ${employee.lastName}`,
        params: [
          employee.onboardingPeriodId,
          {
            ...electionWindowOnlyData,
            submissionType: SubmissionType.BY_OPERATOR,
          },
        ],
      }))
    );

    if (errors.length === 0) {
      closeModal();
    }
  });

  return (
    <Drawer
      opened={isOpen}
      title="Change election window"
      onClose={() => {
        closeModal();
        reset(undefined, { keepDefaultValues: false });
        setErrors([]);
      }}
    >
      <>
        <form onSubmit={onSubmit}>
          <Group>
            <ChangeEnrollmentAlertBox isSingleEmployee={isSingleEmployee} />
            <Box style={{ width: '100%' }}>
              <Box style={inlineGroupStyle}>
                <Controller
                  control={control}
                  name="electionWindow"
                  render={({ field: { onChange, ...rest } }) => (
                    <DateRangeInput
                      {...rest}
                      required
                      label="Election window"
                      maxDate={
                        sharedEnrollmentType ===
                          OnboardingType.OPEN_ENROLLMENT &&
                        openEnrollmentDto?.effectiveFrom
                          ? parseDateISO(openEnrollmentDto.effectiveFrom)
                              .subtract(1, 'day')
                              .toDate()
                          : undefined
                      }
                      onChange={(value) => {
                        const [newFrom, newUntil] = value;
                        if (newFrom && newUntil) {
                          formValues.electionWindow = [newFrom, newUntil];
                          handleFormStateChange(
                            formValues,
                            'electionWindow',
                            setValue as unknown as UseFormReturn<ChangeEnrollmentsFormFields>['setValue'],
                            singleEmployeeDto,
                            associatedOtherOnboardingPeriodsSingleEmployee
                          );
                        }
                        onChange(value);
                      }}
                      style={formFieldStyle}
                      disabled={!openEnrollmentDto}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="expectedStartOfCoverage"
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      required
                      label="Expected start of coverage"
                      style={formFieldStyle}
                      minDate={
                        isSingleEmployee
                          ? sharedEnrollmentType === OnboardingType.SPECIAL &&
                            openEnrollmentDto?.effectiveFrom &&
                            electionWindow?.length
                            ? parseDateISO(
                                maximalDateISO(
                                  formatDateISO(
                                    parseDate(electionWindow?.[1]).add(1, 'day')
                                  ),
                                  openEnrollmentDto?.effectiveFrom,
                                  singleEmployeeDto?.eligibleFrom
                                )
                              ).toDate()
                            : undefined
                          : electionWindow?.[1]
                          ? parseDateISO(
                              maximalDateISO(
                                formatDateISO(parseDate(electionWindow[1])),
                                openEnrollmentDto?.effectiveFrom
                              )
                            ).toDate()
                          : undefined
                      }
                      maxDate={
                        openEnrollmentDto?.effectiveUntil
                          ? sharedEnrollmentType === OnboardingType.SPECIAL
                            ? parseDateISO(
                                minimalDateISO(
                                  openEnrollmentDto.effectiveUntil,
                                  singleEmployeeDto?.eligibleUntil
                                )
                              ).toDate()
                            : parseDateISO(
                                openEnrollmentDto.effectiveUntil
                              ).toDate()
                          : undefined
                      }
                      disabled={!openEnrollmentDto}
                      readOnly={
                        sharedEnrollmentType === OnboardingType.OPEN_ENROLLMENT
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Group>
          <Center>
            <Button type="submit" size="lg" ml="xl" disabled={!isValid}>
              Submit
            </Button>
          </Center>
        </form>
        {!isSingleEmployee && errors.length > 0 && (
          <Box mt="xl">
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                { accessor: 'key', title: 'Employee Name' },
                { accessor: 'error', title: 'Message' },
              ]}
              records={errors}
              idAccessor="key"
            />
          </Box>
        )}
      </>
    </Drawer>
  );
};
