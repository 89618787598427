import { EnrollmentStatus } from '@zorro/clients';
import { ValueOf } from '@zorro/types';
import { Badge, Box, Text, Tooltip, brand } from '@zorro/zorro-ui-design';

import { enrollmentStatusConfig } from './EnrollmentStatusUtils';

type Props = {
  enrollmentStatus?: EnrollmentStatus;
  shouldDisableTooltip?: boolean;
  backgroundColor?: ValueOf<typeof brand>;
  iconSize?: number;
};

export function EnrollmentStatusBadge({
  enrollmentStatus = EnrollmentStatus.NO_ENROLLMENTS,
  backgroundColor = brand.zorroGray900,
  shouldDisableTooltip,
  iconSize,
}: Props) {
  const { tooltipLabel, label, renderIcon } =
    enrollmentStatusConfig[enrollmentStatus];

  return (
    <Tooltip
      label={tooltipLabel}
      position="top"
      disabled={shouldDisableTooltip || !tooltipLabel}
    >
      <Box w="min-content">
        <Badge
          style={{
            backgroundColor,
            padding: '21px 22px 21px 3px',
            display: 'flex',
          }}
          leftSection={renderIcon(iconSize)}
        >
          <Text size="sm" style={{ paddingLeft: '6px' }}>
            {label}
          </Text>
        </Badge>
      </Box>
    </Tooltip>
  );
}
