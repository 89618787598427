/* eslint-disable react/boolean-prop-naming,@typescript-eslint/naming-convention */
import {
  TOKEN_CLIENT_NOT_INITIALIZED_ERROR_MESSAGE,
  useGoogleIdentityServices,
  useGooglePickerScript,
} from '@zorro/shared/utils';
import { ZorroError } from '@zorro/types';
import { Button, Flex, Loader, Stack, Text } from '@zorro/zorro-ui-design';
import { ComponentPropsWithoutRef, useEffect, useState } from 'react';

const GOOGLE_PICKER_CONTAINER_HEIGHT = 120;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gapi: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

interface OverridableTokenClientConfig {
  scope?: string;
  include_granted_scopes?: boolean;
  prompt?: string;
  enable_granular_consent?: boolean;
  enable_serial_consent?: boolean;
  login_hint?: string;
  hint?: string;
  state?: string;
}

interface TokenResponse {
  access_token: string;
  expires_in: string;
  hd: string;
  prompt: string;
  token_type: string;
  scope: string;
  state: string;
  error: string;
  error_description: string;
  error_uri: string;
}

interface TokenClient {
  requestAccessToken: (overrideConfig?: OverridableTokenClientConfig) => void;
  callback: (response: TokenResponse) => void;
}

export type GooglePickerDoc = {
  id: string;
  name: string;
};

type GooglePickerResponse = {
  action: string;
  docs: GooglePickerDoc[];
};

type GooglePickerProps = {
  googleAppId: string;
  googleBrowserKey: string;
  googleClientId: string;
  modify?: boolean;
  onCreatePicker?: (accessToken: string) => void;
  onOpen?: () => void;
  onFilePicked?: (file: GooglePickerDoc) => void;
} & ComponentPropsWithoutRef<typeof Button>;

export const GooglePicker = ({
  googleAppId,
  googleBrowserKey,
  googleClientId,
  modify,
  onCreatePicker,
  onFilePicked,
  onOpen,
  ...props
}: GooglePickerProps): React.ReactElement => {
  const [googlePickerScriptLoaded, googlePickerScriptError] =
    useGooglePickerScript();
  const [isPickerReady, setIsPickerReady] = useState(false);
  const [googleIdentityScriptLoaded, googleIdentityScriptError] =
    useGoogleIdentityServices();
  const [tokenClient, setTokenClient] = useState<TokenClient | null>(null);
  let googleAccessToken: string | null = null;
  const scope =
    'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/spreadsheets.readonly';
  useEffect(() => {
    const onPickerApiLoad = () => {
      setIsPickerReady(true);
    };

    if (googlePickerScriptLoaded) {
      window.gapi.load('picker', { callback: onPickerApiLoad });
    }
  }, [googlePickerScriptLoaded]);

  useEffect(() => {
    if (googleIdentityScriptLoaded) {
      setTokenClient(
        window.google.accounts.oauth2.initTokenClient({
          client_id: googleClientId,
          scope,
          callback: '',
        })
      );
    }
  }, [googleIdentityScriptLoaded, googleClientId]);

  const chooseFileFinished = (data: GooglePickerResponse) => {
    if (data.action === window.google.picker.Action.PICKED) {
      const file = data.docs[0];
      if (onFilePicked) {
        onFilePicked(file);
      }
    }
  };

  function createPicker() {
    const view = new window.google.picker.DocsView();
    view.setMode(window.google.picker.DocsViewMode.LIST);
    const picker = new window.google.picker.PickerBuilder()
      .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
      .setDeveloperKey(googleBrowserKey)
      .setAppId(googleAppId)
      .setOAuthToken(googleAccessToken)
      .setTitle('Choose a spreadsheet to sync')
      .addView(view)
      .addView(new window.google.picker.DocsUploadView())
      .setCallback(chooseFileFinished)
      .build();
    picker.setVisible(true);
    onCreatePicker?.(googleAccessToken ?? '');
  }

  const chooseFileClicked = () => {
    onOpen?.();
    if (tokenClient === null) {
      throw new ZorroError(TOKEN_CLIENT_NOT_INITIALIZED_ERROR_MESSAGE);
    }
    tokenClient.callback = (response: TokenResponse) => {
      if (response.error !== undefined) {
        throw response;
      }
      googleAccessToken = response.access_token;
      createPicker();
    };
    tokenClient.requestAccessToken({ prompt: 'select_account' });
  };

  if (googlePickerScriptError || googleIdentityScriptError) {
    return <span>Could not load Google Picker</span>;
  }
  if (!isPickerReady || !googleAppId || !googleBrowserKey) {
    return (
      <Flex
        justify="center"
        align="center"
        mih={GOOGLE_PICKER_CONTAINER_HEIGHT}
      >
        <Stack align="center">
          <Loader />
          <Text fw={500}>Loading Google picker...</Text>
        </Stack>
      </Flex>
    );
  }
  return (
    <Button onClick={chooseFileClicked} {...props}>
      {props.children ?? (modify ? 'Change spreadsheet' : 'Choose spreadsheet')}
    </Button>
  );
};
