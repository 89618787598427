import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { UploadLogoIcon } from '@zorro/shared/assets';
import {
  EMPLOYER_LOGO_WAS_UPLOADED_MESSAGE,
  UPLOADING_EMPLOYER_LOGO_FAILED_ERROR_MESSAGE,
  createEmployerLogoUrl,
  downloadFile,
  getEmployerLogo,
  logger,
  showErrorNotification,
  showSuccessNotification,
} from '@zorro/shared/utils';
import {
  Box,
  Button,
  Dropzone,
  FormErrorMessage,
  Group,
  InputWrapper,
  Space,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import Image from 'next/image';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  Path,
  UseFormWatch,
} from 'react-hook-form';

import { UploadedFileBox } from '../UploadedFileBox';

const TWO_MEGABYTES = 2 * 1024 * 1024;

export type UploadLogoFormFields = {
  logoUrl?: string;
};

type Props<T extends UploadLogoFormFields> = {
  control: Control<T>;
  errors: FieldErrors<T>;
  watch: UseFormWatch<T>;
  onLogoUploaded: (logoPath: string) => void;
  onDeleteCurrentLogo: () => void;
  isRequired?: boolean;
  isDisabled?: boolean;
};

export const UploadLogoInput = <T extends UploadLogoFormFields>({
  onDeleteCurrentLogo,
  onLogoUploaded,
  control,
  errors,
  watch,
  isRequired = false,
  isDisabled = false,
}: Props<T>) => {
  const [isLogoUploadPending, setIsLogoUploadPending] = useState(false);
  const [didLogoUpdate, setDidLogoUpdate] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const handleLogoUpload = async (file: File) => {
    setIsLogoUploadPending(true);

    try {
      const { logoUrl } = await createEmployerLogoUrl(file);
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
      onLogoUploaded(logoUrl);
      setDidLogoUpdate(true);
      showSuccessNotification({ message: EMPLOYER_LOGO_WAS_UPLOADED_MESSAGE });
    } catch (error) {
      logger.error(error);

      showErrorNotification({
        message: UPLOADING_EMPLOYER_LOGO_FAILED_ERROR_MESSAGE,
      });
    } finally {
      setIsLogoUploadPending(false);
    }
  };

  const logoUrl = watch('logoUrl' as FieldPath<T>);
  const isUploadDisabled = (Boolean(logoUrl) && didLogoUpdate) || isDisabled;

  return (
    <>
      {(!logoUrl || !didLogoUpdate) && (
        <Controller
          control={control}
          name={'logoUrl' as FieldPath<T>}
          render={({ field: { ref: _ref, onBlur, ...rest } }) => (
            <InputWrapper label="Company logo" required={isRequired}>
              <Dropzone
                {...rest}
                multiple={false}
                accept={IMAGE_MIME_TYPE}
                maxSize={TWO_MEGABYTES}
                onDrop={async (files: File[]) => {
                  await handleLogoUpload(files[0]);
                  onBlur();
                }}
                onReject={(files) => {
                  showErrorNotification({
                    message: files[0].errors[0].message,
                    title: files[0].errors[0].code,
                  });
                }}
                loading={isLogoUploadPending}
                disabled={isUploadDisabled}
              >
                <Stack align="center" gap={12} w="100%">
                  <UploadLogoIcon />
                  <Text> Upload company logo</Text>
                  <Text> Or drag it here</Text>
                </Stack>
              </Dropzone>
            </InputWrapper>
          )}
        />
      )}

      {logoUrl && didLogoUpdate && (
        <Group
          style={{
            borderRadius: '4px',
            border: '1px solid #D9DBDF',
            width: '270px',
            margin: '0px',
          }}
          gap={0}
        >
          {imagePreview && (
            <Box
              style={{
                position: 'relative',
                height: '100px',
                width: '270px',
              }}
            >
              <Image
                src={imagePreview}
                alt="Preview"
                width={100}
                height={270}
                style={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          )}
          <UploadedFileBox onClickDelete={onDeleteCurrentLogo} name={logoUrl} />
        </Group>
      )}

      {logoUrl && !didLogoUpdate && (
        <>
          <Space h="sm" />
          <Box
            mt="xs"
            style={(theme) => ({
              borderRadius: theme.radius.sm,
            })}
          >
            <Button
              variant="subtle"
              p={0}
              onClick={async () => {
                const signedLogoUrl = await getEmployerLogo(logoUrl);
                const extension = logoUrl.split('.').pop();
                downloadFile(signedLogoUrl, `currentLogo.${extension}`, false);
              }}
            >
              Download
            </Button>{' '}
            existing logo file.
          </Box>

          <FormErrorMessage fieldName={'logoUrl' as Path<T>} errors={errors} />
        </>
      )}
    </>
  );
};
