import { EmployerDto } from '@zorro/clients';
import {
  callEndpoint,
  showErrorNotification,
  showSuccessNotification,
  useMonolithQuery,
} from '@zorro/shared/utils';
import {
  RichTextEditor,
  Title,
  useRichTextEditor,
} from '@zorro/zorro-ui-design';
import _debounce from 'lodash/debounce';

const mutateElectionInstructions = _debounce(
  async (employerId: EmployerDto['id'], content: string) => {
    try {
      await callEndpoint({
        method: 'electionInstructionsControllerUpsert',
        params: [employerId, { content }],
      });
      showSuccessNotification({
        message: 'Election instructions have been saved.',
      });
    } catch (error) {
      showErrorNotification(error);
    }
  },
  2000,
  { maxWait: 15_000 }
);

type ElectionInstructionsEditorProps = {
  employerId: EmployerDto['id'];
};

const ElectionInstructionsEditor = ({
  employerId,
}: ElectionInstructionsEditorProps) => {
  const {
    isLoading: isLoadingElectionInstructions,
    data: electionInstructions,
  } = useMonolithQuery({
    method: 'electionInstructionsControllerFind',
    params: [employerId],
  });

  const { editor } = useRichTextEditor(
    {
      content: electionInstructions
        ? JSON.parse(electionInstructions.content)
        : undefined,
      onUpdate({ editor }) {
        const content = JSON.stringify(editor.getJSON());
        mutateElectionInstructions(employerId, content);
      },
    },
    [electionInstructions]
  );

  if (isLoadingElectionInstructions) {
    return 'Loading Election instructions...';
  }

  return (
    <>
      <Title order={4}>Election instructions</Title>
      <RichTextEditor editor={editor} />
    </>
  );
};

export { ElectionInstructionsEditor };
