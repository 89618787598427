import { BusinessEntityType } from '@zorro/clients';
import { validationMessages } from '@zorro/shared/utils';
import * as yup from 'yup';

import { taxIdExclusions, taxIdSchemaObject } from '../TaxIdInput';

export const getCompanyDetailsSchemaObject = (
  isFinalizationMode: boolean,
  shouldHideSignedAgreement: boolean = false
) => {
  return yup.object().shape(
    {
      name: yup.string().required(validationMessages.companyNameRequired),
      isLegalNameSameAsCompanyName: yup
        .boolean()
        .required(validationMessages.isLegalNameSameAsCompanyName),
      isMailingAddressSameAsHQAddress: yup
        .boolean()
        .required(validationMessages.isMailingAddressSameAsHQAddress),
      mailingAddress: yup.string().when('isMailingAddressSameAsHQAddress', {
        is: false,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired(),
      }),
      ...(shouldHideSignedAgreement
        ? { signedAgreement: yup.mixed<File | string>() }
        : {
            signedAgreement: yup
              .mixed<File | string>()
              .required(validationMessages.signedAgreementRequired),
          }),
      ...(isFinalizationMode
        ? {
            legalName: yup.string().when('isLegalNameSameAsCompanyName', {
              is: false,
              then: () => yup.string().required(),
              otherwise: () => yup.string().notRequired(),
            }),
            address: yup.string().required(validationMessages.addressRequired),
            businessEntityType: yup
              .mixed<BusinessEntityType>()
              .oneOf(
                Object.values(BusinessEntityType),
                validationMessages.businessEntityType
              )
              .typeError(validationMessages.businessEntityType)
              .required(validationMessages.businessEntityType),
            taxId: taxIdSchemaObject.taxId.required(
              validationMessages.taxIdTypeRequired
            ),
            taxIdType: taxIdSchemaObject.taxIdType.required(
              validationMessages.taxIdTypeRequired
            ),
            logoUrl: yup.string().required(validationMessages.logoUrlRequired),
          }
        : {
            legalName: yup.string(),
            address: yup.string(),
            businessEntityType: yup
              .mixed<BusinessEntityType>()
              .oneOf(
                Object.values(BusinessEntityType),
                validationMessages.businessEntityType
              )
              .typeError(validationMessages.businessEntityType)
              .nullable(),
            taxId: taxIdSchemaObject.taxId,
            taxIdType: taxIdSchemaObject.taxIdType,
            logoUrl: yup.string(),
          }),
    },
    taxIdExclusions
  );
};

export type CompanyDetailsFormFields = yup.InferType<
  ReturnType<typeof getCompanyDetailsSchemaObject>
>;
