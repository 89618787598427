import {
  Anchor,
  Breadcrumbs,
  BreadcrumbsProps,
  MantineColor,
  MantineStyleProp,
} from '@mantine/core';
import { IconChevronRight, IconHelpCircle } from '@tabler/icons-react';
import Link from 'next/link';
import { Fragment, ReactNode } from 'react';

import { brand } from '../theme';

type BreadCrumbsItem = {
  href: string;
  title: string;
};

export interface IBreadcrumbsProps extends Omit<BreadcrumbsProps, 'children'> {
  items?: BreadCrumbsItem[];
  shouldShowHelpIcon?: boolean;
  children?: ReactNode;
  style?: MantineStyleProp;
}

export function ZorroBreadcrumbs({
  items,
  style,
  shouldShowHelpIcon = false,
  ...props
}: IBreadcrumbsProps) {
  const breadcrumbsItems = items?.map((item, index) => (
    <Fragment key={item.title}>
      {shouldShowHelpIcon && (
        <IconHelpCircle color={brand.zorroGray400} height="20px" />
      )}
      <Link href={item.href} passHref legacyBehavior>
        <Anchor
          ml="4px"
          c={
            (index === items.length - 1
              ? 'zorroCoal.9'
              : 'zorroGray.4') as MantineColor
          }
        >
          {item.title}
        </Anchor>
      </Link>
    </Fragment>
  ));

  return (
    <Breadcrumbs
      separator={<IconChevronRight height="20px" color={brand.zorroGray400} />}
      style={{ flexFlow: 'row wrap', ...style }}
      {...props}
    >
      {breadcrumbsItems}
    </Breadcrumbs>
  );
}
