/* eslint-disable id-length */
import { Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';

export interface ITextProps extends TextProps {
  letterSpacing?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  title?: string;
  children: string | ReactNode;
}

export const ZorroText = ({
  letterSpacing = '-0.02em',
  component = 'div',
  c = 'zorroCoal.9',
  fw = 400,
  size,
  children,
  ...props
}: ITextProps) => {
  return (
    <Text
      component={component}
      style={{ letterSpacing }}
      size={size}
      fw={fw}
      c={c}
      {...props}
    >
      {children}
    </Text>
  );
};
