import { RadioGroupProps } from '@mantine/core';
import { IconCheck, IconQuestionMark, IconX } from '@tabler/icons-react';
import { YesNoNotSure } from '@zorro/clients';
import {
  Box,
  FormErrorMessage,
  Grid,
  Icon,
  RadioCard,
  RadioGroup,
  brand,
} from '@zorro/zorro-ui-design';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  radioGroupProps?: RadioGroupProps;
  label?: string;
  description?: string;
  shouldShowUnknown?: boolean;
};

export function YesNoNotSureInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  radioGroupProps,
  label,
  description,
  shouldShowUnknown,
  ...formProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    formState: { errors },
  } = useController(formProps);
  return (
    <>
      <RadioGroup {...field} label={label} description={description}>
        <Grid>
          <Grid.Col span={6}>
            <RadioCard
              icon={
                <Box
                  bg={brand.zorroGreen300}
                  w="2rem"
                  h="2rem"
                  style={(theme) => ({
                    borderRadius: theme.spacing.xxs,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <Icon icon={IconCheck} />
                </Box>
              }
              label="Yes"
              value={YesNoNotSure.YES}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <RadioCard
              icon={
                <Box
                  bg={brand.zorroFire300}
                  w="2rem"
                  h="2rem"
                  style={(theme) => ({
                    borderRadius: theme.spacing.xxs,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <Icon icon={IconX} />
                </Box>
              }
              label="No"
              value={YesNoNotSure.NO}
            />
          </Grid.Col>
          {shouldShowUnknown && (
            <Grid.Col span={6}>
              <RadioCard
                icon={
                  <Box
                    bg={brand.zorroSky100}
                    w="2rem"
                    h="2rem"
                    style={(theme) => ({
                      borderRadius: theme.spacing.xxs,
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    })}
                  >
                    <Icon icon={IconQuestionMark} />
                  </Box>
                }
                label="I don't know"
                value={YesNoNotSure.NOT_SURE}
              />
            </Grid.Col>
          )}
        </Grid>
      </RadioGroup>
      <FormErrorMessage fieldName={formProps.name} errors={errors} />
    </>
  );
}
