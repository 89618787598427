import { DateInput, DateInputProps } from '@mantine/dates';
import { IconCalendarEvent } from '@tabler/icons-react';
import { EN_LOCALE_DATE_FORMAT } from '@zorro/shared/formatters';
import { useForwardRef } from '@zorro/shared/utils';
import { KeyboardEvent, forwardRef } from 'react';

import { ZorroIcon } from '../../Icon';
import classNames from './DateInput.module.scss';

type IDateInputProps = DateInputProps;

export const ZorroDateInput = forwardRef<HTMLInputElement, IDateInputProps>(
  (
    {
      placeholder = EN_LOCALE_DATE_FORMAT,
      size = 'md',
      clearable = false,
      rightSection,
      onChange,
      ...props
    },
    ref
  ) => {
    const innerRef = useForwardRef<HTMLInputElement | null>(ref);

    const handleOnKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        innerRef.current?.blur();
      }
    };

    return (
      <DateInput
        ref={innerRef}
        placeholder={placeholder}
        size={size}
        clearable={clearable}
        classNames={classNames}
        rightSection={rightSection || <ZorroIcon icon={IconCalendarEvent} />}
        onKeyDown={handleOnKeyDown}
        onChange={onChange}
        {...props}
      />
    );
  }
);

ZorroDateInput.displayName = 'ZorroDateInput';
