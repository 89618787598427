import { validationMessages } from '@zorro/shared/utils';
import * as yup from 'yup';

export const premiumAmountSchema = yup.object({
  employeeMonthlyContribution: yup
    .number()
    .min(0, validationMessages.employeeMonthlyContributionNotNegative)
    .typeError(validationMessages.employeeMonthlyContributionNotNegative)
    .required(validationMessages.employeeMonthlyContributionRequired),
  premium: yup
    .number()
    .min(0, validationMessages.premiumPositive)
    .typeError(validationMessages.premiumRequired)
    .required(validationMessages.premiumRequired),
});

export type PremiumAmountFormFields = yup.InferType<typeof premiumAmountSchema>;
