import { FamilyUnit, InsuredPersonDto } from '@zorro/clients';
import { Text } from '@zorro/zorro-ui-design';

type FamilyMembersReadOnlyProps = {
  employee: InsuredPersonDto | null;
  spouse?: InsuredPersonDto;
  dependents?: InsuredPersonDto[];
  checkedMembers?: FamilyUnit | null;
};

export const FamilyMembersReadOnly = ({
  employee,
  spouse,
  dependents,
  checkedMembers,
}: FamilyMembersReadOnlyProps) => {
  const getMembersStr = () => {
    switch (checkedMembers) {
      case FamilyUnit.FAMILY: {
        return `${employee?.firstName}, ${spouse?.firstName}, ${dependents
          ?.map((dependent) => dependent.firstName)
          .join(', ')}`;
      }
      case FamilyUnit.EMPLOYEE_CHILD: {
        return `${employee?.firstName}, ${dependents
          ?.map((dependent) => dependent.firstName)
          .join(', ')}`;
      }
      case FamilyUnit.EMPLOYEE_ONLY: {
        return employee?.firstName;
      }
      case FamilyUnit.EMPLOYEE_SPOUSE: {
        return `${employee?.firstName}, ${spouse?.firstName}`;
      }
      default: {
        return '';
      }
    }
  };

  return (
    <Text className="fs-mask" component="span" c="zorroCoal.9">
      {getMembersStr()}
    </Text>
  );
};
