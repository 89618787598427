/* eslint-disable id-length */
import { yupResolver } from '@hookform/resolvers/yup';
import { MIME_TYPES } from '@mantine/dropzone';
import { IconInfoCircle } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { GetEmployerDto } from '@zorro/clients';
import {
  NO_DATA_FOUND_IN_FILE_ERROR_MESSAGE,
  NO_FILE_SELECTED_ERROR_MESSAGE,
  showErrorNotification,
  showSuccessNotification,
  useForm,
  useMonolithQuery,
  useMurrietaRouter,
  validationMessages,
} from '@zorro/shared/utils';
import {
  Alert,
  Anchor,
  Box,
  Button,
  Center,
  Checkbox,
  FormErrorMessage,
  Grid,
  Icon,
  Space,
  Stack,
  Table,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import csv from 'csvtojson';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import * as XLSX from 'xlsx';
import * as yup from 'yup';

import { FileUploader } from '../../FileUploader';
import { UploadedFileBox } from '../../UploadedFileBox';
import { createEmployeesFromRosterUpload } from './CreateEmployeesFromRosterUploadUtils';
import { RosterInput, RosterUploadError } from './uploadRoster.types';

export const uploadRosterSchema = yup.object({
  rosterFile: yup.mixed<File>().required(validationMessages.rosterFileRequired),
  sendActivationEmail: yup.boolean().required(),
});

export type UploadRosterFormFields = yup.InferType<typeof uploadRosterSchema>;

export type UploadRosterProps = {
  employer: GetEmployerDto;
  onSuccess: () => void;
  successText: string;
  onSkip?: () => void;
};

const HEADER_COMPATIBLE_TO_CSV = [
  'first_name',
  'last_name',
  'id_from_employer',
  'company_email',
  'personal_email',
  'phone',
  'address',
  'date_of_birth',
  'gender',
  'plan_id',
  'class',
  'salary',
  'hire_date',
  'eligibility_start_date',
];

const emptyRow = (row: RosterInput) => {
  return Object.values(row).every((value) => !value || value.trim() === '');
};

export function UploadRosterForm({
  employer,
  onSuccess,
  successText,
  onSkip,
}: UploadRosterProps) {
  const {
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { isValid, isSubmitting, errors },
  } = useForm<UploadRosterFormFields>({
    mode: 'onChange',
    resolver: yupResolver(uploadRosterSchema),
    defaultValues: {
      rosterFile: undefined,
      sendActivationEmail: false,
    },
  });
  const { reloadPage } = useMurrietaRouter();
  const queryClient = useQueryClient();
  const shouldDownloadRosterSample = true;

  const [isRosterFileUploadPending, setIsRosterFileUploadPending] =
    useState(false);
  const [uploadErrors, setUploadErrors] = useState<RosterUploadError[]>([]);

  const handleRosterFileRemoval = () => {
    resetField('rosterFile');
  };

  const uploadRoster = async ({
    rosterFile,
    sendActivationEmail,
  }: UploadRosterFormFields) => {
    setUploadErrors([]);
    if (!rosterFile) {
      showErrorNotification({ message: NO_FILE_SELECTED_ERROR_MESSAGE });
      return;
    }
    setIsRosterFileUploadPending(true);

    let rosterTextContent: string;

    if (rosterFile.name.toLowerCase().endsWith('.xlsx')) {
      const workbook = XLSX.read(await rosterFile.arrayBuffer(), {
        cellText: false,
        cellDates: true,
      });
      const sheetIdx = Math.max(
        workbook.SheetNames.findIndex((name) =>
          name.toLowerCase().includes('roster')
        ),
        0
      );
      const sheet = workbook.Sheets[workbook.SheetNames[sheetIdx]];

      // In XSLX we have 2 header rows. We skip one, and change the other to be compatible to our CSV format
      const range = XLSX.utils.decode_range(sheet['!ref'] || 'A1');
      HEADER_COMPATIBLE_TO_CSV.forEach((header, index) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 1, c: index });
        sheet[cellAddress] = { t: 's', v: header };
      });
      range.s.r = 1;
      sheet['!ref'] = XLSX.utils.encode_range(range);

      rosterTextContent = XLSX.utils.sheet_to_csv(sheet, {
        blankrows: false,
        skipHidden: true,
      });
    } else if (rosterFile.name.toLowerCase().endsWith('.csv')) {
      rosterTextContent = await rosterFile.text();
    } else {
      throw new Error('Invalid file format');
    }

    const roster = (
      (await csv({ eol: '\n' }).fromString(rosterTextContent)) as RosterInput[]
    ).filter((row) => !emptyRow(row));

    if (roster.length === 0) {
      setIsRosterFileUploadPending(false);
      showErrorNotification({ message: NO_DATA_FOUND_IN_FILE_ERROR_MESSAGE });
      return;
    }
    const employerId = employer.id as string;
    const uploadRosterResult = await createEmployeesFromRosterUpload(
      employerId,
      roster,
      sendActivationEmail
    );
    if (uploadRosterResult.success) {
      onSuccess();
      showSuccessNotification({
        message: 'Roster uploaded successfully!',
        title: 'Success!',
        id: 'roster-upload-success-notification',
      });
    } else {
      showErrorNotification({
        message: uploadRosterResult.message,
        title: 'Oh no!',
        id: 'roster-upload-fail-notification',
      });
      setUploadErrors(uploadRosterResult.errors);
    }
    setIsRosterFileUploadPending(false);
    reloadPage();
    await queryClient.invalidateQueries();
  };

  const rosterFileField = watch('rosterFile');

  const { data: employeeClasses } = useMonolithQuery({
    method: 'allowanceModelControllerGetEmployeeClasses',
    params: [employer.id],
  });

  return (
    <>
      <Alert
        variant="light"
        bg={brand.zorroGray900}
        style={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: brand.zorroGray200,
          marginBottom: '16px',
        }}
      >
        <Box style={{ display: 'inline-flex' }}>
          <Icon
            icon={IconInfoCircle}
            size="20px"
            style={{
              flexShrink: 0,
              marginRight: '12px',
              marginTop: '4px',
            }}
          />
          <Stack gap="xs" style={{ flex: 1 }}>
            <Text size="md" c={brand.zorroGray400}>
              Please use one of the exact class names supported by the
              company&apos;s allowance model:
            </Text>
            <Text size="md" c={brand.zorroGray400} fs="italic">
              {employeeClasses?.classes.join(', ')}
            </Text>
          </Stack>
        </Box>
      </Alert>
      <form onSubmit={handleSubmit(uploadRoster)}>
        {!rosterFileField && (
          <FileUploader
            control={control as unknown as Control}
            name="rosterFile"
            label="Upload roster"
            isLoading={isRosterFileUploadPending}
            onDrop={(files: File[], onBlur, onChange) => {
              onChange(files[0]);
              onBlur();
            }}
            isRequired
            isMultiple={false}
            isDisabled={!!rosterFileField}
            accept={[MIME_TYPES.csv, MIME_TYPES.xlsx]}
            zoneLabelText="Drag file here or click to select it"
            buttonText="Upload roster"
          />
        )}
        {rosterFileField && (
          <UploadedFileBox
            onClickDelete={handleRosterFileRemoval}
            name={rosterFileField.name}
          />
        )}
        <Space h="sm" />
        {shouldDownloadRosterSample && (
          <Anchor href="/files/sample_roster.xlsx" size="sm" fw={500} download>
            Download Roster Sample
          </Anchor>
        )}
        {!shouldDownloadRosterSample && (
          <Text size="sm">
            For your convenience{' '}
            <Anchor href="/" download>
              download
            </Anchor>{' '}
            the current roster
          </Text>
        )}
        <FormErrorMessage fieldName="rosterFile" errors={errors} />
        <Controller
          control={control}
          name="sendActivationEmail"
          render={({ field: { value, ...rest } }) => (
            <Checkbox
              {...rest}
              my="sm"
              ml="xs"
              isChecked={value}
              shouldHaveBorder={false}
              label="Send invitation to all new employees"
            />
          )}
        />
        <Space h="sm" />
        <Center>
          <Stack gap="xl" justify="center">
            <Button
              type="submit"
              disabled={!isValid || isSubmitting}
              data-testid="upload-roster-submit-button"
            >
              {successText}
            </Button>
            {onSkip && (
              <Button variant="subtle" onClick={onSkip}>
                Skip
              </Button>
            )}
          </Stack>
        </Center>
      </form>

      {uploadErrors && uploadErrors.length > 0 && (
        <>
          <Space h="xl" />

          <Grid gutter="xl">
            <Grid.Col>
              <Text c="zorroFire.7" size="md" fw="bold">
                Errors
              </Text>
              <Table
                columns={[
                  {
                    accessor: 'employeeDetails',
                    title: 'Employee name',
                    render: ({ employeeDetails }) =>
                      `${employeeDetails.firstName} ${employeeDetails.lastName}`,
                  },
                  { accessor: 'message' },
                ]}
                records={uploadErrors}
              />
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
}
