import { LogoDark } from '@zorro/shared/ui';
import { Group } from '@zorro/zorro-ui-design';
import { MurrietaUser } from '~/components/layouts/MurrietaUser';

interface HeaderSideBarProps {
  profilePicture?: string | null;
}

export const HeaderSideBar = ({ profilePicture }: HeaderSideBarProps) => {
  return (
    <Group justify="space-between" h="100%" data-testid="header-side-bar">
      <LogoDark />
      <MurrietaUser profilePicture={profilePicture} />
    </Group>
  );
};
