import { yupResolver } from '@hookform/resolvers/yup';
import { IconAlertTriangle } from '@tabler/icons-react';
import { editCompanyEmailSchema, useForm } from '@zorro/shared/utils';
import {
  Box,
  Button,
  Flex,
  FormErrorMessage,
  Grid,
  Icon,
  Modal,
  Space,
  Text,
  TextInput,
  brand,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';

import { ModalControlProps } from '../../ModalControls/ModalControls';
import { useMonolithMutation } from '../../hooks/useMonolithMutation';

export type EditCompanyEmailFormFields = {
  email: string;
};

type EditCompanyEmailModalProps = {
  modalControlProps: ModalControlProps;
  employeeId: string;
};

export const EditCompanyEmailModal = ({
  modalControlProps,
  employeeId,
}: EditCompanyEmailModalProps) => {
  const {
    watch,
    control,
    formState: { isValid, isSubmitting, errors },
  } = useForm<EditCompanyEmailFormFields>({
    mode: 'onChange',
    resolver: yupResolver(editCompanyEmailSchema),
  });
  const { onCloseModal } = modalControlProps;

  const { mutate: editCompanyEmailMutation } = useMonolithMutation({
    method: 'employeesControllerUpdateCompanyEmail',
  });

  return (
    <Modal
      padding={40}
      size="md"
      onClose={() => onCloseModal()}
      title={
        <Flex align="center">
          <Icon icon={IconAlertTriangle} color={brand.zorroFire700} size={32} />
          <Text size="xl" ml="sm" fw={500}>
            Change company email
          </Text>
        </Flex>
      }
    >
      <Text>
        The work email is used by the employee to log in to Zorro. Make sure the
        employee is aware of this change.
      </Text>
      <Space h="md" />
      <Grid>
        <Grid.Col>
          <Controller
            control={control}
            name="email"
            render={({
              field: { ref: _ref, ...rest },
              fieldState: { error },
            }) => <TextInput {...rest} label="Company email" />}
          />

          <FormErrorMessage fieldName="email" errors={errors} />
        </Grid.Col>
      </Grid>

      <Space h="lg" mt="lg" />

      <Box>
        <Flex justify="space-between">
          <Button variant="secondary" onClick={() => onCloseModal()}>
            Cancel
          </Button>
          <Space w="sm" />
          <Button
            disabled={!isValid || isSubmitting}
            onClick={async () => {
              await editCompanyEmailMutation([
                employeeId,
                { email: watch().email },
              ]);
              onCloseModal();
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};
