import { QueryObserverResult } from '@tanstack/react-query';

import { LoadingOverlayContextProps } from '../LoadingOverlayContext';

export enum ModalLocation {
  EMPLOYEES_PAGE = 'EMPLOYEES_PAGE',
  SINGLE_EMPLOYEE_PAGE = 'SINGLE_EMPLOYEE_PAGE',
  DON_SINGLE_EMPLOYEE_PAGE = 'DON_SINGLE_EMPLOYEE_PAGE',
}

export type ModalControlProps = {
  onCloseModal: () => void;
  modalLocation: ModalLocation;
  loadingOverlayContext: LoadingOverlayContextProps;
  refetchData: () => Promise<QueryObserverResult>;
  reloadPage: () => void;
};

// this method is designed to reduce code duplication -- wraps a function that is triggered by a Modal/Drawer and
// handles the loading state, refetching data, and closing the modal
export const inProgressWrapper = async <T>(
  action: () => Promise<T>,
  modalControlProps: ModalControlProps
): Promise<NonNullable<Awaited<T>> | null | void> => {
  const { loadingOverlayContext, refetchData, reloadPage, onCloseModal } =
    modalControlProps;
  const { startLoading, stopLoading } = loadingOverlayContext;

  try {
    startLoading();
    const result = await action();

    if (
      !result ||
      (Array.isArray(result) &&
        result.filter(({ status }) => status === 'rejected')?.length !== 0)
    ) {
      await refetchData();
      reloadPage();
      onCloseModal();

      return null;
    }

    return result;
  } finally {
    stopLoading();
  }
};
