import {
  IconChevronDown,
  IconChevronRight,
  IconEdit,
  IconHandClick,
  IconMail,
} from '@tabler/icons-react';
import { EnrollmentStatus } from '@zorro/clients';
import { Button, Group, Icon, Menu, Text } from '@zorro/zorro-ui-design';
import { ElementType, ReactElement } from 'react';

export enum MenuActions {
  ELECTION_SUMMARY = 'Election summary',
  ACCOUNT_ACTIVATION = 'Account activation',
  WAIVER_CONFIRMATION = 'Waiver confirmation',
  ELECTION_REMINDER = 'Election reminder',
  ADD_ENROLLMENT_RECORD = 'Add enrollment record',
  EDIT_ENROLLMENT_RECORD = 'Edit enrollment record',
  DELETE_ENROLLMENT_RECORD = 'Delete enrollment record',
  CHANGE_ELECTION_WINDOW = 'Change election window',
  CHANGE_SHOPPING_STATUS = 'Change shopping status',
  WAIVE_COVERAGE = 'Waive coverage',
  UPDATE_ELIGIBILITY = 'Update eligibility',
  DOWNLOAD_PLAN_LIST = 'Download plan list',
  DELETE_EMPLOYEE = 'Delete employee',
  DELETE_EMPLOYEES = 'Delete employees',
  SET_ROLE = 'Set a role',
  RESET_PASSWORD = 'Reset password',
  TERMINATE_EMPLOYEE = 'Terminate employee',
  SET_LEAVE_OF_ABSENCE = 'Set a leave of absence',
  LOG_IN_AS = 'Log in as',
  EDIT_COMPANY_EMAIL = 'Edit company email',
  NONE = '',
}

export type ActionMenuItemProps = {
  title: MenuActions;
  onClick?: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
};

type ActionMenuSubsectionProps = {
  setIsModalOpenById: (id: MenuActions) => void;
  title: string;
  icon: ElementType;
  items: ActionMenuItemProps[] | ReactElement;
  shouldShowMenuDivider?: boolean;
  isHidden?: boolean;
};

const ICON_SIZE = '1.5rem';

function ActionMenuSubsection({
  setIsModalOpenById,
  title,
  icon,
  items,
  shouldShowMenuDivider,
  isHidden: isSubsectionHidden,
}: ActionMenuSubsectionProps) {
  if (isSubsectionHidden) {
    return null;
  }

  const itemsToRender = Array.isArray(items)
    ? items
        .filter(({ isHidden }) => !isHidden)
        .map(({ title, isDisabled }) => {
          return (
            <Menu.Item
              onClick={() => setIsModalOpenById(title)}
              disabled={isDisabled}
              key={title}
            >
              {title}
            </Menu.Item>
          );
        })
    : items;

  return (
    <>
      <Menu.Label>
        <Group gap="xs">
          <Icon icon={icon} size={ICON_SIZE} />
          <Text size="sm" fw="500">
            {title}
          </Text>
        </Group>
      </Menu.Label>
      {itemsToRender}
      {shouldShowMenuDivider && <Menu.Divider />}
    </>
  );
}

type ActionMenuProps = {
  selectedEmployeesCount: number;
  hasOnboardingPeriod?: boolean;
  setIsModalOpenById: (id: MenuActions) => void;
  isGallagherMode?: boolean;
  isAgent?: boolean;
  isAccountSupervisor?: boolean;
  isOmnipotentAdmin?: boolean;
  isDon?: boolean;
  hasEnrollmentPeriod?: boolean;
  enrollmentStatuses?: (EnrollmentStatus | undefined)[];
};

const areAllStatuses = (
  statuses: (EnrollmentStatus | undefined)[] | undefined
) => {
  let areAllOnboardingPeriodsActive = true;
  let areAllOnboardingPeriodsSubmitted = true;
  let areAllOnboardingPeriodsWaived = true;

  if (!statuses || statuses.length === 0) {
    return {
      areAllOnboardingPeriodsActive: false,
      areAllOnboardingPeriodsSubmitted: false,
      areAllOnboardingPeriodsWaived: false,
    };
  }

  for (const status of statuses) {
    if (!status) {
      areAllOnboardingPeriodsActive = false;
      areAllOnboardingPeriodsSubmitted = false;
      areAllOnboardingPeriodsWaived = false;
      break;
    }
    if (
      ![
        EnrollmentStatus.ELECTION_ACTIVE_STARTED,
        EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED,
      ].includes(status)
    ) {
      areAllOnboardingPeriodsActive = false;
    }

    if (
      ![
        EnrollmentStatus.ELECTION_SUBMITTED,
        EnrollmentStatus.ENROLLMENT_CONFIRMED,
        EnrollmentStatus.CARRIER_APPLICATION_SENT,
        EnrollmentStatus.ACTIVE_COVERAGE,
      ].includes(status)
    ) {
      areAllOnboardingPeriodsSubmitted = false;
    }

    if (
      ![
        EnrollmentStatus.WAIVED_ELECTION,
        EnrollmentStatus.WAIVED_COVERAGE,
      ].includes(status)
    ) {
      areAllOnboardingPeriodsWaived = false;
    }

    // Early exit if all conditions are already false
    if (
      !areAllOnboardingPeriodsActive &&
      !areAllOnboardingPeriodsSubmitted &&
      !areAllOnboardingPeriodsWaived
    ) {
      break;
    }
  }
  return {
    areAllOnboardingPeriodsActive,
    areAllOnboardingPeriodsSubmitted,
    areAllOnboardingPeriodsWaived,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function ActionMenu({
  setIsModalOpenById,
  selectedEmployeesCount,
  hasOnboardingPeriod,
  isGallagherMode,
  isAgent,
  isAccountSupervisor,
  isOmnipotentAdmin,
  isDon,
  hasEnrollmentPeriod,
  enrollmentStatuses,
}: ActionMenuProps) {
  const isMulti = selectedEmployeesCount > 1;
  const {
    areAllOnboardingPeriodsActive,
    areAllOnboardingPeriodsSubmitted,
    areAllOnboardingPeriodsWaived,
  } = areAllStatuses(enrollmentStatuses);

  return (
    <Menu
      width={256}
      data-testid="actions-menu"
      disabled={selectedEmployeesCount === 0}
      offset={6}
    >
      <Menu.Target>
        <Button
          size="sm"
          fw={500}
          fz="sm"
          rightSection={<IconChevronDown />}
          variant={selectedEmployeesCount === 0 ? 'subtle' : 'filled'}
          disabled={selectedEmployeesCount === 0}
        >
          Actions
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <ActionMenuSubsection
          title="Communication"
          setIsModalOpenById={setIsModalOpenById}
          icon={IconMail}
          items={
            <Menu
              width={256}
              data-testid="actions-menu-submenu"
              position="left-start"
              closeOnItemClick={false}
              offset={6}
            >
              <Menu.Target>
                <Menu.Item>
                  <Group justify="space-between">
                    <Text size="sm" fw="400">
                      Send an email
                    </Text>
                    <Icon icon={IconChevronRight} />
                  </Group>
                </Menu.Item>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() =>
                    setIsModalOpenById(MenuActions.ACCOUNT_ACTIVATION)
                  }
                >
                  <Text size="sm">{MenuActions.ACCOUNT_ACTIVATION}</Text>
                </Menu.Item>
                <Menu.Item
                  disabled={!areAllOnboardingPeriodsActive}
                  onClick={() =>
                    setIsModalOpenById(MenuActions.ELECTION_REMINDER)
                  }
                >
                  <Text size="sm">{MenuActions.ELECTION_REMINDER}</Text>
                </Menu.Item>
                <Menu.Item
                  disabled={!areAllOnboardingPeriodsSubmitted}
                  onClick={() =>
                    setIsModalOpenById(MenuActions.ELECTION_SUMMARY)
                  }
                >
                  <Text size="sm">{MenuActions.ELECTION_SUMMARY}</Text>
                </Menu.Item>
                <Menu.Item
                  disabled={!areAllOnboardingPeriodsWaived}
                  onClick={() =>
                    setIsModalOpenById(MenuActions.WAIVER_CONFIRMATION)
                  }
                >
                  <Text size="sm">{MenuActions.WAIVER_CONFIRMATION}</Text>
                </Menu.Item>
                {isGallagherMode && (
                  <>
                    <Menu.Item disabled>
                      <Text size="sm">Onboarding period launch</Text>
                    </Menu.Item>
                    <Menu.Item disabled>
                      <Text size="sm">Onboarding deadline reminder</Text>
                    </Menu.Item>
                  </>
                )}
              </Menu.Dropdown>
            </Menu>
          }
          shouldShowMenuDivider
        />
        <ActionMenuSubsection
          title="Benefit management"
          setIsModalOpenById={setIsModalOpenById}
          icon={IconEdit}
          items={[
            {
              title: MenuActions.CHANGE_SHOPPING_STATUS,
              isDisabled: !hasOnboardingPeriod,
              isHidden: isDon || !isOmnipotentAdmin,
            },
            {
              title: MenuActions.WAIVE_COVERAGE,
              isDisabled: !hasOnboardingPeriod,
              isHidden: isDon,
            },
            {
              title: MenuActions.ADD_ENROLLMENT_RECORD,
              isHidden: isDon,
              isDisabled: !hasEnrollmentPeriod,
            },
            {
              title: MenuActions.EDIT_ENROLLMENT_RECORD,
              isHidden: isDon || isMulti,
              isDisabled: !hasEnrollmentPeriod || !hasOnboardingPeriod,
            },
            {
              title: MenuActions.DELETE_ENROLLMENT_RECORD,
              isHidden: isDon || isMulti,
              isDisabled: !hasOnboardingPeriod,
            },
            {
              title: MenuActions.CHANGE_ELECTION_WINDOW,
              isHidden: isDon,
              isDisabled: !hasEnrollmentPeriod || !hasOnboardingPeriod,
            },
            {
              title: MenuActions.UPDATE_ELIGIBILITY,
              isDisabled: isAccountSupervisor,
              isHidden: isDon || (isAgent && !isAccountSupervisor),
            },
            {
              title: MenuActions.DOWNLOAD_PLAN_LIST,
              isDisabled: !hasOnboardingPeriod,
              isHidden: isDon || isMulti,
            },
          ]}
          shouldShowMenuDivider={!isAgent || !isMulti}
          isHidden={isDon}
        />
        <ActionMenuSubsection
          title="Employee management"
          setIsModalOpenById={setIsModalOpenById}
          icon={IconHandClick}
          items={[
            {
              title: MenuActions.EDIT_COMPANY_EMAIL,
              isHidden: isMulti,
            },
            {
              title: MenuActions.LOG_IN_AS,
              isHidden: isMulti || isDon,
            },
            {
              title: isMulti
                ? MenuActions.DELETE_EMPLOYEES
                : MenuActions.DELETE_EMPLOYEE,
              isHidden: isDon || isAgent,
            },
            {
              title: MenuActions.SET_ROLE,
              isHidden: isDon || (isAgent && !isAccountSupervisor),
            },
            {
              title: MenuActions.RESET_PASSWORD,
              isHidden: isDon || isMulti || isAgent,
            },
            {
              title: MenuActions.TERMINATE_EMPLOYEE,
              isHidden: isMulti,
            },
            {
              title: MenuActions.SET_LEAVE_OF_ABSENCE,
              isHidden: isMulti,
            },
          ]}
          shouldShowMenuDivider={false}
          isHidden={isAgent && isMulti}
        />
      </Menu.Dropdown>
    </Menu>
  );
}
