import { useQueryClient } from '@tanstack/react-query';
import { BagIcon } from '@zorro/shared/assets';
import {
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useDynamicRouter,
} from '@zorro/shared/utils';
import { RoleDisplayName, Roles } from '@zorro/types';
import {
  Button,
  Center,
  Drawer,
  Flex,
  Grid,
  Select,
  Space,
  Table,
  Text,
} from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ModalControlProps } from '../../ModalControls';

type SelectedEmployees = {
  userId: string;
  firstName: string;
  lastName: string;
};

export type SetRoleDrawerProps = {
  selectedEmployees: SelectedEmployees[];
  currentRole: Roles | null;
  modalControlProps: ModalControlProps;
  isOpened: boolean;
};

export const ChangeEmployeeRoleDrawer = ({
  selectedEmployees,
  currentRole,
  modalControlProps,
  isOpened,
}: SetRoleDrawerProps) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [selectedRole, setSelectedRole] = useState<Roles | null>(null);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);
  const { onCloseModal } = modalControlProps;

  async function changeEmployeeRole(newRole: Roles | null) {
    try {
      startLoading();

      if (!newRole) {
        return;
      }

      const employeeBatches = _chunk(selectedEmployees, 5);

      let results: PromiseSettledResult<unknown>[] = [];
      for (const batch of employeeBatches) {
        const batchResults = await Promise.allSettled(
          batch.map(({ userId }) => {
            return callEndpoint({
              method: 'usersControllerChangeCustomerFacingRole',
              params: [userId, { role: newRole }],
            });
          })
        );

        results = [...results, ...batchResults];
      }

      await queryClient.invalidateQueries();
      reloadPage();
      stopLoading();

      await handleBatchNotifications(
        results,
        { singular: 'employee' },
        'updated'
      );
      const resultsErros = getErrorsFromPromises(
        results,
        selectedEmployees.map(({ firstName, lastName }) => ({
          value: `${firstName} ${lastName}`,
        })),
        'value'
      );

      if (resultsErros.length > 0 && selectedEmployees.length > 1) {
        setErrors(resultsErros);
      }

      if (resultsErros.length === 0) {
        onCloseModal();
      }
    } catch {
      /* empty */
    }
  }

  return (
    <Drawer
      onClose={() => {
        onCloseModal();
        setErrors([]);
      }}
      opened={isOpened}
      title={
        <Flex align="center">
          <BagIcon />
          <Text size="xl" ml="sm" fw={500}>
            Set a role
          </Text>
        </Flex>
      }
    >
      <>
        <Center mt="md">
          <Select
            label="Role"
            placeholder="Role"
            data={[
              {
                value: Roles.EMPLOYEE,
                label: RoleDisplayName.EMPLOYEE,
                disabled: currentRole === Roles.EMPLOYEE,
              },
              {
                value: Roles.EMPLOYER_ADMIN,
                label: RoleDisplayName.ADMIN,
                disabled: currentRole
                  ? Roles.EMPLOYER_ADMIN.includes(currentRole)
                  : false,
              },
            ]}
            onChange={(value) => {
              if (value) {
                setSelectedRole(value as Roles);
              }
            }}
          />
        </Center>

        <Space h="xl" />

        <Center>
          <Button
            onClick={async () => await changeEmployeeRole(selectedRole)}
            size="lg"
            disabled={!selectedRole}
          >
            Save
          </Button>
        </Center>
        {errors && errors?.length > 0 && (
          <Grid gutter="xl">
            <Grid.Col>
              <Text c="zorroFire.7" size="md" fw="bold">
                Errors
              </Text>
              <Table
                columns={[
                  {
                    accessor: 'value',
                    title: 'Employee name',
                  },
                  { accessor: 'error', title: 'Message' },
                ]}
                records={errors}
                idAccessor="employeeId"
              />
            </Grid.Col>
          </Grid>
        )}
      </>
    </Drawer>
  );
};
