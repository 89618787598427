import { Checkbox, CheckboxGroupProps } from '@mantine/core';

export type ICheckboxGroupProps = CheckboxGroupProps;

export const ZorroCheckboxGroup = ({
  size = 'sm',
  ...props
}: ICheckboxGroupProps) => {
  return (
    <Checkbox.Group
      size={size}
      styles={(theme) => ({
        label: {
          fontSize: theme.fontSizes.sm,
          fontWeight: 400,
        },
      })}
      {...props}
    />
  );
};
