import {
  MURRIETA_DYNAMIC_ROUTES,
  useDynamicMurrietaRouter,
} from '@zorro/shared/utils';
import { StrictExtract } from '@zorro/types';
import { Button, Stack } from '@zorro/zorro-ui-design';

import { InsuredFormInputs, useInsuredForm } from '../../InsuredForm';
import { MajorMedicalPlanCard } from '../../MajorMedicalPlanCard';
import {
  PremiumAmountFormInputs,
  usePremiumAmountForm,
} from '../../PremiumAmountForm';

type ElectionSubmittedTabRoutes = StrictExtract<
  typeof MURRIETA_DYNAMIC_ROUTES,
  | 'CARRIER_APPLICATION_ENROLLMENT_DETAILS'
  | 'ENROLLMENT_CONFIRMATION_ENROLLMENT_DETAILS'
>;

const isFinalizationMode = true;

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
  routeButton?: {
    route: ElectionSubmittedTabRoutes;
    label: string;
  };
};

export const ElectionSubmittedTabContent = ({
  employerId,
  employeeId,
  onboardingPeriodId,
  routeButton,
}: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { premiumAmountForm, employerMonthlyContribution } =
    usePremiumAmountForm({ employeeId, onboardingPeriodId });
  const insuredForm = useInsuredForm({
    isFinalizationMode,
    onboardingPeriodId,
    employeeId,
  });

  return (
    <Stack>
      <MajorMedicalPlanCard onboardingPeriodId={onboardingPeriodId} />
      <PremiumAmountFormInputs
        premiumAmountForm={premiumAmountForm}
        employerMonthlyContribution={employerMonthlyContribution}
        shouldShowInCard
        isReadonlyMode
      />
      <InsuredFormInputs
        isFinalizationMode={isFinalizationMode}
        insuredForm={insuredForm}
        employerId={employerId}
        isReadonlyMode
      />
      {routeButton && (
        <Button
          ml="auto"
          size="md"
          fw={400}
          onClick={() =>
            navigateToRoute(routeButton.route, [
              { employerId, employeeId, onboardingPeriodId },
            ])
          }
        >
          {routeButton.label}
        </Button>
      )}
    </Stack>
  );
};
