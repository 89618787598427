import { useAuth } from '@frontegg/nextjs';
import { AppShellSideBar, AppShellSideBarProps } from '@zorro/shared/ui';
import { ReactNode } from 'react';
import { HeaderSideBar } from '~/components/layouts/HeaderSideBar';

interface Props extends Omit<AppShellSideBarProps, 'headerComponent'> {
  children: ReactNode;
}

export function DefaultLayout({ children, ...props }: Props) {
  const { user, isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <AppShellSideBar
      {...props}
      headerComponent={
        <HeaderSideBar profilePicture={user?.profilePictureUrl} />
      }
    >
      {children}
    </AppShellSideBar>
  );
}
