import { yupResolver } from '@hookform/resolvers/yup';
import { EmployerDto, Gender } from '@zorro/clients';
import { InfoIconColored } from '@zorro/shared/assets';
import { getDateUtil, parseDate, parseDateISO } from '@zorro/shared/formatters';
import {
  addEmployeeSchema,
  getAdultDefaultDate,
  useForm,
} from '@zorro/shared/utils';
import {
  AddressInput,
  Box,
  Button,
  Center,
  CurrencyInput,
  DateInput,
  Flex,
  FormErrorMessage,
  Grid,
  InputWrapper,
  List,
  MonthPickerInput,
  PhoneInput,
  Space,
  Stack,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { EmployeeClassInput } from '../../EmployeeClassInput/EmployeeClassInput';
import { calculateEligibleFrom } from './CreateEmployeeUtils';

export interface CreateEmployeeFormFields {
  idFromEmployer?: string;
  firstName: string;
  lastName: string;
  email: string;
  personalEmail?: string;
  phone?: string;
  address?: string;
  dateOfBirth: Date;
  gender?: Gender | null;
  class: string;
  salary?: number;
  hireDate: Date;
  eligibleFrom: Date;
}

export type CreateEmployeeProps = {
  onCreateEmployee: (data: CreateEmployeeFormFields) => Promise<void>;
  employerId: EmployerDto['id'];
  waitingPeriod: EmployerDto['waitingPeriod'];
  effectiveFrom?: string;
};

export const CreateEmployeeForm = (props: CreateEmployeeProps) => {
  const {
    onCreateEmployee,
    employerId,
    waitingPeriod,
    effectiveFrom = '',
  } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { control, formState, watch, setValue, getValues } =
    useForm<CreateEmployeeFormFields>({
      mode: 'onBlur',
      resolver: yupResolver(addEmployeeSchema(waitingPeriod)),
    });

  const { errors, isValid } = formState;

  const hireDate = watch('hireDate');

  useEffect(() => {
    const calculatedEligibleFrom = getDateUtil().max(
      calculateEligibleFrom(hireDate, waitingPeriod),
      parseDateISO(effectiveFrom)
    );

    const eligibleFrom = getValues('eligibleFrom');
    const parsedEligibleFrom = parseDate(eligibleFrom);

    if (hireDate && !calculatedEligibleFrom.isSame(parsedEligibleFrom, 'day')) {
      setValue('eligibleFrom', calculatedEligibleFrom.toDate(), {
        shouldValidate: true,
      });
    }
  }, [getValues, setValue, hireDate, waitingPeriod, effectiveFrom]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onCreateEmployee(getValues());
    setIsSubmitting(false);
  };

  return (
    <Stack>
      <Flex
        align="flex-start"
        ta="center"
        p="0.75rem 0.75rem 0.75rem 1rem"
        style={(theme) => ({
          border: `1px solid ${theme.colors.zorroGray[6]}`,
          borderRadius: theme.radius.md,
        })}
      >
        <InfoIconColored />
        <Box ml="sm" ta="left">
          <Text size="sm" fw={600}>
            Here’s what to expect:
          </Text>
          <List size="sm" fw={400} pr="0.75rem" pl="0.5rem">
            <List.Item>
              After you add a new employee, the Zorro team will review their
              details and activate them in the system.
            </List.Item>
            <List.Item>
              The new employee will be notified of their upcoming benefit
              onboarding period with Zorro according to your communication
              preferences.
            </List.Item>
            <List.Item>
              To guarantee coverage on the eligibility date, you should add new
              employees as much in advance as possible and according to the
              guidelines and deadlines articulated during your Zorro employer
              admin set-up.
            </List.Item>
          </List>
        </Box>
      </Flex>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="idFromEmployer"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <TextInput
                {...rest}
                label="Employee ID"
                placeholder="Employee ID - optional"
              />
            )}
          />

          <FormErrorMessage fieldName="idFromEmployer" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <TextInput
                {...rest}
                required
                label="First name"
                placeholder="First name"
              />
            )}
          />

          <FormErrorMessage fieldName="firstName" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <TextInput
                {...rest}
                required
                label="Last name"
                placeholder="Last name"
              />
            )}
          />

          <FormErrorMessage fieldName="lastName" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <TextInput
                {...rest}
                required
                label="Company email"
                placeholder="Company email"
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="email" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="personalEmail"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <TextInput
                {...rest}
                label="Personal email"
                placeholder="Personal Email"
              />
            )}
          />

          <FormErrorMessage fieldName="personalEmail" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { value, ...rest }, fieldState: { error } }) => (
              <PhoneInput
                {...rest}
                label="Phone number"
                placeholder="Phone number - optional"
                control={control}
              />
            )}
          />

          <FormErrorMessage fieldName="phone" errors={errors} />
        </Grid.Col>

        <Grid.Col>
          <Controller
            control={control}
            name="address"
            render={({
              field: { onChange, ...rest },
              fieldState: { error },
            }) => (
              <AddressInput
                {...rest}
                notifySubscribers={(address: string) => onChange(address)}
                onChange={onChange}
                label="Address"
                placeholder="Address - optional"
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="address" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <DateInput
                {...rest}
                required
                label="Date of birth"
                defaultLevel="decade"
                defaultDate={getAdultDefaultDate()}
              />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, ...rest } }) => (
              <InputWrapper label="Gender">
                <TabButtonGroup {...rest} onChange={onChange}>
                  <Tabs.List grow>
                    <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                    <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                  </Tabs.List>
                </TabButtonGroup>
              </InputWrapper>
            )}
          />

          <FormErrorMessage errors={errors} fieldName="gender" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <EmployeeClassInput
            control={control}
            name="class"
            employerId={employerId}
          />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="salary"
            render={({ field: { ...rest } }) => (
              <CurrencyInput
                {...rest}
                label="Yearly salary"
                placeholder="Yearly Salary - optional"
              />
            )}
          />

          <FormErrorMessage fieldName="salary" errors={errors} />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="hireDate"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <DateInput {...rest} required label="Hire date" />
            )}
          />

          <FormErrorMessage errors={errors} fieldName="hireDate" />
        </Grid.Col>

        <Grid.Col span={{ sm: 6 }}>
          <Controller
            control={control}
            name="eligibleFrom"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <MonthPickerInput
                {...rest}
                label="Eligibility start date"
                monthsListFormat="MMM D"
                valueFormat="MMMM D, YYYY"
                minDate={
                  effectiveFrom
                    ? parseDateISO(effectiveFrom).toDate()
                    : undefined
                }
              />
            )}
          />
          <FormErrorMessage errors={errors} fieldName="eligibleFrom" />
        </Grid.Col>
      </Grid>

      <Space h="lg" mt="lg" />

      <Center>
        <Button disabled={!isValid || isSubmitting} onClick={handleSubmit}>
          Add Employee
        </Button>
      </Center>
    </Stack>
  );
};
