import { IconUpload, IconUserPlus } from '@tabler/icons-react';
import { AddIcon } from '@zorro/shared/assets';
import { ActionIcon, Group, Icon, Menu, Text } from '@zorro/zorro-ui-design';

type EmployeeManagementActionsMenuProps = {
  handleAddNewEmployee: () => void;
  handleUploadRoster?: () => void;
  isDisabled?: boolean;
  isOperator?: boolean;
};

export function EmployeeManagementActionsMenu({
  handleAddNewEmployee,
  handleUploadRoster,
  isOperator,
  isDisabled = false,
}: EmployeeManagementActionsMenuProps) {
  return (
    <Menu width={256} data-testid="actions-menu" offset={6}>
      <Menu.Target>
        <ActionIcon w={34} h={34} grow radius="xl" variant="circle">
          <AddIcon />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={isDisabled ? undefined : handleUploadRoster}
          data-testid="employee-management-actions-menu-upload-roster"
          disabled={isDisabled || !isOperator}
        >
          <Group>
            <Icon icon={IconUpload} />
            <Text size="sm">Upload roster</Text>
          </Group>
        </Menu.Item>
        <Menu.Item
          onClick={isDisabled ? undefined : handleAddNewEmployee}
          data-testid="employee-management-actions-menu-upload-roster"
          disabled={isDisabled}
        >
          <Group>
            <Icon icon={IconUserPlus} />
            <Text size="sm">Add a new employee</Text>
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
