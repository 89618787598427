import { RadioGroupProps } from '@mantine/core';
import { AgentIcon, EmployeeIcon } from '@zorro/shared/assets';
import {
  Box,
  FormErrorMessage,
  Grid,
  Icon,
  RadioCard,
  RadioGroup,
  brand,
} from '@zorro/zorro-ui-design';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

export enum AgentOrEmployee {
  AGENT = 'AGENT',
  EMPLOYEE = 'EMPLOYEE',
}

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  radioGroupProps?: RadioGroupProps;
  label?: string;
  description?: string;
};

export function AgentOrEmployeeInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  radioGroupProps,
  label,
  description,
  ...formProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    formState: { errors },
  } = useController(formProps);
  return (
    <>
      <RadioGroup
        {...field}
        label={label}
        description={description}
        variant="with-description"
      >
        <Grid>
          <Grid.Col span={6}>
            <RadioCard
              icon={
                <Box
                  bg={brand.zorroYolk200}
                  w="2rem"
                  h="2rem"
                  style={(theme) => ({
                    borderRadius: theme.spacing.xxs,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <Icon icon={AgentIcon} />
                </Box>
              }
              label="Enrollment team agent"
              value={AgentOrEmployee.AGENT}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <RadioCard
              icon={
                <Box
                  bg={brand.primaryButtonSelected}
                  w="2rem"
                  h="2rem"
                  style={(theme) => ({
                    borderRadius: theme.spacing.xxs,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  })}
                >
                  <Icon icon={EmployeeIcon} />
                </Box>
              }
              label="Employee"
              value={AgentOrEmployee.EMPLOYEE}
            />
          </Grid.Col>
        </Grid>
      </RadioGroup>
      <FormErrorMessage fieldName={formProps.name} errors={errors} />
    </>
  );
}

AgentOrEmployeeInput.displayName = 'AgentOrEmployeeInput';
