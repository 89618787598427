/* eslint-disable unicorn/filename-case */
import {
  callEndpoint,
  exportEmployeePlanList,
  responseErrorToString,
  showErrorNotification,
} from '@zorro/shared/utils';

import { ModalControlProps } from '../../ModalControls';

type OnboardingPeriodIdAndName = {
  onboardingPeriodId: string;
  fullName: string;
};

export function downloadPlans(
  onboardingPeriodIdAndNames: OnboardingPeriodIdAndName[],
  employerName: string,
  modalControlProps: ModalControlProps
) {
  const { loadingOverlayContext, onCloseModal } = modalControlProps;
  const { startLoading, stopLoading } = loadingOverlayContext;
  onCloseModal();
  startLoading();
  const promises = onboardingPeriodIdAndNames.map(
    async ({ onboardingPeriodId, fullName }) => {
      const medicalPlans = await callEndpoint({
        method: 'majorMedicalControllerSearchMedicalPlansForOnboardingPeriod',
        params: [onboardingPeriodId, {}],
      });
      return exportEmployeePlanList(medicalPlans, fullName, employerName);
    }
  );

  Promise.all(promises)
    .catch((error) => {
      showErrorNotification({ message: responseErrorToString(error) });
    })
    .finally(() => stopLoading());
}
