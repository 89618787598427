import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Gender, InsuredSubtype } from '@zorro/clients';
import { DateUtilInstance, getNowAsDate } from '@zorro/shared/formatters';
import {
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
  validationMessages,
} from '@zorro/shared/utils';
import { YesNo, insuredSubtypeLabelConfig } from '@zorro/types';
import {
  ActionIcon,
  AddressInput,
  Box,
  Button,
  Collapse,
  DateInput,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  InputWrapper,
  PasswordInput,
  Select,
  Space,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { Fragment, KeyboardEvent, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import * as yup from 'yup';

import { CheckmarkBoxWrapper } from './CheckmarkBoxWrapper';
import { getInsuredBaseSchema } from './InsuredFormUtils';

const dependentRelationshipValues = Object.values([
  InsuredSubtype.CHILD,
  InsuredSubtype.OTHER_DEPENDENT,
]);

const insuredTypeOptions = dependentRelationshipValues.map((status) => ({
  label: insuredSubtypeLabelConfig[status],
  value: status,
}));

const getDependentSchema = (
  isFinalizationMode: boolean,
  minDateOfBirth: Date
) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      dateOfBirth: yup
        .date()
        .min(minDateOfBirth, validationMessages.dependentMaxAgeValid)
        .typeError(validationMessages.dateOfBirthRequired)
        .required(validationMessages.dateOfBirthRequired),
      ...(isFinalizationMode
        ? {
            subtype: yup
              .mixed<InsuredSubtype>()
              .required(validationMessages.relationshipRequired)
              .oneOf(
                dependentRelationshipValues,
                validationMessages.relationshipRequired
              ),
          }
        : {
            subtype: yup
              .mixed<InsuredSubtype>()
              .oneOf(
                dependentRelationshipValues,
                validationMessages.relationshipRequired
              )
              .nullable(),
          }),
    })
  );

export const getDependentInsuredSchema = (
  isFinalizationMode: boolean,
  minDateOfBirth: Date = getNowAsDate()
) => {
  return yup.object({
    dependents: yup
      .array()
      .of(getDependentSchema(isFinalizationMode, minDateOfBirth))
      .required(),
  });
};

export type InsuredDependentFormFields = yup.InferType<
  ReturnType<typeof getDependentSchema>
>;
export type InsuredDependentsFormFields = yup.InferType<
  ReturnType<typeof getDependentInsuredSchema>
>;

type DependentFormProps = {
  index: number;
  control: Control<InsuredDependentsFormFields>;
  formState: FormState<InsuredDependentsFormFields>;
  watch: UseFormWatch<InsuredDependentsFormFields>;
  trigger: UseFormTrigger<InsuredDependentsFormFields>;
  handleDeleteDependent: (index: number) => void;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isReadonlyMode?: boolean;
};

const DependentForm = ({
  index,
  control,
  formState,
  watch,
  trigger,
  handleDeleteDependent,
  targetEnrollmentDate,
  isFinalizationMode,
  isReadonlyMode,
}: DependentFormProps) => {
  const dependent = watch(`dependents.${index}`);
  const [isOpen, setIsOpen] = useState<boolean>(!isReadonlyMode);
  let isValid: boolean;
  const minDateOfBirth = insuredMinDateOfBirth(true, targetEnrollmentDate);
  try {
    isValid = minDateOfBirth
      ? getDependentInsuredSchema(
          isFinalizationMode,
          minDateOfBirth
        ).isValidSync(dependent)
      : true;
  } catch {
    isValid = false;
  }

  const dependentErrors = formState.errors?.dependents?.[index] || {};

  const isFilledOut = isValid && Object.keys(dependentErrors).length === 0;
  const fullName = `${dependent.firstName || ''} ${
    dependent.lastName || ''
  }`.trim();
  const isFemale = dependent.gender === Gender.FEMALE;
  const subtypeLabel =
    dependent.subtype === InsuredSubtype.OTHER_DEPENDENT
      ? 'Dependent'
      : 'Child';

  const handleArrowClick = async () => {
    if (isReadonlyMode) {
      setIsOpen((prev) => !prev);
    } else {
      const result = await trigger();
      if (!isOpen || result) {
        setIsOpen((prev) => !prev);
      }
    }
  };

  useEffect(() => {
    if (!isValid && !isReadonlyMode) {
      setIsOpen(true);
    }
  }, [isValid, setIsOpen, isReadonlyMode]);

  return (
    <CheckmarkBoxWrapper
      hasErrors={Object.keys(dependentErrors).length > 0 && !isReadonlyMode}
      isFilledOut={isFilledOut}
      isOpen={isOpen}
      w="100%"
    >
      <Group justify="space-between">
        <Text fw="600" className="fs-mask">
          {!fullName || isOpen ? 'Dependent' : `${subtypeLabel} (${fullName})`}
        </Text>

        <ActionIcon
          variant="transparent"
          onClick={handleArrowClick}
          onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
            if (event.code === 'Space' || event.code === 'Enter') {
              handleArrowClick();
            }
          }}
        >
          {isOpen ? (
            <Icon icon={IconChevronUp} aria-label="Collapse dependent form" />
          ) : (
            <Icon icon={IconChevronDown} aria-label="Expand dependent form" />
          )}
        </ActionIcon>
      </Group>

      <Collapse in={isOpen}>
        <Space h="xl" />
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.firstName`}
              control={control}
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="First name"
                  placeholder="First name"
                  readOnly={isReadonlyMode}
                  required
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.firstName`}
              />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.lastName`}
              control={control}
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="Last name"
                  placeholder="Last name"
                  readOnly={isReadonlyMode}
                  required
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.lastName`}
              />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.subtype`}
              control={control}
              render={({ field: { ...rest } }) => (
                <Select
                  {...rest}
                  label="Relationship"
                  placeholder="Select relationship"
                  data={insuredTypeOptions}
                  readOnly={isReadonlyMode}
                  required={isFinalizationMode}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.subtype`}
              />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.dateOfBirth`}
              control={control}
              render={({ field }) => (
                <DateInput
                  {...field}
                  label="Date of birth"
                  defaultLevel="decade"
                  minDate={insuredMinDateOfBirth(true, targetEnrollmentDate)}
                  maxDate={insuredMaxDateOfBirth(true, targetEnrollmentDate)}
                  readOnly={isReadonlyMode}
                  required
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.dateOfBirth`}
              />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.gender`}
              control={control}
              render={({ field }) => (
                <InputWrapper label="Gender" required={isFinalizationMode}>
                  <TabButtonGroup {...field}>
                    <Tabs.List grow>
                      <Tabs.Tab disabled={isReadonlyMode} value={Gender.MALE}>
                        Male
                      </Tabs.Tab>
                      <Tabs.Tab disabled={isReadonlyMode} value={Gender.FEMALE}>
                        Female
                      </Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.gender`}
              />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.isSmoker`}
              control={control}
              render={({ field: { ...rest } }) => (
                <InputWrapper
                  label="Tobacco User"
                  required={isFinalizationMode}
                >
                  <TabButtonGroup {...rest}>
                    <Tabs.List grow>
                      <Tabs.Tab disabled={isReadonlyMode} value={YesNo.YES}>
                        Yes
                      </Tabs.Tab>
                      <Tabs.Tab disabled={isReadonlyMode} value={YesNo.NO}>
                        No
                      </Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.isSmoker`}
              />
            )}
          </Grid.Col>

          {isFemale && (
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                name={`dependents.${index}.isPregnant`}
                control={control}
                render={({ field: { ...rest } }) => (
                  <InputWrapper label="Pregnant" required={isFinalizationMode}>
                    <TabButtonGroup {...rest}>
                      <Tabs.List grow>
                        <Tabs.Tab disabled={isReadonlyMode} value={YesNo.YES}>
                          Yes
                        </Tabs.Tab>
                        <Tabs.Tab disabled={isReadonlyMode} value={YesNo.NO}>
                          No
                        </Tabs.Tab>
                      </Tabs.List>
                    </TabButtonGroup>
                  </InputWrapper>
                )}
              />

              {!isReadonlyMode && (
                <FormErrorMessage
                  errors={formState.errors}
                  fieldName={`dependents.${index}.isPregnant`}
                />
              )}
            </Grid.Col>
          )}

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name={`dependents.${index}.ssn`}
              control={control}
              render={({ field: { value, ...rest } }) => (
                <PasswordInput
                  {...rest}
                  label="SSN"
                  placeholder="XXXXXXXXX"
                  value={value || ''}
                  maxLength={9}
                  onKeyDown={validateOnlyNumbers}
                  readOnly={isReadonlyMode}
                  required={isFinalizationMode}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.ssn`}
              />
            )}
          </Grid.Col>

          <Grid.Col>
            <Controller
              control={control}
              name={`dependents.${index}.residentialAddress`}
              render={({ field: { value, onBlur, onChange, ...rest } }) => (
                <AddressInput
                  {...rest}
                  onBlur={() => {
                    onChange(value);
                    onBlur();
                  }}
                  notifySubscribers={(newAddress) => onChange(newAddress)}
                  onChange={onChange}
                  label="Residential address"
                  value={value || ''}
                  placeholder="Type residential address"
                  readOnly={isReadonlyMode}
                  required={isFinalizationMode}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={formState.errors}
                fieldName={`dependents.${index}.residentialAddress`}
              />
            )}
          </Grid.Col>

          {!isReadonlyMode && (
            <Grid.Col style={{ textAlign: 'right' }}>
              <Button
                variant="subtle"
                p={1}
                onClick={() => handleDeleteDependent(index)}
                onKeyDown={(event: KeyboardEvent<HTMLButtonElement>) => {
                  if (event.code === 'Space' || event.code === 'Enter') {
                    handleDeleteDependent(index);
                  }
                }}
              >
                Delete
              </Button>
            </Grid.Col>
          )}
        </Grid>
      </Collapse>
    </CheckmarkBoxWrapper>
  );
};

type DependentsFormProps = {
  control: Control<InsuredDependentsFormFields>;
  formState: FormState<InsuredDependentsFormFields>;
  watch: UseFormWatch<InsuredDependentsFormFields>;
  trigger: UseFormTrigger<InsuredDependentsFormFields>;
  onDeleteDependent: (index: number) => void;
  onAppendDependent: () => void;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isReadonlyMode?: boolean;
};

export const DependentsFormInputs = ({
  control,
  formState,
  watch,
  trigger,
  isReadonlyMode,
  isFinalizationMode,
  onAppendDependent,
  onDeleteDependent,
  targetEnrollmentDate,
}: DependentsFormProps) => {
  const dependents = watch('dependents');

  return (
    <>
      {dependents.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DependentForm
            handleDeleteDependent={() => onDeleteDependent(index)}
            targetEnrollmentDate={targetEnrollmentDate}
            isFinalizationMode={isFinalizationMode}
            isReadonlyMode={isReadonlyMode}
            formState={formState}
            control={control}
            trigger={trigger}
            watch={watch}
            index={index}
          />
        </Fragment>
      ))}
      {!isReadonlyMode && (
        <Box
          w="100%"
          mx="auto"
          opacity={1}
          tabIndex={0}
          p="18px 48px"
          pos="relative"
          bg="zorroWhite.0"
          style={(theme) => ({
            cursor: 'pointer',
            borderRadius: theme.radius.md,
            boxShadow: `inset 0 0 0 1px ${theme.colors.zorroGray[7]}`,
          })}
          onClick={onAppendDependent}
          onKeyDown={(event) => {
            if (event.code === 'Space' || event.code === 'Enter') {
              event.preventDefault();
              onAppendDependent();
            }
          }}
        >
          <Text ta="center" c="zorroIris.9">
            + Add dependent (under 26)
          </Text>
        </Box>
      )}
    </>
  );
};
