export const SupportIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#E8E8E8" />
      <path
        d="M14.8572 26.2855V22.857C14.8572 20.4321 15.8204 18.1066 17.5351 16.392C19.2497 14.6774 21.5752 13.7141 24 13.7141C26.4249 13.7141 28.7504 14.6774 30.465 16.392C32.1796 18.1066 33.1429 20.4321 33.1429 22.857V26.2855"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8571 32C30.8571 33.8937 27.7874 35.4286 24 35.4286"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8572 26.2857C14.8572 25.6795 15.098 25.0981 15.5266 24.6695C15.9553 24.2408 16.5367 24 17.1429 24H18.2857C18.892 24 19.4733 24.2408 19.902 24.6695C20.3306 25.0981 20.5715 25.6795 20.5715 26.2857V29.7143C20.5715 30.3205 20.3306 30.9019 19.902 31.3305C19.4733 31.7592 18.892 32 18.2857 32H17.1429C16.5367 32 15.9553 31.7592 15.5266 31.3305C15.098 30.9019 14.8572 30.3205 14.8572 29.7143V26.2857Z"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4285 26.2857C27.4285 25.6795 27.6693 25.0981 28.0979 24.6695C28.5266 24.2408 29.108 24 29.7142 24H30.857C31.4632 24 32.0446 24.2408 32.4733 24.6695C32.9019 25.0981 33.1428 25.6795 33.1428 26.2857V29.7143C33.1428 30.3205 32.9019 30.9019 32.4733 31.3305C32.0446 31.7592 31.4632 32 30.857 32H29.7142C29.108 32 28.5266 31.7592 28.0979 31.3305C27.6693 30.9019 27.4285 30.3205 27.4285 29.7143V26.2857Z"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
