import {
  SAVED_SUCCESSFULLY_MESSAGE,
  showSuccessNotification,
} from '@zorro/shared/utils';
import { Button, Drawer, Stack } from '@zorro/zorro-ui-design';

import { useLoadingOverlay } from '../LoadingOverlayContext';
import { PlanYearSetupForm } from './PlanYearSetupForm';
import { usePlanYearSetupForm } from './usePlanYearSetupForm';

const isFinalizationMode = true;

type Props = {
  setIsOpen: (open: boolean) => void;
  employerId: string;
  isOpen: boolean;
  isEdit: boolean;
  openEnrollmentPeriodId?: string;
};

export const PlanYearSetupDrawer = ({
  employerId,
  setIsOpen,
  isOpen,
  isEdit,
  openEnrollmentPeriodId,
}: Props) => {
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { planYearSetupForm, onSubmit } = usePlanYearSetupForm({
    selectedOpenEnrollmentPeriodId: openEnrollmentPeriodId,
    isFinalizationMode,
    employerId,
  });

  const { isValid, isDirty } = planYearSetupForm.formState || {};
  const isSubmitDisabled = isEdit ? !isValid || !isDirty : !isValid;

  const handleClose = () => {
    planYearSetupForm.reset();
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    startLoading();
    if (await onSubmit()) {
      showSuccessNotification({ message: SAVED_SUCCESSFULLY_MESSAGE });
      handleClose();
    }

    stopLoading();
  };

  return (
    <Drawer
      title={isEdit ? 'Edit plan year' : 'New plan year'}
      onClose={handleClose}
      opened={isOpen}
    >
      <Stack>
        <PlanYearSetupForm
          planYearSetupForm={planYearSetupForm}
          isFinalizationMode
        />
        <Button
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          size="md"
          m="auto"
          mt="lg"
        >
          Submit
        </Button>
      </Stack>
    </Drawer>
  );
};
