/* eslint-disable id-length */
import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

import { ITextProps, ZorroText } from '../Text';
import classNames from './Link.module.scss';

export interface ILinkProps extends LinkProps {
  textProps?: ITextProps;
  variant?: 'default' | 'subtle';
  children: string | ReactNode;
}

export const ZorroLink = ({
  children,
  variant,
  textProps,
  ...props
}: ILinkProps) => {
  return (
    <Link {...props} className={classNames.link} data-variant={variant}>
      <ZorroText {...textProps}>{children}</ZorroText>
    </Link>
  );
};
