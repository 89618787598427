import { EmployeeDto, InsuredPersonDto } from '@zorro/clients';
import { YesNo } from '@zorro/types';

export type EmployeeTransformed = Omit<
  EmployeeDto,
  'dateOfBirth' | 'id' | 'shortId' | 'userId' | 'employerId'
> & {
  dateOfBirth: Date;
};

export type InsuredPersonTransformed = Omit<
  InsuredPersonDto,
  'dateOfBirth' | 'isSmoker' | 'isPregnant'
> & {
  dateOfBirth: Date;
  isSmoker: YesNo;
  isPregnant: YesNo;
};

export type EmployeeOnly = {
  employee: EmployeeTransformed;
};

export type EmployeeWithInsured = {
  employee: InsuredPersonTransformed & EmployeeTransformed;
  spouse?: InsuredPersonTransformed;
  dependents?: InsuredPersonTransformed[];
};

export type InsuredDetailsEditFormFields = EmployeeOnly | EmployeeWithInsured;

export enum InsuredTypes {
  SPOUSE = 'SPOUSE',
  DEPENDENT = 'Dependent',
}

export type InsuredDetailsAddFormFields = InsuredPersonTransformed &
  EmployeeTransformed & {
    insuredType?: InsuredTypes;
  };
