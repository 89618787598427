import { yupResolver } from '@hookform/resolvers/yup';
import { IconInfoCircle } from '@tabler/icons-react';
import { QueryObserverResult } from '@tanstack/react-query';
import { FamilyUnit, SelfReportType } from '@zorro/clients';
import { HealthPlanIcon } from '@zorro/shared/assets';
import {
  EMPLOYEE_HEALTH_PLAN_UPDATE_SUCCESS_MESSAGE,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
  useForm,
  validationMessages,
} from '@zorro/shared/utils';
import {
  CreateFamilyFormFields,
  EmployeePageData,
  getDefaultCoveredOptionsSupplementals,
} from '@zorro/types';
import {
  Box,
  Button,
  Center,
  Checkbox,
  CurrencyInput,
  Divider,
  Drawer,
  Flex,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  Select,
  Space,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { CarrierInput } from '../../CarrierInput';
import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { SelfReportTypeInput } from '../../SelfReportTypeInput';

function createFamilyFormFieldsSupplementals(
  hasSpouse: boolean,
  hasDependents: boolean,
  isEdgeCase: boolean
): CreateFamilyFormFields[] {
  return Object.values(
    getDefaultCoveredOptionsSupplementals(hasSpouse, hasDependents, isEdgeCase)
  );
}

type EmployeeHealthPlanFormFields = {
  waive: boolean;
  edgeCase: boolean;
  familyUnit: FamilyUnit;
  premium: number;
  externalID: string;
  employeeMonthlyContribution: number;
  carrierName: string;
  planName: string;
  allowance: number;
  selfPay: boolean;
  selfPayAmount: number;
  isCombinedPlan: boolean;
  extendedPlanDetails: string;
  selfReportType: SelfReportType;
};

const editEmployeeHealthPlanSchema = yup.object({
  waive: yup.boolean().required(validationMessages.waiveRequired),
  edgeCase: yup.boolean().required(validationMessages.edgeCaseRequired),
  familyUnit: yup
    .mixed<FamilyUnit>()
    .oneOf(Object.values(FamilyUnit), validationMessages.familyUnitRequired)
    .typeError(validationMessages.familyUnitRequired)
    .required(validationMessages.familyUnitRequired),
  premium: yup
    .number()
    .min(0, validationMessages.premiumPositive)
    .when(['waive', 'edgeCase'], {
      is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
      then: (schema) =>
        schema
          .required(validationMessages.premiumRequired)
          .typeError(validationMessages.premiumRequired)
          .positive(validationMessages.premiumPositive),
    }),
  externalID: yup.string().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && !edgeCase,
    then: (schema) => schema.required(validationMessages.externalIDRequired),
  }),
  employeeMonthlyContribution: yup.number().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
    then: (schema) =>
      schema
        .required(validationMessages.employeeMonthlyContributionRequired)
        .typeError(validationMessages.employeeMonthlyContributionRequired)
        .min(0, validationMessages.employeeMonthlyContributionNotNegative),
  }),
  carrierName: yup.string().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
    then: (schema) => schema.required(validationMessages.carrierRequired),
  }),
  planName: yup.string().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
    then: (schema) => schema.required(validationMessages.carrierRequired),
  }),
  allowance: yup.number().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => {
      return !waive && edgeCase;
    },
    then: (schema) =>
      schema
        .required(validationMessages.allowanceRequired)
        .typeError(validationMessages.allowanceRequired)
        .min(0, validationMessages.allowanceNotNegative),
  }),
  selfPay: yup.boolean().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
    then: (schema) =>
      schema
        .required(validationMessages.selfPayRequired)
        .typeError(validationMessages.selfPayRequired),
  }),
  selfPayAmount: yup.number().when(['waive', 'selfPay'], {
    is: (waive: boolean, selfPay: boolean) => !waive && selfPay,
    then: (schema) =>
      schema
        .required(validationMessages.selfPayRequired)
        .typeError(validationMessages.selfPayRequired)
        .positive(validationMessages.selfPayPositive),
  }),
  isCombinedPlan: yup.boolean().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
    then: (schema) =>
      schema
        .required(validationMessages.isCombinedPlanRequired)
        .typeError(validationMessages.isCombinedPlanRequired),
  }),
  extendedPlanDetails: yup.string(),
  selfReportType: yup.mixed<SelfReportType>().when(['waive', 'edgeCase'], {
    is: (waive: boolean, edgeCase: boolean) => !waive && edgeCase,
    then: (schema) =>
      schema
        .oneOf(
          Object.values(SelfReportType),
          validationMessages.selfReportTypeRequired
        )
        .nullable(),
  }),
});

export type EditEmployeeHealthPlanDrawerProps = {
  isOpened: boolean;
  setIsOpened: (open: boolean) => void;
  hasSpouse: boolean;
  hasDependents: boolean;
  employeePageData: EmployeePageData;
  selectedOnboardingPeriodId: string;
  refetchEmployeeData: () => Promise<
    QueryObserverResult<EmployeePageData | null>
  >;
};

export const EditEmployeeHealthPlanDrawer = ({
  isOpened,
  setIsOpened,
  hasSpouse,
  hasDependents,
  employeePageData,
  selectedOnboardingPeriodId,
  refetchEmployeeData,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
EditEmployeeHealthPlanDrawerProps) => {
  const { startLoading, stopLoading } = useLoadingOverlay();

  const majorMedicalBenefit =
    employeePageData?.selectedOnboardingPeriod?.majorMedicalBenefit;
  const plan = majorMedicalBenefit?.plan;
  const selfReportType = plan?.selfReportType || SelfReportType.NOT_APPLICABLE;
  const { control, watch, formState, getValues } =
    useForm<EmployeeHealthPlanFormFields>({
      mode: 'onBlur',
      resolver: yupResolver(editEmployeeHealthPlanSchema),
      defaultValues: isOpened
        ? {
            familyUnit: plan?.familyUnit || undefined,
            carrierName: plan?.carrierName || '',
            allowance: plan?.employerMonthlyContribution || 0,
            planName: plan?.name || '',
            externalID: plan?.externalID || '',
            premium: plan?.premium || 0,
            waive: majorMedicalBenefit?.isWaived || false,
            edgeCase:
              plan?.isManuallyEntered ||
              plan?.isCombinedPlan ||
              selfReportType !== SelfReportType.NOT_APPLICABLE ||
              false,
            employeeMonthlyContribution: plan?.employeeMonthlyContribution || 0,
            selfPayAmount: plan?.selfPayAmount || 0,
            selfPay: Boolean(plan?.selfPayAmount),
            isCombinedPlan: plan?.isCombinedPlan || false,
            extendedPlanDetails: plan?.extendedPlanDetails || '',
            selfReportType: plan?.selfReportType || undefined,
          }
        : undefined,
    });

  const { isValid, isSubmitting, errors } = formState;

  const isWaived = watch('waive');
  const isEdgeCase = watch('edgeCase');
  const isCombinedPlan = watch('isCombinedPlan');
  const selfPay = watch('selfPay');
  const edgeCaseEditableField = isWaived || !isEdgeCase;
  const selfPayTooltipContent =
    "Amount paid to carrier from employee's credit card and not through Echo card.\nTypically the same as total premium.";

  const saveEmployeeHealthPlan = async () => {
    startLoading();
    const { edgeCase, ...data } = getValues();

    try {
      if (data.waive) {
        await callEndpoint({
          method: 'majorMedicalControllerPutMajorMedicalWaiverByAdmin',
          params: [selectedOnboardingPeriodId],
        });
      } else {
        const baseUpdateMajorMedicalPlanDto = {
          externalID: data.externalID,
          familyUnit: data.familyUnit,
          selfPayAmount: data.selfPay ? data.selfPayAmount : null,
        };

        await callEndpoint({
          method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
          params: [
            selectedOnboardingPeriodId,
            edgeCase,
            edgeCase
              ? {
                  updateManuallyEnteredMajorMedicalPlan: {
                    ...baseUpdateMajorMedicalPlanDto,
                    employeeMonthlyContribution:
                      data.employeeMonthlyContribution,
                    premium: data.premium,
                    allowance: data.allowance,
                    carrierName: data.carrierName,
                    name: data.planName,
                    isCombinedPlan: data.isCombinedPlan,
                    extendedPlanDetails: data.extendedPlanDetails,
                    selfReportType: data.selfReportType,
                    benefitsSummaryUrl: null,
                    maxOutOfPocket: null,
                    isHsaEligible: null,
                    deductible: null,
                  },
                }
              : {
                  updateExternalMajorMedicalPlan: baseUpdateMajorMedicalPlanDto,
                },
          ],
        });
      }

      showSuccessNotification({
        message: EMPLOYEE_HEALTH_PLAN_UPDATE_SUCCESS_MESSAGE,
      });
      await refetchEmployeeData();
      setIsOpened(false);
    } catch (error) {
      showErrorNotification({ message: responseErrorToString(error) });
    } finally {
      stopLoading();
    }
  };

  return (
    <Drawer
      title={
        <Flex align="center">
          <HealthPlanIcon imageAlt="Health plan" />
          <Text size="xl" ml="sm" fw={500}>
            Edit health plan
          </Text>
        </Flex>
      }
      opened={isOpened}
      onClose={() => setIsOpened(false)}
      size="xl"
      padding={40}
    >
      <>
        <Grid gutter="xl">
          <Grid.Col>
            <Group>
              <Controller
                control={control}
                name="waive"
                render={({ field: { value, ref: _ref, ...rest } }) => (
                  <Checkbox
                    {...rest}
                    size="md"
                    shouldHaveBorder={false}
                    isChecked={value}
                    label="Waive"
                  />
                )}
              />
              <Controller
                control={control}
                name="edgeCase"
                render={({ field: { value, ref: _ref, ...rest } }) => (
                  <Switch
                    {...rest}
                    size="md"
                    disabled={isWaived}
                    checked={value}
                    label="Edge case"
                    styles={(theme) => ({
                      label: {
                        fontSize: theme.fontSizes.md,
                        color: theme.colors.zorroCoal[9],
                      },
                      track: {
                        border: 'unset',
                        borderRadius: 24,
                      },
                      thumb: {
                        border: 'unset',
                      },
                    })}
                  />
                )}
              />
            </Group>
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="familyUnit"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <Select
                  {...rest}
                  required
                  disabled={isWaived}
                  label="Family unit"
                  placeholder="Family unit"
                  data={createFamilyFormFieldsSupplementals(
                    hasSpouse,
                    hasDependents,
                    isEdgeCase
                  )}
                />
              )}
            />
            <FormErrorMessage fieldName="familyUnit" errors={errors} />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="premium"
              render={({ field: { ...rest }, fieldState: { error } }) => (
                <CurrencyInput
                  {...rest}
                  placeholder="$0.00"
                  decimalScale={2}
                  label="Total premium"
                  disabled={edgeCaseEditableField}
                  required={!edgeCaseEditableField}
                />
              )}
            />
            <FormErrorMessage fieldName="premium" errors={errors} />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="employeeMonthlyContribution"
              render={({ field: { ...rest }, fieldState: { error } }) => (
                <CurrencyInput
                  {...rest}
                  disabled
                  label="Employee contribution"
                  placeholder="$0.00"
                />
              )}
            />
            <FormErrorMessage
              fieldName="employeeMonthlyContribution"
              errors={errors}
            />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="allowance"
              render={({ field: { ...rest }, fieldState: { error } }) => (
                <CurrencyInput
                  {...rest}
                  label="Allowance"
                  decimalScale={2}
                  placeholder="0.00"
                  disabled={edgeCaseEditableField}
                  required={!edgeCaseEditableField}
                />
              )}
            />
            <FormErrorMessage fieldName="allowance" errors={errors} />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="externalID"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <TextInput
                  {...rest}
                  required={!isEdgeCase}
                  disabled={isWaived}
                  label="Plan ID"
                  placeholder="Plan ID"
                />
              )}
            />
            <FormErrorMessage fieldName="externalID" errors={errors} />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <CarrierInput
              name="carrierName"
              control={control}
              isDisabled={edgeCaseEditableField}
              isRequired={!edgeCaseEditableField}
            />
          </Grid.Col>
          <Grid.Col>
            <Controller
              control={control}
              name="planName"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <TextInput
                  {...rest}
                  label="Plan Name"
                  placeholder="Plan name"
                  disabled={edgeCaseEditableField}
                  required={!edgeCaseEditableField}
                />
              )}
            />
            <FormErrorMessage fieldName="planName" errors={errors} />
          </Grid.Col>
          <Grid.Col>
            <Divider mb="lg" />
            <Controller
              control={control}
              name="selfPay"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  disabled={isWaived}
                  required={!isWaived}
                  label="Self pay (payment from personal credit card)"
                />
              )}
            />
          </Grid.Col>
          {selfPay && (
            <Grid.Col span={6}>
              <Controller
                control={control}
                name="selfPayAmount"
                render={({ field: { ...rest }, fieldState: { error } }) => (
                  <CurrencyInput
                    {...rest}
                    disabled={!selfPay || isWaived}
                    decimalScale={2}
                    label={
                      <Group gap="0.25rem" h="1.25rem">
                        <Text
                          style={{ whiteSpace: 'pre-line' }}
                          size="0.875rem"
                        >
                          Self pay amount
                        </Text>
                        <Tooltip
                          label={selfPayTooltipContent}
                          position="bottom"
                        >
                          <Box pos="relative">
                            <Icon icon={IconInfoCircle} size="1.25rem" />
                          </Box>
                        </Tooltip>
                      </Group>
                    }
                    placeholder="Employee payment amount"
                  />
                )}
              />
              <FormErrorMessage fieldName="selfPayAmount" errors={errors} />
            </Grid.Col>
          )}
          {isEdgeCase && (
            <>
              <Grid.Col>
                <Divider mb="lg" />
                <Controller
                  control={control}
                  name="isCombinedPlan"
                  render={({ field: { value, ref: _ref, ...rest } }) => (
                    <Checkbox
                      {...rest}
                      size="md"
                      shouldHaveBorder={false}
                      disabled={isWaived}
                      isChecked={value}
                      label="Combined plan"
                    />
                  )}
                />
              </Grid.Col>
              {isCombinedPlan && (
                <Grid.Col>
                  <Controller
                    control={control}
                    name="extendedPlanDetails"
                    render={({
                      field: { ref: _ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <TextInput
                        {...rest}
                        label="Extended plan details"
                        placeholder="Extended plan details"
                      />
                    )}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={6}>
                <SelfReportTypeInput control={control} name="selfReportType" />
              </Grid.Col>
            </>
          )}
        </Grid>
        <Space h="xl" />
        <Center mb="md" mt="xl">
          <Button
            disabled={!isWaived && (!isValid || isSubmitting)}
            onClick={saveEmployeeHealthPlan}
          >
            Save
          </Button>
        </Center>
      </>
    </Drawer>
  );
};
