import { FileWithPath } from '@mantine/dropzone';
import { GenericFileIcon } from '@zorro/shared/assets';
import { showErrorNotification } from '@zorro/shared/utils';
import { Dropzone, InputWrapper, Stack, Text } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

export type FileUploaderProps = {
  control: Control;
  name: string;
  label: string;
  accept?: string[];
  isLoading: boolean;
  children?: ReactNode;
  onDrop: (
    files: FileWithPath[],
    onDrop: () => void,
    onChange: (file: FileWithPath) => void
  ) => void;
  minHeight?: number;
  dataTestId?: string;
  isRequired?: boolean;
  isMultiple?: boolean;
  isDisabled?: boolean;
};

export const FileUploader = ({
  control,
  name,
  label,
  accept,
  isLoading,
  children,
  onDrop,
  minHeight,
  isMultiple = false,
  isDisabled = false,
  isRequired = false,
  buttonText,
  zoneLabelText,
}: FileUploaderProps & {
  buttonText: string;
  zoneLabelText: string;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref: _ref, onBlur, onChange, ...rest } }) => (
        <InputWrapper label={label} required={isRequired}>
          <Dropzone
            {...rest}
            accept={accept}
            multiple={isMultiple}
            onDrop={(files: File[]) => {
              onDrop(files, onBlur, onChange);
            }}
            onReject={(files) => {
              showErrorNotification({
                message: files[0].errors[0].message,
                title: files[0].errors[0].code,
              });
            }}
            loading={isLoading}
            disabled={isDisabled}
            minHeight={minHeight}
          >
            <Stack align="center" gap={12} w="100%">
              {children ?? <GenericFileIcon />}
              {buttonText && <Text>{buttonText}</Text>}
              {zoneLabelText && <Text>{zoneLabelText}</Text>}
            </Stack>
          </Dropzone>
        </InputWrapper>
      )}
    />
  );
};
