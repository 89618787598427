import { useAuth } from '@frontegg/nextjs';
import { EmployeeDto, UserDtoWithRoles } from '@zorro/clients';

import { useDynamicRouter } from './useDynamicRouter';
import { useMonolithQuery } from './useMonolithQuery';

export type ImpersonationContextProps = {
  impersonatedUserDetails?: UserDtoWithRoles | null;
  impersonatedEmployeeDetails?: EmployeeDto | null;
  isLoading: boolean;
};

export const useImpersonation = (): ImpersonationContextProps => {
  const { isAuthenticated } = useAuth();
  const { router } = useDynamicRouter({});
  const impersonatedEmployeeId = router.query.impersonatedEmployeeId
    ? (router.query.impersonatedEmployeeId as string)
    : '';
  const {
    data: impersonatedEmployeeDetails,
    isLoading: isImpersonatedEmployeeDetailsLoading,
  } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: isAuthenticated &&
      impersonatedEmployeeId && [impersonatedEmployeeId],
  });
  const {
    data: impersonatedUserDetails,
    isLoading: isImpersonatedUserDetailsLoading,
  } = useMonolithQuery({
    method: 'usersControllerGetFronteggUser',
    params: isAuthenticated &&
      impersonatedEmployeeDetails && [
        impersonatedEmployeeDetails.userId,
        impersonatedEmployeeDetails.employerId,
      ],
  });
  return {
    impersonatedUserDetails: isAuthenticated
      ? impersonatedUserDetails ?? null
      : undefined,
    impersonatedEmployeeDetails: isAuthenticated
      ? impersonatedEmployeeDetails ?? null
      : undefined,
    isLoading:
      isImpersonatedEmployeeDetailsLoading || isImpersonatedUserDetailsLoading,
  };
};
