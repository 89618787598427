/* eslint-disable unicorn/filename-case */
import { callEndpoint } from '@zorro/shared/utils';

import { ModalControlProps } from '../../ModalControls';

export async function resetPassword(
  userId: string,
  modalControlProps: ModalControlProps
) {
  modalControlProps.onCloseModal();
  const passwordResetLink = await callEndpoint({
    method: 'usersControllerGeneratePasswordResetToken',
    params: [userId],
  });
  window.open(passwordResetLink.link, '_blank');
}
