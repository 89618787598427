/* eslint-disable unicorn/filename-case */
import { Link } from '@tiptap/extension-link';
import { Underline } from '@tiptap/extension-underline';
import { UseEditorOptions, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { DependencyList } from 'react';

type TUseRichTextEditor = UseEditorOptions;

export function useRichTextEditor(
  { extensions, immediatelyRender, ...options }: TUseRichTextEditor,
  deps: DependencyList
) {
  const editor = useEditor(
    {
      extensions: [StarterKit, Link, Underline],
      // 🧠 must be false for SSR enabled backends
      immediatelyRender: false,
      ...options,
    },
    deps
  );

  return { editor };
}
