import { MantineSize } from '@mantine/core';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Autocomplete,
  Checkbox,
  FormErrorMessage,
  Select,
} from '@zorro/zorro-ui-design';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  placeholder?: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  size?: MantineSize;
  shouldShowError?: boolean;
  isFreeText?: boolean;
  setIsFreeText?: (checked: boolean) => void;
  carrierNames?: string[];
};

export function CarrierInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  shouldShowError = true,
  placeholder = 'Carrier',
  label = 'Carrier',
  size = 'md',
  isRequired,
  isDisabled,
  carrierNames = [],
  isFreeText = false,
  setIsFreeText,
  ...formProps
}: Props<TFieldValues, TName>) {
  const {
    field,
    formState: { errors },
  } = useController(formProps);

  const { data: carriers = [] } = useMonolithQuery({
    method: 'majorMedicalCarriersControllerGetCarriers',
    params: [],
    enabled: carrierNames.length === 0,
  });

  const carrierOptions =
    carrierNames.length > 0
      ? carrierNames
      : [...new Set(carriers.map(({ name }) => name))];

  return (
    <>
      {isFreeText ? (
        <Autocomplete
          {...field}
          size={size}
          data={carrierOptions}
          required={isRequired}
          disabled={isDisabled}
          placeholder={placeholder}
          label={label}
        />
      ) : (
        <Select
          {...field}
          size={size}
          disabled={isDisabled}
          required={isRequired}
          data={carrierOptions}
          placeholder={placeholder}
          label={label}
          searchable
        />
      )}

      {setIsFreeText && (
        <Checkbox
          onChange={({ currentTarget }) => setIsFreeText(currentTarget.checked)}
          label="Carrier not listed"
          shouldHaveBorder={false}
          isChecked={isFreeText}
          my="sm"
        />
      )}

      {shouldShowError && (
        <FormErrorMessage fieldName={formProps.name} errors={errors} />
      )}
    </>
  );
}
