import { AdminPortal } from '@frontegg/nextjs';
import { IconClock12, IconDoorExit, IconUserCog } from '@tabler/icons-react';
import { callEndpoint, logout } from '@zorro/shared/utils';
import { Avatar, Icon, Menu, UnstyledButton } from '@zorro/zorro-ui-design';

type Props = {
  profilePicture?: string | null;
  isZorroInternalUser?: boolean;
};

export const MurrietaUser = ({
  profilePicture,
  isZorroInternalUser,
}: Props) => {
  return (
    <Menu data-testid="user-menu">
      <Menu.Target>
        <UnstyledButton
          aria-label="Expand user menu tooltip"
          className="excluded-from-print"
        >
          <Avatar
            size={48}
            src={profilePicture}
            alt="User avatar"
            radius="xl"
            data-testid="user-avatar"
          />
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={<Icon icon={IconUserCog} />}
          data-testid="user-settings"
          onClick={() => AdminPortal.show()}
        >
          My settings
        </Menu.Item>

        {isZorroInternalUser && (
          <Menu.Item
            leftSection={<Icon icon={IconClock12} />}
            onClick={async () => {
              await callEndpoint({
                method: 'onboardingPeriodsControllerUpdateEveryStatus',
                params: [],
              });
              alert('Onboarding statuses updated');
            }}
          >
            Update Onboarding Statuses
          </Menu.Item>
        )}

        <Menu.Item
          leftSection={<Icon icon={IconDoorExit} />}
          onClick={logout}
          data-testid="user-logout"
        >
          Log Out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
