import * as yup from 'yup';

import { validationMessages } from '../validationMessages';

export const emailValidationSchema = yup
  .string()
  .email(validationMessages.emailValid)
  // eslint-disable-next-line require-unicode-regexp
  .matches(/^$|^[^\n\r@\u2028\u2029]*@[^\n\r.\u2028\u2029]*\..*$/, {
    message: validationMessages.emailValid,
  });
