export const AgentIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.91659 4.58346C7.91659 5.13599 8.13608 5.66589 8.52678 6.05659C8.91748 6.4473 9.44738 6.66679 9.99992 6.66679C10.5525 6.66679 11.0824 6.4473 11.4731 6.05659C11.8638 5.66589 12.0833 5.13599 12.0833 4.58346C12.0833 4.03092 11.8638 3.50102 11.4731 3.11032C11.0824 2.71962 10.5525 2.50012 9.99992 2.50012C9.44738 2.50012 8.91748 2.71962 8.52678 3.11032C8.13608 3.50102 7.91659 4.03092 7.91659 4.58346Z"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99989 17.8068L14.2457 13.5601C14.4872 13.3217 14.6792 13.0379 14.8106 12.725C14.9419 12.4122 15.0101 12.0764 15.0112 11.7371C15.0123 11.3977 14.9463 11.0615 14.817 10.7478C14.6876 10.4341 14.4975 10.1491 14.2575 9.90911C14.0176 9.66916 13.7326 9.47904 13.4188 9.34969C13.1051 9.22034 12.7689 9.15431 12.4296 9.15541C12.0903 9.15651 11.7545 9.22471 11.4416 9.35609C11.1287 9.48747 10.8449 9.67943 10.6066 9.92092L9.99989 10.5268L9.39322 9.92092C9.15483 9.67943 8.87103 9.48747 8.55816 9.35609C8.24529 9.22471 7.90952 9.15651 7.57019 9.15541C7.23085 9.15431 6.89465 9.22034 6.58093 9.34969C6.26722 9.47904 5.98218 9.66916 5.74224 9.90911C5.50229 10.1491 5.31217 10.4341 5.18282 10.7478C5.05347 11.0615 4.98744 11.3977 4.98854 11.7371C4.98964 12.0764 5.05784 12.4122 5.18922 12.725C5.3206 13.0379 5.51256 13.3217 5.75405 13.5601L9.99989 17.8068Z"
        stroke="#1A1721"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
