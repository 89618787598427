import { DatesRangeValue } from '@mantine/dates';
import {
  AllowanceModelDto,
  FamilyUnit,
  OEPPaymentMethod,
} from '@zorro/clients';
import {
  formatDateEnLocaleRange,
  formatDateTimeISO,
  getNow,
} from '@zorro/shared/formatters';
import {
  exportToCsv,
  logger,
  optionalNumber,
  showErrorNotification,
  validationMessages,
} from '@zorro/shared/utils';
import * as yup from 'yup';

export const getPlanYearSetupSchema = (isFinalizationMode: boolean) => {
  return yup.object({
    initialDrawAmount: yup.number().transform(optionalNumber),
    initialDrawDate: yup.date(),
    reserveAmount: yup.number().transform(optionalNumber),
    ...(isFinalizationMode
      ? {
          effectiveDates: yup
            .tuple([
              yup.date().required().nullable(),
              yup.date().required().nullable(),
            ])
            .required(),
          oepDates: yup
            .tuple([
              yup.date().required().nullable(),
              yup.date().required().nullable(),
            ])
            .required(),
          allowanceModel: yup
            .mixed<File | AllowanceModelDto>()
            .required(validationMessages.allowanceModelRequired),
          totalPEPMFees: yup
            .number()
            .required(validationMessages.totalPepmFees),
          pepmBrokerCommission: yup
            .number()
            .required(validationMessages.pepmBrokerCommission),
          monthlyPlatformFee: yup
            .number()
            .required(validationMessages.monthlyPlatformFee),
          initialSetupFee: yup
            .number()
            .required(validationMessages.initialSetupFee),
          paymentMethod: yup
            .mixed<OEPPaymentMethod>()
            .oneOf(
              Object.values(OEPPaymentMethod),
              validationMessages.paymentMethodRequired
            )
            .typeError(validationMessages.paymentMethodRequired)
            .required(validationMessages.paymentMethodRequired),
        }
      : {
          effectiveDates: yup
            .tuple([
              yup.date().required().nullable(),
              yup.date().required().nullable(),
            ])
            .optional(),
          oepDates: yup
            .tuple([
              yup.date().required().nullable(),
              yup.date().required().nullable(),
            ])
            .optional(),
          allowanceModel: yup.mixed<File | AllowanceModelDto>(),
          totalPEPMFees: yup.number().transform(optionalNumber),
          pepmBrokerCommission: yup.number().transform(optionalNumber),
          monthlyPlatformFee: yup.number().transform(optionalNumber),
          initialSetupFee: yup.number().transform(optionalNumber),
          paymentMethod: yup
            .mixed<OEPPaymentMethod>()
            .oneOf(
              Object.values(OEPPaymentMethod),
              validationMessages.paymentMethodRequired
            )
            .typeError(validationMessages.paymentMethodRequired)
            .nullable(),
        }),
  });
};

export type PlanYearSetupFormFields = yup.InferType<
  ReturnType<typeof getPlanYearSetupSchema>
>;

export const renderNullableDateRange = (range?: DatesRangeValue): string => {
  if (!range?.[0] || !range?.[1]) return '-';

  return formatDateEnLocaleRange(range[0], range[1]);
};

export function exportAllowanceModelToCsv(
  allowanceModel: AllowanceModelDto,
  employerName = ''
) {
  try {
    const allowanceModelItemsWithRowId = allowanceModel.items.map((model) => ({
      ...model,
      rowId: `${model.ageFrom}_${model.ageTo}_${model.class}`,
      familyType:
        model.numberOfDependents === null ? 'max' : model.numberOfDependents,
    }));
    const exportableRowByRowId = allowanceModelItemsWithRowId.reduce(
      (group: { [rowId: string]: Record<string, number> }, item) => {
        group[item.rowId] = group[item.rowId] ?? {
          ageFrom: item.ageFrom,
          ageTo: item.ageTo,
          class: item.class,
        };
        let key = '';
        switch (item.familyUnit) {
          case FamilyUnit.EMPLOYEE_ONLY: {
            key = 'EE';
            break;
          }
          case FamilyUnit.EMPLOYEE_SPOUSE: {
            key = 'ES';
            break;
          }
          case FamilyUnit.EMPLOYEE_CHILD: {
            key = `EC${item.familyType}`;
            break;
          }
          case FamilyUnit.FAMILY: {
            key = `FA${item.familyType}`;
            break;
          }
        }
        if (key !== '') {
          group[item.rowId][key] = item.stipend;
        }
        return group;
      },
      {}
    );

    const companyName = employerName.replaceAll(' ', '');
    const timestamp = formatDateTimeISO(getNow());
    const fileName = `${allowanceModel.year}_allowance_model_${companyName}_${timestamp}.csv`;

    const fields = [
      { label: 'ageFrom', value: 'ageFrom' },
      { label: 'ageTo', value: 'ageTo' },
      { label: 'class', value: 'class' },
      { label: 'EE', value: 'EE' },
      { label: 'ES', value: 'ES' },
      { label: 'EC1', value: 'EC1' },
      { label: 'EC2', value: 'EC2' },
      { label: 'ECmax', value: 'ECmax' },
      { label: 'FA1', value: 'FA1' },
      { label: 'FA2', value: 'FA2' },
      { label: 'FAmax', value: 'FAmax' },
    ];

    exportToCsv(Object.values(exportableRowByRowId), fileName, fields);
  } catch (error) {
    showErrorNotification({
      message: 'An error occurred trying to export allowance model to csv.',
    });
    logger.error(
      `An error occurred trying to export allowance model to csv. employer name: ${employerName}. error: ${error}`
    );
  }
}
