import {
  CreateEmployeeDto,
  EmployeeDto,
  FamilyUnit,
  Gender,
  SelfReportType,
  UpdateMajorMedicalPlanDto,
} from '@zorro/clients';
import {
  formatDateISO,
  getNowAsDate,
  parseDateEnLocalePermissive,
  parseStringToFloat,
} from '@zorro/shared/formatters';
import {
  assertOrThrow,
  callEndpoint,
  logger,
  parsePhoneNumber,
} from '@zorro/shared/utils';
import { ZorroError } from '@zorro/types';
import { HttpStatusCode } from 'axios';
import { $enum } from 'ts-enum-util';

import { RosterInput, RosterUploadResult } from './uploadRoster.types';

function throwRosterParseError(
  field: string,
  errorMessage = 'See sample roster file for details.'
) {
  throw new Error(`${field} is invalid. ${errorMessage}`);
}

/**
 * Since we can't iterate over fields of interfaces I've declared an object so I can use it's keys
 * with `Object.keys` and it's shape with `typeof EmployeeInput`, for more info:
 * https://stackoverflow.com/questions/45670705/iterate-over-interface-properties-in-typescript
 *
 * Note that these fields will always be strings since they are parsed from CSV input
 */
const EmployeeInput = {
  company_email: 'Company Email',
  personal_email: 'Personal Email',
  first_name: 'First Name',
  last_name: 'Last Name',
  id_from_employer: 'Id From Employer',
  phone: 'Phone',
  address: 'Address',
  date_of_birth: 'Date Of Birth',
  gender: 'Gender',
  plan_id: 'Plan Id',
  class: 'Class',
  salary: 'Salary',
  hire_date: 'Hire Date',
  eligibility_start_date: 'Eligibility Start Date',
};

type EmployeeInputType = typeof EmployeeInput;
type EmployeeInputKey = keyof EmployeeInputType;
const requiredFields: EmployeeInputKey[] = [
  'first_name',
  'last_name',
  'date_of_birth',
  'class',
];

function instanceOfEmployeeInput(object: object): object is EmployeeInputType {
  return Object.keys(EmployeeInput).every((key: string) => {
    return key in object;
  });
}

function isValidEmail(email: string) {
  const expression = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/u;
  return expression.test(email);
}

function validateRequiredFields(employeeInput: EmployeeInputType) {
  for (const field of requiredFields) {
    if (!employeeInput[field] || employeeInput[field].length === 0) {
      throwRosterParseError(EmployeeInput[field]);
    }
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function validateFieldsFormat(employeeInput: EmployeeInputType) {
  // validate fields format
  const personalEmail = employeeInput.personal_email;
  if (
    personalEmail &&
    personalEmail.length > 0 &&
    !isValidEmail(personalEmail)
  ) {
    throwRosterParseError(
      'personal_email',
      `Can't parse email address: ${personalEmail}`
    );
  }

  const companyEmail = employeeInput.company_email;
  if (!companyEmail) {
    throwRosterParseError(EmployeeInput.company_email);
  }
  if (!isValidEmail(companyEmail)) {
    throwRosterParseError(
      'company_email',
      `Can't parse email address: ${companyEmail}`
    );
  }

  if (
    employeeInput.phone &&
    parsePhoneNumber(employeeInput.phone) === undefined
  ) {
    throwRosterParseError(
      'phone',
      `Can't parse the number: ${employeeInput.phone}`
    );
  }

  if (
    employeeInput.salary &&
    Number.isNaN(parseStringToFloat(employeeInput.salary))
  ) {
    throwRosterParseError(EmployeeInput.salary);
  }

  if (
    employeeInput.date_of_birth &&
    !parseDateEnLocalePermissive(employeeInput.date_of_birth).isValid()
  ) {
    throwRosterParseError(EmployeeInput.date_of_birth);
  }

  if (
    employeeInput.hire_date &&
    !parseDateEnLocalePermissive(employeeInput.hire_date).isValid()
  ) {
    throwRosterParseError(EmployeeInput.hire_date);
  }

  if (
    employeeInput.eligibility_start_date &&
    !parseDateEnLocalePermissive(employeeInput.eligibility_start_date).isValid()
  ) {
    throwRosterParseError(EmployeeInput.eligibility_start_date);
  }

  if (employeeInput.gender) {
    const upperCaseGender = employeeInput.gender.toUpperCase();

    const genderValues = Object.values(Gender) as string[];
    if (!genderValues.includes(upperCaseGender)) {
      throwRosterParseError(
        'gender',
        `It must be one of : ${genderValues.join(',')}`
      );
    }
  }

  if (employeeInput.id_from_employer?.includes(' ')) {
    throwRosterParseError('id_from_employer', 'It must not contain spaces');
  }

  if (!employeeInput.address || employeeInput.address.length === 0) {
    throwRosterParseError(
      'address',
      'Address could not be validated. Please enter a full and correct address, for example: 123 Main St, Springfield, IL 62704.'
    );
  }
}

function processEmployeeRosterEntry(
  employerId: string,
  employeeInput: EmployeeInputType,
  existingEmployee?: EmployeeDto
): CreateEmployeeDto {
  if (!instanceOfEmployeeInput(employeeInput)) {
    throw new Error(
      `CSV file must contain the following columns: ${Object.keys(
        EmployeeInput
      ).join(',')} but had ${Object.keys(employeeInput).join(',')}`
    );
  }

  validateEmployeeInput(employeeInput);

  return existingEmployee
    ? mergeWithExistingEmployee(employerId, employeeInput, existingEmployee)
    : createNewEmployee(employerId, employeeInput);
}

function validateEmployeeInput(employeeInput: EmployeeInputType) {
  validateRequiredFields(employeeInput);
  validateFieldsFormat(employeeInput);
}

function mergeWithExistingEmployee(
  employerId: string,
  employeeInput: EmployeeInputType,
  existingEmployee: EmployeeDto
): CreateEmployeeDto {
  return {
    employerId,
    firstName: employeeInput.first_name || existingEmployee.firstName,
    lastName: employeeInput.last_name || existingEmployee.lastName,
    idFromEmployer:
      employeeInput.id_from_employer || existingEmployee.idFromEmployer,
    email: existingEmployee.email,
    personalEmail:
      employeeInput.personal_email || existingEmployee.personalEmail,
    address: employeeInput.address || existingEmployee.address,
    phone: employeeInput.phone || existingEmployee.phone,
    dateOfBirth: employeeInput.date_of_birth
      ? formatDateISO(parseDateEnLocalePermissive(employeeInput.date_of_birth))
      : existingEmployee.dateOfBirth,
    gender: employeeInput.gender
      ? (employeeInput.gender.toUpperCase() as Gender)
      : existingEmployee.gender,
    existingPlanID:
      employeeInput.plan_id === ''
        ? (existingEmployee.existingPlan as unknown as string)
        : employeeInput.plan_id,
    class: employeeInput.class || existingEmployee.class,
    salary: employeeInput.salary
      ? parseStringToFloat(employeeInput.salary)
      : existingEmployee.salary,
    hireDate: employeeInput.hire_date
      ? formatDateISO(parseDateEnLocalePermissive(employeeInput.hire_date))
      : existingEmployee.hireDate,
    eligibleFrom: employeeInput.eligibility_start_date
      ? formatDateISO(
          parseDateEnLocalePermissive(employeeInput.eligibility_start_date)
        )
      : existingEmployee.eligibleFrom,
  };
}

function createNewEmployee(
  employerId: string,
  employeeInput: EmployeeInputType
): CreateEmployeeDto {
  return {
    employerId,
    firstName: employeeInput.first_name,
    lastName: employeeInput.last_name,
    idFromEmployer: employeeInput.id_from_employer,
    email: employeeInput.company_email,
    personalEmail:
      employeeInput.personal_email?.length > 0
        ? employeeInput.personal_email
        : null,
    address: employeeInput.address,
    phone: employeeInput.phone || null,
    dateOfBirth: formatDateISO(
      parseDateEnLocalePermissive(employeeInput.date_of_birth)
    ),
    gender: employeeInput.gender
      ? (employeeInput.gender.toUpperCase() as Gender)
      : null,
    existingPlanID: employeeInput.plan_id,
    class: employeeInput.class,
    salary: employeeInput.salary
      ? parseStringToFloat(employeeInput.salary)
      : null,
    hireDate: employeeInput.hire_date
      ? formatDateISO(parseDateEnLocalePermissive(employeeInput.hire_date))
      : null,
    eligibleFrom: employeeInput.eligibility_start_date
      ? formatDateISO(
          parseDateEnLocalePermissive(employeeInput.eligibility_start_date)
        )
      : null,
  };
}

function logAndAddErrorToResult(
  result: RosterUploadResult,
  index: number,
  error: Error,
  message: string,
  employee: {
    firstName: string;
    lastName: string;
    email: string;
  }
) {
  logger.error(error, message);
  result.success = false;
  result.message =
    'One or more employees could not be created. Check `errors` for details.';
  result.errors.push({
    id: `${index} ${message}`,
    index,
    message,
    employeeDetails: {
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
    },
  });
}

function doesInputHaveRequiredBenefitFields(inputRow: RosterInput): boolean {
  // checks only the mandatory fields. Optional fields can be omitted or left empty
  const { carrier_name: carrierName, allowance, premium } = inputRow;
  return (
    Boolean(carrierName && allowance && premium) &&
    !Number.isNaN(parseStringToFloat(allowance)) &&
    !Number.isNaN(parseStringToFloat(premium))
  );
}

async function createBenefitsFromRoster(
  inputRow: RosterInput,
  createdEmployeeDto: EmployeeDto
) {
  const premium = parseStringToFloat(inputRow.premium);
  const allowance = parseStringToFloat(inputRow.allowance);
  const updateMajorMedicalPlanDto: UpdateMajorMedicalPlanDto = {
    updateManuallyEnteredMajorMedicalPlan: {
      familyUnit: $enum(FamilyUnit).asValueOrDefault(
        inputRow.family_unit,
        FamilyUnit.EMPLOYEE_ONLY
      ),
      carrierName: inputRow.carrier_name,
      premium,
      name: inputRow.plan_name ?? '',
      allowance,
      employeeMonthlyContribution: Math.max(0, premium - allowance),
      selfReportType: SelfReportType.NOT_APPLICABLE,
      isCombinedPlan: false,
      isHsaEligible: null,
      benefitsSummaryUrl: null,
      maxOutOfPocket: null,
      deductible: null,
      externalID: null,
    },
  };
  const onboardingPeriodDtos = await callEndpoint({
    method: 'onboardingPeriodsControllerFindMany',
    params: [createdEmployeeDto.id],
  });

  assertOrThrow(
    onboardingPeriodDtos.length === 1,
    'this feature is not currently supported for employees with multiple onboarding periods'
  );
  const periodId = onboardingPeriodDtos[0].id;

  await callEndpoint({
    method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
    params: [periodId, true, updateMajorMedicalPlanDto],
  });
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function createEmployeesFromRosterUpload(
  employerId: string,
  inputRoster: RosterInput[],
  sendActivationEmail: boolean
): Promise<RosterUploadResult> {
  const startTime = getNowAsDate().getTime();
  const rosterLength = inputRoster.length;

  if (rosterLength > 1000) {
    return {
      success: false,
      message:
        'Roster upload is limited to 1000 employees. Consider splitting the file.',
      errors: [],
    };
  }

  const result: RosterUploadResult = {
    success: false,
    message: '',
    errors: [],
  };
  const employeesToCreate: CreateEmployeeDto[] = [];

  try {
    const existingEmployees = await callEndpoint({
      method: 'employeesControllerFindAll',
      params: [employerId],
    });

    const existingEmployeesMap = new Map<string, EmployeeDto>(
      existingEmployees.map((employeeDto) => [employeeDto.email, employeeDto])
    );

    logger.debug('Iterating roster to create employees...');

    for (let index = 0; index < rosterLength; index++) {
      const inputRow = inputRoster[index];
      const employeeRow: EmployeeInputType = inputRow as EmployeeInputType;
      try {
        const existingEmployee = existingEmployeesMap.get(
          employeeRow.company_email
        );
        const employee = processEmployeeRosterEntry(
          employerId,
          employeeRow,
          existingEmployee
        );
        employeesToCreate.push(employee);
      } catch (error) {
        logger.error(error);
        const message = `${error.message}`;
        logAndAddErrorToResult(result, index, error, message, {
          firstName: employeeRow.first_name,
          lastName: employeeRow.last_name,
          email: employeeRow.company_email,
        });
      }
    }
  } catch {
    return {
      success: false,
      message: 'Failed to fetch existing employees',
      errors: [],
    };
  }

  try {
    const createManyResult = await callEndpoint({
      method: 'employeesControllerUpsertMany',
      params: [{ employees: employeesToCreate, sendActivationEmail }],
    });

    createManyResult.errors.forEach((error) => {
      logAndAddErrorToResult(
        result,
        error.index,
        new ZorroError(error.message),
        error.message,
        {
          firstName: error.employee.firstName,
          lastName: error.employee.lastName,
          email: error.employee.email,
        }
      );
    });

    await Promise.all(
      createManyResult.upsertedEmployees.map(async (createdEmployee) => {
        const inputRow = inputRoster.find(
          (input) => input.company_email === createdEmployee.email
        );
        if (inputRow && doesInputHaveRequiredBenefitFields(inputRow)) {
          await createBenefitsFromRoster(inputRow, createdEmployee);
        }
      })
    );
    const message = `Created ${
      createManyResult.upsertedEmployees.length
    } of ${rosterLength} employees from roster in ${
      (getNowAsDate().getTime() - startTime) / 1000
    } seconds`;

    result.message = message;

    if (result.errors.length === 0) {
      result.success = true;
      logger.info(message);
    } else {
      logger.error(message);
    }
    return result;
  } catch (error) {
    if (error.status === HttpStatusCode.BadRequest) {
      for (const key in error.body.errors.employees) {
        for (const field in error.body.errors.employees[key]) {
          logAndAddErrorToResult(
            result,
            Number.parseInt(key),
            new ZorroError(error.body.errors.employees[key][field]),
            error.body.errors.employees[key][field],
            {
              firstName: error.employee.firstName,
              lastName: error.employee.lastName,
              email: error.employee.email,
            }
          );
        }
      }
      return result;
    }

    result.success = false;
    result.message = `Failed to upload with error ${error.message}`;
    return result;
  }
}
