import { ContactsForm, useContactsForm } from '../../ContactsForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
  isDon?: boolean;
};

export const AddEmployerContactStep = ({
  employerId,
  isFinalizationMode = false,
  isDon = false,
}: Props) => {
  const { contactsForm, onSubmit, duplicateFieldName } = useContactsForm(
    employerId,
    isFinalizationMode,
    isDon
  );

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !contactsForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.CONTACTS}
      isSubmitDisabled={isSubmitDisabled}
      title="Add company contacts"
      onSubmit={handleStepSubmit}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={isDon}
    >
      <ContactsForm
        shouldShowOnboardingContacts={isFinalizationMode}
        duplicateFieldName={duplicateFieldName}
        isFinalizationMode={isFinalizationMode}
        contactsForm={contactsForm}
        isDon={isDon}
      />
    </AddEmployerStep>
  );
};
