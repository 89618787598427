import { IconAlertTriangle } from '@tabler/icons-react';
import {
  Button,
  Center,
  Flex,
  Modal,
  Space,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import { FormState } from 'react-hook-form';

import { InsuredDetailsEditFormFields } from './insuredForm.types';

type InsuredDeleteModalProps = {
  name: string;
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  fieldName: 'employee' | 'spouse' | `dependents.${number}`;
  formState: FormState<InsuredDetailsEditFormFields>;
  deleteFamilyMember: (
    insuredFieldName: 'employee' | 'spouse' | `dependents.${number}`
  ) => Promise<void>;
};

export const InsuredDeleteModal = ({
  name,
  isModalOpen,
  setIsModalOpen,
  formState,
  fieldName,
  deleteFamilyMember,
}: InsuredDeleteModalProps) => {
  const { isSubmitting } = formState;

  return (
    <Modal
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      size="md"
      title={
        <Flex align="center">
          <IconAlertTriangle color={brand.zorroFire700} />
          <Text size="xl" ml="sm" fw={500}>
            Remove family member
          </Text>
        </Flex>
      }
    >
      <Text>Are you sure you want to delete {name}?</Text>

      <Space h="lg" mt="lg" />

      <Center mt="md">
        <Button
          variant="secondary"
          onClick={() => setIsModalOpen(false)}
          size="lg"
        >
          Cancel
        </Button>
        <Space w="md" />
        <Button
          disabled={isSubmitting}
          onClick={async () => {
            await deleteFamilyMember(fieldName);
            setIsModalOpen(false);
          }}
          bg="zorroFire.7"
          size="lg"
        >
          Delete
        </Button>
      </Center>
    </Modal>
  );
};
