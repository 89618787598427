import { FamilyUnit } from '@zorro/clients';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';

import {
  DependentsFormInputs,
  InsuredDependentFormFields,
} from './DependentsFormInputs';
import { EmployeeFormInputs } from './EmployeeFormInputs';
import { getDefaultInsuredFormFields } from './InsuredFormUtils';
import { SpouseFormInputs } from './SpouseFormInputs';
import { useInsuredForm } from './useInsuredForm';

type Props = {
  insuredForm: ReturnType<typeof useInsuredForm>;
  employerId: string;
  isFinalizationMode: boolean;
  isReadonlyMode?: boolean;
};

export const InsuredFormInputs = ({
  insuredForm,
  employerId,
  isFinalizationMode,
  isReadonlyMode = false,
}: Props) => {
  const {
    employeeForm,
    spouseForm,
    dependentsForm,
    handleAddressValidation,
    hasAnyDependents,
    insured,
    isSpouseActive,
    setIsSpouseActive,
    targetEnrollmentDate,
    familyUnit,
  } = insuredForm;

  const employeeAddress = employeeForm.watch('residentialAddress');
  const { append, remove } = useFieldArray({
    control: dependentsForm.control,
    name: 'dependents',
  });

  useEffect(() => {
    setIsSpouseActive(Boolean(insured?.spouse));
  }, [setIsSpouseActive, insured, isReadonlyMode]);

  const shouldShowSpouseForm =
    isReadonlyMode && familyUnit
      ? familyUnit.includes(FamilyUnit.EMPLOYEE_SPOUSE) ||
        familyUnit.includes(FamilyUnit.FAMILY)
      : true;
  const shouldShowDependentsForm =
    isReadonlyMode && familyUnit
      ? familyUnit.includes(FamilyUnit.EMPLOYEE_CHILD) ||
        familyUnit.includes(FamilyUnit.FAMILY)
      : true;

  return (
    <>
      <EmployeeFormInputs
        {...employeeForm}
        targetEnrollmentDate={targetEnrollmentDate}
        isFinalizationMode={isFinalizationMode}
        isReadonlyMode={isReadonlyMode}
        employerId={employerId}
      />
      {shouldShowSpouseForm && (
        <SpouseFormInputs
          {...spouseForm}
          setIsSpouseActive={(isActive) => {
            employeeForm.trigger();
            handleAddressValidation();
            if (hasAnyDependents) {
              dependentsForm.trigger();
            }
            setIsSpouseActive(isActive);
          }}
          isSpouseActive={isSpouseActive}
          isReadonlyMode={isReadonlyMode}
          isFinalizationMode={isFinalizationMode}
          targetEnrollmentDate={targetEnrollmentDate}
        />
      )}
      {shouldShowDependentsForm && (
        <DependentsFormInputs
          {...dependentsForm}
          onDeleteDependent={(index) => remove(index)}
          onAppendDependent={() => {
            employeeForm.trigger();
            handleAddressValidation();
            if (hasAnyDependents) {
              dependentsForm.trigger();
            }
            if (isSpouseActive) {
              spouseForm.trigger();
            }
            append(
              getDefaultInsuredFormFields(
                employeeAddress
              ) as InsuredDependentFormFields
            );
          }}
          isReadonlyMode={isReadonlyMode}
          isFinalizationMode={isFinalizationMode}
          targetEnrollmentDate={targetEnrollmentDate}
        />
      )}
    </>
  );
};
