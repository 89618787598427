import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

export const WomanTypingOnLaptop = ({ style }: SVGIconProps) => {
  return (
    <Center w="100%" style={style}>
      <svg
        width="410"
        height="410"
        viewBox="0 0 410 410"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_5520_2938"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          x="0"
          y="0"
          width="410"
          height="410"
        >
          <rect width="410" height="410" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_5520_2938)">
          <path
            d="M317.961 218.528L317.113 226.63C314.905 231.906 312.698 237.179 310.493 242.448C309.723 244.299 308.949 246.146 308.173 247.99C307.821 248.852 307.321 249.647 306.696 250.339C306.634 250.433 306.531 250.493 306.418 250.501C306.231 250.501 306.164 250.252 306.15 250.065C306.044 248.067 305.805 246.078 305.436 244.112C303.37 234.616 292.344 231.136 283.697 231.351C280.237 231.404 276.828 232.193 273.698 233.667C269.182 235.852 267.423 241.931 267.183 242.156L266.991 242.937L263.895 255.837C263.243 258.713 262.744 260.232 260.089 260.999C260.002 261.028 257.356 262.437 257.27 262.437C256.244 262.725 259.053 262.107 257.941 261.92L254.418 261.368L252.917 261.023L231.025 255.842C231.874 255.985 232.741 256.143 233.623 256.321L238.705 257.534L237.362 251.091C233.868 231.035 239.658 212.838 249.524 202.959C252.122 200.361 253.411 199.695 256.441 197.964L258.142 201.995C258.142 201.995 275.553 207.187 279.656 191.258C281.315 191.402 284.502 191.378 286.146 191.737C294.717 193.534 302.458 198.103 308.173 204.737C308.676 205.313 309.17 205.907 309.659 206.511L309.918 206.827C310.22 207.206 310.541 207.594 310.843 207.983L311.059 208.289C313.564 211.56 315.869 214.979 317.961 218.528Z"
            fill="#8033EF"
          />
          <path
            d="M86.2618 226.78C23.1629 150.23 125.577 107.5 159.064 104.437C233.499 97.635 193.262 77.1138 217.498 54.656C241.734 32.1981 333.373 69.1469 309.4 126.852C285.427 184.557 364.564 120.51 398.081 193.602C402.936 204.209 405.274 215.795 404.912 227.454C404.489 239.774 401.316 251.843 395.627 262.778C384.923 283.687 365.039 302.315 336.282 313.858C332.038 315.587 327.596 317.142 322.956 318.522"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M342.916 330.751H252.318L249.619 329.945C249.619 329.945 249.073 299.185 245.459 291.314L258.881 295.877L271.881 299.616C271.881 299.616 289.765 299.99 299.99 281.736C299.99 281.736 301.711 277.379 303.254 269.523C304.587 262.706 311.672 225.125 314.644 216.731C317.851 222.335 322.875 231.975 325.3 237.851C327.927 244.232 330.137 250.588 331.944 256.479C334.952 266.178 337.325 276.063 339.048 286.07C341.625 300.824 342.919 315.773 342.916 330.751Z"
            fill="#8033EF"
          />
          <path
            d="M346.876 248.22C346.296 254.993 341.704 256.096 341.704 256.096C337.821 258.392 331.858 255.923 330.655 251.618C330.637 251.573 330.627 251.524 330.626 251.475C329.667 247.788 327.798 243.052 325.286 237.871C320.444 226.155 314.151 214.344 306.078 205.001C300.363 198.367 292.622 193.798 284.052 192.001C284.023 192.001 284.009 191.972 283.98 191.972C272.614 188.55 267.083 181.561 264.686 174.859C264.686 174.859 264.686 174.859 264.686 174.83C263.66 170.598 262.615 166.47 263.176 163.973C264.135 159.597 264.614 154.865 263.42 153.907C261.92 152.651 259.207 153.581 252.304 154.971C246.326 156.198 243.139 155.503 242.42 151.85C242.306 151.284 242.248 150.707 242.247 150.129C242.218 150.494 240.809 149.046 240.742 149.391C240.291 151.256 239.39 152.004 237.425 152.823C234.506 154.036 233.312 158.719 232.881 161.84C232.43 165.162 233.926 169.744 235.11 172.865C233.595 171.135 231.86 171.336 229.146 172.386C228.466 172.645 226.75 172.573 225.393 172.098C225.585 171.934 225.811 171.814 226.055 171.748C226.491 171.604 223.327 170.032 223.787 169.931C228.394 168.891 228.046 163.379 227.5 160C226.143 151.491 230.752 141.13 236.5 134.5C242.837 127.209 253.747 126.664 262.5 130C269.374 132.612 275.433 139.027 277.815 146.175C277.815 146.203 277.815 146.232 277.844 146.261C278.147 147.17 278.379 148.101 278.539 149.046C279.416 154.362 277.542 159.75 276.502 165.004C276.128 166.897 277.077 168.963 277.278 170.464C278.434 179.475 288.836 182.021 297.066 185.846C314.366 193.904 328.493 207.681 337.994 224.434C344.302 235.527 346.555 243.091 346.876 248.22Z"
            fill="#1A1721"
          />
          <path
            d="M255.674 196.176C255.487 196.277 255.3 196.378 255.127 196.493C252.02 198.273 249.164 200.459 246.633 202.993C236.773 212.872 230.982 231.069 234.477 251.125L235.819 257.534"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M342.917 330.75C342.919 315.778 341.624 300.833 339.048 286.084C337.325 276.077 334.952 266.192 331.944 256.493C330.137 250.602 327.927 244.246 325.3 237.866C322.861 231.895 320.043 226.086 316.864 220.475C313.781 214.98 310.174 209.796 306.092 204.996C300.377 198.362 292.636 193.793 284.066 191.996C282.422 191.648 280.759 191.398 279.086 191.248"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M165.737 200.447L165.493 200.404C159.544 199.407 153.497 201.502 149.439 205.965L133.045 223.825L131.128 225.935C131.008 226.064 130.896 226.2 130.792 226.342"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M142.062 231.006L151.059 221.026L151.074 221.012"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M220.331 282.556L262.653 297.469C270.901 300.375 280.004 299.551 287.596 295.211C295.187 290.871 300.516 283.444 302.195 274.862L312.209 223.744C313.244 218.44 312.07 212.943 308.959 208.524C308.529 207.908 308.061 207.319 307.559 206.76"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M192.322 280.495C186.934 284.133 182.975 295.182 180.87 302.435C180.129 304.933 177.829 306.644 175.223 306.634C174.364 306.634 173.516 306.448 172.736 306.088C170.272 304.933 168.923 302.237 169.476 299.573C172.999 282.345 185.434 248.335 228.149 255.832C229.002 255.976 229.87 256.134 230.747 256.311L235.819 257.534L250.041 260.999L253.876 261.925C255.331 262.278 256.855 262.223 258.281 261.766C258.369 261.749 258.456 261.725 258.54 261.694C261.21 260.779 263.226 258.562 263.885 255.818L267.121 242.357L267.308 241.576L271.545 223.979"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M171.489 225.336H156.993C153.552 225.336 150.762 222.546 150.762 219.104C150.762 215.662 153.552 212.872 156.993 212.872H177.529"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M160.364 225.331C160.157 227.871 159.189 229.75 157.761 231.049C154.079 234.405 147.335 234.021 142.642 231.725"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M133.064 223.821C128.908 218.797 129.757 211.986 132.983 206.722C142.134 191.786 163.043 194.652 176.604 200.86C187.591 205.874 197.216 213.409 206.588 220.926C211.166 224.597 215.662 228.423 220.01 232.43C221.627 233.893 223.826 234.535 225.976 234.17C228.126 233.805 229.991 232.475 231.035 230.561C233.432 226.136 236.025 221.309 236.514 219.972"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M157.766 231.049C157.766 231.049 179.389 242.779 195.452 259.633"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M187.044 286.856C184.647 290.95 186.709 296.961 191.239 298.399C197.024 300.266 203.14 300.879 209.181 300.197C215.772 299.43 220.153 293.989 223.039 287.757C223.461 286.82 223.466 285.747 223.051 284.806C222.635 283.865 221.84 283.145 220.863 282.825C219.054 282.229 217.092 283.125 216.357 284.881C215.398 287.096 213.917 289.866 211.952 291.113"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M198.333 286.089C198.333 286.089 192.413 292.455 197.082 299.195C197.287 299.478 197.529 299.733 197.801 299.952"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M206.142 287.139C206.142 287.139 200.668 299.458 207.69 300.359"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M400.497 330.76H61.4772"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M194.848 307.545H161.039C160.328 314.155 154.745 319.164 148.097 319.155H34.4606C34.4738 325.554 39.6576 330.738 46.0562 330.751H194.848C201.258 330.748 206.458 325.564 206.482 319.155V311.361C206.482 309.243 204.765 307.526 202.647 307.526L194.848 307.545Z"
            fill="#F0E6FF"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M142.642 231.725C143.638 233.104 144.354 234.664 144.751 236.317L160.766 303.111C161.12 304.561 161.212 306.062 161.039 307.545C160.328 314.155 154.745 319.164 148.097 319.155H32.3514C26.3341 319.154 21.1025 315.027 19.7012 309.175L3.7147 242.386C2.78349 238.512 3.67996 234.424 6.1472 231.295C8.61443 228.166 12.3801 226.34 16.3649 226.342H132.101C135.95 226.334 139.602 228.044 142.062 231.006L142.642 231.725Z"
            fill="#F0E6FF"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M284.051 191.982C284.023 191.982 284.008 191.953 283.98 191.953C272.614 188.53 267.082 181.541 264.685 174.84C264.685 174.84 264.685 174.84 264.685 174.811C262.811 169.586 262.854 164.529 263.53 161.509C263.935 159.909 264.192 158.276 264.297 156.629C264.455 151.965 261.033 153.106 251.244 155.057C246.748 155.968 243.872 154.261 242.434 151.836C242.294 151.624 242.172 151.401 242.069 151.169C241.399 149.758 241.12 148.191 241.264 146.635"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M235.632 173.498C235.459 173.253 235.282 173.019 235.109 172.832C233.595 171.101 231.859 171.302 229.146 172.352C228.466 172.611 226.749 172.539 225.393 172.065C224.252 171.657 223.399 170.952 223.787 169.898C223.989 169.458 224.384 169.138 224.856 169.03C225.308 168.916 225.766 168.825 226.227 168.757C230.882 167.717 229.841 162.329 229.276 158.949C227.905 150.446 229.726 140.437 235.531 133.807C241.926 126.516 252.385 124.997 261.22 128.333C268.99 131.267 276.3 137.632 277.815 146.141C277.815 146.17 277.815 146.199 277.844 146.227C277.873 146.256 277.844 146.299 277.844 146.328C278.74 151.644 277.844 157.406 276.804 162.664C276.181 165.78 276.416 168.326 277.283 170.43C280.001 177.318 289.171 179.72 296.563 183.128C314.055 191.186 328.306 204.963 337.912 221.716C345.495 235.004 347.293 243.177 346.876 248.22C346.296 254.993 341.704 256.096 341.704 256.096C337.821 258.392 331.853 255.923 330.655 251.618C330.637 251.573 330.627 251.524 330.626 251.475C329.667 247.788 327.798 243.052 325.286 237.871"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M241.245 146.635C241.245 149.199 240.037 151.73 237.41 152.809C234.457 154.026 233.259 157.233 232.827 160.354C232.225 164.923 233.208 169.561 235.613 173.493C237.842 177.194 241.47 181.273 246.609 181.273C256.024 181.273 260.06 176.733 260.06 176.733"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <circle cx="251.019" cy="160.225" r="1.60105" fill="#1A1721" />
          <circle cx="236.936" cy="161.159" r="1.60105" fill="#1A1721" />
          <path
            d="M243.843 158.719C243.843 158.719 243.043 165.042 243.364 165.909C243.685 166.777 245.689 165.785 245.689 165.785"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            d="M251.601 172.035C247.736 173.726 246.736 174.226 243 175.316"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <ellipse
            cx="253.202"
            cy="158.227"
            rx="7.2958"
            ry="7.47316"
            transform="rotate(-54.76 253.202 158.227)"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <ellipse
            cx="234.48"
            cy="159.167"
            rx="7.2958"
            ry="7.47316"
            transform="rotate(-54.76 234.48 159.167)"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M234.026 152.996H234.347C237.671 153.005 240.434 155.559 240.703 158.873C240.827 160.502 240.285 162.112 239.201 163.335C238.117 164.558 236.584 165.29 234.951 165.363H234.63C231.306 165.354 228.543 162.8 228.274 159.486C228.15 157.857 228.692 156.247 229.776 155.024C230.86 153.801 232.393 153.069 234.026 152.996ZM239.62 158.954C239.409 156.2 237.109 154.076 234.347 154.084V154.079H234.079C232.729 154.139 231.461 154.745 230.565 155.757C229.679 156.758 229.237 158.076 229.343 159.41C229.551 162.165 231.852 164.292 234.616 164.285H234.884C236.237 164.228 237.507 163.622 238.403 162.607C239.289 161.606 239.728 160.287 239.62 158.954Z"
            fill="white"
          />
          <path
            d="M245.809 158.619L241.878 158.82"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <ellipse
            opacity="0.53"
            cx="253.202"
            cy="158.226"
            rx="6.1789"
            ry="6.3275"
            transform="rotate(-54.76 253.202 158.226)"
            fill="white"
          />
          <ellipse
            opacity="0.53"
            cx="234.733"
            cy="158.697"
            rx="6.1789"
            ry="6.3275"
            transform="rotate(-54.76 234.733 158.697)"
            fill="white"
          />
          <path
            d="M249.414 181.273L258.143 202.01C258.143 202.01 274.982 206.54 279.076 190.606"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
          <ellipse
            cx="79.5619"
            cy="275.335"
            rx="12.516"
            ry="14.0643"
            transform="rotate(-17.5 79.5619 275.335)"
            fill="#8033EF"
          />
          <path
            d="M247.084 330.751C246.897 313.872 243.92 297.196 242.549 290.389"
            stroke="#1A1721"
            strokeWidth="1.5"
          />
        </g>
      </svg>
    </Center>
  );
};
