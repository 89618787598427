import { DatesRangeValue } from '@mantine/dates';
import { useQueryClient } from '@tanstack/react-query';
import { OpenEnrollmentPeriodDto } from '@zorro/clients';
import { IconZorroWritingSign } from '@zorro/shared/assets';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useDynamicRouter,
} from '@zorro/shared/utils';
import {
  Button,
  DateRangeInput,
  Flex,
  Grid,
  Group,
  Modal,
  Space,
  Stack,
  Table,
  Text,
} from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ModalControlProps } from '../../ModalControls';

type SelectedEmployees = {
  employeeId: string;
  onboardingPeriodId: string;
  firstName: string;
  lastName: string;
};

const SINGLE_SELECTION_TEXT =
  'By confirming, you will waive coverage for the selected onboarding period of the employee. This action will remove any existing coverage listed for this employee during that period.';
const MULTI_SELECTION_TEXT =
  'By confirming, you will waive coverage for the onboarding periods of the selected employees. This action will remove any existing coverage listed for these employees during those periods.';

export type WaiveCoverageModalProps = {
  selectedEmployees: SelectedEmployees[];
  openEnrollmentPeriod?: OpenEnrollmentPeriodDto;
  modalControlProps: ModalControlProps;
};

export const WaiveCoverageModal = ({
  selectedEmployees,
  modalControlProps,
  openEnrollmentPeriod,
}: WaiveCoverageModalProps) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const { onCloseModal } = modalControlProps;
  const confirmationText =
    selectedEmployees.length > 1 ? MULTI_SELECTION_TEXT : SINGLE_SELECTION_TEXT;
  const [selectedDates, setSelectedDates] = useState<DatesRangeValue>([
    null,
    null,
  ]);
  const [resultErrors, setResultErrors] = useState<Record<string, string>[]>(
    []
  );
  const minDate = openEnrollmentPeriod
    ? parseDateISO(openEnrollmentPeriod.effectiveFrom).toDate()
    : undefined;
  const maxDate = openEnrollmentPeriod
    ? parseDateISO(openEnrollmentPeriod.effectiveUntil).toDate()
    : undefined;

  const waiveCoverageAction = async () => {
    try {
      startLoading();

      const [from, until] = selectedDates as [Date, Date];

      const employeeBatches = _chunk(selectedEmployees, 5);

      let results: PromiseSettledResult<unknown>[] = [];
      for (const batch of employeeBatches) {
        const batchResults = await Promise.allSettled(
          batch.map(({ employeeId, onboardingPeriodId }) =>
            callEndpoint({
              method: 'benefitsControllerWaiveCoverage',
              params: [
                {
                  employeeId,
                  onboardingPeriodId,
                  effectiveDates: {
                    from: formatDateISO(from),
                    until: formatDateISO(until),
                  },
                },
              ],
            })
          )
        );

        results = [...results, ...batchResults];
      }

      await queryClient.invalidateQueries();
      reloadPage();
      stopLoading();

      await handleBatchNotifications(
        results,
        { singular: 'employee' },
        'waived'
      );

      const errorsFromPromises = getErrorsFromPromises(
        results,
        selectedEmployees.map((item) => ({
          ...item,
          fullName: `${item.firstName} ${item.lastName}`,
        })),
        'fullName'
      );

      if (errorsFromPromises.length > 0 && selectedEmployees.length > 1) {
        setResultErrors(errorsFromPromises);
      }

      if (errorsFromPromises.length === 0) {
        onCloseModal();
      }
    } catch {
      /* empty */
    }
  };

  return (
    <Modal
      size="30rem"
      onClose={() => {
        onCloseModal();
        setResultErrors([]);
      }}
      styles={{
        body: {
          paddingRight: 40,
          paddingLeft: 40,
        },
      }}
      title={
        <Flex align="center">
          <>
            <IconZorroWritingSign />
            <Text size="xl" ml="md" fw={500}>
              Waive Coverage
            </Text>
          </>
        </Flex>
      }
    >
      <Stack>
        <Text>{confirmationText}</Text>
        <DateRangeInput
          minDate={minDate}
          maxDate={maxDate}
          label="Effective Period"
          required
          value={selectedDates}
          onChange={setSelectedDates}
        />
      </Stack>

      <Space h="xl" />
      <Group justify="space-evenly" my="md">
        <Button variant="secondary" onClick={onCloseModal} size="xl">
          Cancel
        </Button>
        <Button
          onClick={async () => await waiveCoverageAction()}
          size="xl"
          disabled={selectedDates.includes(null)}
        >
          Confirm
        </Button>
      </Group>
      {resultErrors && resultErrors?.length > 0 && (
        <Grid gutter="xl">
          <Grid.Col>
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                {
                  accessor: 'fullName',
                  title: 'Employee name',
                },
                { accessor: 'error', title: 'Message' },
              ]}
              records={resultErrors}
              idAccessor="employeeId"
            />
          </Grid.Col>
        </Grid>
      )}
    </Modal>
  );
};
