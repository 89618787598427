import { IconDots, IconDownload } from '@tabler/icons-react';
import { BenefitDocumentDto } from '@zorro/clients';
import { FileIcon } from '@zorro/shared/assets';
import { formatDateEnLocale } from '@zorro/shared/formatters';
import {
  downloadBenefitDocument,
  useImpersonation,
  useRoles,
} from '@zorro/shared/utils';
import {
  ActionIcon,
  Box,
  Card,
  Flex,
  Icon,
  Menu,
  Space,
  Text,
  brand,
} from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../hooks/useMonolithMutation';

export type Props = {
  document: BenefitDocumentDto;
  onDelete: () => void;
};

export const BenefitDocument = ({ document, onDelete }: Props) => {
  const { isAgent, isZorroOperations } = useRoles();
  const { impersonatedEmployeeDetails } = useImpersonation();
  const isDon =
    Boolean(impersonatedEmployeeDetails) || !(isAgent || isZorroOperations);

  const { mutate: updateDocument } = useMonolithMutation({
    method: 'benefitDocumentsControllerUpdateBenefitDocument',
    successMessage: 'Document updated',
  });

  return (
    <Card key={document.fileName} h="64px" p="sm">
      <Flex justify="space-between" align="center" w="100%">
        <Flex
          justify="space-evenly"
          align="center"
          style={{ overflow: 'hidden' }}
        >
          <Box
            bg={brand.primaryButtonSelected}
            w="40px"
            h="40px"
            style={(theme) => ({
              borderRadius: theme.spacing.xxs,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            })}
          >
            <FileIcon />
          </Box>

          <Space w="sm" />
          <Text
            mr="sm"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {document.fileName}
          </Text>
        </Flex>
        <Flex align="flex-end">
          <Text c={brand.zorroGray400} style={{ textWrap: 'nowrap' }}>
            Updated on{' '}
            {document.updatedAt
              ? formatDateEnLocale(document.updatedAt)
              : formatDateEnLocale(document.createdAt)}
          </Text>
          <Space w="xl" />
          <IconDownload
            style={{ cursor: 'pointer', color: brand.zorroGray400 }}
            data-testid="documents-download-button"
            onClick={async () =>
              await downloadBenefitDocument(
                document.documentUrl,
                document.fileName
              )
            }
          />
          {!isDon && (
            <>
              <Space w="xs" />
              <Menu data-testid="user-menu">
                <Menu.Target>
                  <ActionIcon variant="transparent">
                    <Icon icon={IconDots} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    data-testid="change-document-sharing-option"
                    onClick={() =>
                      updateDocument([
                        document.id,
                        {
                          isSharedWithEmployee: !document.isSharedWithEmployee,
                        },
                      ])
                    }
                  >
                    Change to{' '}
                    {document.isSharedWithEmployee ? 'admin' : 'employee'}{' '}
                    document
                  </Menu.Item>
                  <Menu.Item data-testid="delete-document" onClick={onDelete}>
                    Delete
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
