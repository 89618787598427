import { Stack } from '@mantine/core';

import { FormFooter } from '../FormFooter';
import { InsuredFormInputs, useInsuredForm } from '../InsuredForm';

const isFinalizationMode = false;

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
};

export const PersonalDetailsTabContent = ({
  employerId,
  employeeId,
  onboardingPeriodId,
}: Props) => {
  const insuredForm = useInsuredForm({
    onboardingPeriodId,
    employeeId,
    isFinalizationMode,
  });

  const handleCancel = () => {
    insuredForm.employeeForm.reset();
    insuredForm.spouseForm.reset();
    insuredForm.dependentsForm.reset();
  };

  const { isDirty: isEmployeeDirty } = insuredForm.employeeForm.formState;
  const { isDirty: isSpouseDirty } = insuredForm.spouseForm.formState;
  const { isDirty: isDependentsDirty } = insuredForm.dependentsForm.formState;

  const isAnyFieldTouched =
    isEmployeeDirty || isSpouseDirty || isDependentsDirty;

  return (
    <Stack maw={800} mb="5rem">
      <InsuredFormInputs
        isFinalizationMode={isFinalizationMode}
        insuredForm={insuredForm}
        employerId={employerId}
      />

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{
          onClick: () => insuredForm.onSubmit(),
          disabled: !isAnyFieldTouched,
        }}
        secondaryLabel={isAnyFieldTouched ? 'Cancel' : undefined}
        secondaryButtonProps={{ onClick: handleCancel }}
      />
    </Stack>
  );
};
