import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { OnboardingPeriodReportDto } from '@zorro/clients';
import { DoctorDtoEnhanced, EmployeePageData } from '@zorro/types';

import { nullIfNotFound } from '../async';
import { callEndpoint } from '../callEndpoint';
import { findLatestPeriod } from '../enrollment';
import { logger } from '../logger';

type UseEmployeePageQueryProps = {
  employeeId: string;
  explicitlySelectedOnboardingPeriodId?: string;
  initialOpenEnrollmentPeriodId?: string;
  initialOnboardingPeriodId?: string;
};

type UseEmployeePageQueryReturnType = {
  data: EmployeePageData | null;
  isLoading: boolean;
  refetch: () => Promise<QueryObserverResult<EmployeePageData | null>>;
};

const selectOnboardingPeriod = (
  explicitlySelectedOnboardingPeriodId: string | undefined,
  allOnboardingPeriods: OnboardingPeriodReportDto[],
  initialOpenEnrollmentPeriodId: string | undefined
) => {
  if (allOnboardingPeriods) {
    if (explicitlySelectedOnboardingPeriodId) {
      const selectedOnboardingPeriod = allOnboardingPeriods.find(
        ({ id }) => id === explicitlySelectedOnboardingPeriodId
      );
      if (selectedOnboardingPeriod) {
        return selectedOnboardingPeriod;
      }
    }
    if (initialOpenEnrollmentPeriodId) {
      const latestOnboardingPeriodByInitialOepId = findLatestPeriod(
        allOnboardingPeriods.filter(
          ({ enrollmentPeriodId }) =>
            initialOpenEnrollmentPeriodId === enrollmentPeriodId
        )
      );
      if (latestOnboardingPeriodByInitialOepId) {
        return latestOnboardingPeriodByInitialOepId;
      }
    }
    const latestPeriod = findLatestPeriod(allOnboardingPeriods);
    if (latestPeriod) {
      return latestPeriod;
    }
  }
  return null;
};

export const useEmployeePageQuery = ({
  employeeId,
  explicitlySelectedOnboardingPeriodId,
  initialOpenEnrollmentPeriodId,
}: UseEmployeePageQueryProps): UseEmployeePageQueryReturnType => {
  const {
    isLoading,
    refetch,
    data = null,
  } = useQuery({
    queryKey: [
      useEmployeePageQuery.name,
      employeeId,
      explicitlySelectedOnboardingPeriodId,
    ],
    queryFn: async () => {
      try {
        const [onboardingPeriods, employee] = await Promise.all([
          callEndpoint({
            method:
              'onboardingPeriodReportsControllerGenerateEmployeeOnboardingReport',
            params: [employeeId],
          }),
          callEndpoint({
            method: 'employeesControllerFindOne',
            params: [employeeId],
          }),
        ]);
        const selectedOnboardingPeriod = selectOnboardingPeriod(
          explicitlySelectedOnboardingPeriodId,
          onboardingPeriods,
          initialOpenEnrollmentPeriodId
        );
        const doctorNPIs: number[] = employee.carePreferences
          ? employee.carePreferences.doctors.map((doctor) => doctor.npi)
          : [];

        if (doctorNPIs.length > 0) {
          const doctorsData = await callEndpoint({
            method: 'insuranceControllerSearchProvidersByIds',
            params: [doctorNPIs],
          });

          employee.carePreferences?.doctors.forEach(
            (doctor: DoctorDtoEnhanced) => {
              const doctorFound = doctorsData.find(
                (data) => data.npis?.[0] === doctor.npi
              );
              doctor.name = doctorFound?.presentationName;
            }
          );
        }

        const insuredPeople = selectedOnboardingPeriod
          ? await nullIfNotFound(() =>
              callEndpoint({
                method: 'insuredControllerFindInsuredForPeriod',
                params: [selectedOnboardingPeriod.id],
              })
            )
          : null;

        return {
          selectedOnboardingPeriod,
          onboardingPeriods,
          insuredPeople,
          employee,
        };
      } catch (error) {
        logger.error(error);
      }

      return null;
    },
  });

  return { isLoading, data, refetch };
};
