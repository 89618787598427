/* eslint-disable unicorn/filename-case */
import { ModalControlProps } from '../../ModalControls';

export function impersonateEmployee(
  employeeId: string,
  modalControlProps: ModalControlProps
) {
  modalControlProps.onCloseModal();
  window.open(
    `${process.env.NEXT_PUBLIC_ZORRO_UI_BASE_URL}?impersonatedEmployeeId=${employeeId}`,
    '_blank'
  );
}
