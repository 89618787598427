import { IconInfoCircle, IconLink } from '@tabler/icons-react';
import {
  EmployerSignupStatus,
  EmployerStatus,
  GetEmployerDto,
} from '@zorro/clients';
import { getNow, parseDateISO } from '@zorro/shared/formatters';
import { showSuccessNotification } from '@zorro/shared/utils';
import { ValueOf } from '@zorro/types';
import {
  Alert,
  Button,
  Group,
  Stack,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import CopyToClipboard from 'react-copy-to-clipboard';

const reviewStatusInfoConfig: {
  [key in EmployerStatus]?: {
    getTitle: (employerName: string) => string;
    getSubtitle: (employerName: string) => string;
    backgroundColor: ValueOf<typeof brand>;
    borderColor: ValueOf<typeof brand>;
    shouldShowButtons?: boolean;
  };
} = {
  [EmployerStatus.WAITING_FOR_EMPLOYER]: {
    getTitle: (employerName) =>
      `Waiting for ${employerName} to complete their company profile`,
    getSubtitle: (employerName) =>
      `You've invited ${employerName} to complete their profile. We'll notify you once they're done`,
    backgroundColor: brand.zorroYolk200,
    borderColor: brand.zorroYolk800,
    shouldShowButtons: true,
  },
  [EmployerStatus.NEEDS_REVIEW]: {
    getTitle: () => 'Account awaiting review and finalization',
    getSubtitle: (employerName) =>
      `${employerName} has completed their profile. Please review and finalize`,
    backgroundColor: brand.zorroSky100,
    borderColor: brand.zorroSky500,
  },
  [EmployerStatus.IN_ZORRO_SETUP]: {
    getTitle: () => 'Account in Zorro setup',
    getSubtitle: () =>
      'Zorro team is preparing the account. We will notify you once it is active and ready for employee onboarding',
    backgroundColor: brand.primaryButtonSelected,
    borderColor: brand.zorroIris900,
  },
};

type Props = {
  employer: GetEmployerDto;
};

export const ReviewStatus = ({ employer }: Props) => {
  const { name, signupStatus, invitationLink, invitationLinkExpiry } = employer;
  const statusInfo = reviewStatusInfoConfig[signupStatus];
  if (!statusInfo) return null;
  const { getTitle, getSubtitle, backgroundColor, borderColor } = statusInfo;

  const isInvitationLinkValid = invitationLinkExpiry
    ? parseDateISO(invitationLinkExpiry).isAfter(getNow())
    : false;
  const shouldShowCopyInvite =
    signupStatus === EmployerSignupStatus.WAITING_FOR_EMPLOYER &&
    Boolean(invitationLink) &&
    isInvitationLinkValid;

  return (
    <Alert
      variant="light"
      bg={backgroundColor}
      style={{
        border: `1px solid ${borderColor}`,
      }}
    >
      <Stack gap="xs">
        <Group gap="xs">
          <IconInfoCircle />
          <Text fw={600}>{getTitle(name)}</Text>
        </Group>
        <Text fw={400} size="sm">
          {getSubtitle(name)}
        </Text>
        {shouldShowCopyInvite && (
          <Group mt="sm">
            <CopyToClipboard
              text={invitationLink || ''}
              onCopy={() =>
                showSuccessNotification({
                  title: '',
                  message: 'Invitation link copied to clipboard',
                })
              }
            >
              <Button size="xs" bg="transparent" variant="secondary">
                <IconLink />
                <Text ml="xs">Copy invitation link</Text>
              </Button>
            </CopyToClipboard>
          </Group>
        )}
      </Stack>
    </Alert>
  );
};
