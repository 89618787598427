import { Pill } from '@mantine/core';
import { Roles } from '@zorro/clients';
import {
  COMPANY_SETUP_DYNAMIC_ROUTES,
  SAVED_SUCCESSFULLY_MESSAGE,
  showSuccessNotification,
  useDynamicMurrietaRouter,
  useImpersonation,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { Group, Stack, Tabs, Title } from '@zorro/zorro-ui-design';
import { ReactElement } from 'react';
import { FieldValues, FormState } from 'react-hook-form';

import { EmployerDotsMenu } from '../EmployerDotsMenu';
import { FormFooter } from '../FormFooter';
import { useLoadingOverlay } from '../LoadingOverlayContext';

export enum CompanySetupTabType {
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  CONTACTS = 'CONTACTS',
  COMPANY_SETUP = 'COMPANY_SETUP',
  ZORRO_SETUP = 'ZORRO_SETUP',
  PLAN_YEAR_SETUP = 'PLAN_YEAR_SETUP',
}

const allSetupTabs = Object.values(CompanySetupTabType);
const setupTabConfig: Record<
  CompanySetupTabType,
  {
    label: string;
    route: keyof typeof COMPANY_SETUP_DYNAMIC_ROUTES;
    contentWidth?: string;
  }
> = {
  [CompanySetupTabType.COMPANY_DETAILS]: {
    label: 'Company details',
    route: 'COMPANY_SETUP_COMPANY_DETAILS',
    contentWidth: '710px',
  },
  [CompanySetupTabType.CONTACTS]: {
    label: 'Contacts',
    route: 'COMPANY_SETUP_CONTACTS',
    contentWidth: '710px',
  },
  [CompanySetupTabType.COMPANY_SETUP]: {
    label: 'Company setup',
    route: 'COMPANY_SETUP_COMPANY_SETUP',
    contentWidth: '710px',
  },
  [CompanySetupTabType.ZORRO_SETUP]: {
    label: 'Zorro setup',
    route: 'COMPANY_SETUP_ZORRO_SETUP',
    contentWidth: '710px',
  },
  [CompanySetupTabType.PLAN_YEAR_SETUP]: {
    label: 'Plan year setup',
    route: 'COMPANY_SETUP_PLAN_YEAR_SETUP',
  },
};

type Props = {
  tab: CompanySetupTabType;
  employerId: string;
  children: ReactElement;
  formState?: FormState<FieldValues>;
  onSubmit?: () => Promise<boolean>;
  onCancel?: () => void;
};

export const CompanySetupTabWrapper = ({
  tab,
  employerId,
  onSubmit,
  onCancel,
  formState,
  children,
}: Props) => {
  const { loggedInUserRoles, isAgentWithoutSupervisor: isReadonlyMode } =
    useRoles();
  const { impersonatedUserDetails } = useImpersonation();
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { contentWidth } = setupTabConfig[tab];
  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });

  const handleTabClick = (newTab: CompanySetupTabType) => {
    const { route } = setupTabConfig[newTab];
    navigateToRoute(route, [employerId]);
  };

  const handleSubmit = async () => {
    startLoading();
    if (await onSubmit?.()) {
      showSuccessNotification({ message: SAVED_SUCCESSFULLY_MESSAGE });
    }

    stopLoading();
  };

  const { isValid, isDirty } = formState || {};
  const isSubmitDisabled = !isValid || !isDirty;

  const isEmployerAdmin = impersonatedUserDetails
    ? impersonatedUserDetails.roles
        .map((frontEggRole) => frontEggRole.key)
        .includes(Roles.EMPLOYER_ADMIN)
    : loggedInUserRoles.includes(Roles.EMPLOYER_ADMIN);
  const setupTabs = isEmployerAdmin
    ? allSetupTabs.filter(
        (setupTab) => setupTab !== CompanySetupTabType.ZORRO_SETUP
      )
    : allSetupTabs;

  return (
    <Stack maw={contentWidth} mb="6rem">
      <Group gap="xs">
        <Title fw={600}>Setup</Title>
        {!isReadonlyMode && !isEmployerAdmin && (
          <EmployerDotsMenu employerId={employerId} />
        )}
        {employer?.isDemo && (
          <Pill ml="sm" size="md" bg="#E0E3FE" radius="sm">
            Demo company
          </Pill>
        )}
      </Group>
      <Tabs maw="710px" mt="sm" keepMounted={false} value={tab}>
        <Tabs.List grow>
          {setupTabs.map((newTab) => (
            <Tabs.Tab
              onClick={() => handleTabClick(newTab)}
              value={newTab}
              key={newTab}
            >
              {setupTabConfig[newTab].label}
            </Tabs.Tab>
          ))}
        </Tabs.List>
      </Tabs>
      {children}
      {formState && onSubmit && !isReadonlyMode && (
        <FormFooter
          primaryLabel="Save changes"
          primaryButtonProps={{
            disabled: isSubmitDisabled,
            onClick: handleSubmit,
          }}
          secondaryLabel={onCancel ? 'Cancel' : undefined}
          secondaryButtonProps={{ onClick: onCancel }}
        />
      )}
    </Stack>
  );
};
