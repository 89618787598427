import { Accordion, AccordionProps } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ZorroIcon } from '../Icon';
import classNames from './Accordion.module.scss';
import { ZorroAccordionControl } from './AccordionControl';
import { ZorroAccordionItem } from './AccordionItem';
import { ZorroAccordionPanel } from './AccordionPanel';

interface AccordionItemData {
  id?: string;
  header: ReactNode | string;
  icon?: ReactNode;
  children: ReactNode;
}

export interface IAccordionProps
  extends Omit<AccordionProps, 'children' | 'classNames'> {
  items?: AccordionItemData[];
  children?: ReactNode;
}

export const ZorroAccordion = ({
  items,
  children,
  ...props
}: IAccordionProps) => {
  return (
    <Accordion
      chevron={<ZorroIcon icon={IconChevronDown} />}
      chevronSize={24}
      classNames={classNames}
      {...props}
    >
      {items && items.length > 0
        ? items.map((item) => {
            /*
             * INFO: This way we can have Accordion item open by default based on the item.id
             * If not a requirement, just let uuid handle the id generation so we dont need to pass it as prop.
             */
            const identifier = item.id || uuidv4();

            return (
              <Accordion.Item
                key={identifier}
                value={identifier}
                data-testid="accordion-item"
              >
                <Accordion.Control icon={item.icon}>
                  {item.header}
                </Accordion.Control>
                <Accordion.Panel>{item.children}</Accordion.Panel>
              </Accordion.Item>
            );
          })
        : children}
    </Accordion>
  );
};

ZorroAccordion.Item = ZorroAccordionItem;
ZorroAccordion.Control = ZorroAccordionControl;
ZorroAccordion.Panel = ZorroAccordionPanel;
