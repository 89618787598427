import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Gender, InsuredSubtype } from '@zorro/clients';
import { DateUtilInstance } from '@zorro/shared/formatters';
import {
  getAdultDefaultDate,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
  validationMessages,
} from '@zorro/shared/utils';
import { YesNo, insuredSubtypeLabelConfig } from '@zorro/types';
import {
  ActionIcon,
  AddressInput,
  Button,
  Collapse,
  DateInput,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  InputWrapper,
  PasswordInput,
  Select,
  Space,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormReset,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import * as yup from 'yup';

import { CheckmarkBoxWrapper } from './CheckmarkBoxWrapper';
import {
  getDefaultInsuredFormFields,
  getInsuredBaseSchema,
} from './InsuredFormUtils';

const spouseRelationshipValues = Object.values([
  InsuredSubtype.SPOUSE,
  InsuredSubtype.DOMESTIC_PARTNER,
]);

const insuredTypeOptions = spouseRelationshipValues.map((status) => ({
  label: insuredSubtypeLabelConfig[status],
  value: status,
}));

export const getSpouseInsuredSchema = (isFinalizationMode: boolean) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      ...(isFinalizationMode
        ? {
            subtype: yup
              .mixed<InsuredSubtype>()
              .required(validationMessages.relationshipRequired)
              .oneOf(
                spouseRelationshipValues,
                validationMessages.relationshipRequired
              ),
          }
        : {
            subtype: yup
              .mixed<InsuredSubtype>()
              .oneOf(
                spouseRelationshipValues,
                validationMessages.relationshipRequired
              )
              .nullable(),
          }),
    })
  );

export type InsuredSpouseFormFields = yup.InferType<
  ReturnType<typeof getSpouseInsuredSchema>
>;

type Props = {
  setIsSpouseActive: (value: boolean) => void;
  isSpouseActive: boolean;
  control: Control<InsuredSpouseFormFields>;
  formState: FormState<InsuredSpouseFormFields>;
  watch: UseFormWatch<InsuredSpouseFormFields>;
  trigger: UseFormTrigger<InsuredSpouseFormFields>;
  reset: UseFormReset<InsuredSpouseFormFields>;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isReadonlyMode?: boolean;
};

export const SpouseFormInputs = ({
  isFinalizationMode,
  setIsSpouseActive,
  isReadonlyMode,
  isSpouseActive,
  formState,
  control,
  trigger,
  watch,
  reset,
  targetEnrollmentDate,
}: Props) => {
  const { isValid, errors } = formState;
  const [isOpen, setIsOpen] = useState<boolean>(!isReadonlyMode);

  useEffect(() => {
    if (!isValid && isSpouseActive && !isReadonlyMode) {
      setIsOpen(true);
    }
  }, [isValid, isSpouseActive, isReadonlyMode, setIsOpen]);

  const fullName = `${watch('firstName') || ''} ${
    watch('lastName') || ''
  }`.trim();
  const isFemale = watch('gender') === Gender.FEMALE;
  const subtypeLabel =
    watch('subtype') === InsuredSubtype.DOMESTIC_PARTNER
      ? 'Domestic partner'
      : 'Spouse';

  const handleArrowClick = async () => {
    if (isReadonlyMode) {
      setIsOpen((prev) => !prev);
    } else {
      const result = await trigger();
      if (!isOpen || result) {
        setIsOpen((prev) => !prev);
      }
    }
  };

  const handleCheckmarkClick = () => {
    if (!isSpouseActive) {
      setIsSpouseActive(true);
      setIsOpen(true);
    }
  };

  const handleDeleteSpouse = () => {
    setIsOpen(false);
    reset(getDefaultInsuredFormFields(undefined));
    setIsSpouseActive(false);
  };

  if (isReadonlyMode && !isSpouseActive) {
    return null;
  }

  return (
    <CheckmarkBoxWrapper
      hasErrors={
        isSpouseActive && Object.keys(errors).length > 0 && !isReadonlyMode
      }
      isFilledOut={isSpouseActive && isValid}
      handleKeyDown={handleCheckmarkClick}
      handleClick={handleCheckmarkClick}
      tabIndex={isSpouseActive ? -1 : 0}
      style={{ cursor: isSpouseActive ? 'default' : 'pointer' }}
      py={isSpouseActive && isOpen ? '2rem' : '1.125rem'}
      px={{ base: '1.5rem', sm: '3rem' }}
      isOpen={isOpen}
      opacity={1}
      w="100%"
    >
      {isSpouseActive ? (
        <>
          <Group justify="space-between">
            <Text fw="600" className="fs-mask">
              {!fullName || isOpen
                ? 'Spouse / partner'
                : `${subtypeLabel} (${fullName})`}
            </Text>

            <ActionIcon
              variant="transparent"
              onClick={handleArrowClick}
              onKeyDown={(event) => {
                if (event.code === 'Space' || event.code === 'Enter') {
                  handleArrowClick();
                }
              }}
            >
              {isOpen ? (
                <Icon icon={IconChevronUp} aria-label="Collapse spouse form" />
              ) : (
                <Icon icon={IconChevronDown} aria-label="Expand spouse form" />
              )}
            </ActionIcon>
          </Group>

          <Collapse in={isOpen}>
            <Space h="xl" />
            <Grid>
              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field: { ...rest } }) => (
                    <TextInput
                      {...rest}
                      label="First name"
                      placeholder="First name"
                      readOnly={isReadonlyMode}
                      required
                    />
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="firstName" />
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field: { ...rest } }) => (
                    <TextInput
                      {...rest}
                      label="Last name"
                      placeholder="Last name"
                      readOnly={isReadonlyMode}
                      required
                    />
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="lastName" />
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="subtype"
                  render={({ field: { ...rest } }) => (
                    <Select
                      {...rest}
                      label="Relationship"
                      placeholder="Select relationship"
                      data={insuredTypeOptions}
                      readOnly={isReadonlyMode}
                      required={isFinalizationMode}
                    />
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="subtype" />
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field: { ...rest } }) => (
                    <DateInput
                      {...rest}
                      label="Date of birth"
                      defaultLevel="decade"
                      defaultDate={getAdultDefaultDate()}
                      minDate={insuredMinDateOfBirth(
                        false,
                        targetEnrollmentDate
                      )}
                      maxDate={insuredMaxDateOfBirth(
                        false,
                        targetEnrollmentDate
                      )}
                      readOnly={isReadonlyMode}
                      required
                    />
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field: { ...rest } }) => (
                    <InputWrapper label="Gender" required={isFinalizationMode}>
                      <TabButtonGroup {...rest}>
                        <Tabs.List grow>
                          <Tabs.Tab
                            disabled={isReadonlyMode}
                            value={Gender.MALE}
                          >
                            Male
                          </Tabs.Tab>
                          <Tabs.Tab
                            disabled={isReadonlyMode}
                            value={Gender.FEMALE}
                          >
                            Female
                          </Tabs.Tab>
                        </Tabs.List>
                      </TabButtonGroup>
                    </InputWrapper>
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="gender" />
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="isSmoker"
                  render={({ field: { ...rest } }) => (
                    <InputWrapper
                      label="Tobacco User"
                      required={isFinalizationMode}
                    >
                      <TabButtonGroup {...rest}>
                        <Tabs.List grow>
                          <Tabs.Tab disabled={isReadonlyMode} value={YesNo.YES}>
                            Yes
                          </Tabs.Tab>
                          <Tabs.Tab disabled={isReadonlyMode} value={YesNo.NO}>
                            No
                          </Tabs.Tab>
                        </Tabs.List>
                      </TabButtonGroup>
                    </InputWrapper>
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="isSmoker" />
                )}
              </Grid.Col>

              {isFemale && (
                <Grid.Col span={{ sm: 6 }}>
                  <Controller
                    control={control}
                    name="isPregnant"
                    render={({ field: { ...rest } }) => (
                      <InputWrapper
                        label="Pregnant"
                        required={isFinalizationMode}
                      >
                        <TabButtonGroup {...rest}>
                          <Tabs.List grow>
                            <Tabs.Tab
                              disabled={isReadonlyMode}
                              value={YesNo.YES}
                            >
                              Yes
                            </Tabs.Tab>
                            <Tabs.Tab
                              disabled={isReadonlyMode}
                              value={YesNo.NO}
                            >
                              No
                            </Tabs.Tab>
                          </Tabs.List>
                        </TabButtonGroup>
                      </InputWrapper>
                    )}
                  />

                  {!isReadonlyMode && (
                    <FormErrorMessage errors={errors} fieldName="isPregnant" />
                  )}
                </Grid.Col>
              )}

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="ssn"
                  render={({ field: { value, ...rest } }) => (
                    <PasswordInput
                      {...rest}
                      label="SSN"
                      placeholder="XXXXXXXXX"
                      value={value || ''}
                      maxLength={9}
                      onKeyDown={validateOnlyNumbers}
                      required={isFinalizationMode}
                      readOnly={isReadonlyMode}
                    />
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="ssn" />
                )}
              </Grid.Col>

              <Grid.Col>
                <Controller
                  control={control}
                  name="residentialAddress"
                  render={({ field: { value, onBlur, onChange, ...rest } }) => (
                    <AddressInput
                      {...rest}
                      onBlur={() => {
                        onChange(value);
                        onBlur();
                      }}
                      notifySubscribers={(newAddress) => onChange(newAddress)}
                      onChange={onChange}
                      label="Residential address"
                      value={value || ''}
                      placeholder="Type residential address"
                      readOnly={isReadonlyMode}
                      required={isFinalizationMode}
                    />
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage
                    errors={errors}
                    fieldName="residentialAddress"
                  />
                )}
              </Grid.Col>

              {!isReadonlyMode && (
                <Grid.Col style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button variant="subtle" p={1} onClick={handleDeleteSpouse}>
                    Delete
                  </Button>
                </Grid.Col>
              )}
            </Grid>
          </Collapse>
        </>
      ) : (
        <Text ta="center" c="zorroIris.9">
          + Add spouse / domestic partner
        </Text>
      )}
    </CheckmarkBoxWrapper>
  );
};
