import { Stack, Text, Title } from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

type StepHeadingProps = {
  title: string;
  subtitle?: string | ReactNode;
};

export function StepHeading({ title, subtitle }: StepHeadingProps) {
  return (
    <Stack align="center" gap="xs">
      <Title data-testid="step-heading-title" ta="center">
        {title}
      </Title>

      {subtitle && (
        <Text ta="center" data-testid="step-heading-subtitle">
          {subtitle}
        </Text>
      )}
    </Stack>
  );
}
