import { yupResolver } from '@hookform/resolvers/yup';
import { Gender, OnboardingPeriodReportDto } from '@zorro/clients';
import { SquareRoundedPlusIcon } from '@zorro/shared/assets';
import { getNowAsDate, parseDateISO } from '@zorro/shared/formatters';
import {
  allowedChildDateOfBirth,
  allowedNonChildDateOfBirth,
  familyMemberSchema,
  getAdultDefaultDate,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  ssnRegex,
  useForm,
  validateOnlyNumbers,
  validationMessages,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import {
  AddressInput,
  Button,
  Center,
  DateInput,
  Drawer,
  Flex,
  FormErrorMessage,
  Grid,
  InputWrapper,
  PasswordInput,
  Select,
  Space,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { InsuredDetailsAddFormFields, InsuredTypes } from './insuredForm.types';

const insuredDetailsAddSchema = () => {
  const { ssn: _, ...schema } = familyMemberSchema();
  return yup.object().shape({
    ...schema,
    ssn: yup.string().matches(ssnRegex, {
      message: validationMessages.ssnNumberValid,
      excludeEmptyString: true,
    }),
    address: yup.string().notRequired(),
    dateOfBirth: yup.string().when('insuredType', {
      is: InsuredTypes.DEPENDENT,
      // See more: https://github.com/sindresorhus/eslint-plugin-unicorn/issues/1710
      then: () => allowedChildDateOfBirth.dateOfBirth,
      otherwise: () => allowedNonChildDateOfBirth.dateOfBirth,
    }),
  });
};

type InsuredDetailsAddDrawerProps = {
  isModalOpen: boolean;
  isSpouseDisabled: boolean;
  setIsModalOpen: (open: boolean) => void;
  addFamilyMember: (
    insured: InsuredDetailsAddFormFields,
    isDependent: boolean
  ) => void;
  planEffectiveFrom?: Date;
  employeeAddress?: string | null;
  selectedOnboardingPeriod: OnboardingPeriodReportDto | null;
};

export const InsuredDetailsAddDrawer = ({
  isModalOpen,
  isSpouseDisabled,
  setIsModalOpen,
  addFamilyMember,
  planEffectiveFrom,
  employeeAddress,
  selectedOnboardingPeriod,
}: InsuredDetailsAddDrawerProps) => {
  const { setValue, control, formState, reset, watch } =
    useForm<InsuredDetailsAddFormFields>({
      mode: 'onBlur',
      resolver: yupResolver(insuredDetailsAddSchema()),
      defaultValues: {
        firstName: '',
        lastName: '',
        residentialAddress: employeeAddress,
        insuredType: InsuredTypes.DEPENDENT,
        isSmoker: YesNo.NO,
      },
    });

  const { errors, isValid, isSubmitting } = formState;

  const isDependent = watch('insuredType') === InsuredTypes.DEPENDENT;
  const targetEnrollmentDate =
    selectedOnboardingPeriod &&
    parseDateISO(selectedOnboardingPeriod?.targetEnrollmentDate);

  const isFemale = watch('gender') === Gender.FEMALE;

  useEffect(() => {
    setValue('residentialAddress', employeeAddress, { shouldValidate: true });
  }, [employeeAddress, setValue]);

  return (
    <Drawer
      opened={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      title={
        <Flex align="center">
          <SquareRoundedPlusIcon />
          <Text size="xl" ml="sm" fw={500}>
            Add new family member
          </Text>
        </Flex>
      }
    >
      <>
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="firstName"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <TextInput
                  {...rest}
                  label="First name"
                  placeholder="First name"
                />
              )}
            />

            <FormErrorMessage fieldName="firstName" errors={errors} />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="lastName"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <TextInput
                  {...rest}
                  label="Last name"
                  placeholder="Last name"
                />
              )}
            />

            <FormErrorMessage fieldName="lastName" errors={errors} />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="insuredType"
              render={({ field: { ref: _ref, ...rest } }) => (
                <Select
                  {...rest}
                  label="Type"
                  data={[
                    {
                      label: 'Spouse',
                      value: InsuredTypes.SPOUSE,
                      disabled: isSpouseDisabled,
                    },
                    {
                      label: 'Dependent',
                      value: InsuredTypes.DEPENDENT,
                    },
                  ]}
                  defaultValue="dependent"
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="insuredType" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="gender"
              render={({ field: { ref: _ref, onChange, ...rest } }) => (
                <InputWrapper label="Gender">
                  <TabButtonGroup {...rest} onChange={onChange}>
                    <Tabs.List grow>
                      <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                      <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            <FormErrorMessage errors={errors} fieldName="gender" />
          </Grid.Col>

          <Grid.Col>
            <Controller
              control={control}
              name="residentialAddress"
              render={({
                field: { ref: _ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <AddressInput
                  {...rest}
                  notifySubscribers={(address: string) => {
                    onChange(address);
                  }}
                  onChange={onChange}
                  label="Residential address"
                  value={rest.value || ''}
                  placeholder="Residential address"
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="residentialAddress" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  label="Date of birth"
                  defaultLevel="decade"
                  defaultDate={
                    isDependent ? getNowAsDate() : getAdultDefaultDate()
                  }
                  minDate={insuredMinDateOfBirth(
                    isDependent,
                    targetEnrollmentDate
                  )}
                  maxDate={insuredMaxDateOfBirth(
                    isDependent,
                    targetEnrollmentDate
                  )}
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="isSmoker"
              render={({ field: { ref: _ref, onChange, ...rest } }) => (
                <InputWrapper label="Tobacco user">
                  <TabButtonGroup
                    {...rest}
                    onChange={onChange}
                    defaultValue={YesNo.NO}
                  >
                    <Tabs.List grow>
                      <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                      <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            <FormErrorMessage errors={errors} fieldName="isSmoker" />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="ssn"
              render={({
                field: { value, ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <PasswordInput
                  {...rest}
                  label="SSN"
                  placeholder="XXXXXXXXX"
                  value={value || ''}
                  maxLength={9}
                  onKeyDown={validateOnlyNumbers}
                />
              )}
            />

            <FormErrorMessage fieldName="ssn" errors={errors} />
          </Grid.Col>

          {isFemale && (
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                control={control}
                name="isPregnant"
                render={({ field: { ref: _ref, onChange, ...rest } }) => (
                  <InputWrapper label="Pregnant">
                    <TabButtonGroup {...rest} onChange={onChange}>
                      <Tabs.List grow>
                        <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                        <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                      </Tabs.List>
                    </TabButtonGroup>
                  </InputWrapper>
                )}
              />

              <FormErrorMessage errors={errors} fieldName="isPregnant" />
            </Grid.Col>
          )}
        </Grid>

        <Space h="lg" mt="lg" />

        <Center>
          <Button
            disabled={!isValid || isSubmitting}
            onClick={() => {
              const insured = watch();

              addFamilyMember(insured, isDependent);
              reset();
              setIsModalOpen(false);
            }}
          >
            Save
          </Button>
        </Center>
      </>
    </Drawer>
  );
};
