import {
  EmployeeDto,
  EmployeePaymentMethodType,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Card } from '@zorro/zorro-ui-design';

import { PaymentMethodTypeOption } from './PaymentMethodTypeOption';
import { PAYMENT_METHOD_TYPE_SELECT_OPTIONS } from './paymentMethodType.consts';

type PaymentMethodTypeProps = {
  type?: EmployeePaymentMethodType;
  employeeId?: EmployeeDto['id'];
  onboardingPeriodId?: OnboardingPeriodDto['id'];
};

const PaymentMethodType = (props: PaymentMethodTypeProps) => {
  const { employeeId, onboardingPeriodId, type } = props;

  const isDataFetchMode = Boolean(employeeId && onboardingPeriodId);

  const { data, isLoading } = useMonolithQuery({
    method: 'paymentsControllerEmployeePaymentMethodDetails',
    params: employeeId &&
      onboardingPeriodId && [employeeId, { onboardingPeriodId }],
  });

  if (isDataFetchMode && (isLoading || !data)) {
    return null;
  }

  const paymentMethodTypeOptionProps = PAYMENT_METHOD_TYPE_SELECT_OPTIONS.find(
    ({ value }) => {
      const calculatedType = isDataFetchMode ? data?.type : type;
      return value === calculatedType;
    }
  );

  if (!paymentMethodTypeOptionProps) {
    return null;
  }

  return (
    <Card p="md" maw="48rem">
      <PaymentMethodTypeOption {...paymentMethodTypeOptionProps} />
    </Card>
  );
};

export { PaymentMethodType };
