import { withFronteggApp } from '@frontegg/nextjs/pages';
import * as FullStory from '@fullstory/browser';
import '@mantine/carousel/styles.css';
import '@mantine/charts/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CORRELATION_ID_HEADER } from '@zorro/clients';
import { LoadingOverlayProvider } from '@zorro/shared/ui';
import { useAppProgressBar } from '@zorro/shared/utils';
import { NavigationProgress, Theme } from '@zorro/zorro-ui-design';
import axios from 'axios';
import { AppProps } from 'next/app';
import Head from 'next/head';
import {
  ComponentType,
  ReactNode,
  Suspense,
  lazy,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DefaultLayout } from '~/components/layouts/DefaultLayout';

const ReactQueryDevtoolsProduction = lazy(() =>
  // @ts-expect-error react-query-devtools
  import('@tanstack/react-query-devtools/production').then((build) => ({
    default: build.ReactQueryDevtools,
  }))
);

interface Props extends AppProps {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Component: ComponentType & {
    getLayout?: (page: ReactNode) => ReactNode;
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function App({ Component, pageProps }: Props) {
  const { session } = pageProps;

  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // @ts-expect-error react-query-devtools
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  // eslint-disable-next-line react/hook-use-state
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            staleTime: 60_000,
            retry: false,
          },
        },
      })
  );

  const userId = session?.user.sub;
  const getLayout =
    Component.getLayout ??
    ((page: ReactNode) => <DefaultLayout>{page}</DefaultLayout>);

  axios.interceptors.request.use(function (config) {
    config.headers[CORRELATION_ID_HEADER] = uuidv4();
    return config;
  });

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID && userId) {
      FullStory.init(
        {
          /*
           * TODO: For some reason `process.env[EnvParams.NEXT_PUBLIC_FULLSTORY_ORG_ID]!`
           * returns undefined. Changed it as below temporarily.
           */
          orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID as string,
        },
        () => {
          FullStory.FullStory('setIdentity', {
            uid: userId,
            properties: {
              displayName: userId,
            },
          });
          FullStory.FullStory('setProperties', {
            type: 'page',
            properties: {
              application: 'murrieta',
              name: pageProps.name,
            },
          });
          FullStory.FullStory('setProperties', {
            type: 'user',
            properties: {
              permissions: session?.user.permissions,
              roles: session?.user.roles,
              employerId: session?.user.tenantId,
            },
          });
        }
      );
    }
    /*
     * Should be called only once so no subscription.
     * Even if we did subscribe to pageProps.session.user.sub we shouldn't re-identify the user.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useAppProgressBar();

  return (
    <>
      <Head>
        <title>Zorro Ops</title>
      </Head>
      <QueryClientProvider client={queryClient}>
        <MantineProvider theme={Theme}>
          <LoadingOverlayProvider>
            <NavigationProgress />
            <Notifications position="top-right" />
            {getLayout(<Component {...pageProps} />)}
          </LoadingOverlayProvider>
        </MantineProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </Suspense>
        )}
      </QueryClientProvider>
    </>
  );
}

export default withFronteggApp(App, {
  hostedLoginBox: true,
  lazyLoadAdminPortal: true,
  authOptions: {
    keepSessionAlive: true,
  },
  themeOptions: {
    adminPortal: {
      pages: {
        profile: {
          fieldsProperties: {
            address: {
              appearance: 'hidden',
            },
            jobTitle: {
              appearance: 'hidden',
            },
          },
        },
        privacy: {
          fieldsProperties: {
            loginSessions: {
              appearance: 'hidden',
            },
          },
        },
      },
    },
  },
});
