import { Gender, OnboardingPeriodReportDto } from '@zorro/clients';
import { EditColorIcon } from '@zorro/shared/assets';
import { getNowAsDate, parseDateISO } from '@zorro/shared/formatters';
import {
  getAdultDefaultDate,
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import {
  AddressInput,
  Button,
  Center,
  CurrencyInput,
  DateInput,
  Drawer,
  Flex,
  FormErrorMessage,
  Grid,
  InputWrapper,
  PasswordInput,
  PhoneInput,
  Space,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { Control, Controller, FormState } from 'react-hook-form';

import { EmployeeClassInput } from '../EmployeeClassInput/EmployeeClassInput';
import { InsuredDetailsEditFormFields } from './insuredForm.types';

type InsuredDetailsEditDrawerProps = {
  label: string;
  setIsModalOpen: (open: boolean) => void;
  isFemale: boolean;
  fieldName: 'employee' | 'spouse' | `dependents.${number}`;
  formState: FormState<InsuredDetailsEditFormFields>;
  control: Control<InsuredDetailsEditFormFields>;
  saveChangedPersonalDetails: () => void;
  planEffectiveFrom?: Date;
  employerId?: string;
  isOpened?: boolean;
  hasOnboardingPeriod: boolean;
  selectedOnboardingPeriod: OnboardingPeriodReportDto | null;
};

export const InsuredDetailsEditDrawer = ({
  label,
  setIsModalOpen,
  isFemale,
  fieldName,
  formState,
  control,
  saveChangedPersonalDetails,
  planEffectiveFrom,
  employerId,
  isOpened,
  hasOnboardingPeriod,
  selectedOnboardingPeriod,
}: InsuredDetailsEditDrawerProps) => {
  const { errors, isValid, isSubmitting } = formState || {};
  const isDependent = label.toLowerCase().includes('dependent');
  const targetEnrollmentDate =
    selectedOnboardingPeriod &&
    parseDateISO(selectedOnboardingPeriod?.targetEnrollmentDate);

  return (
    <Drawer
      opened={!!isOpened}
      onClose={() => {
        control._reset();
        setIsModalOpen(false);
      }}
      title={
        <Flex align="center">
          <EditColorIcon />
          <Text size="xl" ml="sm" fw={500}>
            Edit {label}
          </Text>
        </Flex>
      }
    >
      <>
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name={`${fieldName}.firstName`}
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <TextInput
                  {...rest}
                  label="First name"
                  placeholder="First name"
                />
              )}
            />

            <FormErrorMessage
              fieldName={`${fieldName}.firstName`}
              errors={errors}
            />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name={`${fieldName}.lastName`}
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <TextInput
                  {...rest}
                  label="Last name"
                  placeholder="Last name"
                />
              )}
            />

            <FormErrorMessage
              fieldName={`${fieldName}.lastName`}
              errors={errors}
            />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name={`${fieldName}.dateOfBirth`}
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  label="Date of birth"
                  defaultLevel="decade"
                  defaultDate={
                    label.includes('Dependent')
                      ? getNowAsDate()
                      : getAdultDefaultDate()
                  }
                  minDate={insuredMinDateOfBirth(
                    isDependent,
                    targetEnrollmentDate
                  )}
                  maxDate={insuredMaxDateOfBirth(
                    isDependent,
                    targetEnrollmentDate
                  )}
                />
              )}
            />

            <FormErrorMessage
              errors={errors}
              fieldName={`${fieldName}.dateOfBirth`}
            />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name={`${fieldName}.gender`}
              render={({ field: { ref: _ref, onChange, ...rest } }) => (
                <InputWrapper label="Gender">
                  <TabButtonGroup {...rest} onChange={onChange}>
                    <Tabs.List grow>
                      <Tabs.Tab value={Gender.MALE}>Male</Tabs.Tab>
                      <Tabs.Tab value={Gender.FEMALE}>Female</Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            <FormErrorMessage
              errors={errors}
              fieldName={`${fieldName}.gender`}
            />
          </Grid.Col>

          <Grid.Col>
            <Controller
              control={control}
              name={`${fieldName}.residentialAddress`}
              render={({
                field: { ref: _ref, onChange, ...rest },
                fieldState: { error },
              }) => (
                <AddressInput
                  {...rest}
                  notifySubscribers={(address: string) => {
                    onChange(address);
                  }}
                  onChange={onChange}
                  label="Residential address"
                  value={rest.value ?? ''}
                  placeholder="Residential address"
                />
              )}
            />

            <FormErrorMessage
              errors={errors}
              fieldName={`${fieldName}.residentialAddress`}
            />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name={`${fieldName}.isSmoker`}
              render={({ field: { ref: _ref, onChange, ...rest } }) =>
                hasOnboardingPeriod ? (
                  <InputWrapper label="Tobacco user">
                    <TabButtonGroup {...rest} onChange={onChange}>
                      <Tabs.List grow>
                        <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                        <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                      </Tabs.List>
                    </TabButtonGroup>
                  </InputWrapper>
                ) : (
                  <TextInput disabled label="Tobacco user" value="N/A" />
                )
              }
            />

            <FormErrorMessage
              errors={errors}
              fieldName={`${fieldName}.isSmoker`}
            />
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name={`${fieldName}.ssn`}
              render={({
                field: { ref: _ref, value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <PasswordInput
                  {...rest}
                  disabled={!hasOnboardingPeriod}
                  onChange={(evt) => {
                    onChange(evt.target.value.replace(/\D/u, ''));
                  }}
                  label="SSN"
                  value={value || ''}
                />
              )}
            />

            <FormErrorMessage fieldName={`${fieldName}.ssn`} errors={errors} />
          </Grid.Col>

          {fieldName === 'employee' && (
            <>
              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name={`${fieldName}.personalEmail`}
                  render={({
                    field: { ref: _ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <TextInput
                      {...rest}
                      value={rest.value ?? undefined}
                      label="Personal email"
                    />
                  )}
                />

                <FormErrorMessage
                  fieldName={`${fieldName}.personalEmail`}
                  errors={errors}
                />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name={`${fieldName}.salary`}
                  render={({
                    field: { value, ...rest },
                    fieldState: { error },
                  }) => (
                    <CurrencyInput
                      {...rest}
                      value={value ?? undefined}
                      label="Yearly salary"
                      placeholder="Yearly salary"
                    />
                  )}
                />

                <FormErrorMessage
                  fieldName={`${fieldName}.salary`}
                  errors={errors}
                />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name={`${fieldName}.phone`}
                  render={({
                    field: { value, ...rest },
                    fieldState: { error },
                  }) => (
                    <PhoneInput
                      {...rest}
                      label="Phone number"
                      placeholder="Phone number"
                      value={value ?? undefined}
                      control={control}
                    />
                  )}
                />

                <FormErrorMessage
                  fieldName={`${fieldName}.phone`}
                  errors={errors}
                />
              </Grid.Col>

              <Grid.Col span={{ sm: 6 }}>
                {employerId && (
                  <EmployeeClassInput
                    control={control}
                    name={`${fieldName}.class`}
                    employerId={employerId}
                  />
                )}
              </Grid.Col>
            </>
          )}

          {isFemale && (
            <Grid.Col span={{ sm: 6 }}>
              <Controller
                control={control}
                name={`${fieldName}.isPregnant`}
                render={({ field: { ref: _ref, onChange, ...rest } }) =>
                  hasOnboardingPeriod ? (
                    <InputWrapper label="Pregnant">
                      <TabButtonGroup {...rest} onChange={onChange}>
                        <Tabs.List grow>
                          <Tabs.Tab value={YesNo.YES}>Yes</Tabs.Tab>
                          <Tabs.Tab value={YesNo.NO}>No</Tabs.Tab>
                        </Tabs.List>
                      </TabButtonGroup>
                    </InputWrapper>
                  ) : (
                    <TextInput disabled label="Pregnant" value="N/A" />
                  )
                }
              />

              <FormErrorMessage
                errors={errors}
                fieldName={`${fieldName}.isPregnant`}
              />
            </Grid.Col>
          )}
        </Grid>

        <Space h="lg" mt="lg" />
        <Center>
          <Button
            disabled={!isValid || isSubmitting}
            onClick={() => {
              saveChangedPersonalDetails();
              setIsModalOpen(false);
            }}
          >
            Save
          </Button>
        </Center>
      </>
    </Drawer>
  );
};
