import { IconInfoCircle } from '@tabler/icons-react';
import {
  EnrollmentStatus,
  FamilyUnit,
  InsuredPeopleDto,
  MajorMedicalPlanDto,
} from '@zorro/clients';
import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import {
  Anchor,
  Box,
  Grid,
  Group,
  Icon,
  Skeleton,
  Space,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { EnrollmentStatusBadge } from '../EnrollmentStatus/EnrollmentStatusBadge';
import { FamilyMembersReadOnly } from '../FamilyMembers';
import { IconWithTitle } from './IconWithTitle';

type FirstIconColumnProps = {
  isLoading: boolean;
  title: string;
  icon?: ReactNode;
  isHealthPlan?: boolean;
  selectedMedicalPlan?: MajorMedicalPlanDto | null;
  enrollmentStatus?: EnrollmentStatus;
  shouldShowBenefitStatus?: boolean;
  editActionColumn?: ReactNode;
  isSummaryRow?: boolean;
};

const FirstIconColumn = ({
  isLoading,
  title,
  icon,
  isHealthPlan,
  selectedMedicalPlan,
  enrollmentStatus,
  shouldShowBenefitStatus = true,
  editActionColumn,
  isSummaryRow = false,
}: FirstIconColumnProps) => {
  const formattedEnrollmentStatus =
    enrollmentStatus &&
    [
      EnrollmentStatus.ELECTION_ACTIVE_STARTED,
      EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED,
    ].includes(enrollmentStatus)
      ? EnrollmentStatus.ELECTION_ACTIVE
      : enrollmentStatus;
  return (
    <Grid.Col pl={0} span={{ md: 3, sm: 12 }}>
      <Group gap={0}>
        <IconWithTitle
          title={title}
          icon={icon}
          editActionColumn={editActionColumn}
          gap={10}
          mr={14}
          pl={6}
        />
        {shouldShowBenefitStatus && formattedEnrollmentStatus && (
          <EnrollmentStatusBadge
            enrollmentStatus={formattedEnrollmentStatus}
            shouldDisableTooltip
          />
        )}
      </Group>
      {isLoading && isSummaryRow && (
        <Grid.Col pl={{ base: 8, lg: 62 }}>
          <Skeleton height={26} width={194} />
          <Skeleton height={26} width={140} mt="0.5rem" />
        </Grid.Col>
      )}
      {isHealthPlan && selectedMedicalPlan && (
        <Grid.Col pl={{ base: 8, lg: 62 }}>
          <Text
            component="span"
            data-testid="enrollment-summary-card-carrier-name"
          >
            {selectedMedicalPlan.carrierName}
          </Text>
          ,{' '}
          <Text
            component="span"
            data-testid="enrollment-summary-card-plan-name"
          >
            {selectedMedicalPlan.name}
          </Text>
          {selectedMedicalPlan.benefitsSummaryUrl && (
            <>
              <Space h="xs" />
              <Anchor
                href={selectedMedicalPlan.benefitsSummaryUrl}
                data-testid="enrollment-summary-card-benefit-summary-url"
                className="excluded-from-print"
                target="_blank"
                fw={600}
              >
                View details
              </Anchor>
            </>
          )}
          {selectedMedicalPlan?.externalID && (
            <Text fw={600} mt="0.5rem">
              Plan ID:{' '}
              <Text component="span">{selectedMedicalPlan?.externalID}</Text>
            </Text>
          )}
        </Grid.Col>
      )}
    </Grid.Col>
  );
};

type Props = {
  isLoading: boolean;
  title: string;
  yourContribution: number;
  employerContribution: number;
  familyUnitCovered?: FamilyUnit | null;
  selectedMedicalPlan?: MajorMedicalPlanDto | null;
  enrollmentStatus?: EnrollmentStatus;
  editActionColumn?: ReactNode;
  isSupplemental?: boolean;
  icon?: ReactNode;
  insuredPeople?: InsuredPeopleDto | null;
  selfPayAmount?: number | null;
  shouldShowBenefitStatus?: boolean;
  shouldShowEmptyValues?: boolean;
  isSummaryRow?: boolean;
};

export const WithEmployeeContribution = ({
  isLoading,
  title,
  familyUnitCovered,
  selectedMedicalPlan,
  editActionColumn,
  employerContribution,
  enrollmentStatus,
  shouldShowBenefitStatus,
  shouldShowEmptyValues,
  yourContribution,
  isSupplemental,
  icon,
  insuredPeople,
  selfPayAmount,
  isSummaryRow = false,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const isMedicalPlanWaived =
    enrollmentStatus === EnrollmentStatus.WAIVED_ELECTION ||
    enrollmentStatus === EnrollmentStatus.WAIVED_COVERAGE;
  return (
    <>
      <FirstIconColumn
        isLoading={isLoading}
        title={title}
        icon={icon}
        shouldShowBenefitStatus={shouldShowBenefitStatus}
        enrollmentStatus={enrollmentStatus}
        selectedMedicalPlan={selectedMedicalPlan}
        isHealthPlan={title === 'Medical Plan'}
        editActionColumn={editActionColumn}
        isSummaryRow={isSummaryRow}
      />

      <Grid.Col span={{ md: 3 }}>
        <Group>
          <Text size="sm" fw={600} display={{ md: 'none' }}>
            Who is covered?
          </Text>
          {isLoading && isSummaryRow ? (
            <Skeleton height={26} width={100} />
          ) : (
            <Text data-testid="enrollment-summary-card-family-unit-covered">
              {isMedicalPlanWaived ? (
                '-'
              ) : (
                <FamilyMembersReadOnly
                  employee={insuredPeople ? insuredPeople.employee : null}
                  spouse={insuredPeople?.spouse}
                  dependents={insuredPeople?.dependents}
                  checkedMembers={familyUnitCovered}
                />
              )}
            </Text>
          )}
        </Group>
      </Grid.Col>

      <Grid.Col span={{ md: 1 }}>
        {isSupplemental ? null : (
          <Group>
            <Text size="sm" fw={600} display={{ md: 'none' }}>
              Your contribution:
            </Text>
            {isLoading ? (
              <Skeleton height={26} width={86} />
            ) : (
              <Text
                fw={600}
                data-testid="enrollment-summary-card-row-your-contribution"
              >
                {isMedicalPlanWaived || shouldShowEmptyValues
                  ? '-'
                  : formatCurrencyEnUs(yourContribution)}
              </Text>
            )}
          </Group>
        )}
      </Grid.Col>
      <Grid.Col display={{ base: 'none', md: 'inherit' }} span={{ md: 1 }}>
        <Text fw={600} ta="center">
          {!isMedicalPlanWaived && !isLoading && '+'}
        </Text>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }}>
        <Group>
          <Text size="sm" fw={600} display={{ md: 'none' }}>
            Employer&apos;s contribution:
          </Text>
          {isLoading ? (
            <Skeleton height={26} width={86} />
          ) : (
            <Text
              fw={600}
              data-testid="enrollment-summary-card-row-employer-contribution"
            >
              {isMedicalPlanWaived || shouldShowEmptyValues
                ? '-'
                : formatCurrencyEnUs(employerContribution)}
            </Text>
          )}
        </Group>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }} display={{ base: 'none', md: 'inherit' }}>
        <Text fw={600} ta="center">
          {!isMedicalPlanWaived && !isLoading && '='}
        </Text>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }}>
        <Group>
          <Text size="sm" fw={600} display={{ md: 'none' }}>
            Total Premium:
          </Text>
          <Group gap="0.125rem" pos="relative">
            {isLoading ? (
              <Skeleton height={26} width={86} />
            ) : (
              <Text fw={600} data-testid="enrollment-summary-card-row-total">
                {isMedicalPlanWaived || shouldShowEmptyValues
                  ? '-'
                  : formatCurrencyEnUs(employerContribution + yourContribution)}
              </Text>
            )}
            {selectedMedicalPlan && selfPayAmount && (
              <Tooltip
                label={
                  <Text>
                    Employee’s self payment (personal credit card):{' '}
                    <Text fw={600} display="inline">
                      {formatCurrencyEnUs(selfPayAmount)}
                    </Text>
                  </Text>
                }
              >
                <Box h="1.5rem">
                  <Icon icon={IconInfoCircle} size="1.25rem" />
                </Box>
              </Tooltip>
            )}
          </Group>
        </Group>
      </Grid.Col>
      <Grid.Col span={{ md: 1 }} />
    </>
  );
};
