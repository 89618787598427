import { MantineTheme } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  BenefitEnrollmentDto,
  EnrollmentStatus,
  ExtendedBenefitStatus,
  GetEmployerDto,
  LeaveOfAbsenceDto,
  OnboardingType,
} from '@zorro/clients';
import { formatCurrencyEnUs, getNowAsDate } from '@zorro/shared/formatters';
import {
  exportToCsv,
  getToolTipContentForEmploymentStatus,
  isDefined,
} from '@zorro/shared/utils';
import { EmploymentStatus, recordsPerPage } from '@zorro/types';
import {
  Anchor,
  Box,
  Center,
  Group,
  Icon,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';
import { DataTableSortStatus } from 'mantine-datatable';
import Link from 'next/link';
import { ReactNode, useState } from 'react';

import { EnrollmentStatusBadge } from '../EnrollmentStatus';
import { Filter, SelectFilter } from '../Filters';
import { SearchableDataTable } from '../SearchableDataTable';
import { stringComparator } from './EmployeesListTableUtils';

export type EmployeeRow = {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  age: number;
  class: string;
  benefitEligibility?: EmploymentStatus | null;
  eligibleFrom?: string | null;
  eligibleUntil?: string | null;
  leaveOfAbsence?: LeaveOfAbsenceDto | null;
  role: string;
  onboardingStatus?: string;
  id: string;
  idFromEmployer?: string | null;
  donIdFromEmployer?: string | null;
  terminationDate?: string | null;
  enrollmentStatus?: EnrollmentStatus;
  enrollmentStatusLabel?: string;
  enrollmentDeadline?: string;
  enrollmentType?: OnboardingType;
  enrollmentEffectiveDate: string;
  enrollmentSubmissionType?: string;
  enrollmentIsCombinedPlan?: string;
  enrollmentSelfReportType?: string;
  medicalStatus: ExtendedBenefitStatus | null;
  medicalCarrier: string;
  medicalPlanName: string;
  medicalPlanId: string;
  medicalPlanMarket: string;
  medicalFamilyUnit: string;
  medicalEmployeeContribution: number | null;
  medicalEmployerContribution: number | null;
  medicalPremium: number | null;
  medicalBenefitEnrollment?: BenefitEnrollmentDto | null;
  onboardingPeriodId?: string;
  userId: string;
};

export function renderCellValue(cellValue: string) {
  return <Text size="sm">{cellValue || '-'}</Text>;
}

// TODO: Remove ColumnId, replace with generic `keyof EmployeeRow` type.
export enum ColumnId {
  FULL_NAME = 'fullName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  BIRTHDATE = 'birthDate',
  AGE = 'age',
  CLASS = 'class',
  BENEFIT_ELIGIBILITY = 'benefitEligibility',
  ROLE = 'role',
  ONBOARDING_STATUS = 'onboardingStatus',
  ENROLLMENT_STATUS = 'enrollmentStatus',
  ENROLLMENT_TYPE = 'enrollmentType',
  ENROLLMENT_DEADLINE = 'enrollmentDeadline',
  ENROLLMENT_EFFECTIVE_DATE = 'enrollmentEffectiveDate',
  ENROLLMENT_SUBMISSION_TYPE = 'enrollmentSubmissionType',
  ENROLLMENT_COMBINED_PLAN = 'enrollmentIsCombinedPlan',
  ENROLLMENT_SELF_REPORT_TYPE = 'enrollmentSelfReportType',
  EMPLOYEE_DETAILS = 'employeeDetails',
  MEDICAL = 'medical',
  MEDICAL_STATUS = 'medicalStatus',
  MEDICAL_CARRIER = 'medicalCarrier',
  MEDICAL_PLAN_NAME = 'medicalPlanName',
  MEDICAL_PLAN_ID = 'medicalPlanId',
  MEDICAL_PLAN_MARKET = 'medicalPlanMarket',
  MEDICAL_FAMILY_UNIT = 'medicalFamilyUnit',
  MEDICAL_EMPLOYER_CONTRIBUTION = 'medicalEmployerContribution',
  MEDICAL_EMPLOYEE_CONTRIBUTION = 'medicalEmployeeContribution',
  MEDICAL_PREMIUM = 'medicalPremium',
  EMPLOYEE_ID = 'id',
  ID_FROM_EMPLOYER = 'idFromEmployer',
  // Duplicating ID_FROM_EMPLOYER in order to be able to place the column in a different place in don UI
  DON_ID_FROM_EMPLOYER = 'donIdFromEmployer',
}

type Props = {
  employer: GetEmployerDto;
  employeeRows: EmployeeRow[];
  componentsBeforeDownload?: ReactNode;
  selectedEmployees?: EmployeeRow[];
  setSelectedEmployees?: (employees: EmployeeRow[]) => void;
  hiddenColumnIds?: ColumnId[];
  selectedEnrollmentPeriodId: string | null;
  employeeProfileUrl: string;
  isSelectable?: boolean;
  isLoading?: boolean;
  impersonatedEmployeeId?: string;
};

type EmployeeRowKey = keyof EmployeeRow;

/* eslint-disable sonarjs/cognitive-complexity */
export const EmployeesListTable = ({
  employer,
  employeeRows,
  componentsBeforeDownload,
  selectedEnrollmentPeriodId,
  selectedEmployees,
  setSelectedEmployees,
  hiddenColumnIds = [],
  employeeProfileUrl,
  isSelectable,
  isLoading = false,
  impersonatedEmployeeId,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState<Filter<EmployeeRow>[]>([]);
  const [page, setPage] = useState(1);

  function shouldHideColumnId(id: ColumnId) {
    return hiddenColumnIds.includes(id);
  }

  const createSelectOptions = (property: EmployeeRowKey) => {
    const result = [{ value: '', label: '-' }];
    const set = new Set(employeeRows.map((row) => row[property] as string));
    const filteredOptions = [...set]
      .filter(Boolean)
      .map((cls) => ({ value: cls, label: cls }));
    result.push(...filteredOptions);
    return result;
  };

  const handleFilterChange = (value: Filter<EmployeeRow>[]) => {
    setFilters(value);
    setPage(1);
    setSelectedEmployees?.([]);
  };

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
    setPage(1);
    setSelectedEmployees?.([]);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setSelectedEmployees?.([]);
  };

  const downloadEmployees = () => {
    const timestamp = getNowAsDate().getTime();
    const fileName = `${employer.name}_employees_enrollment_data_${timestamp}.csv`;
    const fields = [
      { label: 'First Name', value: ColumnId.FIRST_NAME },
      { label: 'Last Name', value: ColumnId.LAST_NAME },
      { label: 'Id', value: ColumnId.DON_ID_FROM_EMPLOYER },
      { label: 'Email', value: ColumnId.EMAIL },
      { label: 'Birth Date', value: ColumnId.BIRTHDATE },
      { label: 'Age', value: ColumnId.AGE },
      { label: 'Class', value: ColumnId.CLASS },
      { label: 'Benefit eligibility', value: ColumnId.BENEFIT_ELIGIBILITY },
      { label: 'Role', value: ColumnId.ROLE },
      { label: 'Onboarding Status', value: ColumnId.ONBOARDING_STATUS },
      { label: 'Employee Id (Zorro)', value: ColumnId.EMPLOYEE_ID },
      { label: 'Employee Id (employer)', value: ColumnId.ID_FROM_EMPLOYER },
      { label: 'Enrollment Status', value: ColumnId.ENROLLMENT_STATUS },
      { label: 'Enrollment Type', value: ColumnId.ENROLLMENT_TYPE },
      { label: 'Enrollment Deadline', value: ColumnId.ENROLLMENT_DEADLINE },
      {
        label: 'Enrollment Effective Date',
        value: ColumnId.ENROLLMENT_EFFECTIVE_DATE,
      },
      {
        label: 'Enrollment Submission Type',
        value: ColumnId.ENROLLMENT_SUBMISSION_TYPE,
      },
      { label: 'Combined plan', value: ColumnId.ENROLLMENT_COMBINED_PLAN },
      {
        label: 'Self report type',
        value: ColumnId.ENROLLMENT_SELF_REPORT_TYPE,
      },
      { label: 'Medical Status', value: ColumnId.MEDICAL_STATUS },
      { label: 'Medical Carrier', value: ColumnId.MEDICAL_CARRIER },
      { label: 'Medical Plan Name', value: ColumnId.MEDICAL_PLAN_NAME },
      { label: 'Medical Plan Id', value: ColumnId.MEDICAL_PLAN_ID },
      { label: 'Plan Market', value: ColumnId.MEDICAL_PLAN_MARKET },
      { label: 'Medical Family Unit', value: ColumnId.MEDICAL_FAMILY_UNIT },
      {
        label: 'Medical Employee Contribution',
        value: ColumnId.MEDICAL_EMPLOYEE_CONTRIBUTION,
      },
      {
        label: 'Medical Employer Contribution',
        value: ColumnId.MEDICAL_EMPLOYER_CONTRIBUTION,
      },
      { label: 'Medical Premium', value: ColumnId.MEDICAL_PREMIUM },
    ];
    exportToCsv(
      employeeRows,
      fileName,
      fields.filter((field) => !hiddenColumnIds?.includes(field.value))
    );
  };

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: 'fullName',
    direction: 'asc',
  });

  const filtered = employeeRows.filter((employee) => {
    let keep = true;
    filters.forEach((filter) => {
      if (!filter.filterCallback(employee)) {
        keep = false;
      }
    });
    return keep;
  });

  const sorted = filtered.sort((first: EmployeeRow, second: EmployeeRow) => {
    let firstStr = first[sortStatus.columnAccessor as keyof EmployeeRow];
    if (typeof firstStr !== 'string') {
      firstStr = firstStr?.toString();
    }
    let secondStr = second[sortStatus.columnAccessor as keyof EmployeeRow];
    if (typeof secondStr !== 'string') {
      secondStr = secondStr?.toString();
    }

    return stringComparator(firstStr, secondStr);
  });

  let records = sortStatus.direction === 'asc' ? sorted : sorted.reverse();
  if (searchValue) {
    records = records.filter((employee) => {
      const lowerCaseValue = searchValue.toLowerCase();
      return (
        employee.fullName?.toLowerCase().includes(lowerCaseValue) ||
        employee.birthDate?.toLowerCase().includes(lowerCaseValue) ||
        employee.email?.toLowerCase().includes(lowerCaseValue) ||
        employee.age?.toString().includes(lowerCaseValue) ||
        employee.class?.toString().toLowerCase().includes(lowerCaseValue) ||
        employee.benefitEligibility?.toLowerCase().includes(lowerCaseValue) ||
        employee.role?.toLowerCase().includes(lowerCaseValue) ||
        employee.onboardingStatus?.toLowerCase().includes(lowerCaseValue) ||
        employee.enrollmentStatusLabel
          ?.toLowerCase()
          .includes(lowerCaseValue) ||
        employee.enrollmentDeadline?.includes(lowerCaseValue) ||
        employee.enrollmentType?.toLowerCase().includes(lowerCaseValue) ||
        employee.enrollmentEffectiveDate
          ?.toLowerCase()
          .includes(lowerCaseValue) ||
        employee.medicalStatus?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalCarrier?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalPlanId?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalFamilyUnit?.toLowerCase().includes(lowerCaseValue) ||
        employee.medicalEmployerContribution
          ?.toString()
          ?.toLowerCase()
          .includes(lowerCaseValue) ||
        employee.medicalEmployeeContribution
          ?.toString()
          ?.toLowerCase()
          .includes(lowerCaseValue) ||
        employee.medicalPremium
          ?.toString()
          .toLowerCase()
          .includes(lowerCaseValue) ||
        employee.id?.toLowerCase().includes(lowerCaseValue) ||
        employee.idFromEmployer?.toLowerCase().includes(lowerCaseValue)
      );
    });
  }

  const pageRecords = records.slice(
    (page - 1) * recordsPerPage,
    page * recordsPerPage
  );

  return (
    <SearchableDataTable
      searchValue={searchValue}
      componentsBeforeDownload={componentsBeforeDownload}
      onSearchChange={handleSearchValueChange}
      onDownload={downloadEmployees}
      highlightOnHover
      pinFirstColumn
      isSelectable={isSelectable}
      grow
      groups={[
        {
          id: ColumnId.EMPLOYEE_DETAILS,
          title: (
            <Box
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              Employee details
            </Box>
          ),
          columns: [
            {
              accessor: ColumnId.FULL_NAME,
              sortable: true,
              width: '240px',
              render: (employee) => (
                <Link
                  href={`${employeeProfileUrl}/${
                    employee.id
                  }?initialOEPId=${selectedEnrollmentPeriodId}${
                    impersonatedEmployeeId
                      ? `&impersonatedEmployeeId=${impersonatedEmployeeId}`
                      : ''
                  }`}
                  passHref
                  legacyBehavior
                >
                  <Anchor size="sm">{employee.fullName}</Anchor>
                </Link>
              ),
              hidden: shouldHideColumnId(ColumnId.FULL_NAME),
            },
            {
              accessor: ColumnId.DON_ID_FROM_EMPLOYER,
              title: 'ID',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.DON_ID_FROM_EMPLOYER),
            },
            {
              accessor: ColumnId.EMAIL,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.EMAIL),
            },
            {
              accessor: ColumnId.BIRTHDATE,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.BIRTHDATE),
              render: ({ birthDate }) => renderCellValue(birthDate),
            },
            {
              accessor: ColumnId.AGE,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.AGE),
            },
            {
              accessor: ColumnId.CLASS,
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.CLASS),
              filtering: filters.some((filter) => filter.type === 'class'),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Class:"
                    selectOptions={createSelectOptions('class')}
                    setFilters={handleFilterChange}
                    filters={filters}
                    filterBy="class"
                  />
                </Group>
              ),
            },
            {
              accessor: ColumnId.BENEFIT_ELIGIBILITY,
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === 'benefitEligibility'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Benefit Eligibility:"
                    selectOptions={createSelectOptions('benefitEligibility')}
                    filterBy="benefitEligibility"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.BENEFIT_ELIGIBILITY),
              width: '20rem',
              render: (employee: EmployeeRow) => {
                const shouldShowTooltip =
                  employee.benefitEligibility !==
                  EmploymentStatus.ELIGIBLE_EMPLOYED;

                return (
                  <Group gap="0.25rem">
                    <Text size="sm">{employee.benefitEligibility}</Text>
                    {shouldShowTooltip && (
                      <Tooltip
                        label={getToolTipContentForEmploymentStatus(
                          employee,
                          employee.benefitEligibility
                        )}
                        inline
                      >
                        <Box pos="relative">
                          <Center>
                            <Icon icon={IconInfoCircle} size="1rem" />
                          </Center>
                        </Box>
                      </Tooltip>
                    )}
                  </Group>
                );
              },
            },
            {
              accessor: ColumnId.ROLE,
              filtering: filters.some((filter) => filter.type === 'role'),
              sortable: true,
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Role:"
                    selectOptions={createSelectOptions('role')}
                    setFilters={handleFilterChange}
                    filters={filters}
                    filterBy="role"
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.ROLE),
            },
            {
              accessor: ColumnId.ONBOARDING_STATUS,
              filtering: filters.some(
                (filter) => filter.type === 'onboardingStatus'
              ),
              sortable: true,
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Onboarding status:"
                    selectOptions={createSelectOptions('onboardingStatus')}
                    filterBy="onboardingStatus"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.ONBOARDING_STATUS),
              render: ({ onboardingStatus }) =>
                renderCellValue(onboardingStatus),
            },
          ],
          style: (theme: MantineTheme) => ({
            backgroundColor: theme.colors.zorroGray50,
          }),
        },
        {
          id: 'enrollment',
          title: (
            <Box
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              Enrollment
            </Box>
          ),
          columns: [
            {
              accessor: ColumnId.ENROLLMENT_STATUS,
              title: 'Status',
              render: ({ enrollmentStatus }) => (
                <EnrollmentStatusBadge
                  enrollmentStatus={enrollmentStatus}
                  iconSize={32}
                />
              ),
              sortable: true,
              filtering: filters.some(
                (filter) => filter.type === 'enrollmentStatusLabel'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Enrollment status:"
                    selectOptions={createSelectOptions('enrollmentStatusLabel')}
                    filterBy="enrollmentStatusLabel"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_STATUS),
            },
            {
              accessor: ColumnId.ENROLLMENT_TYPE,
              title: 'Type',
              sortable: true,
              width: '9.125rem',
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_TYPE),
              cellsStyle: () => ({ paddingLeft: '1rem', paddingRight: '1rem' }),
              filtering: filters.some(
                (filter) => filter.type === 'enrollmentType'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Enrollment type:"
                    selectOptions={createSelectOptions('enrollmentType')}
                    filterBy="enrollmentType"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentType }) => renderCellValue(enrollmentType),
            },
            {
              accessor: ColumnId.ENROLLMENT_DEADLINE,
              title: 'Deadline',
              sortable: true,
              width: '8rem',
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_DEADLINE),
              cellsStyle: () => ({
                paddingLeft: '1rem',
                paddingRight: '1.5rem',
              }),
              render: ({ enrollmentDeadline }) =>
                renderCellValue(enrollmentDeadline),
            },
            {
              accessor: ColumnId.ENROLLMENT_EFFECTIVE_DATE,
              title: 'Effective date',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_EFFECTIVE_DATE),
              render: ({ enrollmentEffectiveDate }) =>
                renderCellValue(enrollmentEffectiveDate),
            },
            {
              accessor: ColumnId.ENROLLMENT_SUBMISSION_TYPE,
              title: 'Submission type',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_SUBMISSION_TYPE),
              filtering: filters.some(
                (filter) => filter.type === ColumnId.ENROLLMENT_SUBMISSION_TYPE
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Enrollment submission type:"
                    selectOptions={createSelectOptions(
                      'enrollmentSubmissionType'
                    )}
                    filterBy="enrollmentSubmissionType"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentSubmissionType }) =>
                renderCellValue(enrollmentSubmissionType),
            },
            {
              accessor: ColumnId.ENROLLMENT_COMBINED_PLAN,
              title: 'Combined plan',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_COMBINED_PLAN),
              filtering: filters.some(
                (filter) => filter.type === ColumnId.ENROLLMENT_COMBINED_PLAN
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Combined plan type:"
                    selectOptions={createSelectOptions(
                      'enrollmentIsCombinedPlan'
                    )}
                    filterBy="enrollmentIsCombinedPlan"
                    setFilters={setFilters}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentIsCombinedPlan }) =>
                renderCellValue(enrollmentIsCombinedPlan),
            },
            {
              accessor: ColumnId.ENROLLMENT_SELF_REPORT_TYPE,
              title: 'Self report type',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.ENROLLMENT_SELF_REPORT_TYPE),
              filtering: filters.some(
                (filter) => filter.type === ColumnId.ENROLLMENT_SELF_REPORT_TYPE
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Self report type:"
                    selectOptions={createSelectOptions(
                      'enrollmentSelfReportType'
                    )}
                    filterBy="enrollmentSelfReportType"
                    setFilters={setFilters}
                    filters={filters}
                  />
                </Group>
              ),
              render: ({ enrollmentSelfReportType }) =>
                renderCellValue(enrollmentSelfReportType),
            },
          ],
          style: (theme: MantineTheme) => ({
            backgroundColor: theme.colors.zorroGray50,
          }),
        },
        {
          id: ColumnId.MEDICAL,
          title: (
            <Box
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              Medical
            </Box>
          ),
          columns: [
            {
              accessor: ColumnId.MEDICAL_CARRIER,
              title: 'Carrier',
              width: '240px',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_CARRIER),
              sortable: true,
              render: ({ medicalCarrier }) => renderCellValue(medicalCarrier),
              filtering: filters.some(
                (filter) => filter.type === 'medicalCarrier'
              ),
              filter: (
                <Group p="md">
                  <SelectFilter
                    title="Carrier name"
                    selectOptions={createSelectOptions('medicalCarrier')}
                    filterBy="medicalCarrier"
                    setFilters={handleFilterChange}
                    filters={filters}
                  />
                </Group>
              ),
            },
            {
              accessor: ColumnId.MEDICAL_PLAN_NAME,
              title: 'Plan name',
              width: '240px',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PLAN_NAME),
              cellsStyle: () => ({ padding: '0 1rem' }),
              sortable: true,
              render: ({ medicalPlanName }) => renderCellValue(medicalPlanName),
            },
            {
              accessor: ColumnId.MEDICAL_PLAN_ID,
              title: 'Plan ID',
              width: '240px',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PLAN_ID),
              sortable: true,
              render: ({ medicalPlanId }) => renderCellValue(medicalPlanId),
            },
            {
              accessor: ColumnId.MEDICAL_PLAN_MARKET,
              title: 'Plan market',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PLAN_MARKET),
              sortable: true,
              render: ({ medicalPlanMarket, medicalStatus }) => {
                if (medicalStatus === ExtendedBenefitStatus.WAIVED) {
                  return <Text size="sm">-</Text>;
                }
                return renderCellValue(medicalPlanMarket);
              },
            },
            {
              accessor: ColumnId.MEDICAL_FAMILY_UNIT,
              title: 'Family unit',
              width: '9.125rem',
              cellsStyle: () => ({ padding: '0 1rem' }),
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.MEDICAL_FAMILY_UNIT),
              render: ({ medicalFamilyUnit }) =>
                renderCellValue(medicalFamilyUnit),
            },
            {
              accessor: ColumnId.MEDICAL_EMPLOYER_CONTRIBUTION,
              title: 'Employer contribution',
              hidden: shouldHideColumnId(
                ColumnId.MEDICAL_EMPLOYER_CONTRIBUTION
              ),
              sortable: true,
              render: ({ medicalEmployerContribution }) =>
                isDefined(medicalEmployerContribution)
                  ? formatCurrencyEnUs(medicalEmployerContribution)
                  : '-',
            },
            {
              accessor: ColumnId.MEDICAL_EMPLOYEE_CONTRIBUTION,
              title: 'Employee contribution',
              hidden: shouldHideColumnId(
                ColumnId.MEDICAL_EMPLOYEE_CONTRIBUTION
              ),
              sortable: true,
              render: ({ medicalEmployeeContribution }) =>
                isDefined(medicalEmployeeContribution)
                  ? formatCurrencyEnUs(medicalEmployeeContribution)
                  : '-',
            },
            {
              accessor: ColumnId.MEDICAL_PREMIUM,
              title: 'Premium',
              hidden: shouldHideColumnId(ColumnId.MEDICAL_PREMIUM),
              sortable: true,
              render: ({ medicalPremium }) =>
                isDefined(medicalPremium)
                  ? formatCurrencyEnUs(medicalPremium)
                  : '-',
            },
          ],
          style: (theme: MantineTheme) => ({
            backgroundColor: theme.colors.zorroGray50,
          }),
        },
        {
          id: ColumnId.EMPLOYEE_ID,
          title: '',
          columns: [
            {
              accessor: ColumnId.EMPLOYEE_ID,
              title: 'ID (Zorro)',
              width: '8rem',
              sortable: true,
              hidden: shouldHideColumnId(ColumnId.EMPLOYEE_ID),
              render: ({ id }) => id.slice(0, 8),
            },
            {
              accessor: ColumnId.ID_FROM_EMPLOYER,
              title: 'ID (employer)',
              width: '20.125rem',
              sortable: true,
              cellsStyle: () => ({ padding: '0.75rem' }),
              hidden: shouldHideColumnId(ColumnId.ID_FROM_EMPLOYER),
            },
          ],
          style: {
            borderBottom: 'none !important',
          },
        },
      ]}
      records={pageRecords}
      selectedRecords={selectedEmployees}
      onSelectedRecordsChange={setSelectedEmployees}
      totalRecords={records.length}
      recordsPerPage={recordsPerPage}
      page={page}
      onPageChange={handlePageChange}
      data-testid="employees-data-table"
      sortStatus={sortStatus}
      onSortStatusChange={setSortStatus}
      isLoading={isLoading}
    />
  );
};
