import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import {
  DefaultValues,
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm as useReactHookForm,
} from 'react-hook-form';

type Props<TFieldValues extends FieldValues = FieldValues> =
  UseFormProps<TFieldValues>;

export const useForm = <TFieldValues extends FieldValues = FieldValues>({
  defaultValues,
  ...props
}: Props<TFieldValues>): UseFormReturn<TFieldValues> => {
  const form = useReactHookForm({ ...props, defaultValues });
  useEffect(() => {
    if (!isEqual(defaultValues, form.formState.defaultValues)) {
      form.reset(defaultValues as DefaultValues<TFieldValues>);
    }
  }, [form, defaultValues]);

  return form;
};
