import { yupResolver } from '@hookform/resolvers/yup';
import { OnboardingType } from '@zorro/clients';
import { formatDateISO } from '@zorro/shared/formatters';
import {
  UNEXPECTED_ERROR_MESSAGE,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Box, Drawer, Table, Text } from '@zorro/zorro-ui-design';

import { useBatchCallEndpoint } from '../../hooks';
import { ChangeEnrollmentForm } from './ChangeEnrollmentForm';
import {
  ChangeEnrollmentsFormFields,
  EnrollmentDrawerProps,
  FullChangeEnrollmentSchema,
  getCurrentlyEffectiveYear,
  getLatestPlanYear,
  getSuggestedElectionWindow,
  getSuggestedExpectedStartOfCoverage,
} from './ChangeEnrollmentUtils';

export const AddEnrollmentsDrawer = ({
  employerId,
  selectedEmployees,
  isOpen,
  closeModal,
}: EnrollmentDrawerProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;

  const { data: allOpenEnrollmentPeriods = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
    enabled: isOpen,
  });

  const { data: associatedOnboardingPeriodsSingleEmployee } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isOpen && isSingleEmployee,
  });

  const { data: singleEmployeeDto } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isOpen && isSingleEmployee,
  });
  const {
    executeBatchCall: createEnrollments,
    errors,
    setErrors,
  } = useBatchCallEndpoint({
    singularItemName: 'enrollment record',
    action: 'created',
    methodName: 'onboardingPeriodsControllerCreate',
  });

  const defaultPlanYear =
    getCurrentlyEffectiveYear(allOpenEnrollmentPeriods) ||
    getLatestPlanYear(allOpenEnrollmentPeriods) ||
    allOpenEnrollmentPeriods[0];

  const form = useForm<ChangeEnrollmentsFormFields>({
    mode: 'onChange',
    resolver: yupResolver(FullChangeEnrollmentSchema),
    values: {
      planYear: defaultPlanYear?.id,
      enrollmentType: OnboardingType.SPECIAL,
      electionWindow: getSuggestedElectionWindow(
        defaultPlanYear,
        OnboardingType.SPECIAL
      ),
      expectedStartOfCoverage: getSuggestedExpectedStartOfCoverage(
        defaultPlanYear,
        OnboardingType.SPECIAL,
        getSuggestedElectionWindow(defaultPlanYear, OnboardingType.SPECIAL),
        singleEmployeeDto
      ),
    },
  });

  const submitAddEnrollment = async (data: ChangeEnrollmentsFormFields) => {
    setErrors([]);
    const {
      planYear: enrollmentPeriodId,
      enrollmentType,
      electionWindow,
      expectedStartOfCoverage,
    } = data;
    const [electionFrom, electionUntil] = electionWindow || [];

    if (!electionFrom || !electionUntil || !expectedStartOfCoverage) {
      showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
      return;
    }

    const enrollmentData = {
      enrollmentPeriodId,
      isSpecialEnrollment: enrollmentType === OnboardingType.SPECIAL,
      onboardingFrom: formatDateISO(electionFrom),
      onboardingUntil: formatDateISO(electionUntil),
      expectedStartOfCoverage: formatDateISO(expectedStartOfCoverage),
    };

    const { errors } = await createEnrollments(
      selectedEmployees.map((employee) => ({
        params: [
          {
            employeeId: employee.employeeId,
            ...enrollmentData,
          },
        ],
        key: `${employee.firstName} ${employee.lastName}`,
      }))
    );

    if (errors.length === 0) {
      closeModal();
    }
  };

  return (
    <Drawer
      opened={isOpen}
      title="Add enrollment record"
      onClose={() => {
        closeModal();
        form.reset(undefined, { keepDefaultValues: false });
        setErrors([]);
      }}
    >
      <>
        <ChangeEnrollmentForm
          form={form}
          onSubmit={submitAddEnrollment}
          allOpenEnrollmentPeriods={allOpenEnrollmentPeriods}
          singleEmployeeDto={singleEmployeeDto}
          associatedOtherOnboardingPeriodsSingleEmployee={
            associatedOnboardingPeriodsSingleEmployee
          }
        />
        {!isSingleEmployee && errors.length > 0 && (
          <Box mt="xl">
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                { accessor: 'key', title: 'Employee Name' },
                { accessor: 'error', title: 'Message' },
              ]}
              records={errors}
              idAccessor="key"
            />
          </Box>
        )}
      </>
    </Drawer>
  );
};
