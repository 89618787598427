import { WomanTypingOnLaptop } from '@zorro/shared/assets';
import { useRouter } from '@zorro/shared/utils';
import { Anchor, Button, Space, Stack, Text } from '@zorro/zorro-ui-design';

import { StepHeading } from '../StepHeading';

type SomethingWentWrongProps = {
  title?: string;
  subtitle?: string;
  shouldShowSupportMessage?: boolean;
  actionButtonText?: string;
  actionButtonAction?: () => void;
};

export const SomethingWentWrong = ({
  title = 'Oops!',
  subtitle = 'Something went wrong',
  shouldShowSupportMessage = true,
  actionButtonText,
  actionButtonAction,
}: SomethingWentWrongProps) => {
  const { reloadPage } = useRouter();

  const handleAction = () => {
    if (actionButtonAction) {
      actionButtonAction();
    } else {
      reloadPage();
    }
  };

  return (
    <>
      <StepHeading title={title} subtitle={subtitle} />

      <Space h="xs" />

      <Stack align="center">
        <WomanTypingOnLaptop />

        {actionButtonText && (
          <Button
            style={{ maxWidth: 222 }}
            onClick={handleAction}
            data-testid="something-went-wrong-reload-page-button"
          >
            {actionButtonText}
          </Button>
        )}
        {shouldShowSupportMessage && (
          <Text ta="center">
            If the problem persists, please contact{' '}
            <Anchor
              href="mailto:support@myzorro.co"
              data-testid="something-went-wrong-support-link"
            >
              support@myzorro.co
            </Anchor>
          </Text>
        )}
      </Stack>
    </>
  );
};
