/* eslint-disable unicorn/filename-case */
import {
  DEFAULT_THEME,
  DefaultMantineColor,
  MantineColorsTuple,
  MantineThemeOverride,
} from '@mantine/core';
import { Inter } from 'next/font/google';

export type ExtendedCustomColors =
  | 'zorroIris900'
  | 'zorroIris400'
  | 'zorroIris100'
  | 'zorroIris50'
  | 'zorroPlum900'
  | 'zorroGreen600'
  | 'zorroGreen300'
  | 'zorroGreen100'
  | 'zorroFire700'
  | 'zorroFire500'
  | 'zorroFire300'
  | 'zorroYolk800'
  | 'zorroYolk400'
  | 'zorroYolk200'
  | 'zorroSky500'
  | 'zorroSky300'
  | 'zorroSky100'
  | 'zorroGray800'
  | 'zorroGray700'
  | 'zorroGray600'
  | 'zorroGray500'
  | 'zorroGray400'
  | 'zorroGray300'
  | 'zorroGray200'
  | 'zorroGray100'
  | 'zorroGray50'
  | 'zorroCoal900'
  | 'zorroWhite'
  | DefaultMantineColor;

// TODO: Refactor theme colors to properly use 10 color shades https://app.clickup.com/t/86935ur9d
declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    // colors: Record<ExtendedCustomColors, readonly [string]>;
    colors: Record<ExtendedCustomColors, MantineColorsTuple>;
  }
}

const InterFont = Inter({
  subsets: ['latin'],
});

// See more: https://mantine.dev/theming/typography/#system-fonts
const fontStack = `${InterFont.style.fontFamily}, ${DEFAULT_THEME.fontFamily}`;

// Sorted based on order on https://www.figma.com/file/a67kNsMVoPFBpMTT09K5fE/1.-Zorro-Ui?type=design&node-id=252-451&mode=design&t=3rXZ2SRwiKFsK3oz-0
export const brand = {
  zorroIris900: '#8033EF',
  zorroIris400: '#CDABFF',
  zorroIris100: '#F0E6FF',
  zorroIris50: '#FAF7FF',
  zorroPlum900: '#3C0789',
  zorroGreen600: '#00D16C',
  zorroGreen300: '#80E8B6',
  zorroGreen100: '#E5FAF0',
  zorroFire700: '#FF5636',
  zorroFire500: '#FF8972',
  zorroFire300: '#FFBBAF',
  zorroYolk800: '#FFC214',
  zorroYolk400: '#FFD880',
  zorroYolk200: '#FFEFCC',
  zorroSky500: '#00BEE9',
  zorroSky300: '#66D8F2',
  zorroSky100: '#CCF2FB',
  zorroGray900: '#F7F8FB',
  zorroGray800: '#76747A',
  zorroGray700: '#EDEFF6',
  zorroGray600: '#B2B2B2',
  zorroGray500: '#A4A5AF',
  zorroGray400: '#676979',
  zorroGray300: '#D9D9D9',
  zorroGray200: '#D9DBDF',
  zorroGray100: '#E8E8E8',
  zorroGray50: '#F9F9F9',
  zorroCoal900: '#1A1721',
  zorroWhite: '#FFFFFF',
  primaryButtonSelected: '#E0E3FE',
} as const;

export const ZorroTheme: MantineThemeOverride = {
  defaultRadius: 'md',
  cursorType: 'pointer',
  colors: {
    brand: [
      brand.zorroWhite,
      brand.zorroIris400,
      brand.zorroIris400,
      brand.zorroIris400,
      brand.zorroIris400,
      brand.zorroIris400,
      brand.zorroIris900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
    ],
    zorroIris: [
      brand.zorroIris50,
      brand.zorroIris100,
      brand.zorroIris100,
      brand.zorroIris100,
      brand.zorroIris400,
      brand.zorroIris400,
      brand.zorroIris400,
      brand.zorroIris900,
      brand.zorroIris900,
      brand.zorroIris900,
      brand.zorroIris900,
    ],
    zorroPlum: [
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
      brand.zorroPlum900,
    ],
    zorroGreen: [
      brand.zorroGreen100,
      brand.zorroGreen100,
      brand.zorroGreen100,
      brand.zorroGreen300,
      brand.zorroGreen300,
      brand.zorroGreen300,
      brand.zorroGreen600,
      brand.zorroGreen600,
      brand.zorroGreen600,
      brand.zorroGreen600,
      brand.zorroGreen600,
    ],
    zorroFire: [
      brand.zorroFire300,
      brand.zorroFire300,
      brand.zorroFire300,
      brand.zorroFire300,
      brand.zorroFire500,
      brand.zorroFire500,
      brand.zorroFire500,
      brand.zorroFire700,
      brand.zorroFire700,
      brand.zorroFire700,
    ],
    zorroYolk: [
      brand.zorroYolk200,
      brand.zorroYolk200,
      brand.zorroYolk200,
      brand.zorroYolk200,
      brand.zorroYolk400,
      brand.zorroYolk400,
      brand.zorroYolk400,
      brand.zorroYolk400,
      brand.zorroYolk800,
      brand.zorroYolk800,
    ],
    zorroSky: [
      brand.zorroSky100,
      brand.zorroSky100,
      brand.zorroSky100,
      brand.zorroSky300,
      brand.zorroSky300,
      brand.zorroSky500,
      brand.zorroSky500,
      brand.zorroSky500,
      brand.zorroSky500,
      brand.zorroSky500,
    ],
    zorroGray: [
      brand.zorroGray50,
      brand.zorroGray100,
      brand.zorroGray200,
      brand.zorroGray300,
      brand.zorroGray400,
      brand.zorroGray500,
      brand.zorroGray600,
      brand.zorroGray600,
      brand.zorroGray700,
      brand.zorroGray800,
      brand.zorroGray900,
    ],
    zorroCoal: [
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
      brand.zorroCoal900,
    ],
    // @ts-expect-error Mantine color types expect an array with 10 items https://mantine.dev/theming/colors/#add-custom-colors-types
    zorroWhite: [brand.zorroWhite],
    // @ts-expect-error Mantine color types expect an array with 10 items https://mantine.dev/theming/colors/#add-custom-colors-types
    zorroPrimaryButtonSelected: [brand.primaryButtonSelected],
  },
  primaryColor: 'brand',
  fontFamily: fontStack,
  headings: {
    fontWeight: '500',
    sizes: {
      h1: { fontSize: '1.5rem' },
      h2: { fontSize: '1.25rem' },
      h3: { fontSize: '1.125rem' },
      h4: { fontSize: '1rem' },
      h5: { fontSize: '0.875rem' },
      h6: { fontSize: '0.75rem' },
    },
  },
  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.25rem',
    xl: '1.5rem',
    xxl: '2.25rem',
  },
  shadows: {
    xs: '0px 0px 12px 0px rgba(232, 232, 232, 0.25)',
    sm: '0px 2px 12px 0px rgba(232, 232, 232, 0.40)',
    md: '0px 4px 12px 0px rgba(232, 232, 232, 0.60)',
    lg: '0px 6px 12px 0px rgba(232, 232, 232, 0.80)',
    xl: `0px 8px 12px 0px ${brand.zorroGray100}`,
  },
};
