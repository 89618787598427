const pluralRules = new Intl.PluralRules('en-US');

/*
 * Handle singular and plural cases correctly
 * Example: 1 apple, 2 apples
 * Taken from: https://2ality.com/2019/12/intl-pluralrules.html
 */
export const pluralizeWord = (
  count: number,
  singular: string,
  plural: string
) => {
  const grammaticalNumber = pluralRules.select(count);
  switch (grammaticalNumber) {
    case 'one': {
      return `${count} ${singular}`;
    }
    case 'other': {
      return `${count} ${plural}`;
    }
    default: {
      throw new Error(`Unknown: ${grammaticalNumber}`);
    }
  }
};
