import { validationMessages } from '@zorro/shared/utils';
import * as yup from 'yup';

export enum InvitationType {
  BY_EMAIL = 'BY_EMAIL',
  BY_LINK = 'BY_LINK',
}

export const sendInviteSchema = yup.object({
  invitationType: yup
    .mixed<InvitationType>()
    .oneOf(Object.values(InvitationType), validationMessages.familyUnitRequired)
    .typeError(validationMessages.familyUnitRequired)
    .required(validationMessages.familyUnitRequired),
  email: yup
    .string()
    .required(validationMessages.emailRequired)
    // eslint-disable-next-line require-unicode-regexp
    .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/, {
      message: validationMessages.emailValid,
    }),
});

export type SendInviteFormFields = yup.InferType<typeof sendInviteSchema>;
