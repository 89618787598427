import { IconExclamationCircleFilled } from '@tabler/icons-react';
import { EmployeeDto } from '@zorro/clients';
import { getNow, parseDateISO } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Button, Group, Icon, Text, brand } from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { ChangeElectionWindowDrawer } from '../../EmployeeActions/Enrollment/ChangeElectionWindowDrawer';
import { EnrollmentTabAction } from '../EnrollmentTabMenuActions';

type Props = {
  employee: EmployeeDto;
  onboardingPeriodId: string;
};

export const DeadlinePassedTabContent = ({
  employee,
  onboardingPeriodId,
}: Props) => {
  const { data: onboardingPeriod, isLoading } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employee.id, onboardingPeriodId],
  });

  const [isModalOpenByName, setIsModalOpenByName] = useState<string | null>(
    null
  );

  if (!onboardingPeriod || isLoading) {
    return null;
  }

  const daysAgo = getNow().diff(
    parseDateISO(onboardingPeriod.onboardingUntil),
    'day'
  );

  return (
    <>
      <Group>
        {daysAgo > 0 && (
          <Group gap="0.25rem">
            <Icon
              icon={IconExclamationCircleFilled}
              color={brand.zorroFire700}
            />
            <Text size="sm" c="zorroGray.4">
              Deadline passed {daysAgo} days ago
            </Text>
          </Group>
        )}
        <Button
          size="sm"
          fw={300}
          onClick={() =>
            setIsModalOpenByName(EnrollmentTabAction.CHANGE_ELECTION_WINDOW)
          }
        >
          Change election window
        </Button>
      </Group>
      <ChangeElectionWindowDrawer
        planYearId={onboardingPeriod.enrollmentPeriodId}
        isOpen={
          isModalOpenByName === EnrollmentTabAction.CHANGE_ELECTION_WINDOW
        }
        closeModal={() => setIsModalOpenByName(null)}
        selectedEmployees={[
          {
            employeeId: onboardingPeriod.employeeId,
            firstName: employee.firstName,
            lastName: employee.lastName,
            onboardingPeriodId,
          },
        ]}
      />
    </>
  );
};
