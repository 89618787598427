import { useAuth } from '@frontegg/nextjs';
import { Roles } from '@zorro/clients';

import {
  isAgentRole,
  isAgentWithoutSupervisor,
  isZorroOperationsRole,
} from '../roles';

export const useRoles = (): {
  loggedInUserRoles: Roles[];
  isAgent: boolean;
  isZorroOperations: boolean;
  isAgentWithoutSupervisor: boolean;
} => {
  const { user } = useAuth();
  const roles = (user?.roles.map(({ key }) => key) as Roles[]) ?? [];

  return {
    loggedInUserRoles: roles,
    isZorroOperations: isZorroOperationsRole(roles),
    isAgent: isAgentRole(roles),
    isAgentWithoutSupervisor: isAgentWithoutSupervisor(roles),
  };
};
