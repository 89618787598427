import { validationMessages } from '@zorro/shared/utils';
import * as yup from 'yup';

export const allowanceFormSchema = yup.object({
  allowance: yup
    .number()
    .min(0, validationMessages.allowanceNotNegative)
    .typeError(validationMessages.allowanceRequired)
    .required(validationMessages.allowanceRequired),
  employeeMonthlyContribution: yup
    .number()
    .min(0, validationMessages.employeeMonthlyContributionNotNegative)
    .typeError(validationMessages.employeeMonthlyContributionNotNegative)
    .required(validationMessages.employeeMonthlyContributionRequired),
  premium: yup
    .number()
    .min(0, validationMessages.premiumPositive)
    .typeError(validationMessages.premiumRequired)
    .required(validationMessages.premiumRequired),
});

export type AllowanceFormFields = yup.InferType<typeof allowanceFormSchema>;
