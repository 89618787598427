import { Center } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';

const IconZorroPaymentCard = ({ style }: SVGIconProps) => {
  return (
    <Center style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
      >
        <path
          stroke="#1A1721"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M3.332 13.665h33.333m-30 19.167h26.667a3.333 3.333 0 0 0 3.333-3.333V11.165a3.333 3.333 0 0 0-3.333-3.333H6.665a3.333 3.333 0 0 0-3.333 3.333V29.5a3.333 3.333 0 0 0 3.333 3.333Z"
        />
        <path
          fill="#1A1721"
          d="M23.889 21.263V20.25c0-.148-.108-.268-.241-.268h-2.865c-.153 0-.276-.137-.276-.305v-1.731c0-.064-.047-.115-.104-.115h-1.96c-.686 0-1.242.617-1.242 1.377v1.04c0 .063.046.114.103.114h2.786c.252 0 .372.344.185.532l-3.159 3.176a1.59 1.59 0 0 0-.448 1.12v1.033c0 .148.108.268.241.268h2.883c.152 0 .276.137.276.305v1.754c0 .063.046.114.104.114h2.01c.667 0 1.207-.6 1.207-1.337V26.25c0-.063-.047-.114-.104-.114h-2.95c-.22 0-.325-.302-.161-.465l3.268-3.286a1.59 1.59 0 0 0 .448-1.12l-.001-.002Z"
        />
      </svg>
    </Center>
  );
};

export { IconZorroPaymentCard };
