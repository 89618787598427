import {
  PlanYearSetupForm,
  usePlanYearSetupForm,
} from '../../PlanYearSetupForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
};

export const AddEmployerPlanYearSetupStep = ({
  employerId,
  isFinalizationMode = false,
}: Props) => {
  const { planYearSetupForm, onSubmit } = usePlanYearSetupForm({
    shouldFallbackToEarliestPeriod: true,
    isFinalizationMode,
    employerId,
  });

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !planYearSetupForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.PLAN_YEAR_SETUP}
      subtitle="will not be included in the employer invitation"
      title="Set up the company’s first plan year"
      isSubmitDisabled={isSubmitDisabled}
      onSubmit={handleStepSubmit}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={false}
    >
      <PlanYearSetupForm
        planYearSetupForm={planYearSetupForm}
        isFinalizationMode={isFinalizationMode}
      />
    </AddEmployerStep>
  );
};
