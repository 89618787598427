/* eslint-disable unicorn/filename-case */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useMonolithQuery } from '@zorro/shared/utils';
import Decimal from 'decimal.js';
import { useEffect } from 'react';

import { useMonolithMutation } from '../hooks';
import {
  PremiumAmountFormFields,
  premiumAmountSchema,
} from './PremiumAmountUtils';

type Props = {
  onboardingPeriodId: string;
  employeeId: string;
};

export const usePremiumAmountForm = ({
  employeeId,
  onboardingPeriodId,
}: Props) => {
  const { mutate: mutateMajorMedical } = useMonolithMutation({
    method: 'majorMedicalControllerUpsertMajorMedicalPlanByOperations',
  });

  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindOne',
    params: [employeeId, onboardingPeriodId],
  });
  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });

  const majorMedicalPlan = benefits?.majorMedicalBenefit?.plan;

  const premiumAmountForm = useForm<PremiumAmountFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(premiumAmountSchema),
    defaultValues: {
      employeeMonthlyContribution:
        majorMedicalPlan?.employeeMonthlyContribution,
      premium: majorMedicalPlan?.premium,
    },
  });

  const premium = premiumAmountForm.watch('premium');
  const allowance =
    onboardingPeriod?.allowance ??
    majorMedicalPlan?.employerMonthlyContribution ??
    0;
  const employerMonthlyContribution = Math.min(allowance, premium);

  useEffect(() => {
    if (premium) {
      const employeeMonthlyContribution = Math.max(
        new Decimal(premium).sub(employerMonthlyContribution).toNumber(),
        0
      );
      premiumAmountForm.setValue(
        'employeeMonthlyContribution',
        employeeMonthlyContribution
      );
    }
  }, [employerMonthlyContribution, premium, premiumAmountForm]);

  return {
    premiumAmountForm,
    employerMonthlyContribution,
    onSubmit: async () => {
      if (!majorMedicalPlan) return false;

      const data = premiumAmountForm.getValues();

      try {
        await mutateMajorMedical([
          onboardingPeriodId,
          true,
          {
            updateManuallyEnteredMajorMedicalPlan: {
              ...majorMedicalPlan,
              employeeMonthlyContribution: data.employeeMonthlyContribution,
              premium: data.premium,
              allowance,
            },
          },
        ]);

        return true;
      } catch {
        /* empty */
      }
      return false;
    },
  };
};
