import { ApiError } from '@zorro/clients';

export type ErrorInfo = {
  errors: string[] | Record<string, string[]>;
  statusCode?: number;
};

export type ErrorsBody = Record<string, string[]>;

function joinErrorsArray(errors: string[]): string {
  return errors.length > 1 ? errors.join(', ') : errors[0];
}

function stringtifyErrorsBody(errorsBody: ErrorsBody): string {
  const err: string[] = [];
  for (const key in errorsBody) {
    const currentErrors = errorsBody[key];
    if (currentErrors.length > 0) {
      err.push(...currentErrors);
    }
  }
  return joinErrorsArray(err);
}

export const UNEXPECTED_ERROR_MESSAGE =
  'Something went wrong. Please retry later or contact support if the issue persists';
export function responseErrorToString(error: unknown): string {
  if (
    !error ||
    typeof error !== 'object' ||
    !(error instanceof ApiError) ||
    !('errors' in error.body)
  ) {
    return error && error instanceof Error && 'message' in error
      ? error?.message
      : UNEXPECTED_ERROR_MESSAGE;
  }

  if (Array.isArray(error.body['errors'])) {
    return joinErrorsArray(error.body['errors']);
  } else if (
    typeof error.body['errors'] === 'object' &&
    error.body['errors'] &&
    Object.keys(error.body['errors']).length > 0
  ) {
    return stringtifyErrorsBody(error.body['errors'] as ErrorsBody);
  }
  return UNEXPECTED_ERROR_MESSAGE;
}

export function getErrorsFromPromises<I, K extends keyof I>(
  results: PromiseSettledResult<unknown>[],
  initialData: I[],
  nameKey: K
): { [x: string]: string; error: string }[] {
  return results
    .filter((result) => result.status === 'rejected')
    .map((result, index) => {
      const { reason } = result as PromiseRejectedResult;
      return {
        [nameKey]: `${initialData[index]?.[nameKey]}`,
        error: responseErrorToString(reason),
      };
    });
}
