import { IconZorroNoCard } from '@zorro/shared/assets';
import { Card, Stack, Text } from '@zorro/zorro-ui-design';

const NoPaymentMethod = () => {
  return (
    <Card style={{ width: '24rem' }}>
      <Stack
        align="center"
        justify="center"
        gap="xs"
        style={{ minHeight: '16rem' }}
      >
        <IconZorroNoCard />
        <Text size="sm">No active payment method</Text>
      </Stack>
    </Card>
  );
};

export { NoPaymentMethod };
