import { Radio, RadioCardProps } from '@mantine/core';

import { Text } from '../../../index';
import { ZorroFlex } from '../../Flex';
import { ZorroGroup } from '../../Group';
import { ZorroRadioIndicator } from '../RadioIndicator/RadioIndicator';

export type IRadioCardProps = RadioCardProps & {
  icon?: JSX.Element;
  label?: string;
};

export const ZorroRadioCard = ({ icon, label, ...props }: IRadioCardProps) => {
  return (
    <Radio.Card radius="md" w="100%" h="3rem" pl="xs" pr="xs" {...props}>
      <ZorroGroup align="center" justify="space-between">
        <ZorroFlex align="center">
          {icon}
          <Text size="sm" ml="sm">
            {label}
          </Text>
        </ZorroFlex>
        <ZorroRadioIndicator />
      </ZorroGroup>
    </Radio.Card>
  );
};

ZorroRadioCard.displayName = 'ZorroRadioCard';
