import { Flex, Text, ThemeIcon, UnstyledButton } from '@zorro/zorro-ui-design';
import Link from 'next/link';
import { ReactNode, memo } from 'react';

import classNames from './MainLink.module.scss';

export type MainLinkProps = {
  icon: ReactNode;
  link: string;
  label: string;
  // eslint-disable-next-line react/no-unused-prop-types
  exactLinkOnly?: boolean;
};

type Props = MainLinkProps & {
  isCurrentRoute: boolean;
  onClick: (link: string) => void;
};

export const MainLink = memo(
  ({ label, link, icon, onClick, isCurrentRoute }: Props) => {
    return (
      <Link href={link} passHref legacyBehavior>
        <UnstyledButton
          py="md"
          px="xs"
          classNames={classNames}
          onClick={() => onClick?.(link)}
          data-current-route={isCurrentRoute}
          data-testid={`main-link-${link.slice(1)}`}
        >
          <Flex align="center" justify={{ base: 'center', md: 'inherit' }}>
            <ThemeIcon mr={{ md: 'xs' }} style={{ background: 'transparent' }}>
              {icon}
            </ThemeIcon>
            <Text
              size="md"
              fw={isCurrentRoute ? 500 : 400}
              display={{ base: 'none', md: 'inherit' }}
            >
              {label}
            </Text>
          </Flex>
        </UnstyledButton>
      </Link>
    );
  }
);

MainLink.displayName = 'MainLink';
