import { useDynamicMurrietaRouter } from '@zorro/shared/utils';
import { STEPPER_BODY_MAX_WIDTH } from '@zorro/types';
import {
  Box,
  IButtonProps,
  IPaperProps,
  Stack,
  Text,
  Title,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { FormFooter } from '../../FormFooter';
import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { AddEmployerBreadcrumbs } from './AddEmployerBreadcrumbs';
import { AddEmployerStepper } from './AddEmployerStepper';
import {
  AddEmployerStepType,
  HandleStepSubmit,
  getAddEmployerStepConfig,
} from './AddEmployerUtils';

type Props = {
  step: AddEmployerStepType;
  title: string;
  subtitle?: string;
  children: ReactNode;
  onSubmit: HandleStepSubmit;
  isFinalizationMode: boolean;
  isDon: boolean;
  submitLabel?: string;
  isSubmitDisabled?: boolean;
  employerId?: string;
  secondaryLabel?: string;
  secondaryButtonProps?: IButtonProps | undefined;
  paperProps?: Omit<IPaperProps, 'children'>;
};

export const AddEmployerStep = ({
  step,
  title,
  subtitle,
  children,
  onSubmit,
  isFinalizationMode,
  isDon,
  isSubmitDisabled,
  submitLabel = 'Save and continue',
  employerId,
  secondaryLabel,
  secondaryButtonProps,
  paperProps,
}: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { startLoading, stopLoading } = useLoadingOverlay();
  const { nextRoute } = getAddEmployerStepConfig(step, isDon);

  const handleSubmit = async () => {
    startLoading();
    const result = await onSubmit();
    if (result.navigate && nextRoute) {
      navigateToRoute(nextRoute, [result.employerId]);
    }
    stopLoading();
  };

  const handleSkip = () => {
    if (nextRoute && employerId) {
      navigateToRoute(nextRoute, [employerId]);
    }
  };

  return (
    <>
      {!isDon && <AddEmployerBreadcrumbs />}
      <AddEmployerStepper
        isDon={isDon}
        employerStep={step}
        employerId={employerId}
        isFinalizationMode={isFinalizationMode}
      />
      <Box mx="24" mt="xl" pb={100}>
        <Stack maw={STEPPER_BODY_MAX_WIDTH}>
          <Title size="sm" fw={600}>
            {title}
            {subtitle && (
              <Text component="span" c="zorroGray.4" ml="xs">
                ({subtitle})
              </Text>
            )}
          </Title>
          {children}
        </Stack>
      </Box>
      <FormFooter
        primaryLabel={submitLabel}
        primaryButtonProps={{
          onClick: handleSubmit,
          disabled: isSubmitDisabled,
        }}
        secondaryLabel={
          employerId && secondaryLabel ? secondaryLabel : undefined
        }
        secondaryButtonProps={{ onClick: handleSkip, ...secondaryButtonProps }}
        paperProps={paperProps}
      />
    </>
  );
};
