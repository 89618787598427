import {
  EmployeeDto,
  OnboardingPeriodDto,
  OnboardingType,
  OpenEnrollmentPeriodDto,
} from '@zorro/clients';
import {
  formatDateISO,
  getDateUtil,
  getToday,
  parseDate,
  parseDateISO,
} from '@zorro/shared/formatters';
import { maximalDateISO } from '@zorro/shared/utils';
import { UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';

export interface EnrollmentDrawerProps {
  employerId: EmployeeDto['employerId'];
  selectedEmployees: {
    employeeId: string;
    firstName: string;
    lastName: string;
  }[];
  isOpen: boolean;
  closeModal: () => void;
}

export interface EditEnrollmentDrawerProps
  extends Omit<EnrollmentDrawerProps, 'selectedEmployees'> {
  selectedEmployees: {
    employeeId: string;
    firstName: string;
    lastName: string;
    onboardingPeriodId: string;
  }[];
}

export type ChangeElectionWindowFormFields = {
  electionWindow: [Date, Date] | undefined;
  expectedStartOfCoverage: Date | undefined;
};

export type ChangeEnrollmentsFormFields = ChangeElectionWindowFormFields & {
  planYear: string;
  enrollmentType: OnboardingType;
};

export const ChangeElectionWindowSchemaObject = {
  electionWindow: yup
    .array()
    .of(yup.date())
    .length(2)
    .required('Election window is required'),
  expectedStartOfCoverage: yup
    .date()
    .required('Expected start of coverage is required'),
};

export const FullChangeEnrollmentSchema = yup.object().shape({
  planYear: yup.string().required('Plan year is required'),
  enrollmentType: yup.string().required('Enrollment type is required'),
  ...ChangeElectionWindowSchemaObject,
});

export const formFieldStyle = {
  flex: 1,
  marginBottom: '16px',
};

export const inlineGroupStyle = {
  display: 'flex',
  gap: '16px',
  marginBottom: '16px',
};

export const defaultSpecialUntilDate = 20;

export const addOrEditAlertBoxText = (isSingleEmployee: boolean): string => {
  return `
  Choose the dates for ${
    isSingleEmployee ? 'the employee' : 'employees'
  } to browse and elect plans.
  Coverage generally starts on the first of the month following the election.
  Ensure the period allows adequate time for processing selections.`;
};

export const getSuggestedElectionWindow = (
  selectedOpenEnrollment: OpenEnrollmentPeriodDto | undefined,
  enrollmentType: OnboardingType
): [Date, Date] | undefined => {
  const today = getToday();
  if (enrollmentType === OnboardingType.SPECIAL) {
    const until = getDateUtil().min(
      today.date() > defaultSpecialUntilDate
        ? today.add(1, 'month').date(defaultSpecialUntilDate)
        : today.date(defaultSpecialUntilDate),
      selectedOpenEnrollment
        ? parseDateISO(selectedOpenEnrollment.effectiveUntil)
            .subtract(1, 'month')
            .date(defaultSpecialUntilDate)
        : today.add(1, 'year')
    );
    return today.isBefore(until) ? [today.toDate(), until.toDate()] : undefined;
  }
  // enrollmentType === OnboardingType.OPEN_ENROLLMENT
  return selectedOpenEnrollment
    ? [
        parseDateISO(selectedOpenEnrollment.onboardingFrom).toDate(),
        parseDateISO(selectedOpenEnrollment.onboardingUntil).toDate(),
      ]
    : undefined;
};

export const getSuggestedExpectedStartOfCoverage = (
  selectedOpenEnrollment: OpenEnrollmentPeriodDto | undefined,
  enrollmentType: OnboardingType,
  electionWindow: [Date, Date] | undefined,
  singleEmployeeDto?: EmployeeDto
): Date | undefined => {
  if (
    selectedOpenEnrollment &&
    enrollmentType === OnboardingType.SPECIAL &&
    electionWindow?.every(Boolean)
  ) {
    const nextFirstOfMonthAfterOnboardingUntil = parseDate(electionWindow[1])
      .add(1, 'month')
      .startOf('month');

    const suggestedExpectedStartOfCoverageForSpecial = parseDateISO(
      maximalDateISO(
        formatDateISO(nextFirstOfMonthAfterOnboardingUntil),
        selectedOpenEnrollment.effectiveFrom,
        singleEmployeeDto?.eligibleFrom
      )
    );
    if (
      suggestedExpectedStartOfCoverageForSpecial.isAfter(
        selectedOpenEnrollment.effectiveUntil
      ) ||
      (singleEmployeeDto?.eligibleUntil &&
        suggestedExpectedStartOfCoverageForSpecial.isAfter(
          singleEmployeeDto?.eligibleUntil
        ))
    ) {
      return undefined;
    }
    return suggestedExpectedStartOfCoverageForSpecial.toDate();
  } else if (
    selectedOpenEnrollment &&
    enrollmentType === OnboardingType.OPEN_ENROLLMENT
  ) {
    return parseDateISO(selectedOpenEnrollment.effectiveFrom).toDate();
  }
  return undefined;
};

export const getCurrentlyEffectiveYear = (
  allOpenEnrollmentPeriods: OpenEnrollmentPeriodDto[]
) => {
  const currentYear = allOpenEnrollmentPeriods.find((oep) =>
    getToday().isBetween(oep.effectiveFrom, oep.effectiveUntil)
  );
  return currentYear || null;
};

export const getLatestPlanYear = (
  allOpenEnrollmentPeriods: OpenEnrollmentPeriodDto[]
) => {
  return allOpenEnrollmentPeriods.length > 0
    ? allOpenEnrollmentPeriods.reduce((latest, current) =>
        parseDateISO(latest.effectiveFrom).isAfter(current.effectiveFrom)
          ? latest
          : current
      )
    : null;
};

export const eligibleForSpecialOnly = (
  selectedOEP?: OpenEnrollmentPeriodDto,
  singleEmployeeDto?: EmployeeDto,
  associatedOtherOnboardingPeriodsSingleEmployee?: OnboardingPeriodDto[]
) => {
  if (
    !singleEmployeeDto ||
    !selectedOEP ||
    !associatedOtherOnboardingPeriodsSingleEmployee
  ) {
    return false;
  }

  const effectiveAfterEligibleFrom = parseDateISO(
    selectedOEP.effectiveFrom
  ).isAfter(singleEmployeeDto?.eligibleFrom);

  const hasMatchingOpenEnrollmentPeriod =
    associatedOtherOnboardingPeriodsSingleEmployee?.some(
      (period) =>
        !period.isSpecialEnrollment &&
        period.enrollmentPeriodId === selectedOEP?.id
    );

  return effectiveAfterEligibleFrom || hasMatchingOpenEnrollmentPeriod;
};

export const getAvailableEnrollmentTypes = (
  selectedOEP?: OpenEnrollmentPeriodDto,
  singleEmployeeDto?: EmployeeDto,
  associatedOtherOnboardingPeriodsSingleEmployee?: OnboardingPeriodDto[]
) => {
  const disableOpenEnrollment = eligibleForSpecialOnly(
    selectedOEP,
    singleEmployeeDto,
    associatedOtherOnboardingPeriodsSingleEmployee
  );
  return [
    {
      label: OnboardingType.OPEN_ENROLLMENT,
      value: OnboardingType.OPEN_ENROLLMENT,
      disabled: disableOpenEnrollment,
    },
    { label: OnboardingType.SPECIAL, value: OnboardingType.SPECIAL },
  ];
};

type HandleFormStateSuggestionsData = {
  planYear?: OpenEnrollmentPeriodDto;
  enrollmentType?: OnboardingType;
  electionWindow?: [Date, Date];
};

type FieldsChanged = keyof ChangeEnrollmentsFormFields;

export const handleFormStateChange = (
  changeEnrollmentFormState: HandleFormStateSuggestionsData,
  fieldChanged: FieldsChanged,
  setValue: UseFormReturn<ChangeEnrollmentsFormFields>['setValue'],
  singleEmployeeDto?: EmployeeDto,
  associatedOnboardingPeriodsSingleEmployee?: OnboardingPeriodDto[]
) => {
  if (!changeEnrollmentFormState.planYear) {
    return;
  }

  if (fieldChanged === 'planYear') {
    setValue('planYear', changeEnrollmentFormState.planYear.id, {
      shouldValidate: true,
    });
    if (
      changeEnrollmentFormState.planYear &&
      eligibleForSpecialOnly(
        changeEnrollmentFormState.planYear,
        singleEmployeeDto,
        associatedOnboardingPeriodsSingleEmployee
      )
    ) {
      changeEnrollmentFormState.enrollmentType = OnboardingType.SPECIAL;
    }
  }

  if (!changeEnrollmentFormState.enrollmentType) {
    return;
  }

  setValue('enrollmentType', changeEnrollmentFormState.enrollmentType, {
    shouldValidate: true,
  });

  if (
    fieldChanged === 'electionWindow' &&
    changeEnrollmentFormState.electionWindow
  ) {
    setValue('electionWindow', changeEnrollmentFormState.electionWindow, {
      shouldValidate: true,
    });
  } else {
    const suggestedWindow = getSuggestedElectionWindow(
      changeEnrollmentFormState.planYear,
      changeEnrollmentFormState.enrollmentType
    );
    if (suggestedWindow) {
      setValue('electionWindow', suggestedWindow, {
        shouldValidate: true,
      });
    }
  }

  const suggestedExpectedStartOfCoverage = getSuggestedExpectedStartOfCoverage(
    changeEnrollmentFormState.planYear,
    changeEnrollmentFormState.enrollmentType,
    changeEnrollmentFormState.electionWindow,
    singleEmployeeDto
  );
  if (suggestedExpectedStartOfCoverage) {
    setValue('expectedStartOfCoverage', suggestedExpectedStartOfCoverage, {
      shouldValidate: true,
    });
  }
};

export function getSharedEnrollmentType(
  allOnboardingPeriods: OnboardingPeriodDto[]
): OnboardingType | undefined {
  if (allOnboardingPeriods.length === 0) return undefined;

  const isFirstValueSpecial = allOnboardingPeriods[0].isSpecialEnrollment;
  const homogeneity = allOnboardingPeriods.every(
    (op) => op.isSpecialEnrollment === isFirstValueSpecial
  );
  return homogeneity
    ? isFirstValueSpecial
      ? OnboardingType.SPECIAL
      : OnboardingType.OPEN_ENROLLMENT
    : undefined;
}
