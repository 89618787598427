import { IconTrash } from '@tabler/icons-react';
import { OnboardingPeriodDto } from '@zorro/clients';
import {
  Button,
  Group,
  Icon,
  Modal,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@zorro/zorro-ui-design';
import _noop from 'lodash/noop';

import { useMonolithMutation } from '../../hooks';

export type DeleteEnrollmentRecordModalProps = {
  onClose: () => void;
  onSubmit?: () => void;
  onboardingPeriodId?: OnboardingPeriodDto['id'];
};

export const DeleteEnrollmentRecordModal = ({
  onClose,
  onSubmit = _noop,
  onboardingPeriodId,
}: DeleteEnrollmentRecordModalProps) => {
  const { tryMutate: mutateOnboardingPeriodDelete } = useMonolithMutation({
    successMessage: 'Deleted enrollment record successfully.',
    method: 'onboardingPeriodsControllerDelete',
  });

  if (!onboardingPeriodId) {
    return null;
  }

  const handleEnrollmentDelete = async () => {
    const mutationResult = await mutateOnboardingPeriodDelete([
      onboardingPeriodId,
    ]);

    if (mutationResult.isOk()) {
      onSubmit();
    }

    onClose();
  };

  return (
    <Modal
      size="md"
      onClose={() => onClose()}
      showSeparator={false}
      title={
        <Group>
          <ThemeIcon bg="zorroFire.3" size="lg">
            <Icon icon={IconTrash} size={24} />
          </ThemeIcon>
          <Title order={2}>Delete enrollment record?</Title>
        </Group>
      }
    >
      <Stack gap="xl">
        <Text>Are you sure you want to delete enrollment record?</Text>
        <Group justify="flex-end">
          <Button
            onClick={() => onClose()}
            variant="transparent"
            color="zorroCoal.9"
            size="md"
            fw="400"
          >
            Cancel
          </Button>
          <Button
            onClick={handleEnrollmentDelete}
            color="zorroFire.7"
            size="md"
            fw="400"
          >
            Delete
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
