import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { EmployeeReportRowDto } from '@zorro/clients';
import { recordsPerPage } from '@zorro/types';
import { HttpStatusCode } from 'axios';

import { callEndpoint } from '../callEndpoint';
import { useRouter } from './useRouter';

export function usePaginatedEmployeeReports(
  selectedEnrollmentPeriodId?: string | null
): {
  isLoading: boolean;
  data: EmployeeReportRowDto[];
  refetch: () => Promise<QueryObserverResult<EmployeeReportRowDto[] | null>>;
} {
  const { reloadPage } = useRouter();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'employerReportsControllerGenerateEmployeeReportRestOfPages',
      selectedEnrollmentPeriodId,
    ],
    queryFn: async () => {
      try {
        if (selectedEnrollmentPeriodId) {
          const firstPage = await callEndpoint({
            method: 'employerReportsControllerGenerateEmployeeReport',
            params: [selectedEnrollmentPeriodId, recordsPerPage, 1],
          });
          if (firstPage) {
            const restOfPages = await Promise.all(
              Array.from({ length: firstPage.meta.lastPage - 1 }).map(
                async (_, index) => {
                  const pageNumber = index + 2;
                  return await callEndpoint({
                    method: 'employerReportsControllerGenerateEmployeeReport',
                    params: [
                      selectedEnrollmentPeriodId,
                      recordsPerPage,
                      pageNumber,
                    ],
                  });
                }
              )
            );
            return [firstPage, ...restOfPages].flatMap((page) => page.data);
          }
        }
      } catch (error) {
        if (error.status === HttpStatusCode.Unauthorized) {
          reloadPage();
        }
      }
      return null;
    },
  });

  return {
    isLoading,
    data: data ?? [],
    refetch,
  };
}
