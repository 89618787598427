import { businessEntityTypeLabelConfig } from '@zorro/types';

import { EmployerLogo } from '../../../AppShell';
import { CompanyDetailsFormFields } from '../../../CompanyDetailsForm';
import { AddEmployerStepType } from '../../AddEmployerStep/AddEmployerUtils';
import { ReviewCard } from '../ReviewCard';

type Props = {
  fields: CompanyDetailsFormFields;
  isFinalizationMode: boolean;
  employerId: string;
  isValid: boolean;
};

export const CompanyDetailsCard = ({
  isFinalizationMode,
  employerId,
  fields,
  isValid,
}: Props) => {
  const {
    name,
    address,
    legalName,
    mailingAddress,
    signedAgreement,
    businessEntityType,
    taxId,
    taxIdType,
    logoUrl,
  } = fields;

  return (
    <ReviewCard
      isValid={isValid}
      title="Company details"
      employerId={employerId}
      step={AddEmployerStepType.COMPANY_DETAILS}
      items={[
        {
          name: 'Company name (DBA)',
          value: name,
          isRequired: true,
        },
        {
          name: 'HQ address',
          value: address,
          isRequired: isFinalizationMode,
          span: 8,
        },
        {
          name: 'Legal name',
          value: legalName,
          isRequired: isFinalizationMode,
        },
        {
          name: 'Mailing address',
          value: mailingAddress,
          isRequired: isFinalizationMode,
          span: 8,
        },
        {
          name: 'Signed agreement',
          value: typeof signedAgreement === 'string' && signedAgreement,
          isRequired: true,
        },
        {
          name: 'Business entity type',
          value:
            businessEntityType &&
            businessEntityTypeLabelConfig[businessEntityType],
          isRequired: isFinalizationMode,
        },
        {
          name: 'Tax ID',
          value: taxIdType && taxId && `${taxIdType} ${taxId}`,
          isRequired: isFinalizationMode,
        },
        {
          name: 'Company logo',
          value: logoUrl && <EmployerLogo logoUrl={logoUrl} />,
          isRequired: isFinalizationMode,
        },
      ]}
    />
  );
};
