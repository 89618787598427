import { useMonolithQuery } from '@zorro/shared/utils';
import { Anchor, Card, Grid, Stack, Text } from '@zorro/zorro-ui-design';

import { CarrierLogoNext } from '../CarrierLogoNext';

type Props = {
  onboardingPeriodId: string;
};

export const MajorMedicalPlanCard = ({ onboardingPeriodId }: Props) => {
  const { data: benefits } = useMonolithQuery({
    method: 'benefitsControllerGetBenefits',
    params: [onboardingPeriodId],
  });
  const majorMedicalPlan = benefits?.majorMedicalBenefit?.plan;

  return (
    <Card
      style={(theme) => ({
        borderColor: theme.colors.zorroGray[7],
      })}
    >
      <Grid>
        <Grid.Col span={4} pos="relative">
          <Stack h="100%" justify="center">
            <CarrierLogoNext
              plan={{ carrierName: majorMedicalPlan?.carrierName || '' }}
              width={160}
              height={65}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack gap="xs">
            <Text>{majorMedicalPlan?.name}</Text>
            <Text c="zorroGray.4">Plan ID: {majorMedicalPlan?.externalID}</Text>
          </Stack>
        </Grid.Col>
        {majorMedicalPlan?.benefitsSummaryUrl && (
          <Grid.Col span={4}>
            <Anchor target="_blank" href={majorMedicalPlan?.benefitsSummaryUrl}>
              View plan details
            </Anchor>
          </Grid.Col>
        )}
      </Grid>
    </Card>
  );
};
