import { CompanySetupForm, useCompanySetupForm } from '../../CompanySetupForm';
import { AddEmployerStep } from '../AddEmployerStep';
import {
  AddEmployerStepType,
  HandleStepSubmit,
} from '../AddEmployerStep/AddEmployerUtils';

type Props = {
  employerId: string;
  isFinalizationMode?: boolean;
  isDon?: boolean;
};

export const AddEmployerCompanySetupStep = ({
  employerId,
  isFinalizationMode = false,
  isDon = false,
}: Props) => {
  const { companySetupForm, onSubmit } = useCompanySetupForm(
    employerId,
    isFinalizationMode
  );

  const handleStepSubmit: HandleStepSubmit = async () => {
    if (await onSubmit()) {
      return { navigate: true, employerId };
    }

    return { navigate: false };
  };

  const isSubmitDisabled = !companySetupForm.formState.isValid;

  return (
    <AddEmployerStep
      isFinalizationMode={isFinalizationMode}
      step={AddEmployerStepType.COMPANY_SETUP}
      isSubmitDisabled={isSubmitDisabled}
      title="Add company setup info"
      onSubmit={handleStepSubmit}
      employerId={employerId}
      secondaryLabel="Skip"
      isDon={isDon}
    >
      <CompanySetupForm
        isFinalizationMode={isFinalizationMode}
        companySetupForm={companySetupForm}
      />
    </AddEmployerStep>
  );
};
