import { EmployeePaymentMethodType } from '@zorro/clients';

export const PAYMENT_METHOD_TYPE_SELECT_OPTIONS = [
  {
    value: EmployeePaymentMethodType.ZORRO_PAY,
    label: 'Zorro Pay',
    description:
      'Payments paid from Zorro Pay and amounts above allowance deducted through payroll',
  },
  {
    value: EmployeePaymentMethodType.COMBINED,
    label: 'Combined',
    description:
      "Payments split between Zorro Pay and employee's personal card/account",
  },
  {
    value: EmployeePaymentMethodType.SELF_PAY,
    label: 'Self-pay (reimbursement)',
    description:
      "Payments paid from employee's personal card/account and allowance reimbursed through payroll",
  },
];
