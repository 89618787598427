import { ComboboxData } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { Box, MultiSelect } from '@zorro/zorro-ui-design';
import { Dispatch } from 'react';

import { Filter, FilterRecord } from './FilterUtils';

type Props<T extends FilterRecord> = {
  title: string;
  filters: Filter<T>[];
  filterBy: keyof T;
  setFilters: Dispatch<Filter<T>[]>;
  selectOptions: ComboboxData | undefined;
};

export const SelectFilter = <T extends FilterRecord>({
  selectOptions,
  title,
  filters,
  filterBy,
  setFilters,
}: Props<T>) => {
  const value = (filters.find(({ type }) => type === filterBy)?.value ??
    []) as string[];

  return (
    <Box>
      <MultiSelect
        size="sm"
        data={selectOptions}
        value={value}
        onChange={(newValue) => {
          const newArr = filters.filter((fil) => fil.type !== filterBy);
          if (newValue.length > 0) {
            newArr.push({
              type: filterBy,
              value: newValue,
              filterCallback: (recordValues: T) => {
                let shouldFilter = false;
                newValue.forEach((val) => {
                  const recordValue = recordValues[filterBy];
                  const isArray = Array.isArray(recordValue);
                  if (
                    (isArray && recordValue.join(', ') === val) ||
                    recordValues[filterBy] === val ||
                    (val === '' && !recordValues[filterBy])
                  ) {
                    shouldFilter = true;
                  }
                });
                return shouldFilter;
              },
            });
          }
          setFilters(newArr);
        }}
        label={title}
        leftSection={<IconSearch />}
        rightSection={value && value.length > 0 ? undefined : <div />}
        placeholder={`Search ${title.replace(':', '...')}`}
      />
    </Box>
  );
};
