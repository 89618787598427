import { EmployeePaymentMethodType } from '@zorro/clients';
import {
  IconZorroPaymentCard,
  IconZorroPaymentCombined,
  IconZorroPaymentSelfPay,
} from '@zorro/shared/assets';
import {
  Group,
  ISelectProps,
  Stack,
  Text,
  ThemeIcon,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

const icons: Record<
  EmployeePaymentMethodType,
  { icon: ReactNode; color: string }
> = {
  [EmployeePaymentMethodType.ZORRO_PAY]: {
    icon: <IconZorroPaymentCard />,
    color: brand.zorroIris100,
  },
  [EmployeePaymentMethodType.COMBINED]: {
    icon: <IconZorroPaymentCombined />,
    color: brand.zorroSky100,
  },
  [EmployeePaymentMethodType.SELF_PAY]: {
    icon: <IconZorroPaymentSelfPay />,
    color: brand.zorroYolk400,
  },
};

type PaymentMethodTypeOptionProps = {
  value: EmployeePaymentMethodType;
  label: string;
  description: string;
};

const PaymentMethodTypeOption = ({
  value,
  label,
  description,
}: PaymentMethodTypeOptionProps) => {
  const { icon, color } = icons[value];

  return (
    <Group gap="lg" wrap="nowrap">
      <ThemeIcon color={color} size="xl">
        {icon}
      </ThemeIcon>
      <Stack gap={0}>
        <Text fw={600}>{label}</Text>
        <Text size="sm" c={brand.zorroGray400}>
          {description}
        </Text>
      </Stack>
    </Group>
  );
};

export { PaymentMethodTypeOption };

export const renderPaymentMethodSelectOption = ((props) => {
  const option = props.option as PaymentMethodTypeOptionProps;

  return <PaymentMethodTypeOption {...option} />;
}) satisfies ISelectProps['renderOption'];
