import { IconRepeat } from '@tabler/icons-react';
import { BenefitStatus } from '@zorro/clients';
import { brand } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../hooks/useMonolithMutation';
import { SimpleActionModal } from './SimpleActionModal';

interface UndoEnrollmentConfirmationModalProps {
  benefitEnrollmentId: string;
  isOpen: boolean;
  closeModal: () => void;
}

export const UndoEnrollmentConfirmationModal = ({
  benefitEnrollmentId,
  isOpen,
  closeModal,
}: UndoEnrollmentConfirmationModalProps) => {
  const { mutate: changeBenefitEnrollmentStatus } = useMonolithMutation({
    method: 'benefitEnrollmentsControllerPatch',
  });

  return (
    <SimpleActionModal
      title="Undo enrollment confirmation?"
      titleIcon={
        <IconRepeat
          size={32}
          style={{
            position: 'relative',
            borderRadius: '4px',
            padding: '6px',
            backgroundColor: '#C5C9F0',
          }}
        />
      }
      description="The enrollment will be set back to application sent status"
      actionName="Change status"
      isOpen={isOpen}
      onClose={closeModal}
      buttonBackgroundColor={brand.zorroIris900}
      buttonTextColor={brand.zorroWhite}
      onSubmit={async () => {
        await changeBenefitEnrollmentStatus([
          benefitEnrollmentId,
          { status: BenefitStatus.APPLICATION_SENT },
        ]);
        closeModal();
      }}
    />
  );
};
