import { UploadedGenericFile } from '@zorro/shared/assets';
import { singleLineTruncatedTextWithEllipsis } from '@zorro/shared/formatters';
import { Group, Text } from '@zorro/zorro-ui-design';

type Props = { fileName: string };

export const FileUploadDisplay = ({ fileName }: Props) => {
  const format = `${fileName.split('.').pop()}`;
  const name = fileName.replace(`.${format}`, '');

  return (
    <Group
      display="flex"
      wrap="nowrap"
      w="100%"
      style={{ overflow: 'hidden', marginRight: '10px' }}
    >
      <UploadedGenericFile />
      <Group gap={0} wrap="nowrap" w="100%" style={{ overflow: 'hidden' }}>
        <Text style={singleLineTruncatedTextWithEllipsis}>{name}.</Text>
        <Text>{format}</Text>
      </Group>
    </Group>
  );
};
