/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Roles {
  EMPLOYER_ADMIN = 'EmployerAdmin',
  OMNIPOTENT_ADMIN = 'OmnipotentAdmin',
  OPERATOR = 'Operator',
  EMPLOYEE = 'Employee',
  AGENT = 'Agent',
  ACCOUNT_SUPERVISOR = 'AccountSupervisor',
  AGENCY_ADMIN = 'AgencyAdmin',
}
