/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum PaymentStatus {
  TRUE = 'TRUE',
  TRUE_BY_EMPLOYEE = 'TRUE_BY_EMPLOYEE',
  FALSE = 'FALSE',
  FALSE_BY_EMPLOYEE = 'FALSE_BY_EMPLOYEE',
  UNKOWN_BY_EMPLOYEE = 'UNKOWN_BY_EMPLOYEE',
}
