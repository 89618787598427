import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import {
  CitizenshipStatus,
  EmploymentType,
  Gender,
  WageType,
} from '@zorro/clients';
import { DateUtilInstance } from '@zorro/shared/formatters';
import {
  insuredMaxDateOfBirth,
  insuredMinDateOfBirth,
  validateOnlyNumbers,
  validationMessages,
} from '@zorro/shared/utils';
import { YesNo } from '@zorro/types';
import {
  ActionIcon,
  AddressInput,
  Checkbox,
  Collapse,
  DateInput,
  FormErrorMessage,
  Grid,
  Group,
  Icon,
  InputWrapper,
  PasswordInput,
  PhoneInput,
  Space,
  TabButtonGroup,
  Tabs,
  Text,
  TextInput,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FormState,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import * as yup from 'yup';

import { CitizenshipStatusInput } from '../CitizenshipStatusInput';
import { EmployeeClassInput } from '../EmployeeClassInput/EmployeeClassInput';
import { EmploymentTypeInput } from '../EmploymentTypeInput';
import { WageTypeInput } from '../WageTypeInput';
import { CheckmarkBoxWrapper } from './CheckmarkBoxWrapper';
import { getInsuredBaseSchema } from './InsuredFormUtils';

export const getEmployeeFormSchema = (isFinalizationMode: boolean) =>
  getInsuredBaseSchema(isFinalizationMode).concat(
    yup.object({
      email: yup
        .string()
        .email(validationMessages.emailRequired)
        .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/u, {
          message: validationMessages.emailRequired,
        })
        .required(validationMessages.emailRequired),
      isMailingAddressSameAsResidentialAddress: yup
        .boolean()
        .required(validationMessages.isMailingAddressSameAsResidentialAddress),
      class: yup.string().required(),
      employmentType: yup.mixed<EmploymentType>().nullable(),
      wageType: yup.mixed<WageType>().nullable(),
      ...(isFinalizationMode
        ? {
            phone: yup
              .string()
              .required(validationMessages.phoneNumberRequired)
              // we expect a 10-digit US phone number entered by the user and react-phone-number-input automatically adds +1 prefix to it
              .matches(/^\+?\d{11}$/u, validationMessages.phoneNumberValid),
            personalEmail: yup
              .string()
              .email(validationMessages.personalEmailRequired)
              .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/u, {
                message: validationMessages.personalEmailRequired,
              })
              .required(validationMessages.personalEmailRequired),
            mailingAddress: yup
              .string()
              .when('isMailingAddressSameAsResidentialAddress', {
                is: false,
                then: () =>
                  yup
                    .string()
                    .required(validationMessages.mailingAddressRequired),
                otherwise: () => yup.string().notRequired(),
              }),
            citizenshipStatus: yup
              .mixed<CitizenshipStatus>()
              .oneOf(
                Object.values(CitizenshipStatus),
                validationMessages.citizenshipStatus
              )
              .typeError(validationMessages.citizenshipStatus)
              .required(validationMessages.citizenshipStatus),
          }
        : {
            phone: yup
              .string()
              // we expect a 10-digit US phone number entered by the user and react-phone-number-input automatically adds +1 prefix to it
              .matches(/^\+?\d{11}$/u, {
                message: validationMessages.phoneNumberValid,
                excludeEmptyString: true,
              })
              .nullable(),
            personalEmail: yup
              .string()
              .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/u, {
                message: validationMessages.personalEmailRequired,
                excludeEmptyString: true,
              })
              .optional(),
            mailingAddress: yup.string().optional(),
            citizenshipStatus: yup
              .mixed<CitizenshipStatus>()
              .oneOf(
                Object.values(CitizenshipStatus),
                validationMessages.citizenshipStatus
              )
              .typeError(validationMessages.citizenshipStatus)
              .nullable(),
          }),
    })
  );

export type InsuredEmployeeFormFields = yup.InferType<
  ReturnType<typeof getEmployeeFormSchema>
>;

type Props = {
  control: Control<InsuredEmployeeFormFields>;
  formState: FormState<InsuredEmployeeFormFields>;
  watch: UseFormWatch<InsuredEmployeeFormFields>;
  trigger: UseFormTrigger<InsuredEmployeeFormFields>;
  targetEnrollmentDate: DateUtilInstance;
  isFinalizationMode: boolean;
  isReadonlyMode?: boolean;
  employerId: string;
};

export const EmployeeFormInputs = ({
  control,
  watch,
  formState,
  trigger,
  targetEnrollmentDate,
  employerId,
  isReadonlyMode,
  isFinalizationMode,
}: Props) => {
  const { isValid, errors } = formState;
  const [isOpen, setIsOpen] = useState<boolean>(!isReadonlyMode);

  const isFilledOut =
    isReadonlyMode || (isValid && Object.keys(errors).length === 0);
  const fullName = `${watch('firstName') || ''} ${
    watch('lastName') || ''
  }`.trim();
  const isFemale = watch('gender') === Gender.FEMALE;
  const isMailingAddressSameAsResidentialAddress = watch(
    'isMailingAddressSameAsResidentialAddress'
  );

  const handleArrowClick = async () => {
    if (isReadonlyMode) {
      setIsOpen((prev) => !prev);
    } else {
      const result = await trigger();
      if (!isOpen || result) {
        setIsOpen((prev) => !prev);
      }
    }
  };

  useEffect(() => {
    if (!isValid && !isReadonlyMode) {
      setIsOpen(true);
    }
  }, [isValid, isReadonlyMode, setIsOpen]);

  return (
    <CheckmarkBoxWrapper
      hasErrors={Object.keys(errors).length > 0 && !isReadonlyMode}
      isFilledOut={isFilledOut}
      isOpen={isOpen}
      w="100%"
    >
      <Group justify="space-between">
        <Text fw="600" className="fs-mask">
          {!fullName || isOpen ? 'Employee' : `Employee (${fullName})`}
        </Text>
        <ActionIcon
          variant="transparent"
          onClick={handleArrowClick}
          onKeyDown={(event) => {
            if (event.code === 'Space' || event.code === 'Enter') {
              handleArrowClick();
            }
          }}
        >
          {isOpen ? (
            <Icon icon={IconChevronUp} aria-label="Collapse employee form" />
          ) : (
            <Icon icon={IconChevronDown} aria-label="Expand employee form" />
          )}
        </ActionIcon>
      </Group>

      <Collapse in={isOpen}>
        <Space h="xl" />
        <Grid>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="First name"
                  placeholder="First name"
                  readOnly={isReadonlyMode}
                  required
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="firstName" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  label="Last name"
                  placeholder="Last name"
                  readOnly={isReadonlyMode}
                  required
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="lastName" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="dateOfBirth"
              render={({ field: { ...rest } }) => {
                return (
                  <DateInput
                    {...rest}
                    label="Date of birth"
                    readOnly={isReadonlyMode}
                    minDate={insuredMinDateOfBirth(false, targetEnrollmentDate)}
                    maxDate={insuredMaxDateOfBirth(false, targetEnrollmentDate)}
                    required
                  />
                );
              }}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="dateOfBirth" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="phone"
              render={({ field: { ...rest } }) => (
                <PhoneInput
                  {...rest}
                  control={control}
                  readOnly={isReadonlyMode}
                  required={isFinalizationMode}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="phone" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="gender"
              render={({ field: { ...rest } }) => (
                <InputWrapper label="Gender" required={isFinalizationMode}>
                  <TabButtonGroup {...rest}>
                    <Tabs.List grow>
                      <Tabs.Tab disabled={isReadonlyMode} value={Gender.MALE}>
                        Male
                      </Tabs.Tab>
                      <Tabs.Tab disabled={isReadonlyMode} value={Gender.FEMALE}>
                        Female
                      </Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="gender" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="isSmoker"
              render={({ field: { ...rest } }) => (
                <InputWrapper
                  label="Tobacco user"
                  required={isFinalizationMode}
                >
                  <TabButtonGroup {...rest}>
                    <Tabs.List grow>
                      <Tabs.Tab disabled={isReadonlyMode} value={YesNo.YES}>
                        Yes
                      </Tabs.Tab>
                      <Tabs.Tab disabled={isReadonlyMode} value={YesNo.NO}>
                        No
                      </Tabs.Tab>
                    </Tabs.List>
                  </TabButtonGroup>
                </InputWrapper>
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="isSmoker" />
            )}
          </Grid.Col>

          {isFemale && (
            <>
              <Grid.Col span={{ sm: 6 }}>
                <Controller
                  control={control}
                  name="isPregnant"
                  render={({ field: { ...rest } }) => (
                    <InputWrapper
                      label="Pregnant"
                      required={isFinalizationMode}
                    >
                      <TabButtonGroup {...rest}>
                        <Tabs.List grow>
                          <Tabs.Tab disabled={isReadonlyMode} value={YesNo.YES}>
                            Yes
                          </Tabs.Tab>
                          <Tabs.Tab disabled={isReadonlyMode} value={YesNo.NO}>
                            No
                          </Tabs.Tab>
                        </Tabs.List>
                      </TabButtonGroup>
                    </InputWrapper>
                  )}
                />

                {!isReadonlyMode && (
                  <FormErrorMessage errors={errors} fieldName="isPregnant" />
                )}
              </Grid.Col>
              <Grid.Col span={{ sm: 6 }} />
            </>
          )}

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="email"
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  disabled
                  label="Company email"
                  placeholder="Email"
                  required
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="email" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="personalEmail"
              render={({ field: { ...rest } }) => (
                <TextInput
                  {...rest}
                  readOnly={isReadonlyMode}
                  required={isFinalizationMode}
                  value={rest.value ?? undefined}
                  label="Personal email"
                  placeholder="Email"
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="personalEmail" />
            )}
          </Grid.Col>

          <Grid.Col>
            <Controller
              control={control}
              name="residentialAddress"
              render={({ field: { value, onBlur, onChange, ...rest } }) => (
                <AddressInput
                  {...rest}
                  onBlur={() => {
                    onChange(value);
                    onBlur();
                  }}
                  readOnly={isReadonlyMode}
                  required={isFinalizationMode}
                  notifySubscribers={(newAddress) => onChange(newAddress)}
                  onChange={onChange}
                  label="Residential address"
                  value={value || ''}
                  placeholder="Type residential address"
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="residentialAddress" />
          </Grid.Col>

          <Grid.Col>
            <Controller
              control={control}
              name="isMailingAddressSameAsResidentialAddress"
              render={({ field: { value, ...rest } }) => (
                <Checkbox
                  {...rest}
                  isChecked={value}
                  disabled={isReadonlyMode}
                  label="Mailing address is the same as residential address"
                  shouldHaveBorder={false}
                  size="sm"
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage
                errors={errors}
                fieldName="isMailingAddressSameAsResidentialAddress"
              />
            )}
          </Grid.Col>

          {!isMailingAddressSameAsResidentialAddress && (
            <Grid.Col>
              <Controller
                control={control}
                name="mailingAddress"
                render={({ field: { onChange, ...rest } }) => (
                  <AddressInput
                    {...rest}
                    notifySubscribers={(address: string) => onChange(address)}
                    onChange={onChange}
                    readOnly={isReadonlyMode}
                    required={isFinalizationMode}
                    placeholder="Type mailing address"
                    label="Mailing address"
                    size="md"
                  />
                )}
              />

              {!isReadonlyMode && (
                <FormErrorMessage errors={errors} fieldName="mailingAddress" />
              )}
            </Grid.Col>
          )}

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              control={control}
              name="ssn"
              render={({ field: { value, ...rest } }) => (
                <PasswordInput
                  {...rest}
                  label="SSN"
                  placeholder="XXXXXXXXX"
                  readOnly={isReadonlyMode}
                  value={value || ''}
                  maxLength={9}
                  onKeyDown={validateOnlyNumbers}
                  required={isFinalizationMode}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="ssn" />
            )}
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name="citizenshipStatus"
              control={control}
              render={({ field }) => (
                <CitizenshipStatusInput
                  {...field}
                  selectProps={{
                    readOnly: isReadonlyMode,
                    required: isFinalizationMode,
                  }}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="citizenshipStatus" />
            )}
          </Grid.Col>

          <Grid.Col mt="lg">
            <Text fw={600}>Employment info</Text>
          </Grid.Col>

          <Grid.Col span={{ sm: 6 }}>
            <EmployeeClassInput
              control={control}
              name="class"
              selectProps={{ readOnly: isReadonlyMode }}
              employerId={employerId}
            />
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name="employmentType"
              control={control}
              render={({ field }) => (
                <EmploymentTypeInput
                  {...field}
                  selectProps={{ readOnly: isReadonlyMode }}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="employmentType" />
            )}
          </Grid.Col>
          <Grid.Col span={{ sm: 6 }}>
            <Controller
              name="wageType"
              control={control}
              render={({ field }) => (
                <WageTypeInput
                  {...field}
                  selectProps={{ readOnly: isReadonlyMode }}
                />
              )}
            />

            {!isReadonlyMode && (
              <FormErrorMessage errors={errors} fieldName="wageType" />
            )}
          </Grid.Col>
        </Grid>
      </Collapse>
    </CheckmarkBoxWrapper>
  );
};
