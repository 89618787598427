import { WomanTypingOnLaptop } from '@zorro/shared/assets';
import { APP_SHELL_PADDING } from '@zorro/types';
import { Button, Container, Space, Stack } from '@zorro/zorro-ui-design';
import Link from 'next/link';

import { StepHeading } from '../StepHeading';

export const PageNotFound = () => {
  return (
    <Container mt={80 - APP_SHELL_PADDING}>
      <StepHeading title="Oh no!" subtitle="Page not found" />

      <Space h="xl" mb="xl" />

      <Stack align="center">
        <WomanTypingOnLaptop />

        <Link href="/" passHref legacyBehavior>
          <Button maw={222} data-testid="back-to-home-button">
            Home page
          </Button>
        </Link>
      </Stack>
    </Container>
  );
};
