export const ADDRESS_NOT_SUPPORTED_ERROR_MESSAGE =
  'The provided address is not currently supported';

export const getInvalidAddressErrorMesage = (
  address: string,
  reason?: string
) => {
  return `Could not validate '${address}': ${reason ?? 'unexpected error'}`;
};

// Agency errors messages

export const getAgencyNotFoundErrorMessage = (id: string) => {
  return `Agency ${id} not found`;
};

export const getAgencyCannotBeDeletedErrorMessage = (name: string) => {
  return `Agency ${name} could not be deleted because it has connected employers`;
};

export const getAgencyTypeCannotBeChangedErrorMessage = (name: string) => {
  return `Agency type could not be changed for ${name} because it has connected employers`;
};

export const getAgentCannotEditErrorMessage = (propertyName: string) => {
  return `An agent cannot edit an agency's ${propertyName}`;
};
// Agent errors messages

export const getUserExistsError = (email: string) => {
  return `A user with email ${email} already exists`;
};

export const getAgentNotFoundError = (id: string) => {
  return `Agent ${id} not found`;
};

export const getAgentCannotBeDeletedErrorMessage = (
  firstName: string,
  lastName: string,
  connectedEmployers: string[]
) => {
  return `Agent ${firstName} ${lastName} could not be deleted because it is the producer agent for: ${connectedEmployers.join(
    ', '
  )}`;
};

// Employee errors messages

export const getEmployeeNotFoundErrorMessage = (id: string) => {
  return `Employee with id ${id} not found`;
};

export const getEmployeeAlreadyExistsErrorMessage = (email: string) => {
  return `Employee with email: ${email} already exists`;
};

export const getInvalidPersonalDetailErrorMessage = (
  personalDetailKey: string
) => {
  return `Invalid personal detail: ${personalDetailKey}`;
};

// Employer errors messages

export const getEmployerNotFoundErrorMessage = (employerId: string) => {
  return `Employer with id ${employerId} not found`;
};

export const getEmployerHasEmployeesErrorMessage = (employerId: string) => {
  return `Employer ${employerId} should have no employees prior to deletion`;
};
export const getAgenciesCannotBeAssignedErrorMessage = (
  performingAgencyName: string
) => {
  return `One or more of the agencies provided cannot be assigned by ${performingAgencyName}`;
};

export const COULD_NOT_SEND_INVITATION_EMAIL_ERROR_MESSAGE =
  'Could not send invitation email';

export const getInvalidMedicalPlanFilterErrorMessage = (
  filterExpression: string,
  message: string
) => {
  return `Medical plan filter "${filterExpression}" is invalid: ${message}`;
};

export const EMPLOYER_WITH_THE_SAME_TAX_ID_ALREADY_EXISTS_ERROR_MESSAGE =
  'Employer with the same taxId already exists';

// Open enrollment period errors messages

export const getNoOpenEnrollmentPeriodsFoundErrorMessage = (
  employerId: string
) => {
  return `Open Enrollment Period for employer ${employerId} not found`;
};

export const getOpenEnrollmentPeriodNotFoundErrorMessage = (
  openEnrollmentPeriodId: string
) => {
  return `Open Enrollment Period ${openEnrollmentPeriodId} not found`;
};

export const OVERLAPPING_PERIOD_ERROR_MESSAGE =
  'Open enrollment periods cannot overlap';

export const ENROLLMENT_ERRORS_MESSAGES = {
  EFFECTIVE_PERIOD_END_BEFORE_START:
    'The effective period must end after it begins',
  ONBOARDING_PERIOD_END_BEFORE_START:
    'The onboarding period must end after it begins',
};

export const EXISTING_BENEFITS_ERROR_MESSAGE =
  'Open enrollment periods cannot have existing benefits connected to it';

// User errors messages

export const getRoleNotFoundErrorMessage = (
  newRole: string,
  roleToIdMap: Record<string, string>
) => {
  return `Role with key ${newRole} not found in Frontegg roles: ${JSON.stringify(
    Object.keys(roleToIdMap)
  )}`;
};

export const getUserExistsInDifferentTenantErrorMessage = (email: string) => {
  return `User with email ${email} already exists in a different tenant`;
};

export const getUserSetupIncompleteErrorMessage = (email: string) => {
  return `User with email ${email} has not completed the setup and has no name yet`;
};

export const NO_REQUEST_CONTEXTE_RROR_MESSAGE =
  'No http request context found, are you running a cron job? use m2mClient instead';

export const getNoZipCodeFoundErrorMessage = (employeeId: string) => {
  return `No zip code found for employee ${employeeId}`;
};

// insurace errors messages

export const getQuoteNotFoundErrorMessage = (quoteId: string) => {
  return `Quote not found: ${quoteId}`;
};

export const getQuotePreferenceNotFoundErrorMessage = (quoteId: string) => {
  return `Quote preferences not found: ${quoteId}`;
};

export const getPlanPricingZipNotFoundErrorMessage = (
  zipCode: string,
  planId: string,
  planYear: number
) => {
  return `Plan pricing not found for zip code: ${zipCode}, plan id: ${planId} and plan year: ${planYear}`;
};

export const getRatingAreaNotFoundErrorMessage = (
  fipsCode: string,
  zipCode: string
) => {
  return `Rating area not found for fips code: ${fipsCode} and zip code: ${zipCode}`;
};

export const getUnexpectedQuoteProcessingErrorMessage = () => {
  return 'Could not generate a valid quote. Please retry later or contact support if the issue persists';
};

export const getInsufficientCensusDataErrorMessage = (quoteId: string) => {
  return `The census data is insufficient to create a quote for: ${quoteId}`;
};

export const getCensusPlansDoNotMatchErrorMessage = (planNames: string[]) => {
  return `${planNames.join(
    ', '
  )} are not listed in any of the census employees`;
};

// reports errors messages

export const getInvalidBenefitsErrorMessage = (onboardingPeriodId: string) => {
  return `More than one benefit of the same type was found for onboarding period: ${onboardingPeriodId}`;
};

// employee reports errors messages

export const getEmployeeCountNotFoundErrorMessage = (employerId: string) => {
  return `Could not find the total employee count for employer with id ${employerId}`;
};

// errors messages

export const getEntityNotFoundErrorMessage = (id: string) => {
  return `Entity ${id} not found`;
};

export const getOnboardingPeriodNotFoundErrorMessage = (id: string) => {
  return `Onboarding Period ${id} not found`;
};

// Utility function to get Active Onboarding Period Already Exists Error message
export const getActiveOnboardingPeriodAlreadyExistsErrorMessage = (
  employeeId: string
) => {
  return `There is already an active onboarding period for employee ${employeeId}`;
};

// Utility function to get Benefit Not Found Error message
export const getBenefitNotFoundErrorMessage = (benefitId: string) => {
  return `Benefit ${benefitId} not found`;
};

export const OnboardingPeriodWithStartDateAlreadyExistsErrorMessage =
  'An onboarding period with the same start date already exists for employee';

// insurance errors messages

export const INSURED_UNDERAGE_ERROR =
  'Insured age must be over 18 at enrollment target date';

export const DEPENDANT_OVERAGE_ERROR =
  'Dependent age must be less than 26 at enrollment target date';

export const ENROLLMENT_ALREADY_EXISTS_ERROR =
  'The employee already has another enrollment with the same coverage start date';
export const ELIGIBILITY_START_LATER_THAN_COVERAGE_ERROR =
  "The employee's eligibility start date is later than the open enrollment coverage start date";
export const ELIGIBILITY_STARTS_AFTER_START_OF_EXPECTED_COVERAGE_ERROR =
  'The expected start of coverage is before the employee becomes eligible for benefits';
export const ELIGIBILITY_END_EARLIER_THAN_COVERAGE_ERROR =
  "The employee's eligibility ends before the expected start of coverage";
export const CONFLICTING_ENROLLMENTS_ERROR =
  'The employee already has an open enrollment for this year';
export const ELECTION_WINDOW_CHANGE_AFTER_SUBMISSION_ERROR =
  "Can't change election window and expected start of coverage after election submission. Please reset election first";
export const COVERAGE_START_AFTER_WINDOW_ERROR =
  'The start date of coverage must be after the election window ends';
export const OPEN_ENROLLMENT_INVALID_START_DATE_ERROR =
  'Coverage must begin on the first day of the plan year for open enrollment';
export const START_OF_COVERAGE_NOT_WITHIN_COVERAGE_PERIOD_ERROR =
  'The expected start of coverage must fall within the plan year’s coverage period';

export const getInsuredNotFoundErrorMessage = (periodId: string) => {
  return `Insured people not found for onboarding period ${periodId}`;
};

// contribution models errors messages

export const getAllowanceModelMismatchErrorMessage = (
  employeeId: string,
  employeeAge: number,
  familyUnit: string,
  employeeClass: string
) => {
  return `Allowance model does not match properties of employee ${employeeId}: Age: ${employeeAge}, Family unit: ${familyUnit}, Class: ${employeeClass}`;
};

// major medical errors messages

export const FAMILY_UNIT_NOT_ALLOWED_ERROR_MESSAGE =
  'Family unit update not allowed';

export const SELF_PAY_AMOUNT_TOO_LARGE_ERROR_MESSAGE =
  'Self pay amount cannot be larger than the premium amount';

export const MEDICAL_PLAN_NOT_FOUND_ERROR_MESSAGE = 'Medical plan not found';

export const getMajorMedicalPlanNotSelfReportedErrorMessage = (
  benefitId: string
) => {
  return `Major medical plan ${benefitId} is not self-reported`;
};

export const getPlanSearchDataMissingErrorMessage = (missingField: string) => {
  return `${missingField} is required for plan search`;
};

export const MEDICAL_BENEFIT_SUBMISSION_ERROR_MESSAGE =
  'could not submit a plan for the major medical benefit';

export const EFFECTIVE_OPEN_ENROLLMENT_PERIOD_NOT_FOUND_ERROR_MESSAGE =
  "Couldn't find an effective open enrollment period";

// benefit enrollment errors messages

export const BENEFIT_ENROLLMENT_ERRORS = {
  EFFECTIVE_PERIOD_AFTER_ELIGIBILITY:
    'Effective period can’t start after the end of eligibility (dismissed employee)',
  ENROLLMENT_NOT_EXIST: 'Benefit enrollment does not exist',
  NO_EFFECTIVE_DATES_SPECIFIED: 'No effective dates were specified',
  EXCEED_BOUNDS_OPEN_ENROLLMENT:
    'Unable to exceed bounds of open enrollment period effective dates',
  EFFECTIVE_FROM_DATE_MISMATCH:
    'Effective from date must coincide with employee eligibility from date',
  EFFECTIVE_UNTIL_DATE_MISMATCH:
    'Effective until date must coincide with employee eligibility until date',
  EFFECTIVE_DATES_OVERLAP: 'Effective dates overlap with a later enrollment',
};

// qualifying life events errors messages

export const LIFE_EVENT_DATE_RANGE_ERROR_MESSAGE =
  'Qualifying Life Event occurredOn date is out of date range';

export const LIFE_EVENT_OTHER_REASON_ERROR_MESSAGE =
  'Qualifying Life Event other type must have other reason';

// save quote errors messages

export const ALLOWANCE_MODEL_IN_USE_ERROR_MESSAGE =
  'Quote saved. The allowance model is already in use and cannot be applied.';

// allowance errors messages
export const getInvalidFamilyUnitErrorMessage = (familyUnit: string) => {
  return `[scenarios] Invalid family unit: ${familyUnit}`;
};

export const getNoAllowanceFoundErrorMessage = (employeeId: string) => {
  return `No allowance found for employee: ${employeeId}`;
};

// sheet data errors messages

export const getInvalidDataAtRowWithPlanDataErrorMessage = (index: number) => {
  return `[plan-data] Invalid data at row ${index}`;
};

export const getInvalidDataAtRowWithDetailsErrorMessage = (
  index: number,
  plan: {
    id: string;
    carrier: string;
    type: string;
  }
) => {
  return `[plan-diversity] Invalid data at row ${index}: ${JSON.stringify(
    plan
  )}`;
};

export const getInvalidChildCountErrorMessage = (employeeId: string) => {
  return `Invalid child count for employee ${employeeId}`;
};

export const getScenariosInvalidDataAtRowErrorMessage = (index: number) => {
  return `[scenarios] Invalid data at row ${index}`;
};

export const TOKEN_CLIENT_NOT_INITIALIZED_ERROR_MESSAGE =
  'Token client not initialized';

/* ========== showErrorNotification Murrieta ========== */

export const NO_BENEFIT_ENROLLMENTS_FOUND_ERROR_MESSAGE =
  'No benefit enrollments found';

export const getFailedEmployeeInfoUpdateErrorMessage = (error: string) => {
  return `Failed to update employee info: ${error}`;
};

export const NO_FILE_SELECTED_ERROR_MESSAGE = 'No file selected';

export const NO_DATA_FOUND_IN_FILE_ERROR_MESSAGE = 'No data found in the file';

export const EFFECTIVE_DATE_REQUIRED_ERROR_MESSAGE = 'Effective date required';

export const NO_DATA_FOUND_IN_THE_FILE_ERROR_MESSAGE =
  'No data found in the file';

export const UPLOADING_EMPLOYER_LOGO_FAILED_ERROR_MESSAGE =
  'Uploading employer logo failed.';

export const BENEFIT_UPDATE_ERROR_ENROLLMENT_ID_MISSING =
  "Benefit can't be updated. enrollment id is missing";

export const BENEFIT_CANNOT_BE_RESET_ERROR_MESSAGE =
  'Benefit reset successfully!';

export const NO_ONBOARDING_PERIOD_ERROR_MESSAGE =
  'No onboarding period available.';

export const getFileFormatErrorMessage = (message: string) => {
  return `Something went wrong, possibly the file is not of the right format: \n${message}`;
};
