import { Pill } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { MissingDetailsIcon } from '@zorro/shared/assets';
import { useDynamicMurrietaRouter } from '@zorro/shared/utils';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  Icon,
  Stack,
  StepperCheckmarkIcon,
  Text,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactElement, ReactNode } from 'react';

import {
  AddEmployerStepType,
  getAddEmployerStepConfig,
} from '../AddEmployerStep/AddEmployerUtils';

export type ReviewItemProps =
  | {
      name: string;
      value?: ReactNode | string | null;
      icon?: ReactElement;
      isRequired?: boolean;
      span?: number;
    }
  | {
      name: string;
      isSeparator: true;
    }
  | {
      name: string;
      title: string;
    };

const ReviewItem = (props: ReviewItemProps) => {
  if ('isSeparator' in props) {
    return (
      <Divider
        style={(theme) => ({ borderColor: theme.colors.zorroGray[3] })}
        w="100%"
      />
    );
  }

  if ('title' in props) {
    return (
      <Grid.Col pl="2rem" pr="0.5rem">
        <Stack gap="xs">
          <Group gap="0.25rem" wrap="nowrap">
            {/* eslint-disable-next-line react/destructuring-assignment  */}
            <Text fw={600}>{props.title}</Text>
          </Group>
        </Stack>
      </Grid.Col>
    );
  }

  const { name, value, icon, isRequired = false, span = 4 } = props;

  return (
    <Grid.Col span={span} pl="2rem" pr="0.5rem">
      <Stack gap="xs">
        <Group gap="0.25rem" wrap="nowrap">
          {icon}
          <Text c="zorroGray.4">
            {name} {isRequired ? '*' : ''}
          </Text>
        </Group>
        <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {value || '-'}
        </Text>
      </Stack>
    </Grid.Col>
  );
};

type Props = {
  employerId: string;
  title: string;
  step: AddEmployerStepType;
  items: ReviewItemProps[];
  isValid: boolean;
};

export const ReviewCard = ({
  employerId,
  title,
  step,
  items,
  isValid,
}: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { route, shouldShowMissingDetails } = getAddEmployerStepConfig(step);

  let icon: ReactElement | undefined = undefined;
  if (isValid) {
    icon = (
      <Box
        bg="zorroGreen.6"
        p="2px 3px 2px 2px"
        style={{ scale: 0.75, borderRadius: 32 }}
      >
        <StepperCheckmarkIcon />
      </Box>
    );
  } else if (shouldShowMissingDetails) {
    icon = <MissingDetailsIcon isRequired />;
  }

  return (
    <Card
      style={(theme) => ({ borderColor: theme.colors.zorroGray[3] })}
      radius="md"
      p={0}
    >
      <Group
        align="start"
        style={(theme) => ({
          borderBottom: `1px solid ${theme.colors.zorroGray[3]}`,
        })}
        wrap="nowrap"
        py="lg"
        px="xl"
        gap={8}
      >
        {icon}
        <Text fw={600}>{title}</Text>
        {!isValid && (
          <Pill
            bg={shouldShowMissingDetails ? 'zorroFire.3' : 'zorroGray.1'}
            radius={4}
            size="md"
          >
            Missing details
          </Pill>
        )}
        <Button
          variant="transparent"
          onClick={() => navigateToRoute(route, [employerId])}
          h="min-content"
          size="md"
          ml="auto"
          py={0}
        >
          <Group gap="xs" wrap="nowrap">
            <Icon icon={IconPencil} color={brand.zorroIris900} />
            Edit
          </Group>
        </Button>
      </Group>

      <Grid my="md" styles={{ inner: { gap: '0.75rem 0' } }}>
        {items.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <ReviewItem key={`${item.name}_${index}`} {...item} />
        ))}
      </Grid>
    </Card>
  );
};
