import { Accordion, AccordionItemProps, Box } from '@mantine/core';

import { CheckmarkCircle } from '../CheckmarkCircle';

interface IAccordionItemProps extends AccordionItemProps {
  isChecked?: boolean;
}

export const ZorroAccordionItem = ({
  value,
  isChecked,
  ...props
}: IAccordionItemProps) => {
  return (
    <Box pos="relative">
      {isChecked && <CheckmarkCircle />}

      <Accordion.Item
        value={value}
        style={(theme) => ({
          backgroundColor: theme.colors.zorroWhite,
        })}
        {...props}
      />
    </Box>
  );
};
