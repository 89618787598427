import { yupResolver } from '@hookform/resolvers/yup';
import { DatesRangeValue } from '@mantine/dates';
import { useQueryClient } from '@tanstack/react-query';
import { LeaveOfAbsenceDto } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  showErrorNotification,
  useDynamicRouter,
  useForm,
} from '@zorro/shared/utils';
import {
  Button,
  Center,
  DateInput,
  DateRangeInput,
  FormErrorMessage,
  Grid,
  Space,
  Stack,
  Switch,
  Table,
  Text,
} from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ModalControlProps } from '../../ModalControls';

export type UpdateEmployeeEligibilityProps = {
  id: string;
  firstName?: string;
  lastName?: string;
  eligibleFrom?: string | null;
  eligibleUntil?: string | null;
  leaveOfAbsence?: LeaveOfAbsenceDto | null;
};

export interface UpdateEligibilityFormFields {
  shouldSetStartDate: boolean;
  startDate?: Date;
  shouldSetEndDate: boolean;
  endDate?: Date;
  shouldSetLeaveOfAbsence: boolean;
  leaveOfAbsence?: DatesRangeValue;
}

const updateEligibilityFormSchema = yup.object().shape({
  shouldSetStartDate: yup.boolean(),
  startDate: yup.date().when('shouldSetStartDate', {
    is: true,
    then: () => yup.date().required(),
    otherwise: () => yup.date().notRequired(),
  }),
  shouldSetEndDate: yup.boolean(),
  endDate: yup.date().when('shouldSetEndDate', {
    is: true,
    then: () => yup.date().required(),
    otherwise: () => yup.date().notRequired(),
  }),
  shouldSetLeaveOfAbsence: yup.boolean(),
  leaveOfAbsence: yup.array().when('shouldSetLeaveOfAbsence', {
    is: true,
    then: () => yup.array().required(),
    otherwise: () => yup.array().notRequired(),
  }),
});

type Props = {
  employees: UpdateEmployeeEligibilityProps[];
  onSuccess: (
    updateEligibilityProps: Omit<UpdateEmployeeEligibilityProps, 'id'>
  ) => void;
  onSubmit?: () => void;
  onError?: () => void;
  modalControlProps: ModalControlProps;
};

export function UpdateEligibilityForm({
  employees,
  onSuccess,
  onSubmit,
  onError,
  modalControlProps,
}: Props) {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const startDate = employees.every(
    (employee) => employee.eligibleFrom === employees[0].eligibleFrom
  )
    ? employees[0].eligibleFrom
    : undefined;
  const endDate = employees.every(
    (employee) => employee.eligibleUntil === employees[0].eligibleUntil
  )
    ? employees[0].eligibleUntil
    : undefined;
  const leaveOfAbsence = employees.every(
    (employee) =>
      employee.leaveOfAbsence?.startDate ===
        employees[0].leaveOfAbsence?.startDate &&
      employee.leaveOfAbsence?.endDate === employees[0].leaveOfAbsence?.endDate
  )
    ? employees[0].leaveOfAbsence
    : undefined;
  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, isSubmitting, errors },
  } = useForm<UpdateEligibilityFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(updateEligibilityFormSchema),
    defaultValues: {
      shouldSetStartDate: Boolean(startDate),
      startDate: startDate ? parseDateISO(startDate).toDate() : undefined,
      shouldSetEndDate: Boolean(endDate),
      endDate: endDate ? parseDateISO(endDate).toDate() : undefined,
      shouldSetLeaveOfAbsence: Boolean(leaveOfAbsence),
      leaveOfAbsence: leaveOfAbsence
        ? [
            parseDateISO(leaveOfAbsence.startDate).toDate(),
            parseDateISO(leaveOfAbsence.endDate).toDate(),
          ]
        : undefined,
    },
  });
  const [resultErrors, setResultErrors] = useState<Record<string, string>[]>(
    []
  );

  const updateEligibility = async (formFields: UpdateEligibilityFormFields) => {
    try {
      onSubmit?.();
      const eligibilityData = {
        eligibleFrom:
          formFields.startDate && formFields.shouldSetStartDate
            ? formatDateISO(formFields.startDate)
            : null,
        eligibleUntil:
          formFields.endDate && formFields.shouldSetEndDate
            ? formatDateISO(formFields.endDate)
            : null,
        leaveOfAbsence:
          formFields.leaveOfAbsence && formFields.shouldSetLeaveOfAbsence
            ? {
                startDate: formatDateISO(formFields.leaveOfAbsence[0] || ''),
                endDate: formatDateISO(formFields.leaveOfAbsence[1] || ''),
              }
            : null,
      };

      startLoading();

      const employeeBatches = _chunk(employees, 5);

      let results: PromiseSettledResult<unknown>[] = [];
      for (const batch of employeeBatches) {
        const batchResults = await Promise.allSettled(
          batch.map((employee) =>
            callEndpoint({
              method: 'employeesControllerUpdateEligibility',
              params: [employee.id, eligibilityData],
            })
          )
        );

        results = [...results, ...batchResults];
      }

      await queryClient.invalidateQueries();
      reloadPage();
      stopLoading();

      const errorsFromPromises = getErrorsFromPromises(
        results,
        employees.map(({ firstName, lastName }) => ({
          value: `${firstName} ${lastName}`,
        })),
        'value'
      );
      if (errorsFromPromises.length > 0 && employees.length > 1) {
        setResultErrors(errorsFromPromises);
      }
      await handleBatchNotifications(
        results,
        {
          singular: 'special onboarding period',
        },
        'updated'
      );

      if (errorsFromPromises.length === 0) {
        onCloseModal();
      }
      onSuccess(eligibilityData);
    } catch {
      showErrorNotification();
      onError?.();
    }
  };
  const { onCloseModal } = modalControlProps;

  return (
    <form onSubmit={handleSubmit(updateEligibility)}>
      <Grid>
        <Grid.Col span={{ sm: 6 }}>
          <Stack>
            <Controller
              control={control}
              name="shouldSetStartDate"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  label="Set eligibility start date"
                  data-testid="eligibility-start-date-switch"
                />
              )}
            />
            <Controller
              control={control}
              name="startDate"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  label="Start date"
                  disabled={!watch('shouldSetStartDate')}
                  data-testid="employee-eligibility-start-date"
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="startDate" />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ sm: 6 }}>
          <Stack>
            <Controller
              control={control}
              name="shouldSetEndDate"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  label="Set eligibility end date"
                  data-testid="eligibility-end-date-switch"
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({
                field: { ref: _ref, ...rest },
                fieldState: { error },
              }) => (
                <DateInput
                  {...rest}
                  label="End date"
                  disabled={!watch('shouldSetEndDate')}
                  data-testid="employee-eligibility-end-date"
                />
              )}
            />

            <FormErrorMessage errors={errors} fieldName="endDate" />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ sm: 6 }}>
          <Stack>
            <Controller
              control={control}
              name="shouldSetLeaveOfAbsence"
              render={({ field: { value, ref: _ref, ...rest } }) => (
                <Switch
                  {...rest}
                  size="md"
                  checked={value}
                  label="Set a leave of absence"
                  data-testid="leave-of-absence-switch"
                />
              )}
            />
            <Controller
              control={control}
              name="leaveOfAbsence"
              render={({ field: { ref: _ref, ...rest } }) => (
                <DateRangeInput
                  {...rest}
                  label="Leave dates"
                  disabled={!watch('shouldSetLeaveOfAbsence')}
                  data-testid="employee-leave-of-absence"
                />
              )}
            />
          </Stack>
        </Grid.Col>
      </Grid>
      <Space h="xl" />
      <Center mt="md">
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          size="lg"
          ml="xl"
        >
          Save
        </Button>
      </Center>
      {resultErrors && resultErrors?.length > 0 && (
        <Grid gutter="xl">
          <Grid.Col>
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                {
                  accessor: 'value',
                  title: 'Employee name',
                },
                { accessor: 'error', title: 'Message' },
              ]}
              idAccessor="value"
              records={resultErrors}
            />
          </Grid.Col>
        </Grid>
      )}
    </form>
  );
}
