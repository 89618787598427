import { EmployerSignupStatus } from '@zorro/clients';

import {
  CompanyDetailsForm,
  useCompanyDetailsForm,
} from '../../CompanyDetailsForm';
import {
  CompanySetupTabType,
  CompanySetupTabWrapper,
} from '../CompanySetupTabWrapper';

const isFinalizationMode = true;
const shouldHideSignedAgreement = true;

type Props = {
  employerId: string;
};

export const CompanyDetailsSetupTab = ({ employerId }: Props) => {
  const { companyDetailsForm, onSubmit } = useCompanyDetailsForm({
    employerId,
    isFinalizationMode,
    shouldHideSignedAgreement,
    employerStatus: EmployerSignupStatus.ACTIVE,
  });
  const { formState } = companyDetailsForm;

  const handleSubmit = async () => Boolean(await onSubmit());

  return (
    <CompanySetupTabWrapper
      onCancel={() => companyDetailsForm.reset()}
      tab={CompanySetupTabType.COMPANY_DETAILS}
      employerId={employerId}
      onSubmit={handleSubmit}
      formState={formState}
    >
      <CompanyDetailsForm
        isFinalizationMode={isFinalizationMode}
        shouldHideSignedAgreement={shouldHideSignedAgreement}
        companyDetailsForm={companyDetailsForm}
      />
    </CompanySetupTabWrapper>
  );
};
