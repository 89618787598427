import { IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react';
import { Gender, OnboardingPeriodReportDto } from '@zorro/clients';
import {
  capitalizeEachFirstLetter,
  formatCurrencyEnUs,
  parseDateISOThenFormatEnLocaleShortYear,
} from '@zorro/shared/formatters';
import { convertToYesNo } from '@zorro/shared/utils';
import {
  ActionIcon,
  Divider,
  Flex,
  Group,
  Icon,
  Menu,
  PasswordInput,
  Stack,
  Text,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';
import { Control, FormState } from 'react-hook-form';

import { GridRowItem } from '../GridRowItem';
import { IconFromGender } from '../IconFromGender';
import { OutlineCard } from '../OutlineCard';
import { InsuredDeleteModal } from './InsuredDeleteModal';
import { InsuredDetailsEditDrawer } from './InsuredDetailsEditDrawer';
import { InsuredDetailsEditFormFields } from './insuredForm.types';

type InsuredGridRowProps = {
  label: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender | null;
  dateOfBirth: string;
  email?: string;
  personalEmail?: string | null;
  phone?: string | null;
  isSmoker?: boolean;
  isPregnant?: boolean;
  address?: string | null;
  county?: string | null;
  ssn?: string | null;
  salary?: number | null;
  employerId?: string;
  employeeClass?: string | null;
  isEditable?: boolean;
  isDeletable?: boolean;
  isFemale?: boolean;
  planEffectiveFrom?: Date;
  fieldName?: 'employee' | 'spouse' | `dependents.${number}`;
  formState?: FormState<InsuredDetailsEditFormFields>;
  control?: Control<InsuredDetailsEditFormFields>;
  saveChangedPersonalDetails?: () => void;
  deleteFamilyMember?: (
    insuredFieldName: 'employee' | 'spouse' | `dependents.${number}`
  ) => Promise<void>;
  hasOnboardingPeriod?: boolean;
  selectedOnboardingPeriod: OnboardingPeriodReportDto | null;
};

export const InsuredGridRow = ({
  label,
  firstName,
  lastName,
  dateOfBirth,
  ssn,
  gender,
  email,
  personalEmail,
  phone,
  isSmoker = false,
  address,
  county,
  isPregnant = false,
  salary,
  employerId,
  employeeClass,
  isEditable,
  isDeletable = true,
  isFemale,
  planEffectiveFrom,
  fieldName,
  formState,
  control,
  saveChangedPersonalDetails,
  deleteFamilyMember,
  hasOnboardingPeriod = false,
  selectedOnboardingPeriod = null,
  ...props
}: InsuredGridRowProps) => {
  const [isPersonalDetailsEditModalOpen, setIsPersonalDetailsEditModalOpen] =
    useState<boolean>(false);

  const [isInsuredDeleteModalOpen, setIsInsuredDeleteModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <OutlineCard h="100%" {...props}>
        <Stack gap="sm">
          <Flex justify="space-between">
            <Group>
              <IconFromGender label={label} />
              <Title
                order={2}
                size="h4"
                fw="600"
                c={brand.zorroCoal900}
                data-testid="insured-grid-row-label"
              >
                {label}
              </Title>
            </Group>

            {isEditable && (
              <Menu>
                <Menu.Target>
                  <ActionIcon variant="transparent">
                    <Icon
                      icon={IconDotsVertical}
                      aria-label="Edit button"
                      data-testid="insured-grid-row-edit-button"
                    />
                  </ActionIcon>
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => setIsPersonalDetailsEditModalOpen(true)}
                  >
                    <Group gap="xs">
                      <Icon icon={IconEdit} />

                      <Text size="sm">Edit</Text>
                    </Group>
                  </Menu.Item>
                  {isDeletable && (
                    <Menu.Item
                      onClick={() => {
                        setIsInsuredDeleteModalOpen(true);
                      }}
                    >
                      <Group gap="xs">
                        <Icon icon={IconTrash} />

                        <Text size="sm">Delete</Text>
                      </Group>
                    </Menu.Item>
                  )}
                </Menu.Dropdown>
              </Menu>
            )}
          </Flex>

          <Divider mx={-24} my="xs" />

          {firstName && lastName && (
            <GridRowItem
              label="Full name"
              value={`${firstName} ${lastName}`}
              data-testid="insured-grid-row-full-name"
            />
          )}

          <GridRowItem
            label="Gender"
            value={gender ? capitalizeEachFirstLetter(gender) : ''}
            data-testid="insured-grid-row-gender"
          />

          <GridRowItem
            label="Date of birth"
            value={parseDateISOThenFormatEnLocaleShortYear(dateOfBirth)}
            data-testid="insured-grid-row-date-of-birth"
          />

          {personalEmail && (
            <GridRowItem
              label="Personal email"
              value={personalEmail}
              data-testid="insured-grid-row-personal-email"
            />
          )}

          {phone && (
            <GridRowItem
              label="Phone number"
              value={phone}
              data-testid="insured-grid-row-phone"
            />
          )}

          <GridRowItem
            label="Smoker"
            value={convertToYesNo(isSmoker)}
            data-testid="insured-grid-row-isSmoker"
          />

          {gender === Gender.FEMALE && (
            <GridRowItem
              label="Pregnant"
              value={convertToYesNo(isPregnant)}
              data-testid="insured-grid-row-isPregnant"
            />
          )}

          {ssn && isEditable && (
            <PasswordInput
              label="SSN:"
              readOnly
              value={ssn}
              variant="unstyled"
              size="md"
              styles={(theme) => ({
                root: {
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: 200,
                  height: 25,
                },
                wrapper: {
                  width: '100%',
                },
                innerInput: {
                  fontSize: theme.fontSizes.md,
                  height: 'auto',
                  paddingLeft: 4,
                },
                label: {
                  fontWeight: 600,
                  fontSize: theme.fontSizes.md,
                  wordBreak: 'normal',
                },
                input: {
                  border: 'none',
                },
              })}
              data-testid="insured-grid-row-ssn-editable"
            />
          )}

          {!isEditable && (
            <GridRowItem
              label="SSN"
              value={convertToYesNo(!!ssn)}
              data-testid="insured-grid-row-ssn-non-editable"
            />
          )}

          <GridRowItem
            label="Address"
            value={address ?? ''}
            data-testid="insured-grid-row-address"
          />

          {county && (
            <GridRowItem
              label="County"
              value={county ?? ''}
              data-testid="insured-grid-row-county"
            />
          )}

          {salary && (
            <GridRowItem
              label="Salary"
              value={formatCurrencyEnUs(salary)}
              data-testid="insured-grid-row-salary"
            />
          )}

          {employeeClass && <GridRowItem label="Class" value={employeeClass} />}
        </Stack>
      </OutlineCard>
      <InsuredDetailsEditDrawer
        label={label.toLowerCase()}
        setIsModalOpen={setIsPersonalDetailsEditModalOpen}
        isFemale={isFemale!}
        fieldName={fieldName!}
        formState={formState!}
        control={control!}
        saveChangedPersonalDetails={saveChangedPersonalDetails!}
        planEffectiveFrom={planEffectiveFrom}
        employerId={employerId}
        isOpened={isEditable && isPersonalDetailsEditModalOpen}
        hasOnboardingPeriod={hasOnboardingPeriod}
        selectedOnboardingPeriod={selectedOnboardingPeriod}
      />
      {isDeletable && formState && isInsuredDeleteModalOpen && (
        <InsuredDeleteModal
          name={`${firstName} ${lastName}`}
          isModalOpen={isInsuredDeleteModalOpen}
          setIsModalOpen={setIsInsuredDeleteModalOpen}
          fieldName={fieldName!}
          formState={formState!}
          deleteFamilyMember={deleteFamilyMember!}
        />
      )}
    </>
  );
};
