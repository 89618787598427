import { IconInfoCircle } from '@tabler/icons-react';
import { EmployerDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  FormErrorMessage,
  Group,
  ISelectProps,
  Icon,
  Select,
  Text,
  Tooltip,
} from '@zorro/zorro-ui-design';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type EmployeeClassInputProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  employerId: EmployerDto['id'];
  selectProps?: ISelectProps;
};

export function EmployeeClassInput<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  employerId,
  selectProps,
  ...formField
}: EmployeeClassInputProps<TFieldValues, TName>) {
  const {
    field,
    formState: { errors },
  } = useController(formField);

  const { data: employeeClasses } = useMonolithQuery({
    method: 'allowanceModelControllerGetEmployeeClasses',
    params: [employerId],
  });

  const classes = employeeClasses?.classes || [];

  // TODO: figure out the best way to handle errors
  // @see https://myzorroworkspace.slack.com/archives/C03EZ1SDJBG/p1712564797793239
  return (
    <>
      <Select
        {...field}
        placeholder="Class"
        data={classes}
        label={
          <Tooltip
            label="Please ensure you select the correct class for the employee, taking into consideration their state of residence if applicable. If you can’t find the right class, please contact our support team for assistance."
            inline
          >
            <Group gap="0.125rem" display="inline-flex">
              <Text size="sm">Class*</Text>
              <Icon icon={IconInfoCircle} size="1rem" />
            </Group>
          </Tooltip>
        }
        {...selectProps}
      />

      <FormErrorMessage fieldName={formField.name} errors={errors} />
    </>
  );
}
