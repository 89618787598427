import { CsvFileIcon } from '@zorro/shared/assets';
import {
  formatCurrencyEnUs,
  formatDateEnLocale,
} from '@zorro/shared/formatters';
import { oepPaymentMethodLabelConfig } from '@zorro/types';
import { Box, Icon } from '@zorro/zorro-ui-design';

import {
  PlanYearSetupFormFields,
  exportAllowanceModelToCsv,
  renderNullableDateRange,
} from '../../../PlanYearSetupForm/PlanYearSetupUtils';
import { AddEmployerStepType } from '../../AddEmployerStep/AddEmployerUtils';
import { ReviewCard } from '../ReviewCard';

type Props = {
  isFinalizationMode: boolean;
  fields: PlanYearSetupFormFields;
  employerName: string;
  employerId: string;
  isValid: boolean;
};

export const PlanYearSetupCard = ({
  employerId,
  employerName,
  fields,
  isValid,
  isFinalizationMode,
}: Props) => {
  const {
    effectiveDates,
    oepDates,
    allowanceModel,
    totalPEPMFees,
    pepmBrokerCommission,
    monthlyPlatformFee,
    initialSetupFee,
    paymentMethod,
    initialDrawAmount,
    initialDrawDate,
    reserveAmount,
  } = fields;

  return (
    <ReviewCard
      isValid={isValid}
      title="Plan year setup"
      employerId={employerId}
      step={AddEmployerStepType.PLAN_YEAR_SETUP}
      items={[
        {
          name: 'Coverage period',
          value: renderNullableDateRange(effectiveDates),
          isRequired: true,
        },
        {
          name: 'Election window',
          value: renderNullableDateRange(oepDates),
          isRequired: true,
        },
        {
          name: 'Allowance model',
          value: allowanceModel && !(allowanceModel instanceof File) && (
            <Box
              onClick={() =>
                exportAllowanceModelToCsv(allowanceModel, employerName)
              }
              w="min-content"
              style={{ cursor: 'pointer' }}
            >
              <Icon icon={CsvFileIcon} />
            </Box>
          ),
          isRequired: isFinalizationMode,
        },
        {
          name: 'charges-separator',
          isSeparator: true,
        },
        {
          name: 'charges',
          title: 'Charges:',
        },
        {
          name: 'Total PEPM fees',
          value: totalPEPMFees && formatCurrencyEnUs(totalPEPMFees),
          isRequired: isFinalizationMode,
        },
        {
          name: 'PEPM broker commission',
          value:
            pepmBrokerCommission && formatCurrencyEnUs(pepmBrokerCommission),
          isRequired: isFinalizationMode,
        },
        {
          name: 'Platform fee',
          value: monthlyPlatformFee && formatCurrencyEnUs(monthlyPlatformFee),
          isRequired: isFinalizationMode,
        },
        {
          name: 'Initial setup fee',
          value: initialSetupFee && formatCurrencyEnUs(initialSetupFee),
          isRequired: isFinalizationMode,
        },
        {
          name: 'payments-separator',
          isSeparator: true,
        },
        {
          name: 'payments',
          title: 'Payments:',
        },
        {
          name: 'Payment method',
          value: paymentMethod && oepPaymentMethodLabelConfig[paymentMethod],
          isRequired: isFinalizationMode,
        },
        {
          name: 'Initial draw amount',
          value: initialDrawAmount && formatCurrencyEnUs(initialDrawAmount),
        },
        {
          name: 'Initial draw date',
          value: initialDrawDate && formatDateEnLocale(initialDrawDate),
        },
        {
          name: 'Reserve amount',
          value: reserveAmount && formatCurrencyEnUs(reserveAmount),
        },
      ]}
    />
  );
};
