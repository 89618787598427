import { Checkbox, CheckboxProps } from '@mantine/core';
import { forwardRef } from 'react';

import classNames from './Checkbox.module.scss';

export interface ICheckboxProps extends CheckboxProps {
  isChecked: boolean;
  shouldHaveBorder?: boolean;
}

export const ZorroCheckbox = forwardRef(
  (
    {
      value,
      isChecked,
      disabled,
      shouldHaveBorder = true,
      ...props
    }: ICheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <Checkbox
        ref={ref}
        value={value}
        checked={isChecked}
        disabled={disabled}
        classNames={classNames}
        color="zorroCoal.9"
        wrapperProps={{
          'data-with-border': shouldHaveBorder,
          'data-disabled': disabled,
          'data-checked': isChecked,
        }}
        {...props}
      />
    );
  }
);

ZorroCheckbox.displayName = 'ZorroCheckbox';
