import {
  ContactType,
  CreateEmployerContactDto,
  EmployerContactDto,
} from '@zorro/clients';
import {
  convertToYesNo,
  isDefined,
  validationMessages,
} from '@zorro/shared/utils';
import { ReactElement } from 'react';
import * as yup from 'yup';

import { ReviewItemProps } from '../AddEmployerFlow/ReviewForm/ReviewCard';

const getContactItemSchema = (isFinalizationMode: boolean) => {
  return yup.object({
    isDuplicateMode: yup.boolean().required(),
    ...(isFinalizationMode
      ? {
          firstName: yup.string().when('isDuplicateMode', {
            is: false,
            then: (schema) =>
              schema.required(validationMessages.firstNameRequired),
            otherwise: (schema) => schema.notRequired(),
          }),
          lastName: yup.string().when('isDuplicateMode', {
            is: false,
            then: (schema) =>
              schema.required(validationMessages.lastNameRequired),
            otherwise: (schema) => schema.notRequired(),
          }),
          phone: yup.string().when('isDuplicateMode', {
            is: false,
            then: (schema) =>
              schema
                .required(validationMessages.phoneNumberRequired)
                .matches(/^\+?\d{11}$/u, validationMessages.phoneNumberValid),
            otherwise: (schema) => schema.notRequired(),
          }),
          email: yup.string().when('isDuplicateMode', {
            is: false,
            then: (schema) =>
              schema
                .required(validationMessages.emailRequired)
                // eslint-disable-next-line require-unicode-regexp
                .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/, {
                  message: validationMessages.emailValid,
                }),
            otherwise: (schema) => schema.notRequired(),
          }),
          isAdmin: yup.bool().when('isDuplicateMode', {
            is: false,
            then: (schema) =>
              schema.required(validationMessages.isAdminRequired),
            otherwise: (schema) => schema.notRequired(),
          }),
        }
      : {
          firstName: yup.string(),
          lastName: yup.string(),
          phone: yup
            .string()
            .nullable()
            /**
             * INFO: we expect a 10-digit US phone number entered by the user
             * and react-phone-number-input automatically adds +1 prefix to it
             */
            .matches(/^\+?\d{11}$/u, {
              message: validationMessages.phoneNumberValid,
              excludeEmptyString: true,
            }),
          email: yup
            .string()
            // eslint-disable-next-line require-unicode-regexp
            .matches(/^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]{2,}$/, {
              message: validationMessages.emailValid,
              excludeEmptyString: true,
            }),
          isAdmin: yup.boolean(),
        }),
  });
};

export const getContactsSchemaObject = (
  isFinalizationMode: boolean,
  isDon: boolean
) => {
  return yup.object({
    legalContacts: yup
      .array()
      .of(getContactItemSchema(isFinalizationMode))
      .required(),
    hrContacts: yup
      .array()
      .of(getContactItemSchema(isFinalizationMode))
      .required(),
    financeContacts: yup
      .array()
      .of(getContactItemSchema(isFinalizationMode))
      .required(),
    onboardingContacts: yup
      .array()
      .of(getContactItemSchema(isFinalizationMode && isDon))
      .required(),
  });
};

export type ContactsFormFields = yup.InferType<
  ReturnType<typeof getContactsSchemaObject>
>;

type ContactItem = yup.InferType<ReturnType<typeof getContactItemSchema>>;
export const getDefaultContact = (): ContactItem => {
  return {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    isAdmin: false,
    isDuplicateMode: false,
  };
};

const contactTypeToContactFieldNameConfig: Record<
  ContactType,
  keyof ContactsFormFields
> = {
  [ContactType.ONBOARDING]: 'onboardingContacts',
  [ContactType.LEGAL]: 'legalContacts',
  [ContactType.HR]: 'hrContacts',
  [ContactType.FINANCE]: 'financeContacts',
};

export const mapContactItemToEmployerContactDto = (
  formFields: ContactsFormFields,
  type: ContactType,
  duplicateFieldName: keyof ContactsFormFields
): CreateEmployerContactDto[] => {
  const fieldName = contactTypeToContactFieldNameConfig[type];
  const contacts = formFields[fieldName][0].isDuplicateMode
    ? formFields[duplicateFieldName]
    : formFields[fieldName];

  return contacts.map(
    ({ firstName, lastName, email, phone, isAdmin = false }) => ({
      isAdmin: type === ContactType.LEGAL ? true : isAdmin,
      firstName: firstName || null,
      lastName: lastName || null,
      email: email || null,
      phone: phone || null,
      type,
    })
  );
};

export function mapEmployerContactDtoToContactItem(
  contacts: EmployerContactDto[]
): ContactItem[] {
  return contacts.map(({ firstName, lastName, phone, email, isAdmin }) => ({
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    phone: phone ?? '',
    email: email ?? '',
    isAdmin,
    isDuplicateMode: false,
  }));
}

export const mapContactItemsToReviewItems = (
  items: ContactItem[],
  contactTitle: string,
  icon: ReactElement,
  isFinalizationMode: boolean
): ReviewItemProps[] => {
  const reviewItems: ReviewItemProps[] = items.flatMap(
    ({ firstName, lastName, phone, email, isAdmin }) => [
      {
        name: `${contactTitle} contact full name`,
        value: firstName && lastName && `${firstName} ${lastName}`,
        isRequired: isFinalizationMode,
        icon,
      },
      {
        name: 'Phone number',
        value: phone,
        isRequired: isFinalizationMode,
      },
      {
        name: 'Email address',
        value: email,
        isRequired: isFinalizationMode,
      },
      {
        name: 'Is admin?',
        value: isDefined(isAdmin) && convertToYesNo(isAdmin),
        isRequired: isFinalizationMode,
        span: 12,
      },
    ]
  );
  reviewItems.push({
    name: 'separator',
    isSeparator: true,
  });

  return reviewItems;
};
