import { useQueryClient } from '@tanstack/react-query';
import { SubmissionType } from '@zorro/clients';
import { EditColorIcon } from '@zorro/shared/assets';
import {
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useDynamicRouter,
} from '@zorro/shared/utils';
import {
  Button,
  Center,
  Flex,
  Grid,
  Modal,
  Select,
  Space,
  Stack,
  Table,
  Text,
} from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useEffect, useState } from 'react';

import { useLoadingOverlay } from '../../LoadingOverlayContext';
import { ModalControlProps } from '../../ModalControls';

type SelectedEmployees = {
  onboardingPeriodId: string;
  firstName: string;
  lastName: string;
};

export type ChangeOnboardingStatusModalProps = {
  isActive: boolean;
  selectedEmployees: SelectedEmployees[];
  modalControlProps: ModalControlProps;
};

export const ChangeShoppingStatusModal = ({
  isActive: initialActiveStatus,
  selectedEmployees,
  modalControlProps,
}: ChangeOnboardingStatusModalProps) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [isActive, setIsActive] = useState<boolean>(initialActiveStatus);
  const { onCloseModal } = modalControlProps;
  const [resultErrors, setResultErrors] = useState<Record<string, string>[]>(
    []
  );

  async function changeOnboardingStatus() {
    try {
      startLoading();

      const employeeBatches = _chunk(selectedEmployees, 5);

      let results: PromiseSettledResult<unknown>[] = [];
      for (const batch of employeeBatches) {
        const batchResults = await Promise.allSettled(
          batch.map(({ onboardingPeriodId }) =>
            callEndpoint({
              method: 'onboardingPeriodsControllerUpdateByAdmin',
              params: [
                onboardingPeriodId,
                { isActive, submissionType: SubmissionType.BY_OPERATOR },
              ],
            })
          )
        );

        results = [...results, ...batchResults];
      }

      await queryClient.invalidateQueries();
      reloadPage();
      stopLoading();

      const errorsFromPromises = getErrorsFromPromises(
        results,
        selectedEmployees.map(({ firstName, lastName }) => ({
          value: `${firstName} ${lastName}`,
        })),
        'value'
      );

      await handleBatchNotifications(
        results,
        { singular: 'onboarding period' },
        'updated'
      );
      if (errorsFromPromises.length > 0 && selectedEmployees.length > 1) {
        setResultErrors(errorsFromPromises);
      }

      if (errorsFromPromises.length === 0) {
        onCloseModal();
      }
    } catch {
      /* empty */
    }
  }

  useEffect(() => {
    setIsActive(initialActiveStatus);
  }, [initialActiveStatus]);

  return (
    <Modal
      size="md"
      onClose={() => {
        onCloseModal();
        setResultErrors([]);
      }}
      styles={{
        body: {
          paddingRight: 24,
          paddingLeft: 24,
        },
      }}
      title={
        <Flex align="center">
          <>
            <EditColorIcon />
            <Text size="xl" ml="sm" fw={500}>
              Change Shopping Status
            </Text>
          </>
        </Flex>
      }
    >
      <Stack>
        <Text>Set shopping status as:</Text>
        <Select
          defaultValue={isActive ? 'Active' : 'Inactive'}
          data={[
            { value: 'Active', label: 'Active' },
            { value: 'Inactive', label: 'Inactive' },
          ]}
          onChange={(value) => {
            setIsActive(value === 'Active');
          }}
          data-testid="period-active-select"
        />
      </Stack>

      <Space h="xl" />

      <Center mt="md">
        <Button
          onClick={async () => {
            await changeOnboardingStatus();
          }}
          size="lg"
        >
          Save
        </Button>
      </Center>
      {resultErrors && resultErrors?.length > 0 && (
        <Grid gutter="xl">
          <Grid.Col>
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                {
                  accessor: 'value',
                  title: 'Employee name',
                },
                { accessor: 'error', title: 'Message' },
              ]}
              records={resultErrors}
              idAccessor="value"
            />
          </Grid.Col>
        </Grid>
      )}
    </Modal>
  );
};
