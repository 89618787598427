import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import { Card, Stack, Title } from '@zorro/zorro-ui-design';

import { EmployeePaymentDetails } from './EmployeePaymentDetails';
import { EmployeePaymentMethodDetails } from './PaymentMethod/EmployeePaymentMethodDetails';
import { ManagePaymentMethodStatus } from './PaymentMethod/ManagePaymentMethodStatus';
import { PaymentAmountDistribution } from './PaymentMethod/PaymentAmountDistribution';
import { PaymentMethodType } from './PaymentMethod/PaymentMethodType';
import { EmployeePaymentStatus } from './PaymentStatus/EmployeePaymentStatus';
import { ManagePaymentStatus } from './PaymentStatus/ManagePaymentStatus';
import { PAYMENT_SECTION_WIDTH } from './paymentsDesign.consts';

type PaymentsTabContentProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId?: OnboardingPeriodDto['id'];
  isManagementEnabled?: boolean;
  isManagedByOperations?: boolean;
};

const PaymentsTabContent = ({
  employeeId,
  onboardingPeriodId,
  isManagementEnabled = false,
  isManagedByOperations = false,
}: PaymentsTabContentProps) => {
  if (onboardingPeriodId) {
    return (
      <Stack gap="xl">
        <Stack gap="sm">
          <Title order={2} size="sm">
            Payment method
          </Title>
          <Card maw={PAYMENT_SECTION_WIDTH}>
            <Stack>
              <PaymentMethodType
                employeeId={employeeId}
                onboardingPeriodId={onboardingPeriodId}
              />
              <Stack>
                <PaymentAmountDistribution
                  employeeId={employeeId}
                  onboardingPeriodId={onboardingPeriodId}
                />
                <EmployeePaymentMethodDetails
                  employeeId={employeeId}
                  onboardingPeriodId={onboardingPeriodId}
                  isEmployeePage
                />
              </Stack>
              {isManagementEnabled && isManagedByOperations && (
                <ManagePaymentMethodStatus
                  employeeId={employeeId}
                  onboardingPeriodId={onboardingPeriodId}
                />
              )}
            </Stack>
          </Card>
        </Stack>
        <Stack gap="sm">
          <Title order={2} size="sm">
            Payment status
          </Title>
          <EmployeePaymentStatus
            employeeId={employeeId}
            onboardingPeriodId={onboardingPeriodId}
          >
            {isManagementEnabled && (
              <ManagePaymentStatus
                employeeId={employeeId}
                onboardingPeriodId={onboardingPeriodId}
              />
            )}
          </EmployeePaymentStatus>
        </Stack>
      </Stack>
    );
  }

  return (
    <EmployeePaymentDetails
      employeeId={employeeId}
      onboardingPeriodId={onboardingPeriodId}
    />
  );
};

export { PaymentsTabContent };
