import {
  AppShell,
  AppShellMainProps,
  AppShellNavbarProps,
  AppShellProps,
} from '@mantine/core';
import { useMobileDetector } from '@zorro/shared/utils';
import {
  HEADER_HEIGHT,
  IMPERSONATION_INDICATOR_HEIGHT,
  NAVBAR_VERTICAL_OFFSET,
  NAVBAR_WIDTH_EXPANDED,
} from '@zorro/types';
import { ReactElement, ReactNode } from 'react';

import { ZorroBox } from '../Box';
import { brand } from '../theme';
import classNames from './AppShell.module.scss';
import { ImpersonationIndicator } from './ImpersonationIndicator';

export interface IAppShellProps extends AppShellProps {
  children: ReactNode;
  headerComponent?: ReactElement;
  navbarComponent?: ReactElement;
  navbarProps?: AppShellNavbarProps;
  mainProps?: AppShellMainProps;
  hasNavbarBorder?: boolean;
  impersonatedEmployeeFullName?: string | null;
}

export function ZorroAppShell({
  children,
  headerComponent,
  navbarComponent,
  navbarProps,
  navbar,
  mainProps,
  hasNavbarBorder = true,
  impersonatedEmployeeFullName,
  ...props
}: IAppShellProps) {
  const { isMobile } = useMobileDetector();

  return (
    <AppShell
      classNames={classNames}
      padding={
        impersonatedEmployeeFullName
          ? HEADER_HEIGHT + IMPERSONATION_INDICATOR_HEIGHT
          : HEADER_HEIGHT
      }
      navbar={{
        width: { sm: NAVBAR_WIDTH_EXPANDED },
        collapsed: { mobile: true, desktop: navbarComponent === undefined },
        breakpoint: 'sm',
        ...navbar,
      }}
      {...props}
    >
      {headerComponent && (
        <AppShell.Header
          data-testid="header"
          h={
            impersonatedEmployeeFullName
              ? HEADER_HEIGHT + IMPERSONATION_INDICATOR_HEIGHT
              : HEADER_HEIGHT
          }
          zIndex={200}
          style={(theme) => ({
            borderBottom: `1px solid ${theme.colors.zorroGray[6]}`,
          })}
        >
          {impersonatedEmployeeFullName && (
            <ImpersonationIndicator
              impersonatedEmployeeFullName={impersonatedEmployeeFullName}
            />
          )}
          <ZorroBox
            px="md"
            py={
              impersonatedEmployeeFullName
                ? IMPERSONATION_INDICATOR_HEIGHT + 12
                : 'sm'
            }
          >
            {headerComponent}
          </ZorroBox>
        </AppShell.Header>
      )}
      <AppShell.Navbar
        data-testid="navbar"
        pt={
          impersonatedEmployeeFullName
            ? NAVBAR_VERTICAL_OFFSET + IMPERSONATION_INDICATOR_HEIGHT
            : NAVBAR_VERTICAL_OFFSET
        }
        bg={brand.zorroGray50}
        miw={80}
        style={(theme) => ({
          borderRight: hasNavbarBorder
            ? `1px solid ${theme.colors.zorroGray[6]}`
            : 'unset',
        })}
        h="100%"
        {...navbarProps}
      >
        {navbarComponent}
      </AppShell.Navbar>
      <AppShell.Main px={isMobile ? 'sm' : undefined} {...mainProps}>
        {children}
      </AppShell.Main>
    </AppShell>
  );
}
