import { Badge, brand } from '@zorro/zorro-ui-design';

const NotAvailableBadge = () => {
  return (
    <Badge size="md" variant="transparent" c="black" bg={brand.zorroFire300}>
      Not available
    </Badge>
  );
};

export { NotAvailableBadge };
