import {
  Button,
  Flex,
  Grid,
  Modal,
  Space,
  Stack,
  Table,
  Text,
} from '@zorro/zorro-ui-design';

interface SimpleActionModalProps {
  title: string;
  titleIcon?: React.ReactNode;
  description: string;
  actionName: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  errors?: Record<string, string>[];
}

export const SimpleActionModal = ({
  title,
  titleIcon,
  description,
  actionName,
  isOpen,
  onClose,
  onSubmit,
  buttonTextColor,
  buttonBackgroundColor,
  errors = [],
}: SimpleActionModalProps) => {
  return (
    <Modal
      size="lg"
      opened={isOpen}
      onClose={onClose}
      title={
        <>
          <Space h="30px" />
          <Flex align="center">
            <>
              {titleIcon}
              <Text size="24px" ml="sm" fw={600}>
                {title}
              </Text>
            </>
          </Flex>
        </>
      }
      showSeparator={false}
    >
      <Stack>
        <Space h="4px" />
        <Text size="lg" w="430px">
          {description}
        </Text>
        <Space h="xl" />

        <Flex justify="flex-end" gap="sm">
          <Button onClick={onClose} color="black" variant="subtle" size="md">
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            size="md"
            bg={buttonBackgroundColor}
            c={buttonTextColor}
            variant="secondary"
          >
            {actionName}
          </Button>
        </Flex>
      </Stack>

      {errors.length > 0 && (
        <Grid gutter="xl">
          <Grid.Col>
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                {
                  accessor: 'value',
                  title: 'Employee name',
                },
                { accessor: 'error', title: 'Message' },
              ]}
              records={errors}
              idAccessor="value"
            />
          </Grid.Col>
        </Grid>
      )}
    </Modal>
  );
};
