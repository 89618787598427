import { EmployeeDto, OnboardingPeriodDto } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Flex,
  Group,
  Loader,
  Switch,
  Text,
  Title,
} from '@zorro/zorro-ui-design';
import { ChangeEvent, useState } from 'react';

import { NoPaymentMethod } from './NoPaymentMethod';
import { PaymentMethodCreditCard } from './PaymentMethodCreditCard';
import { PaymentMethodDirectACH } from './PaymentMethodDirectAch';
import { PaymentMethodNotice } from './PaymentMethodNotice';
import {
  PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH,
  PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH,
} from './paymentsDesign.consts';

type EmployeePaymentDetailsProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId?: OnboardingPeriodDto['id'];
  isElena?: boolean;
};

const EmployeePaymentDetails = ({
  employeeId,
  onboardingPeriodId,
  isElena = false,
}: EmployeePaymentDetailsProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const { data, isLoading } = useMonolithQuery({
    method: 'paymentsControllerEmployeePaymentDetails',
    params: [employeeId, { onboardingPeriodId }],
  });

  if (isLoading) {
    return (
      <Flex justify="center" align="center" direction="column" gap="xs">
        <Loader />
        <Text fw={500}>Loading employee payment details...</Text>
      </Flex>
    );
  }

  if (!data) {
    return <NoPaymentMethod />;
  }

  const handleShowDetailsSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    setShowDetails(event.currentTarget.checked);
  };

  if (data.creditCard) {
    const { billingAddress } = data.creditCard;

    return (
      <>
        <Group
          align="center"
          justify="space-between"
          mb="sm"
          style={{ width: PAYMENT_METHOD_CREDIT_CARD_CONTAINER_WIDTH }}
        >
          <Title order={2} size="1rem">
            {isElena ? 'Your card' : 'Card details'}
          </Title>
          <Switch
            label="Show card details"
            size="xs"
            checked={showDetails}
            onChange={handleShowDetailsSwitch}
          />
        </Group>
        <PaymentMethodCreditCard {...data.creditCard} isMasked={!showDetails} />
        <Group mt="xs" mb="md" gap="xs">
          <Text size="sm" fw={500}>
            Billing address:
          </Text>
          <Text size="sm">{billingAddress}</Text>
        </Group>
        {isElena && <PaymentMethodNotice />}
      </>
    );
  }

  if (data.directACH) {
    return (
      <>
        <Group
          align="center"
          mb="sm"
          justify="space-between"
          style={{ width: PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH }}
        >
          <Title order={2} size="1rem">
            {isElena ? 'Your payment account' : 'Payment account details'}
          </Title>
          <Switch
            label="Show account details"
            size="xs"
            checked={showDetails}
            onChange={handleShowDetailsSwitch}
          />
        </Group>
        <PaymentMethodDirectACH {...data.directACH} isMasked={!showDetails} />
        {isElena && <PaymentMethodNotice />}
      </>
    );
  }

  return null;
};

export { EmployeePaymentDetails };
