import { IconPrinter } from '@tabler/icons-react';
import { ActionIcon, Icon } from '@zorro/zorro-ui-design';

export const PrintButton = () => {
  return (
    <ActionIcon bg="zorroIris.2" size="lg" variant="transparent">
      <Icon icon={IconPrinter} />
    </ActionIcon>
  );
};
