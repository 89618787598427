import { useAuth } from '@frontegg/nextjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { AllAgenciesDto } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  callEndpoint,
  logger,
  responseErrorToString,
  showErrorNotification,
  useForm,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import { UseFormReturn } from 'react-hook-form';

import {
  ZorroSetupFormFields,
  getZorroSetupSchemaObject,
} from './ZorroSetupUtils';

type UseZorroSetupForm = (props?: {
  employerId?: string;
  shouldShowCoverageStartDate?: boolean;
  isFinalizationMode?: boolean;
}) => {
  zorroSetupForm: UseFormReturn<ZorroSetupFormFields>;
  onSubmit: () => Promise<boolean>;
  allAgencies: AllAgenciesDto;
};

export const useZorroSetupForm: UseZorroSetupForm = ({
  employerId,
  shouldShowCoverageStartDate,
  isFinalizationMode,
} = {}) => {
  const { isAgent } = useRoles();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { data: employer } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: employerId && [employerId],
  });

  const {
    data: allAgencies = {
      zorroPartners: [],
      producers: [],
      enrollmentTeams: [],
    },
  } = useMonolithQuery(
    isAgent
      ? {
          method: 'agenciesControllerFindAssignableAgencies',
          params: user && [user.tenantId],
        }
      : {
          method: 'agenciesControllerFindAll',
          params: [],
        }
  );

  const zorroSetupForm = useForm<ZorroSetupFormFields>({
    mode: 'onBlur',
    resolver: yupResolver(
      getZorroSetupSchemaObject(isFinalizationMode, shouldShowCoverageStartDate)
    ),
    defaultValues: {
      producerId: employer?.producerId || null,
      enrollmentTeamIds: employer?.enrollmentTeamIds || [],
      zorroPartnerId: isAgent
        ? employer?.zorroPartnerId || user?.tenantId || ''
        : employer?.zorroPartnerId || '',
      producerAgentId: employer?.producerAgentId || null,
      ccEmailList: employer?.ccEmailList || [],
      crmRecordId: employer?.crmRecordId
        ? Number.parseInt(employer?.crmRecordId)
        : null,
      prospectCoverageStartDate: employer?.prospectCoverageStartDate
        ? parseDateISO(employer.prospectCoverageStartDate).toDate()
        : undefined,
    },
  });

  return {
    allAgencies,
    zorroSetupForm,
    onSubmit: async () => {
      const data = zorroSetupForm.getValues();

      try {
        if (employerId) {
          await callEndpoint({
            method: 'employersControllerUpdate',
            params: [
              employerId,
              {
                producerId: data.producerId,
                enrollmentTeamIds: data.enrollmentTeamIds,
                zorroPartnerId: data.zorroPartnerId,
                crmRecordId: data.crmRecordId?.toString() || null,
                ccEmailList: data.ccEmailList || [],
                isProspect: false,
                producerAgentId: data.producerAgentId,
                prospectCoverageStartDate: data.prospectCoverageStartDate
                  ? formatDateISO(data.prospectCoverageStartDate)
                  : undefined,
              },
            ],
          });
        }

        await queryClient.invalidateQueries();
        return true;
      } catch (error) {
        logger.error(error);
        showErrorNotification({ message: responseErrorToString(error) });
      }

      return false;
    },
  };
};
