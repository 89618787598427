/* ========== showSuccessNotification Murrieta ========== */

export const EMPLOYEE_HEALTH_PLAN_UPDATE_SUCCESS_MESSAGE =
  'Employee health plan updated successfully!';

export const getAgencyCreatedMessage = (agencyName: string) => {
  return `New agency "${agencyName}" was created.`;
};

export const getUserCreatedMessage = (firstName: string, lastName: string) => {
  return `New user ${firstName} ${lastName} was created.`;
};

export const USER_UPDATE_SUCCESS_MESSAGE = 'User updated.';

export const EMPLOYEE_INFO_UPDATE_SUCCESS_MESSAGE =
  'Employee info updated successfully!';

export const COMPANY_EMAIL_UPDATE_SUCCESS_MESSAGE =
  'Company email updated successfully!';

export const PERSONAL_DETAILS_UPDATE_SUCCESS_MESSAGE =
  'Personal details updated successfully!';

export const FAMILY_MEMBER_ADD_SUCCESS_MESSAGE =
  'Family member was added successfully!';

export const FAMILY_MEMBER_REMOVE_SUCCESS_MESSAGE =
  'Family member was removed successfully!';

export const BENEFIT_RESET_SUCCESS_MESSAGE = 'Benefit reset successfully!';

export const QUOTE_CREATION_SUCCESS_MESSAGE = 'Quote created successfully!';

export const AGENCY_UPDATE_SUCCESS_MESSAGE = 'Agency updated successfully!';

export const AGENCY_DELETE_SUCCESS_MESSAGE = 'Agency deleted successfully!';

export const QUOTE_DELETE_SUCCESS_MESSAGE = 'Quote deleted successfully!';

export const EMPLOYEE_ADD_SUCCESS_MESSAGE = 'Employee added successfully!';

export const SAVED_SUCCESSFULLY_MESSAGE = 'Saved successfully!';

export const SET_EMPLOYER_TO_PROSPECT_SUCCESS_MESSAGE =
  'Set employer to prospect';

export const EMPLOYER_LOGO_WAS_UPLOADED_MESSAGE = 'Employer logo was uploaded!';

export const EMAILS_SENT_SUCCESSFULLY_MESSAGE = 'Emails sent successfully!';
