import { Anchor, AnchorProps } from '@mantine/core';
import {
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  forwardRef,
} from 'react';

import classNames from './Anchor.module.scss';

export type IAnchorProps = AnchorProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  download?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: string;
};

export const ZorroAnchor = forwardRef<HTMLAnchorElement, IAnchorProps>(
  // eslint-disable-next-line id-length
  ({ component = 'a', c = 'zorroIris.9', ...props }, ref) => {
    return (
      <Anchor
        ref={ref}
        component={component}
        classNames={classNames}
        c={c}
        {...props}
      />
    );
  }
);

ZorroAnchor.displayName = 'ZorroAnchor';
