import {
  Badge,
  Card,
  CardProps,
  Divider,
  Stack,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

interface CardWithFormatProps extends Omit<CardProps, 'children'> {
  header?: ReactNode;
  content: ReactNode;
  footer?: ReactNode;
  shouldShowTopPlanBadge?: boolean;
  isSelected?: boolean;
}

export const CardWithFormat = ({
  header,
  content,
  footer,
  isSelected,
  shouldShowTopPlanBadge,
  ...props
}: CardWithFormatProps) => {
  return (
    <Stack gap={0} align="flex-start">
      {shouldShowTopPlanBadge && (
        <Badge
          style={(theme) => ({
            backgroundColor: isSelected
              ? theme.colors.zorroIris[9]
              : theme.colors.zorroIris[1],
            borderColor: isSelected
              ? theme.colors.zorroIris[9]
              : theme.colors.zorroIris[4],
            color: isSelected
              ? theme.colors.zorroWhite
              : theme.colors.zorroIris[9],
            borderBottom: 0,
            fontWeight: 500,
          })}
          radius="8px 8px 0px 0px"
        >
          Zorro Top Plan
        </Badge>
      )}
      <Card
        style={(theme) => ({
          width: '100%',
          boxShadow: isSelected
            ? `inset 0 0 0 2px ${theme.colors.zorroIris[9]}`
            : `inset 0 0 0 1px ${theme.colors.zorroGray[7]}`,
          borderStartStartRadius: shouldShowTopPlanBadge ? 0 : undefined,
        })}
        p={0}
        withBorder={false}
        data-testid="card-with-format"
        {...props}
      >
        {header && (
          <>
            {header}
            <Divider
              color={isSelected ? brand.zorroIris900 : brand.zorroGray800}
            />
          </>
        )}

        {content}

        <Divider color={isSelected ? brand.zorroIris400 : brand.zorroGray800} />

        {footer}
      </Card>
    </Stack>
  );
};
