import { yupResolver } from '@hookform/resolvers/yup';
import { OnboardingType, SubmissionType } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  UNEXPECTED_ERROR_MESSAGE,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';
import { Box, Drawer, Table, Text } from '@zorro/zorro-ui-design';

import { useBatchCallEndpoint } from '../../hooks';
import { ChangeEnrollmentForm } from './ChangeEnrollmentForm';
import {
  ChangeEnrollmentsFormFields,
  EditEnrollmentDrawerProps,
  FullChangeEnrollmentSchema,
} from './ChangeEnrollmentUtils';

export const EditEnrollmentsDrawer = ({
  employerId,
  selectedEmployees,
  isOpen,
  closeModal,
}: EditEnrollmentDrawerProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;

  const { data: allOpenEnrollmentPeriods = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
    enabled: isOpen,
  });

  const { data: associatedOnboardingPeriodsSingleEmployee } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isOpen && isSingleEmployee,
  });

  const associatedOtherOnboardingPeriodsSingleEmployee =
    associatedOnboardingPeriodsSingleEmployee?.filter(
      (onboardingPeriod) =>
        onboardingPeriod.id !== selectedEmployees[0].onboardingPeriodId
    );

  const { data: singleEmployeeDto } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isOpen && isSingleEmployee,
  });

  const {
    executeBatchCall: updateEnrollments,
    errors,
    setErrors,
  } = useBatchCallEndpoint({
    singularItemName: 'enrollment record',
    action: 'updated',
    methodName: 'onboardingPeriodsControllerUpdateByAdmin',
  });

  const singleEmployeeOnboardingPeriodForDefaultValues =
    associatedOnboardingPeriodsSingleEmployee
      ? associatedOnboardingPeriodsSingleEmployee.find(
          (period) => period.id === selectedEmployees[0].onboardingPeriodId
        )
      : undefined;

  const editModeSingleEmployeeDefaultValues:
    | ChangeEnrollmentsFormFields
    | undefined = singleEmployeeOnboardingPeriodForDefaultValues
    ? {
        planYear:
          singleEmployeeOnboardingPeriodForDefaultValues.enrollmentPeriodId,
        enrollmentType:
          singleEmployeeOnboardingPeriodForDefaultValues.isSpecialEnrollment
            ? OnboardingType.SPECIAL
            : OnboardingType.OPEN_ENROLLMENT,
        electionWindow: [
          parseDateISO(
            singleEmployeeOnboardingPeriodForDefaultValues.onboardingFrom
          ).toDate(),
          parseDateISO(
            singleEmployeeOnboardingPeriodForDefaultValues.onboardingUntil
          ).toDate(),
        ],
        expectedStartOfCoverage: parseDateISO(
          singleEmployeeOnboardingPeriodForDefaultValues.targetEnrollmentDate
        ).toDate(),
      }
    : undefined;

  const form = useForm<ChangeEnrollmentsFormFields>({
    mode: 'onChange',
    resolver: yupResolver(FullChangeEnrollmentSchema),
    defaultValues: isSingleEmployee
      ? editModeSingleEmployeeDefaultValues
      : undefined, // no default values for bulk edit mode
  });

  const submitEditEnrollment = async (data: ChangeEnrollmentsFormFields) => {
    setErrors([]);
    const {
      planYear: enrollmentPeriodId,
      enrollmentType,
      electionWindow,
      expectedStartOfCoverage,
    } = data;
    const [electionFrom, electionUntil] = electionWindow || [];

    if (!electionFrom || !electionUntil || !expectedStartOfCoverage) {
      showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
      return;
    }

    const enrollmentData = {
      enrollmentPeriodId,
      isSpecialEnrollment: enrollmentType === OnboardingType.SPECIAL,
      onboardingFrom: formatDateISO(electionFrom),
      onboardingUntil: formatDateISO(electionUntil),
      expectedStartOfCoverage: formatDateISO(expectedStartOfCoverage),
    };

    const { errors } = await updateEnrollments(
      selectedEmployees.map((employee) => ({
        key: `${employee.firstName} ${employee.lastName}`,
        params: [
          employee.onboardingPeriodId,
          {
            ...enrollmentData,
            submissionType: SubmissionType.BY_OPERATOR,
          },
        ],
      }))
    );

    if (errors.length === 0) {
      closeModal();
    }
  };

  return (
    <Drawer
      opened={isOpen}
      title="Edit enrollment record"
      onClose={() => {
        closeModal();
        form.reset(undefined, { keepDefaultValues: false });
        setErrors([]);
      }}
    >
      <>
        <ChangeEnrollmentForm
          form={form}
          onSubmit={submitEditEnrollment}
          allOpenEnrollmentPeriods={allOpenEnrollmentPeriods}
          singleEmployeeDto={singleEmployeeDto}
          associatedOtherOnboardingPeriodsSingleEmployee={
            associatedOtherOnboardingPeriodsSingleEmployee
          }
        />
        {!isSingleEmployee && errors.length > 0 && (
          <Box mt="xl">
            <Text c="zorroFire.7" size="md" fw="bold">
              Errors
            </Text>
            <Table
              columns={[
                { accessor: 'key', title: 'Employee Name' },
                { accessor: 'error', title: 'Message' },
              ]}
              records={errors}
              idAccessor="key"
            />
          </Box>
        )}
      </>
    </Drawer>
  );
};
