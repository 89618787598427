import { IconRepeat } from '@tabler/icons-react';
import { brand } from '@zorro/zorro-ui-design';

import { useBatchCallEndpoint } from '../hooks';
import { SimpleActionModal } from './SimpleActionModal';

type SelectedEmployees = {
  onboardingPeriodId: string;
  firstName: string;
  lastName: string;
};

interface ResetElectionModalProps {
  selectedEmployees: SelectedEmployees[];
  isOpen: boolean;
  closeModal: () => void;
}

export const ResetElectionModal = ({
  selectedEmployees,
  isOpen,
  closeModal,
}: ResetElectionModalProps) => {
  const {
    executeBatchCall: batchDeleteMajorMedicalPlans,
    errors,
    setErrors,
  } = useBatchCallEndpoint({
    singularItemName: 'election',
    action: 'reset',
    methodName: 'majorMedicalControllerDeleteMajorMedicalPlan',
  });
  return (
    <SimpleActionModal
      title="Reset election?"
      titleIcon={
        <IconRepeat
          size={32}
          style={{
            position: 'relative',
            borderRadius: '4px',
            padding: '6px',
            backgroundColor: brand.zorroFire500,
          }}
        />
      }
      description="All current plan elections and enrollment data will be deleted"
      actionName="Reset Election"
      isOpen={isOpen}
      onClose={() => {
        setErrors([]);
        closeModal();
      }}
      buttonTextColor={brand.zorroWhite}
      buttonBackgroundColor={brand.zorroFire700}
      errors={errors}
      onSubmit={async () => {
        const { errors } = await batchDeleteMajorMedicalPlans(
          selectedEmployees.map((employee) => ({
            params: [employee.onboardingPeriodId],
            key: `${employee.firstName} ${employee.lastName}`,
          }))
        );
        if (errors.length === 0) {
          closeModal();
        }
      }}
    />
  );
};
