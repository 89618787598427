import { MantineStyleProp } from '@mantine/core';
import { Box, CheckmarkCircle, IBoxProps } from '@zorro/zorro-ui-design';
import {
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';

type CheckmarkBoxWrapperProps = IBoxProps & {
  children: ReactNode;
  isFilledOut: boolean;
  handleClick?: MouseEventHandler<HTMLDivElement>;
  handleKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  tabIndex?: number;
  style?: MantineStyleProp;
  isOpen: boolean;
  hasErrors?: boolean;
};

export function CheckmarkBoxWrapper({
  children,
  isFilledOut,
  handleClick,
  handleKeyDown,
  tabIndex,
  style,
  isOpen,
  hasErrors,
  ...props
}: CheckmarkBoxWrapperProps) {
  return (
    <Box
      px={{ base: 24, sm: 48 }}
      py={isOpen ? 32 : 18}
      mx="auto"
      style={(theme) => ({
        position: 'relative',
        borderRadius: theme.radius.md,
        backgroundColor: theme.colors.zorroWhite,
        boxShadow: hasErrors
          ? `inset 0 0 0 1px ${theme.colors.zorroFire[7]}`
          : `inset 0 0 0 1px ${theme.colors.zorroGray[7]}`,
        ...(typeof style === 'function' ? style(theme) : style || {}),
      })}
      onClick={handleClick}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          handleKeyDown?.(event);
        }
      }}
      tabIndex={tabIndex}
      data-testid="checkmark-box-wrapper"
      {...props}
    >
      {isFilledOut && <CheckmarkCircle />}
      {children}
    </Box>
  );
}
