import { useMonolithQuery } from '@zorro/shared/utils';
import { Card, Group, Stack, brand } from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { ChangeElectionWindowDrawer } from '../EmployeeActions/Enrollment/ChangeElectionWindowDrawer';
import { EnrollmentStatusBadge } from '../EnrollmentStatus';
import { FullPageLoader } from '../FullPageLoader';
import {
  ResetElectionModal,
  UndoApplicationModal,
  UndoEnrollmentConfirmationModal,
} from '../SimpleActionModal';
import { WaiveCoverageDrawer } from '../WaiveCoverageDrawer';
import { EnrollmentTabButtons } from './EnrollmentTabButtons';
import { EnrollmentTabContent } from './EnrollmentTabContent/EnrollmentTabContent';
import { EnrollmentTabLabels } from './EnrollmentTabLabels';
import {
  EnrollmentTabAction,
  EnrollmentTabMenuActions,
} from './EnrollmentTabMenuActions';
import { EnrollmentTabTitle } from './EnrollmentTabTitle';

type Props = {
  employerId: string;
  employeeId: string;
  onboardingPeriodId: string;
};

export const EnrollmentTab = ({
  employerId,
  employeeId,
  onboardingPeriodId,
}: Props) => {
  const [enrollmentTabAction, setEnrollmentTabAction] =
    useState<EnrollmentTabAction>();
  const { data: employee } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });
  const { data: onboardingPeriod } = useMonolithQuery({
    method: 'onboardingPeriodReportsControllerGenerateOnboardingPeriodReport',
    params: [onboardingPeriodId],
  });

  if (!onboardingPeriod || !employee) {
    return <FullPageLoader />;
  }

  const { enrollmentStatus } = onboardingPeriod;

  return (
    <Stack gap="1.5rem" maw={900}>
      <Card p="20px 40px 40px 40px" style={{ borderColor: brand.zorroGray200 }}>
        <Stack>
          <Group justify="space-between">
            <EnrollmentStatusBadge enrollmentStatus={enrollmentStatus} />
            <EnrollmentTabMenuActions
              setEnrollmentTabAction={setEnrollmentTabAction}
              onboardingPeriodId={onboardingPeriodId}
              enrollmentStatus={enrollmentStatus}
              employerId={employerId}
              employeeId={employeeId}
            />
          </Group>
          <EnrollmentTabTitle
            enrollmentStatus={enrollmentStatus}
            employeeId={employeeId}
          />
          <EnrollmentTabLabels
            majorMedicalBenefit={onboardingPeriod.majorMedicalBenefit}
            onboardingPeriod={onboardingPeriod}
            enrollmentStatus={enrollmentStatus}
          />
          <EnrollmentTabContent
            onboardingPeriodId={onboardingPeriodId}
            enrollmentStatus={enrollmentStatus}
            employerId={employerId}
            employee={employee}
          />
        </Stack>
      </Card>
      <EnrollmentTabButtons
        onWaiveCoverageClick={() =>
          setEnrollmentTabAction(EnrollmentTabAction.WAIVE_COVERAGE)
        }
        onboardingPeriodId={onboardingPeriodId}
        enrollmentStatus={enrollmentStatus}
        employerId={employerId}
        employeeId={employeeId}
      />

      <ChangeElectionWindowDrawer
        planYearId={onboardingPeriod.enrollmentPeriodId}
        selectedEmployees={[
          {
            employeeId,
            onboardingPeriodId,
            firstName: employee.firstName,
            lastName: employee.lastName,
          },
        ]}
        isOpen={
          enrollmentTabAction === EnrollmentTabAction.CHANGE_ELECTION_WINDOW
        }
        closeModal={() => setEnrollmentTabAction(undefined)}
      />

      <WaiveCoverageDrawer
        isOpen={enrollmentTabAction === EnrollmentTabAction.WAIVE_COVERAGE}
        onboardingPeriodId={onboardingPeriodId}
        employeeId={employeeId}
        onClose={() => setEnrollmentTabAction(undefined)}
      />
      <ResetElectionModal
        selectedEmployees={[
          {
            onboardingPeriodId: onboardingPeriod.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
          },
        ]}
        isOpen={enrollmentTabAction === EnrollmentTabAction.RESET_ELECTION}
        closeModal={() => setEnrollmentTabAction(undefined)}
      />
      {onboardingPeriod?.majorMedicalBenefit && (
        <UndoEnrollmentConfirmationModal
          benefitEnrollmentId={onboardingPeriod.majorMedicalBenefit.id}
          isOpen={
            enrollmentTabAction ===
            EnrollmentTabAction.UNDO_ENROLLMENT_CONFIRMATION
          }
          closeModal={() => setEnrollmentTabAction(undefined)}
        />
      )}

      {onboardingPeriod?.majorMedicalBenefit && (
        <UndoApplicationModal
          benefitEnrollmentId={onboardingPeriod.majorMedicalBenefit.id}
          isOpen={enrollmentTabAction === EnrollmentTabAction.UNDO_APPLICATION}
          closeModal={() => setEnrollmentTabAction(undefined)}
        />
      )}
    </Stack>
  );
};
