import { useQueryClient } from '@tanstack/react-query';
import {
  MonolithMethodNames,
  MonolithMethods,
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  useDynamicRouter,
} from '@zorro/shared/utils';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import { useLoadingOverlay } from '../LoadingOverlayContext';

type BatchItem<M extends MonolithMethodNames> = {
  key: string;
  params: Parameters<MonolithMethods[M]>;
};

type UseBatchCallEndpointOptions<M extends MonolithMethodNames> = {
  methodName: M;
  singularItemName: string;
  action: string;
  batchSize?: number;
};

export const useBatchCallEndpoint = <M extends MonolithMethodNames>({
  methodName,
  singularItemName,
  action,
  batchSize = 5,
}: UseBatchCallEndpointOptions<M>) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();
  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  const executeBatchCall = async (batchItems: BatchItem<M>[]) => {
    try {
      startLoading();

      const batches = _chunk(batchItems, batchSize);

      let results: PromiseSettledResult<unknown>[] = [];
      for (const batch of batches) {
        const batchResults = await Promise.allSettled(
          batch.map((item) => {
            return callEndpoint({
              method: methodName,
              params: item.params,
            });
          })
        );

        results = [...results, ...batchResults];
      }

      await queryClient.invalidateQueries();
      reloadPage();
      stopLoading();

      await handleBatchNotifications(
        results,
        { singular: singularItemName },
        action
      );

      const resultsErrors = getErrorsFromPromises(
        results,
        batchItems.map(({ key }) => ({ key })),
        'key'
      );

      if (resultsErrors.length > 0) {
        setErrors(resultsErrors);
      }

      return { data: results, errors: resultsErrors };
    } catch (error) {
      stopLoading();
      throw error;
    }
  };

  return { executeBatchCall, errors, setErrors };
};
