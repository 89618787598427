import {
  Applicant,
  FamilyUnit,
  InsuredPeopleDto,
  OnboardingPeriodDto,
} from '@zorro/clients';
import { formatCurrencyEnUs } from '@zorro/shared/formatters';
import {
  calculateAgeForEnrollment,
  validationMessages,
} from '@zorro/shared/utils';
import { calculateFamilyUnit } from '@zorro/types';
import * as yup from 'yup';

export const medicalPlanItemSchema = yup.object({
  whoIsEnrolled: yup
    .array()
    .of(yup.string().required())
    .min(1, validationMessages.whoIsEnrolledRequired)
    .required(validationMessages.whoIsEnrolledRequired),
  planId: yup.string(),
  carrier: yup.string().required(validationMessages.carrierRequired),
  planName: yup.string().required(validationMessages.planNameRequired),
  premium: yup
    .number()
    .min(0, validationMessages.premiumPositive)
    .required(validationMessages.premiumRequired),
  isHsaEligible: yup.boolean(),
  maxOutOfPocket: yup.number(),
  benefitsSummaryUrl: yup.string(),
  deductible: yup.number(),
});

export type MedicalPlanItemFormFields = yup.InferType<
  typeof medicalPlanItemSchema
>;

export type MedicalPlanFormFields = {
  plans: MedicalPlanItemFormFields[];
};

export const medicalPlanFormSchema: yup.ObjectSchema<MedicalPlanFormFields> =
  yup.object({
    plans: yup.array().of(medicalPlanItemSchema).required(),
  });

export const formatExtendedPlanDetails = (
  plans: MedicalPlanItemFormFields[]
): string | undefined => {
  return plans.length > 1
    ? plans
        .map(
          ({ premium, whoIsEnrolled }) =>
            `[${formatCurrencyEnUs(premium)}, ${whoIsEnrolled.join(',')}]`
        )
        .join(' + ')
    : undefined;
};

export const getWhoIsEnrolled = ({
  employee,
  spouse,
  dependents,
}: InsuredPeopleDto): string[] => {
  const whoIsEnrolled: string[] = [];
  whoIsEnrolled.push(`${employee.firstName} ${employee.lastName}`);
  if (spouse) {
    whoIsEnrolled.push(`${spouse.firstName} ${spouse.lastName}`);
  }
  dependents.forEach((dependent) =>
    whoIsEnrolled.push(`${dependent.firstName} ${dependent.lastName}`)
  );

  return whoIsEnrolled;
};

export const getDefaultWhoIsEnrolled = (
  insured: InsuredPeopleDto,
  familyUnit: FamilyUnit = calculateFamilyUnit(insured)
): string[] => {
  const { employee, spouse, dependents } = insured;

  const employeeName = `${employee.firstName} ${employee.lastName}`;
  const spouseName = `${spouse?.firstName} ${spouse?.lastName}`;
  const dependentsNames = dependents.map(
    (dependent) => `${dependent.firstName} ${dependent.lastName}`
  );

  switch (familyUnit) {
    case FamilyUnit.EMPLOYEE_ONLY: {
      return [employeeName];
    }
    case FamilyUnit.EMPLOYEE_SPOUSE: {
      return [employeeName, spouseName];
    }
    case FamilyUnit.EMPLOYEE_CHILD: {
      return [employeeName, ...dependentsNames];
    }
    case FamilyUnit.FAMILY: {
      return [employeeName, spouseName, ...dependentsNames];
    }
    default: {
      return [employeeName];
    }
  }
};

export const getApplicantsFromWhoIsEnrolled = (
  insured: InsuredPeopleDto,
  whoIsEnrolled: string[],
  targetEnrollmentDate: OnboardingPeriodDto['targetEnrollmentDate']
): Applicant[] => {
  const applicants: Applicant[] = [];
  if (whoIsEnrolled.length === 0) return applicants;

  const employeeSelected = whoIsEnrolled.find(
    (name) =>
      name === `${insured.employee?.firstName} ${insured.employee?.lastName}`
  );

  if (employeeSelected) {
    applicants.push({
      age: calculateAgeForEnrollment(
        insured.employee.dateOfBirth,
        targetEnrollmentDate
      ),
      isSmoker: insured.employee.isSmoker,
      isChild: false,
    });
  }

  const spouseSelected = whoIsEnrolled.find(
    (name) =>
      name === `${insured.spouse?.firstName} ${insured.spouse?.lastName}`
  );

  if (spouseSelected && insured.spouse) {
    applicants.push({
      age: calculateAgeForEnrollment(
        insured.spouse.dateOfBirth,
        targetEnrollmentDate
      ),
      isSmoker: insured.spouse.isSmoker,
      isChild: false,
    });
  }

  insured.dependents.forEach((dependent) => {
    const foundDependent = whoIsEnrolled.find(
      (name) => name === `${dependent.firstName} ${dependent.lastName}`
    );
    if (foundDependent) {
      const age = calculateAgeForEnrollment(
        dependent.dateOfBirth,
        targetEnrollmentDate
      );
      applicants.push({
        age,
        isSmoker: dependent.isSmoker,
        isChild: age < 26,
      });
    }
  });

  return applicants;
};

export const getFamilyUnitFromWhoIsEnrolled = (
  insured: InsuredPeopleDto,
  whoIsEnrolled: Set<string>
): FamilyUnit => {
  const hasSpouse = whoIsEnrolled.has(
    `${insured.spouse?.firstName} ${insured.spouse?.lastName}`
  );

  let hasDependents = false;
  insured.dependents.forEach(({ firstName, lastName }) => {
    hasDependents = whoIsEnrolled.has(`${firstName} ${lastName}`);
  });

  if (!(hasSpouse || hasDependents)) {
    return FamilyUnit.EMPLOYEE_ONLY;
  }
  if (!hasSpouse && hasDependents) {
    return FamilyUnit.EMPLOYEE_CHILD;
  }
  if (hasSpouse && !hasDependents) {
    return FamilyUnit.EMPLOYEE_SPOUSE;
  }
  return FamilyUnit.FAMILY;
};

export const getDefaultMedicalPlanFormFields = (
  whoIsEnrolled: string[],
  planId?: string | null,
  planName?: string | null,
  carrier?: string,
  premium?: number
): MedicalPlanItemFormFields => {
  return {
    whoIsEnrolled,
    planId: planId || '',
    planName: planName || '',
    carrier: carrier || '',
    premium: premium || 0,
  };
};
