import { IconSearch } from '@tabler/icons-react';
import {
  Flex,
  Group,
  ITableProps,
  NewTable,
  Space,
  Text,
  TextInput,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode } from 'react';

import { DownloadButton } from '../DownloadButton';

type SearchableDataTableProps = {
  componentsAfterSearch?: ReactNode;
  componentsAfterDownload?: ReactNode;
  componentsBeforeDownload?: ReactNode;
  onDownload: () => void;
  searchValue: string;
  onSearchChange: (value: string) => void;
  isLoading?: boolean;
} & ITableProps;

export function SearchableDataTable({
  componentsAfterSearch,
  componentsAfterDownload,
  componentsBeforeDownload,
  searchValue = '',
  onSearchChange,
  onDownload,
  isLoading = false,
  ...rest
}: SearchableDataTableProps) {
  const selectedRecordsCount = rest?.selectedRecords?.length || 0;

  return (
    <>
      <Flex justify="space-between">
        <Group h="40px">
          <TextInput
            disabled={isLoading}
            leftSection={
              <IconSearch
                style={{
                  color: brand.zorroGray300,
                  width: '20px',
                  height: '20px',
                  marginLeft: '6px',
                }}
              />
            }
            styles={{
              input: {
                fontSize: 'var(--mantine-font-size-sm)',
                fontWeight: 400,
                borderColor: brand.zorroGray300,
              },
            }}
            placeholder="Search Employees"
            size="sm"
            w="400px"
            value={searchValue}
            onInput={(args) => {
              onSearchChange(args.currentTarget.value);
            }}
          />
          {componentsAfterSearch}
        </Group>
        <Group h="100%" gap="lg" justify="right" pos="relative">
          {selectedRecordsCount !== 0 && (
            <Text mr="1.5rem" c="zorroGray.7">
              {selectedRecordsCount} selected
            </Text>
          )}
          {componentsBeforeDownload}
          <DownloadButton onDownload={onDownload} isDisabled={isLoading} />
          {componentsAfterDownload}
        </Group>
      </Flex>

      <Space h="md" />

      <NewTable fetching={isLoading} {...rest} />
    </>
  );
}
