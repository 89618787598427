import { Badge, brand } from '@zorro/zorro-ui-design';

const InHandlingBadge = () => {
  return (
    <Badge size="md" variant="transparent" c="black" bg={brand.zorroIris100}>
      In-handling
    </Badge>
  );
};

export { InHandlingBadge };
