import { UserAndTenantDto } from '@zorro/clients';
import {
  EMAILS_SENT_SUCCESSFULLY_MESSAGE,
  callEndpoint,
  responseErrorToString,
  showErrorNotification,
  showSuccessNotification,
} from '@zorro/shared/utils';

// noinspection ES6PreferShortImport
import { useLoadingOverlay } from '../LoadingOverlayContext';

export function useSendAccountActivationEmails() {
  const { startLoading, stopLoading } = useLoadingOverlay();

  return {
    sendAccountActivationEmails: async (userIds: UserAndTenantDto[]) => {
      startLoading();
      try {
        await callEndpoint({
          method: 'usersControllerSendActivationEmails',
          params: [userIds],
        });

        showSuccessNotification({ message: EMAILS_SENT_SUCCESSFULLY_MESSAGE });
      } catch (error) {
        showErrorNotification({ message: responseErrorToString(error) });
      } finally {
        stopLoading();
      }
    },
  };
}
